import React, { FC, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { debounce } from 'lodash/fp';
import {
  useStableCallback,
  useWrapCallback,
} from '../../../hooks/use-stable-callback';
import BatchImportFile from '../../common/upload/batch-import-file';
import TableBatchDelete from '../../common/table/table-batch-delete';
import UseTable from '../../common/table/use-table';
import { modelPage, deleteModel } from '../../../service/car';
import { download } from '../../../service/download';

import { Button, Divider, Modal, message } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

interface Props {
  searchForm?: { [key: string]: any };
}

const ModelListTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      let params = { ...searchForm, ...listPageData };
      const { records, total } = await modelPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const downloadTemplate = useWrapCallback(async () => {
    setDownloadLoading(true);
    try {
      await download('/car-model/export', '车型.xlsx');
    } catch (err) {
    } finally {
      setDownloadLoading(false);
    }
  }, debounce(500));

  const addRow = useStableCallback(() => {
    history.push('/car/model/add');
  });

  const editRow = useStableCallback((record: any) => {
    history.push(`/car/model/edit/${record.id}`);
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除车型【${record.name}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch([record.id]);
      },
    });
  });

  const deleteBatchRow = useStableCallback((ids: number[]) => {
    if (!ids || !ids.length) return message.warning('请先选择需删除的车型');
    Modal.confirm({
      title: `确定删除选中的车型吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch(ids);
      },
    });
  });

  const deleteBatch = useStableCallback(async (ids: number[]) => {
    try {
      await deleteModel(ids);
      message.success('删除成功');
      setSelectedRowKeys([]);
      getList(1);
    } catch (err) {}
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const columns: ColumnsType = [
    {
      title: '车型名称',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => {
        return <Link to={`/car/model/detail/${record.id}`}>{text}</Link>;
      },
      fixed: 'left',
      width: 140,
    },
    {
      title: '车型分类',
      dataIndex: 'catalog',
      key: 'catalog',
      width: 200,
    },
    {
      title: '车型代码',
      dataIndex: 'code',
      key: 'code',
      width: 100,
    },
    {
      title: '品牌',
      dataIndex: 'brandName',
      key: 'brandName',
      width: 100,
    },
    {
      title: '车系',
      dataIndex: 'seriesName',
      key: 'seriesName',
      width: 100,
    },
    {
      title: '生产厂商',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      width: 120,
    },
    {
      title: '配置销售车款',
      dataIndex: 'saleMode',
      key: 'saleMode',
      width: 120,
    },
    {
      title: '更新时间',
      key: 'updatedTime',
      dataIndex: 'updatedTime',
      width: 180,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 100,
      fixed: 'right',
      render: (text: any, record: any) => (
        <>
          <Button type='link' onClick={() => editRow(record)}>
            编辑
          </Button>
          <Divider type='vertical' />
          <Button type='link' danger onClick={() => deleteRow(record)}>
            删除
          </Button>
        </>
      ),
    },
  ];

  const { selectedRowKeys, setSelectedRowKeys, tableSelect } = TableBatchDelete(
    { deleteBatchRow }
  );

  const { table, setLoading, setList, setListTotal, pageData, setPageData } =
    UseTable({
      columns,
      getList,
      selectedRowKeys,
      onSelectChange,
    });

  useEffect(() => {
    getList(1);
  }, [getList, searchForm]);

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>车型列表</div>
        <div className='header-operate flex-x'>
          <Button
            onClick={downloadTemplate}
            loading={downloadLoading}
            disabled={downloadLoading}
          >
            下载模板
          </Button>
          <BatchImportFile importUrl={'/car-model/import'} getList={getList} />
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {selectedRowKeys.length > 0 && tableSelect}

      {table}
    </div>
  );
};

export default ModelListTable;

import React, { FC, useEffect, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { phoneValidate } from '../../../../utils/validate';
import { createOpenModal, createCloseModal } from '../../../../utils/modal';
import { UnitShopsProps } from '../../../../service/unit';

import { ConfigProvider, Modal, Form, Input, Row, Col, Select } from 'antd';
const { Option } = Select;

interface Props {
  visible: boolean;
  type: string;
  value?: UnitShopsProps;
  onCancel: () => void;
  onConfirm: (values: any) => void;
}

const AddUnitShopsModal: FC<Props> = ({
  visible,
  type,
  value,
  onCancel,
  onConfirm,
}) => {
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onInnerConfirm = useStableCallback(async () => {
    await form.validateFields();
    const values = form.getFieldsValue(true);
    setSubmitLoading(true);
    try {
      onConfirm(values);
      onInnerCancel();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onInnerCancel = useStableCallback(() => {
    form.resetFields();
    onCancel();
  });

  useEffect(() => {
    if (type === 'add') {
      form.setFieldsValue({ isDefault: 'true' });
    } else {
      form.setFieldsValue(value);
    }
  }, [form, type, value]);

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title='添加门店'
        width={'60%'}
        centered
        maskClosable={false}
        visible={visible}
        confirmLoading={submitLoading}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <Form form={form} labelCol={{ span: 6 }} name='add-shops'>
          <Row>
            <Col span={12}>
              <Form.Item
                label='所属门店'
                name='name'
                rules={[{ required: true, message: '请输入所属门店' }]}
              >
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label='联系人'
                name='contract'
                rules={[{ required: true, message: '请输入联系人' }]}
              >
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='性别' name='gender'>
                <Select placeholder='请选择' allowClear>
                  <Option value='true'>男</Option>
                  <Option value='false'>女</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='手机号'
                name='mobile'
                rules={[
                  { required: true, message: '请输入手机号' },
                  phoneValidate,
                ]}
              >
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='电话' name='telephone'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='职位' name='position'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='地址' name='address'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='主联系人' name='isDefault'>
                <Select placeholder='请选择' allowClear>
                  <Option value='true'>是</Option>
                  <Option value='false'>否</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='备注' name='remark'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export const openAddUnitShopsModal = createOpenModal(
  AddUnitShopsModal,
  'AddUnitShopsModal'
);

export const closeAddUnitShopsModal = createCloseModal(
  AddUnitShopsModal,
  'AddUnitShopsModal'
);

import React, { FC, useEffect, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { filterParams } from '../../../../utils/common';
import { createCloseModal, createOpenModal } from '../../../../utils/modal';
import UseTable from '../../../common/table/use-table';
import SelectProductQuery from './select-product-query';
import { filesSubPage, GbrandListProps } from '../../../../service/goods';
import { SelectContext } from './select-context';

import { ConfigProvider, message, Modal } from 'antd';

interface Props {
  queryScene: number; // 1.商品替换 2.BOM
  selectedPartsIdList?: number[]; // 已选择的 替换商品/BOM商品 ID集合
  selectContextValue: {
    gbrandSelectList: GbrandListProps[];
    filesSelectListObj: { [key: string]: string[] };
  };
  visible: boolean;
  onCancel: () => void;
  onConfirm: (list: any) => void;
}

const SelectProductModal: FC<Props> = ({
  queryScene,
  selectedPartsIdList = [],
  selectContextValue,
  visible,
  onCancel,
  onConfirm,
}) => {
  const [resetId, setResetId] = useState<number>(0);
  const [searchForm, setSearchForm] = useState<{ [key: string]: any }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      let params = {
        ...searchForm,
        ...listPageData,
        queryScene,
        selectedPartsIdList,
      };
      const { records, total } = await filesSubPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
      onCancel();
    } finally {
      setLoading(false);
    }
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const columns = React.useMemo(
    () => [
      {
        title: '商品编号',
        dataIndex: 'partsCode',
        key: 'partsCode',
        width: 160,
      },
      {
        title: '商品名称',
        dataIndex: 'name',
        key: 'name',
        width: 180,
      },
      {
        title: '品牌',
        dataIndex: 'brandName',
        key: 'brandName',
        width: 120,
      },
      {
        title: '标准名称',
        dataIndex: 'standardDataName',
        key: 'standardDataName',
        width: 120,
      },
      {
        title: '适用位置',
        dataIndex: 'location',
        key: 'location',
        width: 100,
      },
      {
        title: '最新采购价(元)',
        dataIndex: 'latestPurchasePrice',
        key: 'latestPurchasePrice',
        align: 'right' as any,
        width: 120,
      },
    ],
    []
  );

  const {
    table,
    setLoading,
    setList,
    setListTotal,
    pageData,
    setPageData,
    list,
  } = UseTable({
    columns,
    getList,
    selectedRowKeys,
    onSelectChange,
    scroll: { x: '60%', y: 200 },
  });

  const onInnerConfirm = useStableCallback(() => {
    if (!selectedRowKeys || !selectedRowKeys.length)
      return message.warning('请先选择添加商品');
    const addList = list.filter((item) => selectedRowKeys.includes(item.id));
    onConfirm(addList);
    onInnerCancel();
  });

  const onInnerCancel = useStableCallback(() => {
    setResetId(resetId + 1);
    setSelectedRowKeys([]);
    onCancel();
  });

  const handleQuery = useStableCallback(async (values) => {
    await setSearchForm(filterParams(values));
    setSelectedRowKeys([]);
    getList(1);
  });

  useEffect(() => {
    getList(1);
  }, [getList]);

  return (
    <SelectContext.Provider value={selectContextValue}>
      <ConfigProvider locale={zhCN}>
        <Modal
          title={queryScene === 1 ? '添加替换商品' : '添加BOM商品'}
          width={'60%'}
          style={{ overflow: 'hidden' }}
          centered
          maskClosable={false}
          visible={visible}
          onOk={onInnerConfirm}
          onCancel={onInnerCancel}
        >
          <SelectProductQuery
            resetId={resetId}
            onReset={handleQuery}
            onFinish={handleQuery}
          />

          <div style={{ margin: '16px 0 20px', color: 'rgba(0, 0, 0, 0.65)' }}>
            商品列表
          </div>

          {table}
        </Modal>
      </ConfigProvider>
    </SelectContext.Provider>
  );
};

export const openSelectProductModal = createOpenModal(
  SelectProductModal,
  'SelectProductModal'
);

export const closeSelectProductModal = createCloseModal(
  SelectProductModal,
  'SelectProductModal'
);

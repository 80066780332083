import React from 'react';
import { Link } from 'react-router-dom';
import { erpOrderStatusMap2 } from '../../../../utils/definition';
import { PurchaseOrderListProps } from '../../../../service/purchase';

import { ColumnsType } from 'antd/lib/table';

export const GetPurchaseOrderListColumns = (
  renderOpeartor?: (record: PurchaseOrderListProps) => React.ReactElement,
  excluedIds: string[] = []
): ColumnsType<PurchaseOrderListProps> => {
  const list: ColumnsType<PurchaseOrderListProps> = [
    {
      title: '采购单号',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (text: string, record: any) => (
        <>
          {record.orderStatus !== 0 ? (
            <Link to={`/purchase/porder/detail/${record.orderId}`}>{text}</Link>
          ) : (
            <div>{text}</div>
          )}
        </>
      ),
      fixed: 'left' as any,
      width: 140,
    },
    {
      title: '供应商',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 120,
    },
    {
      title: '采购员',
      dataIndex: 'purchaseMan',
      key: 'purchaseMan',
      width: 90,
    },
    {
      title: '应付总额(元)',
      dataIndex: 'ppayAmtFormat',
      key: 'ppayAmtFormat',
      width: 100,
      align: 'right' as any,
    },
    {
      title: '付款方式',
      dataIndex: 'payment',
      key: 'payment',
      width: 90,
    },
    {
      title: '开票类型',
      dataIndex: 'invoicingType',
      key: 'invoicingType',
      width: 100,
    },
    {
      title: '发票单位名称',
      dataIndex: 'taxCompany',
      key: 'taxCompany',
      width: 140,
    },
    {
      title: '发运方式',
      dataIndex: 'shipping',
      key: 'shipping',
      width: 100,
    },
    {
      title: '订单状态',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      width: 120,
      render: (status: number) => (
        <div className='flex-x m-s-start table-status'>
          <div
            className={`iconfont ${erpOrderStatusMap2[status]?.[2]}`}
            style={{ color: erpOrderStatusMap2[status]?.[1] }}
          ></div>
          <div>{erpOrderStatusMap2[status]?.[0]}</div>
        </div>
      ),
    },
    {
      title: '开单日期',
      dataIndex: 'orderDate',
      key: 'orderDate',
      width: 120,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 140,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

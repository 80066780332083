import '../../goods/files/index.scss';

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import DropDownSelect from '../../common/select/drop-down-select';
import { mangLocal } from '../../../utils/common';
import { warehouseList } from '../../../service/warehouse';
import {
  gbrandList,
  getFilesPartsSupplier,
  GbrandListProps,
} from '../../../service/goods';

import { Form, Input, Button, Row, Col, Select } from 'antd';
const { Option } = Select;

interface Props {
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const StockCenterQuery: FC<Props> = ({ onReset, onFinish }) => {
  const [form] = Form.useForm();

  const [warehouseSelectList, setWarehouseSelectList] = useState<any[]>([]);
  const [supplierSelectList, setSupplierSelectList] = useState<
    { [key: string]: any }[]
  >([]);
  const [gbrandSelectList, setGbrandSelelctList] = useState<GbrandListProps[]>(
    []
  );

  const userInfo = useMemo(() => {
    return mangLocal('userInfo');
  }, []);

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  useEffect(() => {
    warehouseList()
      .then((res) => {
        setWarehouseSelectList(res || []);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    getFilesPartsSupplier(userInfo?.tenantIds?.[0] || '')
      .then(setSupplierSelectList)
      .catch((err) => {});

    gbrandList(userInfo?.tenantIds?.[0] || '')
      .then(setGbrandSelelctList)
      .catch((err) => {});
  }, [userInfo?.tenantIds]);

  return (
    <div className='page-custom common-query goods-files-query'>
      <Form
        form={form}
        name='stock-center-query'
        layout={'inline'}
        onFinish={onFinish}
      >
        <div
          className='files-row flex-x m-s-start a-s-start'
          style={{ border: 'none', marginBottom: '-8px' }}
        >
          <p>仓库：</p>
          <Row>
            <Col span={8}>
              <Form.Item name='warehouseIds' style={{ paddingLeft: '30px' }}>
                <Select mode='multiple' allowClear placeholder='请选择'>
                  {warehouseSelectList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className='files-row flex-x m-s-start a-s-start'>
          <p></p>
          <Row>
            <Col span={8}>
              <Form.Item label='库位编号' name='warehouseLocationCode'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='库位名称' name='warehouseLocationName'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className='files-row flex-x m-s-start a-s-start'>
          <p>商品信息：</p>
          <Row>
            <Col span={8}>
              <Form.Item label='商品编号' name='partsCode'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='商品名称' name='partsName'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='品牌' name='brandId'>
                <DropDownSelect options={gbrandSelectList} hasId={true} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='供应商' name='supplierId'>
                <DropDownSelect
                  options={supplierSelectList}
                  hasId={true}
                  eachName={'supplierName'}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='供应商商品编号' name='supplierPartsCode'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className='files-row flex-x m-s-start a-s-start'>
          <Row>
            <Col span={24}>
              <Form.Item>
                <Button htmlType='button' onClick={onInnerReset}>
                  重置
                </Button>
                <Button type='primary' htmlType='submit'>
                  查询
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default StockCenterQuery;

import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import UnitContactsTable from '../../unit/files/tables/contacts';
import LocationInformationTable from './tables/location-information';
import CityCascader from '../../common/select/city-cascader';

import {
  addWarehouse,
  editWarehouse,
  getWarehouseDetail,
} from '../../../service/warehouse';

import { Form, Input, Button, Spin, message, Row, Col } from 'antd';

interface Props {
  type: 'add' | 'edit';
}

const WarehouseListEdit: FC<Props> = (props) => {
  const history = useHistory();
  const { id } = useParams() as any;

  const [form] = Form.useForm();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const add = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await addWarehouse(form);
      message.success('新增成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const edit = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await editWarehouse(id, form);
      message.success('编辑成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onFinish = useStableCallback((values: any) => {
    const form = { ...values };
    if (!form.warehouseContacts || !form.warehouseContacts.length)
      return message.warning('请至少添加一条联系人信息');
    if (!form.warehouseLocations || !form.warehouseLocations.length)
      return message.warning('请至少添加一条库位信息');

    for (const key in form) {
      if (form[key] === undefined) {
        form[key] = null;
      }
    }

    form?.warehouseContacts?.forEach((each: any) => {
      if (each?.id?.toString().includes('add')) {
        each.id = null;
      }
    });

    if (props.type === 'add') {
      add(form);
    } else {
      edit(form);
    }
  });

  useEffect(() => {
    if (props.type === 'edit') {
      setSpinLoading(true);
      getWarehouseDetail(id)
        .then(form.setFieldsValue)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  }, [props.type, id, form]);

  return (
    <Spin spinning={spinLoading}>
      <Form
        name='warehouse-list-edit'
        className='multi-details'
        layout='vertical'
        form={form}
        scrollToFirstError={true}
        onFinish={onFinish}
      >
        <div className='page-custom'>
          <div className='details-title'>基本信息</div>
          <Row className='details-info'>
            <Col span={6}>
              <Form.Item
                label='仓库编号'
                name='code'
                rules={[{ required: true, message: '请输入仓库编号' }]}
              >
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item
                label='仓库名称'
                name='name'
                rules={[{ required: true, message: '请输入仓库名称' }]}
              >
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item
                label='地区'
                name='locations'
                rules={[{ required: true, message: '请选择地区' }]}
              >
                <CityCascader changeOnSelect={true} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='详细地址' name='address'>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <div className='details-title'>联系人</div>
          <Form.Item name='warehouseContacts' className='details-info'>
            <UnitContactsTable source='warehouse' />
          </Form.Item>

          <div className='details-title' style={{ marginTop: '-20px' }}>
            库位信息
          </div>
          <Form.Item
            validateStatus={'custom-error' as ''}
            name='warehouseLocations'
            className='details-info'
            rules={[
              {
                message: '库位编号或库位名称不能为空',
                validator: async (rule, value) => {
                  if (
                    value?.some((each: any) => !each.code) ||
                    value?.some((each: any) => !each.name)
                  ) {
                    throw new Error(rule?.message as string);
                  }
                },
              },
            ]}
          >
            <LocationInformationTable />
          </Form.Item>
        </div>

        <Row className='details-btn'>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button onClick={() => history.goBack()}>取消</Button>
            <Button type='primary' htmlType='submit' loading={submitLoading}>
              确定
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default WarehouseListEdit;

import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import UnitShopsTable from '../tables/shops';
import UnitCarsTable from '../tables/cars';

import {
  addUnitDetail,
  getUnitDetail,
  UnitBasicInfoProps,
} from '../../../../service/unit';

import { Form, Input, Button, Row, Col, message } from 'antd';

interface Props {
  type: string;
  unitId: number;
  basicInfo: UnitBasicInfoProps;
  detailChange?: () => void;
  detailSave: () => void;
}

const EditUnitDetailInfo: FC<Props> = ({
  type,
  unitId,
  basicInfo,
  detailChange,
  detailSave,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [detailInfo, setDetailInfo] = useState<any>(null);

  const onFinish = useStableCallback(async (values: any) => {
    setSubmitLoading(true);
    const form = { ...values };
    for (const key in form) {
      if (form[key] === undefined) {
        form[key] = null;
      }
    }
    form?.unitShops?.forEach((each: any) => {
      if (each?.id?.toString().includes('add')) {
        each.id = null;
      }
    });
    form?.unitCars?.forEach((each: any) => {
      if (each?.id?.toString().includes('add')) {
        each.id = null;
      }
    });
    try {
      await addUnitDetail(unitId, { ...detailInfo, ...form });
      detailSave();
      message.success('保存成功');
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    const activeMenuRegex =
      type === 'add'
        ? /^\/unit\/ufiles\/add\/(\w+)$/
        : /^\/unit\/ufiles\/edit\/\w+\/(\w+)$/;
    const res = activeMenuRegex.exec(location.pathname);
    if (res?.[1] !== 'detail' || !unitId) return;
    getUnitDetail(unitId)
      .then((data) => {
        setDetailInfo(data);
        form.setFieldsValue(data);
      })
      .catch((err) => {});
  }, [form, location.pathname, unitId, type]);

  return (
    <div>
      <Form
        layout='vertical'
        form={form}
        labelAlign='left'
        onValuesChange={detailChange}
        onFinish={onFinish}
      >
        <div className='common-setting-title'>详细信息</div>
        <div className='common-setting-content'>
          <Row gutter={64}>
            <Col span={8}>
              <Form.Item label='单位拥有人' name='owner'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
        </div>

        {['汽修企业', '汽配商', '4S站'].includes(basicInfo?.catalog) && (
          <>
            <div className='common-setting-title'>门店信息</div>
            <div className='common-setting-content'>
              <Form.Item name='unitShops'>
                <UnitShopsTable />
              </Form.Item>
            </div>
          </>
        )}

        {['个人车主', '企业客户'].includes(basicInfo?.catalog) && (
          <>
            <div className='common-setting-title'>车辆信息</div>
            <div className='common-setting-content'>
              <Form.Item name='unitCars'>
                <UnitCarsTable />
              </Form.Item>
            </div>
          </>
        )}

        <div className='common-setting-buttons flex-x m-s-end minauto'>
          <Button onClick={history.goBack}>取消</Button>
          <Button type='primary' htmlType='submit' loading={submitLoading}>
            保存
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditUnitDetailInfo;

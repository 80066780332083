import React from 'react';

import { UnitShopsProps } from '../../../../service/unit';

import { ColumnsType } from 'antd/lib/table';

export const GetUnitShopsColumns = (
  renderOpeartor?: (record: UnitShopsProps) => React.ReactElement,
  excluedIds: string[] = []
): ColumnsType<UnitShopsProps> => {
  const list: ColumnsType<UnitShopsProps> = [
    {
      title: '所属门店',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: '联系人',
      dataIndex: 'contract',
      key: 'contract',
      width: 100,
    },
    {
      title: '性别',
      dataIndex: 'gender',
      key: 'gender',
      width: 80,
      render: (text: any, record: any) => {
        return text === true || text === 'true'
          ? '男'
          : text === false || text === 'false'
          ? '女'
          : '';
      },
    },
    {
      title: '手机号',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 120,
    },
    {
      title: '电话',
      dataIndex: 'telephone',
      key: 'telephone',
      width: 120,
    },
    {
      title: '职位',
      dataIndex: 'position',
      key: 'position',
      width: 100,
    },
    {
      title: '地址',
      dataIndex: 'address',
      key: 'address',
      width: 160,
      ellipsis: true,
    },
    {
      title: '主联系人',
      dataIndex: 'isDefault',
      key: 'isDefault',
      width: 100,
      render: (text: any, record: any) => {
        return text === true || text === 'true'
          ? '是'
          : text === false || text === 'false'
          ? '否'
          : '';
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      width: 140,
      ellipsis: true,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 120,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

import { preFetch } from './fetch';

// 单位档案
export interface UnitListProps {
  id: number;
  contactMobile: string;
  contactTelephone: string;
  defaultContact: string;
  name: string;
  fname: string;
  location: string;
  locations: string[];
  owner: string;
  propCode: string;
  updatedTime: string;
}

export interface UnitBasicInfoProps {
  name: string;
  propCode: string;
  catalog: string;
  accBank?: string;
  accBankNo?: string;
  accName?: string;
  accRemark?: string;
  address?: string;
  fname?: string;
  id?: string;
  location?: string;
  locations?: string[];
  remark?: string;
  taxAddress?: string;
  taxBank?: string;
  taxBankNo?: string;
  taxCompany?: string;
  taxMailingAddress?: string;
  taxNo?: string;
  taxPhone?: string;
  taxRate?: string;
  taxRemark?: string;
  url?: string;
  unitContacts: UnitContactsProps[];
}

export interface UnitContactsProps {
  name?: string;
  isDefault?: boolean;
  id?: number;
  unitId?: number;
  defaultFormat?: string;
  email?: string;
  mobile?: string;
  qq?: string;
  telephone?: string;
  address?: string;
}

export interface UnitDetailInfoProps {
  id?: number;
  owner: number;
  unitCars?: UnitCarsProps[];
  unitShops?: UnitShopsProps[];
}

export interface UnitCarsProps {
  id?: number;
  carNo?: string;
  frameNo?: string;
  brandId?: number;
  brandName?: string;
  engineCode?: string;
  gearBoxCode?: string;
  gearBoxType?: string;
  modelCatalog?: string;
  modelId?: number;
  modelName?: string;
  powerType?: string;
  remark?: string;
}

export interface UnitShopsProps {
  id?: number;
  name?: string;
  contract?: string;
  address?: string;
  isDefault?: boolean;
  defaultFormat?: string;
  gender?: boolean;
  genderFormat?: string;
  mobile?: string;
  telephone?: string;
  position?: string;
  remark?: string;
}

export interface TaxCompanysProps {
  accBank: string;
  accBankNo: string;
  accName: string;
  accRemark: string;
  address: string;
  catalog: string;
  createBy: string;
  createdTime: string;
  extra: string;
  fname: string;
  id: number;
  location: string;
  name: string;
  owner: string;
  propCode: string;
  remark: string;
  taxAddress: string;
  taxBank: string;
  taxBankNo: string;
  taxCompany: string;
  taxMailingAddress: string;
  taxNo: string;
  taxPhone: string;
  taxRate: string;
  taxRemark: string;
  tenantId: number;
  updateBy: string;
  updatedTime: string;
  url: string;
}

export const unitPage = async (query: {
  [key: string]: any;
}): Promise<{ records: UnitListProps[]; total: number }> => {
  const success = await preFetch('/erp-unit-info/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const deleteUnit = async (ids: number[]) => {
  const success = await preFetch('/erp-unit-info/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

export const getUnitBasic = async (id: number): Promise<UnitBasicInfoProps> => {
  const success = await preFetch(`/erp-unit-info/basic-info/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addUnitBasic = async (
  body: UnitBasicInfoProps
): Promise<number> => {
  const success = await preFetch('/erp-unit-info/basic-info', {
    method: 'POST',
    body,
  });
  return success.data;
};

export const editUnitBasic = async (id: number, body: UnitBasicInfoProps) => {
  const success = await preFetch(`/erp-unit-info/basic-info/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const getUnitDetail = async (
  id: number
): Promise<UnitDetailInfoProps> => {
  const success = await preFetch(`/erp-unit-info/detail-info/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addUnitDetail = async (id: number, body: UnitDetailInfoProps) => {
  const success = await preFetch(`/erp-unit-info/detail-info/${id}`, {
    method: 'POST',
    body,
  });
  return success;
};

export const getTaxCompanysList = async (
  propCode: string = ''
): Promise<TaxCompanysProps[]> => {
  const success = await preFetch('/erp-unit-info/tax-companys', {
    method: 'GET',
    query: { propCode },
  });
  return success.data;
};

export const getUnitNamesList = async (
  propCode: string = ''
): Promise<TaxCompanysProps[]> => {
  const success = await preFetch('/erp-unit-info/names', {
    method: 'GET',
    query: { propCode },
  });
  return success.data;
};

export const getUnitShopContactsList = async (
  id: number | string = ''
): Promise<any> => {
  const success = await preFetch('/erp-unit-info/shop-contacts', {
    method: 'GET',
    query: { id },
  });
  return success.data;
};

import React, { FC, useState, useEffect, useMemo } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { resourceTree } from '../../../service/system';

import { Transfer, Tree } from 'antd';

const searchTree = (data: any[], ids: string[]) => {
  return data
    .map((each) => ({ ...each }))
    .filter((each) => {
      const include = ids.includes(each.key);
      if (include) return true;
      if (each.children) {
        const children = searchTree(each.children, ids);
        if (children.length !== 0) {
          each.children = children;
          return true;
        }
      }
      return false;
    });
};

const disableTree = (data: any[], ids: any[] = []): any[] => {
  return data.map((each) => {
    const include = ids.includes(each.key);

    const res = {
      ...each,
      disabled: include,
    };

    if (each.children) {
      res.children = disableTree(each.children, ids);
      if (
        res.children.length > 0 &&
        res.children.every((each: any) => each.disabled)
      ) {
        res.disabled = true;
      }
    }

    return res;
  });
};

const getDisabledKey = (data: any[], res: string[] = []) => {
  data.forEach((each) => {
    if (each.disabled) {
      res.push(each.key);
    }

    if (each.children && each.children.length > 0) {
      getDisabledKey(each.children, res);
      return;
    }
  });

  return res;
};

const getLeftFormTree = (
  data: any[],
  ids: any[] = [],
  res: string[] = []
): string[] => {
  data.forEach((each) => {
    if (each.children && each.children.length > 0) {
      getLeftFormTree(each.children, ids, res);
      return;
    }

    if (ids.includes(each.key)) {
      res.push(each.key);
    }
  });

  return res;
};

const flattenTree = (list: any[], res: any[] = []) => {
  list.forEach((item: any) => {
    if (item.children && item.children.length > 0) {
      flattenTree(item.children, res);
    } else {
      res.push(item);
    }
  });

  return res;
};

const getAllKeyFromTree = (tree: any[], res: string[] = []) => {
  tree.forEach((each) => {
    res.push(each.key);
    if (each.children && each.children.length > 0) {
      getAllKeyFromTree(each.children, res);
    }
  });

  return res;
};

interface TreeTransferProps {
  dataSource: any;
  targetKeys: any;
  onChange?: (value: string[]) => void;
  [x: string]: any;
}

const TreeTransfer: FC<TreeTransferProps> = ({
  dataSource,
  targetKeys,
  onChange,
}) => {
  const [leftSelectKeys, setLeftSelectKeys] = useState<string[]>([]);
  const [rightSelectKeys, setRightSelectKeys] = useState<string[]>([]);
  const [innerTargetKeys, setInnerTargetKeys] = useState<string[]>([]);

  const leftTree = useMemo(
    () => disableTree(dataSource, innerTargetKeys),
    [dataSource, innerTargetKeys]
  );
  const rightTree = useMemo(
    () => searchTree(dataSource, innerTargetKeys),
    [dataSource, innerTargetKeys]
  );
  const transferData = useMemo(() => flattenTree(dataSource), [dataSource]);

  const handleChange = useStableCallback((_: any, direction: string) => {
    let nowKeys;
    if (direction === 'right') {
      nowKeys = [...innerTargetKeys, ...leftSelectKeys];
      setLeftSelectKeys([]);
    } else {
      nowKeys = innerTargetKeys.filter(
        (each) => !rightSelectKeys.includes(each)
      );
      setRightSelectKeys([]);
    }
    setInnerTargetKeys(nowKeys);
    onChange?.(getAllKeyFromTree(searchTree(dataSource, nowKeys)));
  });

  useEffect(() => {
    setInnerTargetKeys(getLeftFormTree(dataSource, targetKeys));
  }, [dataSource, targetKeys]);

  return (
    <Transfer
      className='tree-transfer'
      selectAllLabels={['资源列表', '已选资源']}
      dataSource={transferData}
      selectedKeys={[...leftSelectKeys, ...rightSelectKeys]}
      targetKeys={innerTargetKeys}
      onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
        setLeftSelectKeys(sourceSelectedKeys);
        setRightSelectKeys(targetSelectedKeys);
      }}
      onChange={handleChange}
    >
      {({ direction }) => {
        if (direction === 'left') {
          return (
            <Tree
              blockNode
              checkable
              defaultExpandAll
              treeData={leftTree}
              checkedKeys={[...leftSelectKeys, ...getDisabledKey(leftTree)]}
              onCheck={(checkedKeys) => {
                const selected = checkedKeys as string[];
                setLeftSelectKeys(
                  getLeftFormTree(
                    leftTree,
                    selected.filter((each) => !innerTargetKeys.includes(each))
                  )
                );
              }}
            />
          );
        } else {
          return (
            <Tree
              blockNode
              checkable
              defaultExpandAll
              treeData={rightTree}
              checkedKeys={rightSelectKeys}
              onCheck={(checkedKeys) => {
                const selected = checkedKeys as string[];
                setRightSelectKeys(getLeftFormTree(rightTree, selected));
              }}
            />
          );
        }
      }}
    </Transfer>
  );
};

const formatTreeData = (data: any) => {
  return data.map((each: any) => ({
    key: each.id,
    title: each.name,
    children: formatTreeData(each.children),
  }));
};

interface RoleEditTreeTransferProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

const RoleEditTreeTransfer: FC<RoleEditTreeTransferProps> = ({
  value,
  onChange,
}) => {
  const [treeData, setTreeData] = useState([]);
  const [targetKeys, setTargetKeys] = useState(value ?? []);

  const onInnerChange = (keys: any) => {
    setTargetKeys(keys);
    onChange?.(keys);
  };

  useEffect(() => {
    resourceTree()
      .then((res) => {
        setTreeData(formatTreeData(res));
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setTargetKeys(value ?? []);
  }, [value]);

  return (
    <TreeTransfer
      dataSource={treeData}
      targetKeys={targetKeys}
      onChange={onInnerChange}
    />
  );
};

export default RoleEditTreeTransfer;

import { preFetch } from './fetch';

// 销售单
export interface SalesOrderListProps {
  invoicingType: string;
  orderDate: string;
  orderId: number;
  orderStatus: number;
  orderStatusFormat: string;
  payment: string;
  ppayAmt: number;
  ppayAmtFormat: string;
  salesMan: string;
  shipping: string;
  taxCompany: string;
  unitId: number;
  unitName: string;
}

export interface OrderLogisticsProps {
  contactNumber: string;
  contacts: string;
  distributeType: number;
  id: number;
  invoicingType: string;
  logisticsNo: string;
  logisticsUnitId: number;
  logisticsUnitName: string;
  orderId: number;
  orderType: number;
  porderId: number;
  remark: string;
  shippingAmt: number;
  shippingAmtFormat: string;
  taxRate: string;
}

export const salesOrderPage = async (query: {
  [key: string]: any;
}): Promise<{ records: SalesOrderListProps[]; total: number }> => {
  const success = await preFetch('/erp-sales-order/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const salesOrderDetail = async (orderId: number) => {
  const success = await preFetch(`/erp-sales-order/detail/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const salesOrderBasicInfo = async (orderId: number) => {
  const success = await preFetch(`/erp-sales-order/basic-info/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const salesOrderReceiveInfo = async (orderId: number) => {
  const success = await preFetch(`/erp-sales-order/receive-info/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const salesOrderBillInfo = async (orderId: number) => {
  const success = await preFetch(`/erp-sales-order/billing-info/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const deleteSalesOrder = async (ids: number[]): Promise<boolean> => {
  const success = await preFetch('/erp-sales-order/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

export const auditSalesOrder = async (orderId: number): Promise<boolean> => {
  const success = await preFetch(`/erp-sales-order/approved/${orderId}`, {
    method: 'PUT',
  });
  return success;
};

export const rejectSalesOrder = async (orderId: number): Promise<boolean> => {
  const success = await preFetch(`/erp-sales-order/reject/${orderId}`, {
    method: 'PUT',
  });
  return success;
};

export const canDeliver = async (orderId: number) => {
  const success = await preFetch(
    `/erp-sales-order/exists-deliver-items/${orderId}`,
    {
      method: 'GET',
    }
  );
  return success.data;
};

export const deliverSalesOrder = async (orderId: number) => {
  const success = await preFetch(`/erp-sales-order/deliver/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const printSalesOrder = async (orderId: number) => {
  const success = await preFetch(`/erp-sales-order/print/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const salesOrderSaveDraft = async (body: {
  [key: string]: any;
}): Promise<boolean> => {
  const success = await preFetch('/erp-sales-order/save-draft', {
    method: 'POST',
    body,
  });
  return success;
};

export const salesOrderSubmit = async (body: {
  [key: string]: any;
}): Promise<boolean> => {
  const success = await preFetch('/erp-sales-order/submit', {
    method: 'POST',
    body,
  });
  return success;
};

// 分摊计算
export const allocationAlgorithm = async (body: { [key: string]: any }) => {
  const success = await preFetch('/erp-order-item/allocation-algorithm', {
    method: 'POST',
    body,
  });
  return success.data;
};

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { EditableRow } from '../../common/editable/editable-row';
import {
  EditableCell,
  InputCell,
  DataType,
} from '../../common/editable/editable-cell';
import BatchImportFile from '../../common/upload/batch-import-file';
import {
  openAddCategoryModal,
  closeAddCategoryModal,
} from './add-category-modal';
import { downloadTemplate } from '../../../utils/definition';
import { clearChildren, flattenArray } from '../../../utils/common';

import {
  categoryTree,
  CategoryTreeProps,
  editCategoryName,
  deleteCategory,
} from '../../../service/goods';

import { Button, message, Modal, Table } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

interface Props {
  activeKey: string;
}

const CategoryList: FC<Props> = ({ activeKey }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<CategoryTreeProps[]>([]);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const [downloadUrl] = useState<string>(
    `${process.env.REACT_APP_DOWNLOAD_URL}${downloadTemplate['商品分类']}`
  );

  const getList = useStableCallback(async () => {
    setLoading(true);
    try {
      const res = await categoryTree();
      setList(clearChildren(res));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const addRow = useStableCallback(() => {
    openAddCategoryModal({
      visible: true,
      onCancel: closeAddCategoryModal,
      onConfirm: getList,
    });
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除分类【${record.name}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await deleteCategory(record.id);
          message.success('删除成功');
          getList();
        } catch (err) {}
      },
    });
  });

  const handleChange = useStableCallback(
    async (row: DataType, changedKey: keyof DataType) => {
      const editList = flattenArray(list as any, []);
      const editRow: any = editList.filter(
        (each: any) => each.id === row.id
      )[0];
      if (editRow.name === row.name) return;
      try {
        await editCategoryName(row.id, row.name);
        message.success('编辑成功');
        getList();
      } catch (err) {}
    }
  );

  const columns = [
    {
      title: '分类名称',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      onCell: (record: DataType): any => ({
        record,
        editable: true,
        editType: 'input',
        title: '分类名称',
        dataIndex: 'name',
        handleChange,
        renderFormCell: (
          name: keyof DataType,
          onChange: () => void,
          { originCell, record }: any
        ) => {
          return (
            <InputCell
              name={name}
              value={record.name}
              onChange={onChange}
              directEdit={false}
              rules={[{ required: true, message: '请输入分类名称' }]}
            >
              {originCell}
            </InputCell>
          );
        },
      }),
    },
    {
      title: '分类ID',
      dataIndex: 'code',
      key: 'code',
      width: '40%',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: '20%',
      render: (text: any, record: any) => (
        <>
          <Button type='link' danger onClick={() => deleteRow(record)}>
            删除
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (activeKey === 'list') {
      getList();
    }
  }, [getList, activeKey]);

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>分类列表</div>
        <div className='header-operate'>
          <Button href={downloadUrl} download>
            下载模板
          </Button>
          <BatchImportFile
            importUrl={'/erp-parts-category/import'}
            getList={getList}
          />
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={getList} />
        </div>
      </div>

      <Table
        className='list-table editable'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        loading={loading}
        components={components}
        columns={columns}
        dataSource={list}
        pagination={false}
      />
    </div>
  );
};

export default CategoryList;

import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { addFilesPrice, filesPriceDetails } from '../../../../service/goods';

import UseReplaceProductPriceTable from '../tables/replace-product-price';

import { Form, Button, Row, Col, message, InputNumber } from 'antd';

interface Props {
  type: string;
  partsId: number;
  priceChange?: () => void;
  priceSave: () => void;
}

const EditGoodsPrice: FC<Props> = ({
  type,
  partsId,
  priceChange,
  priceSave,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [priceInfo, setPriceInfo] = useState<any>(null);

  const { table } = UseReplaceProductPriceTable({
    list: priceInfo?.partsReplacePrices,
    rowKey: 'partsId',
  });

  const onFinish = useStableCallback(async (values: any) => {
    setSubmitLoading(true);
    for (const key in values) {
      if (values[key] === undefined) {
        values[key] = null;
      }
    }
    try {
      await addFilesPrice({ ...priceInfo, ...values, partsId });
      priceSave();
      message.success('保存成功，请继续下一步');
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    const activeMenuRegex =
      type === 'add'
        ? /^\/goods\/gfiles\/add\/(\w+)$/
        : /^\/goods\/gfiles\/edit\/\w+\/(\w+)$/;
    const res = activeMenuRegex.exec(location.pathname);
    if (res?.[1] !== 'price' || !partsId) return;
    filesPriceDetails(partsId)
      .then((data) => {
        setPriceInfo(data);
        form.setFieldsValue(data);
      })
      .catch((err) => {});
  }, [form, location.pathname, partsId, type]);

  return (
    <div>
      <Form
        layout='vertical'
        form={form}
        labelAlign='left'
        onValuesChange={priceChange}
        onFinish={onFinish}
      >
        <div className='common-setting-title'>商品价格</div>
        <div className='common-setting-content'>
          <Row gutter={64}>
            <Col span={8}>
              <Form.Item label='4s销售价(元)' name='salePrice4s'>
                <InputNumber
                  min={0}
                  precision={2}
                  controls={false}
                  placeholder='请输入'
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='4s进店价(元)' name='purchasePrice4s'>
                <InputNumber
                  min={0}
                  precision={2}
                  controls={false}
                  placeholder='请输入'
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='销售商价(元)' name='dealerPrice'>
                <InputNumber
                  min={0}
                  precision={2}
                  controls={false}
                  placeholder='请输入'
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='修理厂价(元)' name='repairPrice'>
                <InputNumber
                  min={0}
                  precision={2}
                  controls={false}
                  placeholder='请输入'
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='车主价(元)' name='ownerPrice'>
                <InputNumber
                  min={0}
                  precision={2}
                  controls={false}
                  placeholder='请输入'
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className='common-setting-title'>替换商品价格</div>
        <div className='common-setting-content'>{table}</div>

        <div className='common-setting-buttons flex-x m-s-end minauto'>
          <Button onClick={history.goBack}>取消</Button>
          <Button type='primary' htmlType='submit' loading={submitLoading}>
            保存
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditGoodsPrice;

import React, { FC } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';

import { Form, Input, Button, Row, Col, DatePicker } from 'antd';
const { RangePicker } = DatePicker;

interface Props {
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const AssemblyOrderQuery: FC<Props> = ({ onReset, onFinish }) => {
  const [form] = Form.useForm();

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  return (
    <div className='page-custom common-query'>
      <Form
        form={form}
        name='assembly-order-query'
        layout={'inline'}
        onFinish={onFinish}
      >
        <Row>
          <Col span={8}>
            <Form.Item label='组装单号' name='orderId'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='经手人' name='inboundContacts'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='商品编号' name='purchaseMan'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='组装日期' name='assemblyDate'>
              <RangePicker />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Button htmlType='button' onClick={onInnerReset}>
                重置
              </Button>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AssemblyOrderQuery;

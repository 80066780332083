import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import BreadcrumbCustom from '../../breadcrumb-custom';

import { modelDetails } from '../../../service/car';

import { Button, Descriptions, Spin } from 'antd';

const ModelDetail: FC = () => {
  const params: any = useParams();
  const history = useHistory();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [modelInfo, setModelInfo] = useState<any>(null);

  useEffect(() => {
    setSpinLoading(true);
    modelDetails(params.id)
      .then(setModelInfo)
      .catch((err) => {})
      .finally(() => {
        setSpinLoading(false);
      });
  }, [params.id]);

  const extraButton = useMemo(() => {
    return (
      <div>
        <Link to={`/car/model/edit/${params.id}`}>
          <Button>编辑</Button>
        </Link>
      </div>
    );
  }, [params.id]);

  return (
    <Spin spinning={spinLoading}>
      <BreadcrumbCustom
        paths={['车型管理', '车型档案', '车型详情']}
        title={`车型详情：${modelInfo?.name}`}
        extra={extraButton}
      />

      <div className='page-custom common-details'>
        <Descriptions title='基本信息'>
          <Descriptions.Item label='车型名称'>
            {modelInfo?.name || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='车型代码'>
            {modelInfo?.code || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='车型分类'>
            {modelInfo?.catalog || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='品牌'>
            {modelInfo?.brandName || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='车系'>
            {modelInfo?.seriesName || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='生产厂商'>
            {modelInfo?.manufacturer || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='配置销售车款'>
            {modelInfo?.saleMode || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='厂商指导价(万元)'>
            {modelInfo?.manuGuidePrice || '-'}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions title='详细信息'>
          <Descriptions.Item label='级别'>
            {modelInfo?.level || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='车体结构'>
            {modelInfo?.bodyStructure || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='动力类型'>
            {modelInfo?.powerType || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='发动机型号'>
            {modelInfo?.engineCode || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='发动机类型'>
            {modelInfo?.engineType || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='排放标准'>
            {modelInfo?.eds || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='变速箱类型'>
            {modelInfo?.gearBoxType || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='变速箱型号'>
            {modelInfo?.gearBoxCode || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='驱动方式'>
            {modelInfo?.drivingModel || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='分动器(四驱)类型'>
            {modelInfo?.fourWd || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='电机型号'>
            {modelInfo?.motorCode || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='保修政策'>
            {modelInfo?.warrantyPolicy || '-'}
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div className='common-buttons flex-x m-s-end minauto'>
        <Button onClick={history.goBack}>返回</Button>
      </div>
    </Spin>
  );
};

export default ModelDetail;

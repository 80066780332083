import React, { FC, useState } from 'react';

import { Image } from 'antd';

interface Props {
  url: string[];
}

const DetailPhotoPreview: FC<Props> = ({ url }) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <Image
        width={120}
        height={120}
        style={{ objectFit: 'scale-down' }}
        src={url[0]}
        preview={{ visible: false }}
        onClick={() => setVisible(true)}
      />
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => setVisible(vis),
          }}
        >
          {url.map((item: string, index: number) => (
            <Image src={item} key={index} />
          ))}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

export default DetailPhotoPreview;

import UseDetailTable from '../../../common/table/detail-table';

interface Params {
  list: { [key: string]: any }[];
  excluedIds?: string[];
}

const UsePartsReplacesTable = ({ list, excluedIds = [] }: Params) => {
  const columns = [
    {
      title: '商品编号',
      dataIndex: 'partsCode',
      key: 'partsCode',
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '品牌',
      dataIndex: 'brandName',
      key: 'brandName',
    },
    {
      title: '最新采购价(元)',
      dataIndex: 'latestPurchasePrice',
      key: 'latestPurchasePrice',
    },
    {
      title: '数量',
      dataIndex: 'assembleNum',
      key: 'assembleNum',
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  const table = UseDetailTable({ columns, list });

  return {
    table,
  };
};

export default UsePartsReplacesTable;

import '../../goods/files/index.scss';
import '../../sales/order/index.scss';

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { closeAuditModal, openAuditModal } from '../../erp/modal/audit-modal';
import {
  closeAddLogisticsModal,
  openAddLogisticsModal,
} from '../../erp/modal/add-logistics-modal';
import {
  closeOutboundOrderPrintModal,
  openOutboundOrderPrintModal,
} from './print';

import BreadcrumbCustom from '../../breadcrumb-custom';
import DetailBasicInfo from './detail/basic';
import DetailLogisticsInfo from './detail/logistics';

import {
  outboundOrderBasicInfo,
  outboundOrderReceiveInfo,
  auditOutboundOrder,
  rejectOutboundOrder,
} from '../../../service/warehouse';

import { Button, message, Spin, Tabs } from 'antd';
const { TabPane } = Tabs;

const OutboundOrderDetail: FC = () => {
  const params: any = useParams();
  const history = useHistory();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [menuItem, setMenuItem] = useState<string>('basic');
  const [basicInfo, setBasicInfo] = useState<any>(null);
  const [logisticsInfo, setLogisticsInfo] = useState<any>(null);

  const changeTabs = useStableCallback((menu) => {
    setSpinLoading(true);
    setMenuItem(menu);
    if (menu === 'logistics') {
      outboundOrderReceiveInfo(params.id)
        .then(setLogisticsInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    } else {
      outboundOrderBasicInfo(params.id)
        .then(setBasicInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  });

  const handlePrint = useStableCallback(() => {
    openOutboundOrderPrintModal({
      visible: true,
      orderId: params.id,
      onCancel: closeOutboundOrderPrintModal,
    });
  });

  const handleAudit = useStableCallback(() => {
    openAuditModal({
      source: '出库单',
      orderId: params.id,
      visible: true,
      onReject: onReject,
      onAudit: onAudit,
      onCancel: closeAuditModal,
    });
  });

  const onReject = useStableCallback(async () => {
    try {
      await rejectOutboundOrder(params.id);
      message.success('驳回成功');
      closeAuditModal();
      changeTabs(menuItem);
    } catch (err) {}
  });

  const onAudit = useStableCallback(async () => {
    try {
      await auditOutboundOrder(params.id);
      message.success('审核通过');
      closeAuditModal();
      changeTabs(menuItem);
    } catch (err) {}
  });

  const handleShipping = useStableCallback(() => {
    openAddLogisticsModal({
      visible: true,
      type: 'add',
      orderType: 11,
      orderId: params.id,
      onCancel: closeAddLogisticsModal,
      onConfirm: () => changeTabs(menuItem),
    });
  });

  useEffect(() => {
    setSpinLoading(true);
    outboundOrderBasicInfo(params.id)
      .then(setBasicInfo)
      .catch((err) => {})
      .finally(() => {
        setSpinLoading(false);
      });
  }, [params.id]);

  const extraButton = useMemo(() => {
    return (
      <div>
        {basicInfo?.orderStatus === 30 && (
          <Button onClick={handlePrint}>打印</Button>
        )}
      </div>
    );
  }, [basicInfo?.orderStatus, handlePrint]);

  return (
    <Spin spinning={spinLoading}>
      <BreadcrumbCustom
        paths={['仓库管理', '出库单', '出库单详情']}
        title={`出库单详情：${params.id}`}
        extra={extraButton}
      />

      <Tabs
        defaultActiveKey='basic'
        className='goods-files-detail'
        onChange={changeTabs}
      >
        <TabPane tab='基本信息' key='basic'>
          <DetailBasicInfo basicInfo={basicInfo} />
        </TabPane>
        <TabPane tab='物流信息' key='logistics'>
          <DetailLogisticsInfo logisticsInfo={logisticsInfo} />
        </TabPane>
      </Tabs>

      <div className='common-buttons flex-x m-s-end minauto'>
        <Button onClick={history.goBack}>返回</Button>
        {basicInfo?.orderStatus === 10 && (
          <Button type='primary' onClick={handleAudit}>
            审核
          </Button>
        )}
        {basicInfo?.orderStatus === 20 && (
          <Button type='primary' onClick={handleShipping}>
            发货
          </Button>
        )}
      </div>
    </Spin>
  );
};

export default OutboundOrderDetail;

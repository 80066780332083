import { FC } from 'react';
import { erpOrderStatusMap1, erpOrderStatusMap2 } from '../../utils/definition';

interface Params {
  type: number; // 1:销售单、出库单 2:采购单、入库单
  status: number;
}

const OrderStatusLogo: FC<Params> = ({ type, status }) => {
  const map = type === 1 ? erpOrderStatusMap1 : erpOrderStatusMap2;
  return <i className={`iconfont ${map[status]?.[3]}`}></i>;
};

export default OrderStatusLogo;

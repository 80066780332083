import { preFetch } from './fetch';

// 采购单
export interface PurchaseOrderListProps {
  invoicingType: string;
  orderDate: string;
  orderId: number;
  orderStatus: number;
  orderStatusFormat: string;
  payment: string;
  ppayAmt: number;
  ppayAmtFormat: string;
  purchaseMan: string;
  shipping: string;
  supplierId: number;
  supplierName: string;
  taxCompany: string;
}

export const purchaseOrderPage = async (query: {
  [key: string]: any;
}): Promise<{ records: PurchaseOrderListProps[]; total: number }> => {
  const success = await preFetch('/order/erp-purchase-order/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const purchaseOrderDetail = async (orderId: number) => {
  const success = await preFetch(
    `/order/erp-purchase-order/detail/${orderId}`,
    {
      method: 'GET',
    }
  );
  return success.data;
};

export const purchaseOrderBasicInfo = async (orderId: number) => {
  const success = await preFetch(
    `/order/erp-purchase-order/basic-info/${orderId}`,
    {
      method: 'GET',
    }
  );
  return success.data;
};

export const purchaseOrderSupplyInfo = async (orderId: number) => {
  const success = await preFetch(
    `/order/erp-purchase-order/supply-info/${orderId}`,
    {
      method: 'GET',
    }
  );
  return success.data;
};

export const purchaseOrderBillInfo = async (orderId: number) => {
  const success = await preFetch(
    `/order/erp-purchase-order/billing-info/${orderId}`,
    {
      method: 'GET',
    }
  );
  return success.data;
};

export const deletePurchaseOrder = async (ids: number[]): Promise<boolean> => {
  const success = await preFetch('/order/erp-purchase-order/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

export const auditPurchaseOrder = async (orderId: number): Promise<boolean> => {
  const success = await preFetch(
    `/order/erp-purchase-order/approved/${orderId}`,
    {
      method: 'PUT',
    }
  );
  return success;
};

export const rejectPurchaseOrder = async (
  orderId: number
): Promise<boolean> => {
  const success = await preFetch(
    `/order/erp-purchase-order/reject/${orderId}`,
    {
      method: 'PUT',
    }
  );
  return success;
};

export const canInbound = async (orderId: number) => {
  const success = await preFetch(
    `/order/erp-purchase-order/exists-warehouse-items/${orderId}`,
    {
      method: 'GET',
    }
  );
  return success.data;
};

export const inboundPurchaseOrder = async (orderId: number) => {
  const success = await preFetch(
    `/order/erp-purchase-order/warehouse/${orderId}`,
    {
      method: 'GET',
    }
  );
  return success.data;
};

export const printPurchaseOrder = async (orderId: number) => {
  const success = await preFetch(`/order/erp-purchase-order/print/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const purchaseOrderSaveDraft = async (body: {
  [key: string]: any;
}): Promise<boolean> => {
  const success = await preFetch('/order/erp-purchase-order/save-draft', {
    method: 'POST',
    body,
  });
  return success;
};

export const purchaseOrderSubmit = async (body: {
  [key: string]: any;
}): Promise<boolean> => {
  const success = await preFetch('/order/erp-purchase-order/submit', {
    method: 'POST',
    body,
  });
  return success;
};

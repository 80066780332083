import React, { FC } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';

import { Form, Input, Button, Row, Col } from 'antd';

interface Props {
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const CarBrandListQuery: FC<Props> = ({ onReset, onFinish }) => {
  const [form] = Form.useForm();

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  return (
    <div className='page-custom common-query'>
      <Form
        form={form}
        name='car-brand-query'
        layout={'inline'}
        onFinish={onFinish}
      >
        <Row>
          <Col span={6}>
            <Form.Item label='品牌名称' name='name'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='首字母' name='firstCode'>
              <Input placeholder='请输入' maxLength={1} allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='品牌ID' name='id'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Button htmlType='button' onClick={onInnerReset}>
                重置
              </Button>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CarBrandListQuery;

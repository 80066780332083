import React, {
  FC,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { EditableContext } from './editable-context';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { Form, Input, InputNumber, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export type DataType = { [key: string]: any };

interface EditableCellProps {
  title: ReactNode;
  editable: boolean;
  editType: string;
  children: ReactNode;
  dataIndex: keyof DataType;
  record: DataType;
  handleChange: (record: DataType, changedKey: keyof DataType) => void;
  renderFormCell: (
    name: keyof DataType,
    onChange: () => void,
    options: {
      originCell: ReactNode;
      record: DataType;
    }
  ) => ReactNode;
}

export const EditableCell: FC<EditableCellProps> = memo(
  ({
    title,
    editable,
    editType,
    children,
    dataIndex,
    record,
    handleChange,
    renderFormCell,
    ...restProps
  }) => {
    const form = useContext(EditableContext)!;

    useEffect(() => {
      form?.setFieldsValue({ [dataIndex]: record?.[dataIndex] });
    }, [dataIndex, form, record]);

    const onChange = async () => {
      const value = form.getFieldValue(dataIndex);
      handleChange({ ...record, [dataIndex]: value }, dataIndex);
    };

    let childNode = children;

    if (editable) {
      childNode = renderFormCell(dataIndex, onChange, {
        originCell: children,
        record,
      });
    }

    return <td {...restProps}>{childNode}</td>;
  }
);

interface InputCellProps {
  name: keyof DataType;
  value?: string;
  rules?: any;
  placeholder?: string;
  directEdit?: boolean;
  isInputNumber?: boolean;
  numberPrecision?: number;
  onChange: () => void;
}

export const InputCell: FC<InputCellProps> = ({
  name,
  value,
  rules,
  placeholder = '请输入',
  directEdit = true,
  isInputNumber = false,
  numberPrecision = 2,
  onChange,
  children,
}) => {
  const foucsRef = useRef<any>(null);
  const [editing, setEditing] = useState(false);
  const form = useContext(EditableContext)!;

  const toggleEdit = useStableCallback(() => {
    setEditing(!editing);
  });

  const onInnerChange = useStableCallback(async () => {
    try {
      setEditing(!editing);
      onChange();
      await form.validateFields();
    } catch {}
  });

  useEffect(() => {
    if (editing) {
      foucsRef.current?.focus();
    }
  }, [editing]);

  return directEdit || editing ? (
    <Form.Item style={{ margin: 0 }} name={name} rules={rules}>
      {isInputNumber ? (
        <InputNumber
          min={0}
          precision={numberPrecision}
          controls={false}
          placeholder={placeholder}
          ref={foucsRef}
          onPressEnter={onInnerChange}
          onBlur={onInnerChange}
        />
      ) : (
        <Input
          placeholder={placeholder}
          ref={foucsRef}
          onPressEnter={onInnerChange}
          onBlur={onInnerChange}
          allowClear
        />
      )}
    </Form.Item>
  ) : (
    <div
      className='editable-cell-value-wrap'
      style={{ display: 'flex' }}
      onClick={toggleEdit}
    >
      <div
        style={{
          flex: '0 1 auto',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value || form.getFieldValue(name) ? (
          children
        ) : (
          <span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>{placeholder}</span>
        )}
      </div>
      <span style={{ flex: '0 0 auto', marginLeft: '8px' }}>
        <EditOutlined style={{ marginLeft: 4 }} />
      </span>
    </div>
  );
};

export const SelectCell: FC<InputCellProps> = ({
  name,
  placeholder = '请选择',
  onChange,
  children,
}) => {
  return (
    <Form.Item style={{ margin: 0 }} name={name}>
      <Select onChange={onChange} placeholder={placeholder}>
        {children}
      </Select>
    </Form.Item>
  );
};

import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import UseReplaceProductPriceTable from '../tables/replace-product-price';
import BatchImportFile from '../../../common/upload/batch-import-file';
import PriceEcharts from './price-echarts';
import { downloadTemplate } from '../../../../utils/definition';
import { filesHistoryPrice } from '../../../../service/goods';

import { Button, Descriptions, DatePicker, Spin, Empty } from 'antd';
const { RangePicker } = DatePicker;

interface Props {
  priceInfo: any;
  partsId: number;
}

const DetailGoodsPrice: FC<Props> = ({ priceInfo, partsId }) => {
  const [downloadUrl] = useState<string>(
    `${process.env.REACT_APP_DOWNLOAD_URL}${downloadTemplate['商品历史价格']}`
  );

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [historyPrice, setHistoryPrice] = useState<{ [key: string]: any }[]>(
    []
  );
  const [dateValues, setDateValues] = useState([
    moment()
      .day(moment().day() - 2)
      .format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ]);

  const { table } = UseReplaceProductPriceTable({
    list: priceInfo?.partsReplacePrices,
    rowKey: 'partsId',
  });

  const getList = useStableCallback(async () => {
    setSpinLoading(true);
    const params = {
      partsId,
      startTime: dateValues?.[0],
      endTime: dateValues?.[1],
    };
    try {
      const data = await filesHistoryPrice(params);
      setHistoryPrice(data);
    } catch (err) {
    } finally {
      setSpinLoading(false);
    }
  });

  const onChange = useStableCallback(async (dates, dateStrings) => {
    await setDateValues(dateStrings);
    getList();
  });

  useEffect(() => {
    getList();
  }, [getList, partsId]);

  const priceExtra = useMemo(() => {
    return (
      <div>
        <RangePicker
          style={{ marginRight: '8px' }}
          ranges={{
            近三天: [moment().day(moment().day() - 2), moment()],
            近一周: [moment().day(moment().day() - 7), moment()],
            近十天: [moment().day(moment().day() - 10), moment()],
          }}
          defaultValue={[moment().day(moment().day() - 2), moment()]}
          onChange={onChange}
        />
        <Button href={downloadUrl} download style={{ marginRight: '8px' }}>
          下载模板
        </Button>
        <BatchImportFile
          importUrl={`/erp-parts-price-record/import/${partsId}`}
          getList={getList}
        />
      </div>
    );
  }, [downloadUrl, getList, onChange, partsId]);

  return (
    <div className='detail-tabpanel'>
      <Descriptions title='商品价格'>
        <Descriptions.Item label='4s销售价(元)'>
          {priceInfo?.salePrice4s || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='4s进店价(元)'>
          {priceInfo?.purchasePrice4s || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='销售商价(元)'>
          {priceInfo?.dealerPrice || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='修理厂价(元)'>
          {priceInfo?.repairPrice || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='车主价(元)'>
          {priceInfo?.ownerPrice || '-'}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        column={1}
        title='替换商品价格'
        style={{ border: 'none', paddingBottom: 0 }}
      >
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          {table}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        column={1}
        title='历史价格'
        extra={priceExtra}
        className='detail-tabpanel-price'
      >
        <Descriptions.Item>
          <Spin spinning={spinLoading}>
            {historyPrice && historyPrice.length ? (
              <PriceEcharts historyPrice={historyPrice} />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Spin>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DetailGoodsPrice;

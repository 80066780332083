import React from 'react';
import { ModelListProps } from '../../../../service/car';
import { ColumnsType } from 'antd/lib/table';

export const GetCarModelsColumns = (
  excluedIds: string[] = [],
  renderOpeartor?: (record: ModelListProps) => React.ReactElement
): ColumnsType<ModelListProps> => {
  const list: ColumnsType<ModelListProps> = [
    {
      title: '车型名称',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: '车型分类',
      dataIndex: 'catalog',
      key: 'catalog',
      width: 180,
    },
    {
      title: '车型代码',
      dataIndex: 'code',
      key: 'code',
      width: 120,
    },
    {
      title: '车系',
      dataIndex: 'seriesName',
      key: 'seriesName',
      width: 100,
    },
    {
      title: '年款',
      dataIndex: 'year',
      key: 'year',
      width: 100,
    },
    {
      title: '底盘号',
      dataIndex: 'chassisCode',
      key: 'chassisCode',
      width: 120,
    },
    {
      title: '发动机型号',
      dataIndex: 'engineCode',
      key: 'engineCode',
      width: 120,
    },
    {
      title: '变速箱型号',
      dataIndex: 'gearBoxCode',
      key: 'gearBoxCode',
      width: 120,
    },
    {
      title: '电机型号',
      dataIndex: 'motorCode',
      key: 'motorCode',
      width: 100,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 100,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

import React, { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import TableBatchDelete from '../../common/table/table-batch-delete';
import UseTable from '../../common/table/use-table';
import { GetOutboundOrderListColumns } from './tables/files-list-columns';
import {
  closeAddLogisticsModal,
  openAddLogisticsModal,
} from '../../erp/modal/add-logistics-modal';
import {
  closeOutboundOrderPrintModal,
  openOutboundOrderPrintModal,
} from './print';
import {
  OutboundOrderListProps,
  outboundOrderPage,
  deleteOutboundOrder,
  finishOutboundOrder,
} from '../../../service/warehouse';

import { Button, Divider, Modal, message } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

interface Props {
  searchForm?: { [key: string]: any };
}

const OutboundOrderTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      if (searchForm && searchForm.outDate) {
        searchForm.startDate = searchForm.outDate[0]?.format('YYYY-MM-DD');
        searchForm.endDate = searchForm.outDate[1]?.format('YYYY-MM-DD');
      }
      let params = { ...searchForm, ...listPageData };
      const { records, total } = await outboundOrderPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const onSelectCheckbox = useStableCallback((record: any) => ({
    disabled: record.orderStatus !== 0,
  }));

  const addRow = useStableCallback(() => {
    history.push('/warehouse/outbound/add/no');
  });

  const editRow = useStableCallback((record: any) => {
    history.push(`/warehouse/outbound/edit/${record.orderId}`);
  });

  const detailRow = useStableCallback((record: any) => {
    history.push(`/warehouse/outbound/detail/${record.orderId}`);
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除出库单【${record.orderId}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch([record.orderId]);
      },
    });
  });

  const deleteBatchRow = useStableCallback((ids: number[]) => {
    if (!ids || !ids.length) return message.warning('请先选择需删除的出库单');
    Modal.confirm({
      title: `确定删除选中的出库单吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch(ids);
      },
    });
  });

  const deleteBatch = useStableCallback(async (ids: number[]) => {
    try {
      await deleteOutboundOrder(ids);
      message.success('删除成功');
      setSelectedRowKeys([]);
      getList(1);
    } catch (err) {}
  });

  const deliveryRow = useStableCallback((record: any) => {
    openAddLogisticsModal({
      visible: true,
      type: 'edit',
      orderType: 11,
      orderId: record.orderId,
      onCancel: closeAddLogisticsModal,
      onConfirm: () => getList(pageData.current),
    });
  });

  const finishRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定完成出库单【${record.orderId}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await finishOutboundOrder(record.orderId);
          message.success('订单完成');
          getList(pageData.current);
        } catch (err) {}
      },
    });
  });

  const printRow = useStableCallback((record: any) => {
    openOutboundOrderPrintModal({
      visible: true,
      orderId: record.orderId,
      onCancel: closeOutboundOrderPrintModal,
    });
  });

  const renderOpeartor = useStableCallback((record: any) => {
    return (
      <>
        {record.orderStatus === 0 && (
          <>
            <Button type='link' onClick={() => editRow(record)}>
              编辑
            </Button>
            <Divider type='vertical' />
            <Button type='link' danger onClick={() => deleteRow(record)}>
              删除
            </Button>
          </>
        )}
        {record.orderStatus === 10 && (
          <Button type='link' onClick={() => detailRow(record)}>
            审核
          </Button>
        )}
        {record.orderStatus === 20 && (
          <Button type='link' onClick={() => detailRow(record)}>
            发货
          </Button>
        )}
        {record.orderStatus === 22 && (
          <>
            <Button type='link' onClick={() => deliveryRow(record)}>
              物流变更
            </Button>
            <Divider type='vertical' />
            <Button type='link' onClick={() => finishRow(record)}>
              完成
            </Button>
          </>
        )}
        {record.orderStatus === 30 && (
          <Button type='link' onClick={() => printRow(record)}>
            打印
          </Button>
        )}
      </>
    );
  });

  const columns: ColumnsType<OutboundOrderListProps> = useMemo(
    () => GetOutboundOrderListColumns(renderOpeartor),
    [renderOpeartor]
  );

  const { selectedRowKeys, setSelectedRowKeys, tableSelect } = TableBatchDelete(
    { deleteBatchRow }
  );

  const { table, setLoading, setList, setListTotal, pageData, setPageData } =
    UseTable({
      columns,
      getList,
      rowKey: 'orderId',
      selectedRowKeys,
      onSelectChange,
      onSelectCheckbox,
    });

  useEffect(() => {
    getList(1);
  }, [getList, searchForm]);

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>出库单列表</div>
        <div className='header-operate flex-x'>
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {selectedRowKeys.length > 0 && tableSelect}

      {table}
    </div>
  );
};

export default OutboundOrderTable;

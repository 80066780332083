import React, { FC } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { tenantTypeMap } from '../../../utils/definition';

import { Form, Input, Button, Select, Row, Col } from 'antd';
const { Option } = Select;

interface Props {
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const TenantListQuery: FC<Props> = ({ onReset, onFinish }) => {
  const [form] = Form.useForm();

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  return (
    <div className='page-custom common-query'>
      <Form
        form={form}
        name='tenant-query'
        layout={'inline'}
        onFinish={onFinish}
      >
        <Row>
          <Col span={8}>
            <Form.Item label='租户编码' name='euscc'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='租户名' name='tenantName'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='法人' name='legalPerson'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='手机号' name='phoneNumber'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='租户类型' name='tenantType'>
              <Select placeholder='请选择' allowClear>
                {Object.entries(tenantTypeMap).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button htmlType='button' onClick={onInnerReset}>
                重置
              </Button>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TenantListQuery;

import React from 'react';
import { Link } from 'react-router-dom';
import { erpOrderStatusMap1 } from '../../../../utils/definition';
import { SalesOrderListProps } from '../../../../service/sales';

import { ColumnsType } from 'antd/lib/table';

export const GetSalesOrderListColumns = (
  renderOpeartor?: (record: SalesOrderListProps) => React.ReactElement,
  excluedIds: string[] = []
): ColumnsType<SalesOrderListProps> => {
  const list: ColumnsType<SalesOrderListProps> = [
    {
      title: '销售单号',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (text: string, record: any) => (
        <>
          {record.orderStatus !== 0 ? (
            <Link to={`/sales/sorder/detail/${record.orderId}`}>{text}</Link>
          ) : (
            <div>{text}</div>
          )}
        </>
      ),
      fixed: 'left' as any,
      width: 140,
    },
    {
      title: '单位名称',
      dataIndex: 'unitName',
      key: 'unitName',
      width: 120,
    },
    {
      title: '业务员',
      dataIndex: 'salesMan',
      key: 'salesMan',
      width: 100,
    },
    {
      title: '应收总额(元)',
      dataIndex: 'ppayAmtFormat',
      key: 'ppayAmtFormat',
      width: 100,
      align: 'right' as any,
    },
    {
      title: '收款方式',
      dataIndex: 'payment',
      key: 'payment',
      width: 100,
    },
    {
      title: '开票类型',
      dataIndex: 'invoicingType',
      key: 'invoicingType',
      width: 120,
    },
    {
      title: '发票单位名称',
      dataIndex: 'taxCompany',
      key: 'taxCompany',
      width: 120,
    },
    {
      title: '发运方式',
      dataIndex: 'shipping',
      key: 'shipping',
      width: 100,
    },
    {
      title: '订单状态',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      width: 120,
      render: (status: number) => (
        <div className='flex-x m-s-start table-status'>
          <div
            className={`iconfont ${erpOrderStatusMap1[status]?.[2]}`}
            style={{ color: erpOrderStatusMap1[status]?.[1] }}
          ></div>
          <div>{erpOrderStatusMap1[status]?.[0]}</div>
        </div>
      ),
    },
    {
      title: '开单日期',
      dataIndex: 'orderDate',
      key: 'orderDate',
      width: 120,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 140,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

import React, { FC } from 'react';
import { shippingMethod } from '../../utils/definition';
import DropDownSelect from '../common/select/drop-down-select';
import { UnitContactsProps, UnitShopsProps } from '../../service/unit';

import { Col, Form, Input, Row, Select } from 'antd';
const { Option, OptGroup } = Select;

interface Props {
  shopList: UnitShopsProps[];
  shopContacts: UnitShopsProps[];
  unitContacts: UnitContactsProps[];
}

const EditReceiptInfo: FC<Props> = ({
  shopList,
  shopContacts,
  unitContacts,
}) => {
  return (
    <Row className='details-form'>
      <Col span={7}>
        <Form.Item
          label='发运方式'
          name='shipping'
          rules={[{ required: true, message: '请选择发运方式' }]}
        >
          <Select placeholder='请选择' allowClear>
            {Object.entries(shippingMethod).map(([key, value]) => (
              <Option value={value} key={key}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={7} offset={1}>
        <Form.Item label='门店(分公司)' name='subShop'>
          <DropDownSelect options={shopList} hasId={true} />
        </Form.Item>
      </Col>
      <Col span={7} offset={1}>
        <Form.Item
          label='联系人'
          name='contacts'
          rules={[{ required: true, message: '请选择联系人' }]}
        >
          <Select placeholder='请选择' allowClear>
            {shopContacts && shopContacts.length && (
              <OptGroup label='门店'>
                {shopContacts?.map((item: any, index: number) => (
                  <Option value={item.id} key={item.id}>
                    {item.contract}
                  </Option>
                ))}
              </OptGroup>
            )}
            {unitContacts && unitContacts.length && (
              <OptGroup label='单位'>
                {unitContacts?.map((item: any, index: number) => (
                  <Option value={item.unitId} key={item.unitId}>
                    {item.name}
                  </Option>
                ))}
              </OptGroup>
            )}
          </Select>
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item
          label='联系电话'
          name='contactNumber'
          rules={[{ required: true, message: '请输入联系电话' }]}
        >
          <Input placeholder={'请输入'} allowClear />
        </Form.Item>
      </Col>
      <Col span={15} offset={1}>
        <Form.Item
          label='地址'
          name='deliveryAddress'
          rules={[{ required: true, message: '请输入地址' }]}
        >
          <Input placeholder={'请输入'} allowClear />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default EditReceiptInfo;

import React, { FC, useEffect, useMemo, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { filterParams } from '../../../../utils/common';
import { createCloseModal, createOpenModal } from '../../../../utils/modal';
import UseTable from '../../../common/table/use-table';
import SelectModelsQuery from './select-models-query';
import { GetCarModelsColumns } from '../tables/car-models-columns';
import { ModelListProps, partsModelPage } from '../../../../service/car';

import { ConfigProvider, message, Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';

interface Props {
  selectedModelIdList?: number[]; // 已选择的 车型 ID集合
  visible: boolean;
  onCancel: () => void;
  onConfirm: (list: any) => void;
}

const SelectModelsModal: FC<Props> = ({
  selectedModelIdList = [],
  visible,
  onCancel,
  onConfirm,
}) => {
  const [resetId, setResetId] = useState<number>(0);
  const [searchForm, setSearchForm] = useState<{ [key: string]: any }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      let params = {
        ...searchForm,
        ...listPageData,
        selectedModelIdList,
      };
      const { records, total } = await partsModelPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
      onCancel();
    } finally {
      setLoading(false);
    }
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const columns: ColumnsType<ModelListProps> = useMemo(
    () => GetCarModelsColumns(),
    []
  );

  const {
    table,
    setLoading,
    setList,
    setListTotal,
    pageData,
    setPageData,
    list,
  } = UseTable({
    columns,
    getList,
    selectedRowKeys,
    onSelectChange,
    scroll: { x: '60%', y: 200 },
  });

  const onInnerConfirm = useStableCallback(() => {
    if (!selectedRowKeys || !selectedRowKeys.length)
      return message.warning('请先选择添加车型');
    const addList = list.filter((item) => selectedRowKeys.includes(item.id));
    onConfirm(addList);
    onInnerCancel();
  });

  const onInnerCancel = useStableCallback(() => {
    setResetId(resetId + 1);
    setSelectedRowKeys([]);
    onCancel();
  });

  const handleQuery = useStableCallback(async (values) => {
    await setSearchForm(filterParams(values));
    setSelectedRowKeys([]);
    getList(1);
  });

  useEffect(() => {
    getList(1);
  }, [getList]);

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title='添加适用车型'
        width={'60%'}
        style={{ overflow: 'hidden' }}
        centered
        maskClosable={false}
        visible={visible}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <SelectModelsQuery
          resetId={resetId}
          onReset={handleQuery}
          onFinish={handleQuery}
        />

        <div style={{ margin: '16px 0 20px', color: 'rgba(0, 0, 0, 0.65)' }}>
          车型列表
        </div>

        {table}
      </Modal>
    </ConfigProvider>
  );
};

export const openSelectModelsModal = createOpenModal(
  SelectModelsModal,
  'SelectModelsModal'
);

export const closeSelectModelsModal = createCloseModal(
  SelectModelsModal,
  'SelectModelsModal'
);

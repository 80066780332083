import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import OrderStatusLogo from '../../../erp/order-status';
import UseOutgoingInfoTable from './outgoing-info-table';
import SalesInfo from './sales-info';

import { Descriptions } from 'antd';

interface Props {
  basicInfo: any;
}

const DetailBasicInfo: FC<Props> = ({ basicInfo }) => {
  const outgoingInfoTable = UseOutgoingInfoTable({ basicInfo });

  const titleExtra = useMemo(() => {
    return (
      <>
        <span>销售单号：{basicInfo?.salesOrder?.orderId}</span>
        <Link
          className='detail-tabpanel-link'
          to={`/sales/sorder/detail/${basicInfo?.salesOrder?.orderId}`}
        >
          查看详情
        </Link>
      </>
    );
  }, [basicInfo]);

  return (
    <div className='detail-tabpanel detail-erp'>
      <div className='detail-erp-logo'>
        <OrderStatusLogo type={1} status={basicInfo?.orderStatus} />
      </div>

      {basicInfo?.salesOrder && (
        <>
          <SalesInfo title={titleExtra} info={basicInfo?.salesOrder} />
          <div className='detail-tabpanel-space'></div>
        </>
      )}

      <Descriptions title='出库信息'>
        <Descriptions.Item label='出库人'>
          {basicInfo?.outboundContacts || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='出库部门'>
          {basicInfo?.outboundDept || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='出库日期'>
          {basicInfo?.orderDate || '-'}
        </Descriptions.Item>
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
          span={3}
        >
          {outgoingInfoTable}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DetailBasicInfo;

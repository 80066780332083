import './index.scss';

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';

import BreadcrumbCustom from '../../breadcrumb-custom';
import DetailBasicInfo from './detail/basic';
import DetailGoodsPrice from './detail/price';
import DetailPhoto from './detail/photo';

import {
  filesBasicDetails,
  filesPriceDetails,
  filesPhotoDetails,
} from '../../../service/goods';

import { Button, Spin, Tabs } from 'antd';
const { TabPane } = Tabs;

const GoodsFilesDetail: FC = () => {
  const params: any = useParams();
  const history = useHistory();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [basicInfo, setBasicInfo] = useState<any>(null);
  const [priceInfo, setPriceInfo] = useState<any>(null);
  const [photoInfo, setPhotoInfo] = useState<any>(null);

  const changeTabs = useStableCallback((menu) => {
    setSpinLoading(true);
    if (menu === 'photo') {
      filesPhotoDetails(params.id)
        .then(setPhotoInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    } else if (menu === 'price') {
      filesPriceDetails(params.id)
        .then(setPriceInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    } else {
      filesBasicDetails(params.id)
        .then(setBasicInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  });

  useEffect(() => {
    setSpinLoading(true);
    filesBasicDetails(params.id)
      .then(setBasicInfo)
      .catch((err) => {})
      .finally(() => {
        setSpinLoading(false);
      });
  }, [params.id]);

  const extraButton = useMemo(() => {
    return (
      <div>
        <Link to={`/goods/gfiles/edit/${params.id}`}>
          <Button>编辑</Button>
        </Link>
      </div>
    );
  }, [params.id]);

  return (
    <Spin spinning={spinLoading}>
      <BreadcrumbCustom
        paths={['商品管理', '商品档案', '商品详情']}
        title={`商品详情：${basicInfo?.name}`}
        extra={extraButton}
      />

      <Tabs
        defaultActiveKey='basic'
        className='goods-files-detail'
        onChange={changeTabs}
      >
        <TabPane tab='基本信息' key='basic'>
          <DetailBasicInfo basicInfo={basicInfo} />
        </TabPane>
        <TabPane tab='商品价格' key='price'>
          <DetailGoodsPrice priceInfo={priceInfo} partsId={params?.id} />
        </TabPane>
        <TabPane tab='图片/视频' key='photo'>
          <DetailPhoto photoInfo={photoInfo} />
        </TabPane>
      </Tabs>

      <div className='common-buttons flex-x m-s-end minauto'>
        <Button onClick={history.goBack}>返回</Button>
      </div>
    </Spin>
  );
};

export default GoodsFilesDetail;

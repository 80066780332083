import React, { FC, useEffect, useMemo, useState } from 'react';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import {
  openSelectModelsModal,
  closeSelectModelsModal,
} from '../edit/select-models-modal';
import { ModelListProps } from '../../../../service/car';
import { GetCarModelsColumns } from './car-models-columns';

import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

interface Props {
  value?: any[];
  onChange?: (newValue: any[]) => void;
}

const CarModelsTable: FC<Props> = ({ value = [], onChange }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);

  const handleValueChange = useStableCallback((newValue: any[]) => {
    setDataSource(newValue);
    onChange?.(newValue);
  });

  const handleDelete = useStableCallback((id: number) => {
    handleValueChange(dataSource.filter((item) => item.id !== id));
  });

  const handleAdd = useStableCallback((list) => {
    handleValueChange([...dataSource, ...list]);
  });

  const handleAddModels = useStableCallback(() => {
    openSelectModelsModal({
      selectedModelIdList: dataSource?.map((item) => {
        return item.id;
      }),
      visible: true,
      onCancel: closeSelectModelsModal,
      onConfirm: handleAdd,
    });
  });

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value);
    }
  }, [value]);

  const renderOpeartor = useStableCallback((record: any) => {
    return (
      <>
        <Button type='link' danger onClick={() => handleDelete(record?.id)}>
          删除
        </Button>
      </>
    );
  });

  const columns: ColumnsType<ModelListProps> = useMemo(
    () => GetCarModelsColumns(['year'], renderOpeartor),
    [renderOpeartor]
  );

  return (
    <div>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <Button
        block
        type='dashed'
        icon={<PlusOutlined />}
        style={{ margin: '16px 0 24px' }}
        onClick={handleAddModels}
      >
        添加车型
      </Button>
    </div>
  );
};

export default CarModelsTable;

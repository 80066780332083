import React, { FC } from 'react';
import OrderStatusLogo from '../../../erp/order-status';
import ReceiptInfo from '../../../erp/receipt-info';
import UseDeliveryInfoTable from '../../../erp/delivery-info-table';

import { Descriptions } from 'antd';

interface Props {
  receiptInfo: any;
}

const DetailReceiptInfo: FC<Props> = ({ receiptInfo }) => {
  const { table: deliveryInfoTable } = UseDeliveryInfoTable({
    list: receiptInfo?.orderLogistics,
    excluedIds: ['outOrderId'],
  });

  return (
    <div className='detail-tabpanel detail-erp'>
      <div className='detail-erp-logo'>
        <OrderStatusLogo type={2} status={receiptInfo?.orderStatus} />
      </div>

      <ReceiptInfo title='供货信息' info={receiptInfo} />

      <Descriptions column={1} title='入库信息'>
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          {deliveryInfoTable}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DetailReceiptInfo;

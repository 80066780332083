import React, { FC, useEffect, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import DropDownSelect from '../../common/select/drop-down-select';
import { createOpenModal, createCloseModal } from '../../../utils/modal';
import { freightShareWay, invoiceType } from '../../../utils/definition';
import {
  addErpOrderLogistics,
  editErpOrderLogistics,
  getErpOrderLogistics,
} from '../../../service/warehouse';
import {
  getUnitNamesList,
  getUnitShopContactsList,
} from '../../../service/unit';

import {
  ConfigProvider,
  Modal,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Select,
  message,
} from 'antd';
const { Option } = Select;

interface Props {
  visible: boolean;
  type: string;
  orderType: number;
  orderId: number;
  onCancel: () => void;
  onConfirm: () => void;
}

const AddLogisticsModal: FC<Props> = ({
  visible,
  type,
  orderType,
  orderId,
  onCancel,
  onConfirm,
}) => {
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [logisticsList, setLogisticsList] = useState<any[]>([]);
  const [contactsList, setContactsList] = useState<any[]>([]);

  const onValuesChange = useStableCallback(async (changedValues) => {
    const key = Object.keys(changedValues)[0];
    if (key === 'logisticsUnitId') {
      if (!changedValues.logisticsUnitId) {
        setContactsList([]);
        form.setFieldsValue({
          contacts: '',
          contactNumber: '',
        });
        return;
      }

      const res = await getUnitShopContactsList(changedValues.logisticsUnitId);
      const defaultContacts = res.unitContacts.filter(
        (each: any) => each.isDefault
      )?.[0];
      setContactsList(res.unitContacts);
      form.setFieldsValue({
        contacts: defaultContacts?.id,
        contactNumber: defaultContacts?.mobile,
      });
    } else if (key === 'contacts') {
      const chooseContact = contactsList.filter(
        (each: any) => each.id === changedValues.contacts
      )?.[0];
      form.setFieldsValue({ contactNumber: chooseContact?.mobile });
    }
  });

  const onInnerConfirm = useStableCallback(async () => {
    await form.validateFields();
    setSubmitLoading(true);
    const params = { ...form.getFieldsValue(true) };
    params.orderId = orderId;
    params.orderType = orderType;
    params.contacts = contactsList.filter(
      (each: any) => each.id === params.contacts
    )?.[0]?.name;
    try {
      if (type === 'add') {
        await addErpOrderLogistics(params);
        message.success('发货成功');
      } else {
        await editErpOrderLogistics(params.id, params);
        message.success('物流更新成功');
      }
      onConfirm();
      onInnerCancel();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onInnerCancel = useStableCallback(() => {
    onCancel();
    form.resetFields();
  });

  useEffect(() => {
    if (type === 'edit') {
      getErpOrderLogistics(orderId)
        .then((res) => {
          form.setFieldsValue(res);
          return getUnitShopContactsList(res.logisticsUnitId);
        })
        .then((res1) => {
          setContactsList(res1.unitContacts);
          const contacts = res1.unitContacts.filter(
            (each: any) => each.name === form.getFieldValue('contacts')
          );
          form.setFieldsValue({ contacts: contacts?.[0]?.id });
        });
    } else {
      form.setFieldsValue({ taxRate: 3, distributeType: 0 });
    }
  }, [form, orderId, type]);

  useEffect(() => {
    if (visible) {
      getUnitNamesList('物流').then(setLogisticsList);
    }
  }, [visible]);

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title='添加物流信息'
        width={'70%'}
        centered
        maskClosable={false}
        visible={visible}
        confirmLoading={submitLoading}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <Form
          name='add-logistics'
          labelCol={{ span: 6 }}
          form={form}
          onValuesChange={onValuesChange}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label='单号'
                name='logisticsNo'
                rules={[{ required: true, message: '请输入单号' }]}
              >
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='物流公司'
                name='logisticsUnitId'
                rules={[{ required: true, message: '请选择物流公司' }]}
              >
                <DropDownSelect options={logisticsList} hasId={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='联系人' name='contacts'>
                <DropDownSelect options={contactsList} hasId={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='联系电话' name='contactNumber'>
                <Input placeholder='请输入' disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='费用(元)' name='shippingAmt'>
                <InputNumber
                  min={0}
                  precision={2}
                  controls={false}
                  placeholder='请输入'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='运费分摊方式'
                name='distributeType'
                rules={[{ required: true, message: '请选择运费分摊方式' }]}
              >
                <Select placeholder='请选择' allowClear>
                  {Object.entries(freightShareWay).map(([key, value]) => (
                    <Option value={Number(key)} key={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='运费税率(%)' name='taxRate'>
                <InputNumber
                  min={0}
                  max={100}
                  precision={2}
                  controls={false}
                  placeholder='请输入'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='运费开票类型' name='invoicingType'>
                <Select placeholder='请选择' allowClear>
                  {Object.entries(invoiceType).map(([key, value]) => (
                    <Option value={value} key={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='备注' name='remark'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export const openAddLogisticsModal = createOpenModal(
  AddLogisticsModal,
  'AddLogisticsModal'
);

export const closeAddLogisticsModal = createCloseModal(
  AddLogisticsModal,
  'AddLogisticsModal'
);

import { useMemo } from 'react';
import { GetCarModelsColumns } from '../tables/car-models-columns';
import { ModelListProps } from '../../../../service/car';
import { ColumnsType } from 'antd/lib/table';
import UseDetailTable from '../../../common/table/detail-table';

interface Params {
  list: { [key: string]: any }[];
}

const UseCarModelsTable = ({ list }: Params) => {
  const columns: ColumnsType<ModelListProps> = useMemo(
    () => GetCarModelsColumns(['year']),
    []
  );

  const table = UseDetailTable({ columns, list });

  return {
    table,
  };
};

export default UseCarModelsTable;

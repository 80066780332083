import React, { useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { filterParams } from '../../../utils/common';

import TenantListQuery from './query';
import TenantListTable from './table';

const TenantManagement = () => {
  const [searchForm, setSearchForm] = useState<{ [key: string]: any }>({});

  const onSearchFinish = useStableCallback((values) => {
    setSearchForm(filterParams(values));
  });
  const onSearchReset = useStableCallback((values) => {
    setSearchForm(filterParams(values));
  });

  return (
    <>
      <TenantListQuery onFinish={onSearchFinish} onReset={onSearchReset} />
      <TenantListTable searchForm={searchForm} />
    </>
  );
};

export default TenantManagement;

import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';

export const GetSelectProductColumns = (
  excluedIds: string[] = []
): ColumnsType => {
  const list: ColumnsType = [
    {
      title: '商品编号',
      dataIndex: 'partsCode',
      key: 'partsCode',
      render: (text: string, record: any) => {
        return (
          <Button
            type='link'
            href={`/goods/gfiles/detail/${record.partsId}`}
            target='_blank'
          >
            {text}
          </Button>
        );
      },
      fixed: 'left' as any,
      width: 100,
    },
    {
      title: '商品名称',
      dataIndex: 'partsName',
      key: 'partsName',
      width: 100,
    },
    {
      title: '品牌',
      dataIndex: 'partsBrandName',
      key: 'partsBrandName',
      width: 80,
    },
    {
      title: '适用车系',
      dataIndex: 'sname',
      key: 'sname',
      width: 80,
    },
    {
      title: '适用车型',
      dataIndex: 'mname',
      key: 'mname',
      width: 120,
    },
    {
      title: '所在仓库',
      dataIndex: 'warehouseNames',
      key: 'warehouseNames',
      width: 140,
      ellipsis: true,
    },
    {
      title: '总库存',
      dataIndex: 'inventoryQtyFormat',
      key: 'inventoryQtyFormat',
      width: 80,
    },
    {
      title: '总动态数',
      dataIndex: 'dynamicQtyFormat',
      key: 'dynamicQtyFormat',
      width: 80,
    },
    {
      title: '销售动态数',
      dataIndex: 'outDynamicQtyFormat',
      key: 'outDynamicQtyFormat',
      width: 100,
    },
    {
      title: '采购动态数',
      dataIndex: 'inDynamicQtyFormat',
      key: 'inDynamicQtyFormat',
      width: 100,
    },
    {
      title: '成本单价(元)',
      dataIndex: 'costAmtFormat',
      key: 'costAmtFormat',
      width: 100,
      align: 'right' as any,
    },
    {
      title: '上次实销单价(元)',
      dataIndex: 'latestActualAmtFormat',
      key: 'latestOutActualAmtFormat',
      width: 130,
      align: 'right' as any,
    },
    {
      title: '上次实购单价(元)',
      dataIndex: 'latestActualAmtFormat',
      key: 'latestInActualAmtFormat',
      width: 130,
      align: 'right' as any,
    },
    {
      title: '4s销售价(元)',
      dataIndex: 'salePrice4sFormat',
      key: 'salePrice4sFormat',
      width: 100,
      align: 'right' as any,
    },
    {
      title: '4s进店价(元)',
      dataIndex: 'purchasePrice4sFormat',
      key: 'purchasePrice4sFormat',
      width: 100,
      align: 'right' as any,
    },
    {
      title: '销售商价(元)',
      dataIndex: 'dealerPriceFormat',
      key: 'dealerPriceFormat',
      width: 100,
      align: 'right' as any,
    },
    {
      title: '修理厂价(元)',
      dataIndex: 'repairPriceFormat',
      key: 'repairPriceFormat',
      width: 100,
      align: 'right' as any,
    },
    {
      title: '车主价(元)',
      dataIndex: 'ownerPriceFormat',
      key: 'ownerPriceFormat',
      width: 100,
      align: 'right' as any,
    },
  ].filter((each) => !excluedIds.includes(each.key));

  return list;
};

import { ColumnsType } from 'antd/lib/table';

export const GetPrintListColumns = (excluedIds: string[] = []): ColumnsType => {
  const list: ColumnsType = [
    {
      title: '序号',
      dataIndex: 'indexCode',
      key: 'indexCode',
    },
    {
      title: '类型',
      dataIndex: 'indexCode',
      key: 'indexCode',
    },
    {
      title: '商品编号',
      dataIndex: 'partsCode',
      key: 'partsCode',
    },
    {
      title: '商品名称',
      dataIndex: 'partsName',
      key: 'partsName',
    },
    {
      title: '品牌',
      dataIndex: 'partsBrandName',
      key: 'partsBrandName',
    },
    {
      title: '车型',
      dataIndex: 'displayModelName',
      key: 'displayModelName',
    },
    {
      title: '单位',
      dataIndex: 'partsUnit',
      key: 'partsUnit',
      align: 'center' as any,
    },
    {
      title: '数量',
      dataIndex: 'orderQtyFormat',
      key: 'orderQtyFormat',
    },
    {
      title: '单价',
      dataIndex: 'spSubAmtFormat',
      key: 'spSubAmtFormat',
      align: 'right' as any,
    },
    {
      title: '成本单价',
      dataIndex: 'spSubAmtFormat',
      key: 'spSubAmtFormat',
      align: 'right' as any,
    },
    {
      title: '金额',
      key: 'spAmtFormat',
      dataIndex: 'spAmtFormat',
      align: 'right' as any,
    },
    {
      title: '组装费用',
      key: 'spAmtFormat',
      dataIndex: 'spAmtFormat',
      align: 'right' as any,
    },
    {
      title: '仓库/库位',
      key: 'warehouseCode',
      dataIndex: 'warehouseCode',
      render: (text: any, record: any) => (
        <div>
          {record.warehouseCode}/{record.locationCode}
        </div>
      ),
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  return list;
};

import React from 'react';
import { Link } from 'react-router-dom';
import { OrderLogisticsProps } from '../../../service/sales';

import { ColumnsType } from 'antd/lib/table';

export const GetDeliveryInfoColumns = (
  excluedIds: string[] = [],
  renderOpeartor?: (record: OrderLogisticsProps) => React.ReactElement
): ColumnsType<OrderLogisticsProps> => {
  const list: ColumnsType<OrderLogisticsProps> = [
    {
      title: '出库单号',
      dataIndex: 'orderId',
      key: 'outOrderId',
      width: 140,
      render: (text: string, record: any) => {
        return (
          <Link to={`/warehouse/outbound/detail/${record.orderId}`}>
            {text}
          </Link>
        );
      },
      fixed: 'left' as any,
    },
    {
      title: '入库单号',
      dataIndex: 'orderId',
      key: 'inOrderId',
      width: 140,
      render: (text: string, record: any) => {
        return (
          <Link to={`/warehouse/inbound/detail/${record.orderId}`}>{text}</Link>
        );
      },
      fixed: 'left' as any,
    },
    {
      title: '物流单号',
      dataIndex: 'logisticsNo',
      key: 'logisticsNo',
      width: 140,
    },
    {
      title: '物流公司',
      dataIndex: 'logisticsUnitName',
      key: 'logisticsUnitName',
      width: 100,
    },
    {
      title: '联系人',
      dataIndex: 'contacts',
      key: 'contacts',
      width: 100,
    },
    {
      title: '联系电话',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      width: 120,
    },
    {
      title: '物流费用(元)',
      dataIndex: 'shippingAmtFormat',
      key: 'shippingAmtFormat',
      width: 100,
      align: 'right' as any,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      width: 120,
    },
  ].filter((each) => !excluedIds.includes(each.key));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 100,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

import React, { FC } from 'react';
import OrderStatusLogo from '../../../erp/order-status';
import ReceiptInfo from '../../../erp/receipt-info';
import UseDeliveryInfoTable from '../../../erp/delivery-info-table';

import { Descriptions } from 'antd';

interface Props {
  logisticsInfo: any;
}

const DetailLogisticsInfo: FC<Props> = ({ logisticsInfo }) => {
  const { table: deliveryInfoTable } = UseDeliveryInfoTable({
    list: logisticsInfo?.logistics && [logisticsInfo.logistics],
    excluedIds: ['outOrderId', 'inOrderId'],
  });

  return (
    <div className='detail-tabpanel detail-erp'>
      <div className='detail-erp-logo'>
        <OrderStatusLogo type={1} status={logisticsInfo?.orderStatus} />
      </div>

      <ReceiptInfo title='收货信息' info={logisticsInfo} />

      <Descriptions column={1} title='物流信息'>
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          {deliveryInfoTable}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DetailLogisticsInfo;

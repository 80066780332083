import { useMemo } from 'react';
import UseDetailTable from '../common/table/detail-table';
import { GetDeliveryInfoColumns } from './tables/delivery-info-columns';
import { OrderLogisticsProps } from '../../service/sales';

import { ColumnsType } from 'antd/lib/table';

interface Params {
  list: { [key: string]: any }[];
  excluedIds?: string[];
}

const UseDeliveryInfoTable = ({ list, excluedIds = [] }: Params) => {
  const columns: ColumnsType<OrderLogisticsProps> = useMemo(
    () => GetDeliveryInfoColumns(excluedIds),
    [excluedIds]
  );

  const table = UseDetailTable({ columns, list });

  return {
    table,
  };
};

export default UseDeliveryInfoTable;

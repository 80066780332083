import React, { FC } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { createCloseModal, createOpenModal } from '../../../utils/modal';

import { Button, ConfigProvider, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface Props {
  source: string;
  orderId: number;
  visible: boolean;
  onReject: () => void;
  onAudit: () => void;
  onCancel: () => void;
}

const AuditModal: FC<Props> = ({
  source,
  orderId,
  visible,
  onReject,
  onAudit,
  onCancel,
}) => {
  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        className='ant-modal-confirm ant-modal-confirm-confirm'
        width={460}
        centered
        closable={false}
        maskClosable={false}
        footer={null}
        visible={visible}
      >
        <div className='ant-modal-confirm-body-wrapper'>
          <div className='ant-modal-confirm-body'>
            <ExclamationCircleOutlined />
            <span className='ant-modal-confirm-title'>
              审核{source}【{orderId}】
            </span>
            <div className='ant-modal-confirm-content'>
              审核通过后，该{source}不可修改或删除，请谨慎操作。
            </div>
          </div>
          <div className='ant-modal-confirm-btns'>
            <Button danger style={{ marginRight: '16px' }} onClick={onReject}>
              驳回
            </Button>
            <Button type='primary' onClick={onAudit}>
              审核通过
            </Button>
            <Button onClick={onCancel}>取消</Button>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export const openAuditModal = createOpenModal(AuditModal, 'AuditModal');

export const closeAuditModal = createCloseModal(AuditModal, 'AuditModal');

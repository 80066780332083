import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import UseTable from '../../common/table/use-table';
import { rolePage, deleteRole } from '../../../service/system';

import { Button, Divider, Modal, message } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

const RoleManagement = () => {
  const history = useHistory();

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      const { records, total } = await rolePage(listPageData);
      setList(records);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const addRow = useStableCallback(() => {
    history.push('/system/role/add');
  });

  const editRow = useStableCallback((record: any) => {
    history.push(`/system/role/edit/${record.id}`);
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除角色【${record.name}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await deleteRole(record.id);
          message.success('删除成功');
          getList(1);
        } catch (err) {}
      },
    });
  });

  useEffect(() => {
    getList(1);
  }, [getList]);

  const columns: ColumnsType = [
    {
      title: '角色编码',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: '角色名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '角色代码',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 120,
      fixed: 'right',
      render: (text: any, record: any) => (
        <>
          <Button type='link' onClick={() => editRow(record)}>
            编辑
          </Button>
          <Divider type='vertical' />
          <Button type='link' danger onClick={() => deleteRow(record)}>
            删除
          </Button>
        </>
      ),
    },
  ];

  const { table, setLoading, setList, setListTotal, pageData, setPageData } =
    UseTable({ columns, getList, rowCanSelect: false });

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>角色列表</div>
        <div className='header-operate'>
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {table}
    </div>
  );
};

export default RoleManagement;

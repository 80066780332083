import './index.scss';

import React, { useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import CategoryList from './category-list';
import StandardName from './standard-name';

import { Tabs } from 'antd';
const { TabPane } = Tabs;

const ClassificationManagement = () => {
  const [activeKey, setActiveKey] = useState<string>('list');

  const onChange = useStableCallback(setActiveKey);

  return (
    <Tabs defaultActiveKey={activeKey} onChange={onChange} className='classify'>
      <TabPane tab='分类列表' key='list'>
        <CategoryList activeKey={activeKey} />
      </TabPane>
      <TabPane tab='标准名称' key='name'>
        <StandardName activeKey={activeKey} />
      </TabPane>
    </Tabs>
  );
};

export default ClassificationManagement;

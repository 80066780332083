import React, {
  FC,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { EditableRow } from '../../../common/editable/editable-row';
import {
  EditableCell,
  InputCell,
  DataType,
} from '../../../common/editable/editable-cell';
import {
  openSelectProductModal,
  closeSelectProductModal,
} from '../../../erp/modal/select-product-modal';

import { Button, Table } from 'antd';

interface Props {
  value?: DataType[];
  onChange?: (newValue: DataType[]) => void;
  onRef: any;
}

const ComponentInfoTable: FC<Props> = ({ value = [], onChange, onRef }) => {
  useImperativeHandle(onRef, () => ({ handleAddComponent }));

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const handleValueChange = useStableCallback((newValue: DataType[]) => {
    setDataSource(newValue);
    onChange?.(newValue);
  });

  const handleDelete = useStableCallback((partsId: React.Key) => {
    handleValueChange(dataSource.filter((item) => item.partsId !== partsId));
  });

  const handleAdd = useStableCallback((list) => {
    handleValueChange([...dataSource, ...list]);
  });

  const handleChange = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      handleValueChange(
        dataSource.map((each) => {
          if (row.partsId === each.partsId) {
            return {
              ...each,
              ...row,
            };
          }
          return each;
        })
      );
    }
  );

  const handleAddComponent = useStableCallback(() => {
    openSelectProductModal({
      queryType: 31,
      selectedPartsIdList: dataSource?.map((item) => {
        return item.partsId;
      }),
      excluedIds: [
        'outDynamicQtyFormat',
        'inDynamicQtyFormat',
        'latestOutActualAmtFormat',
        'salePrice4sFormat',
        'repairPriceFormat',
        'ownerPriceFormat',
      ],
      title: '添加组件',
      visible: true,
      onCancel: closeSelectProductModal,
      onConfirm: handleAdd,
    });
  });

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value);
    }
  }, [value]);

  const columns = React.useMemo(() => {
    const list = [
      {
        title: '组件编号',
        dataIndex: 'partsCode',
        key: 'partsCode',
        render: (text: string, record: any) => {
          return (
            <Link to={`/goods/gfiles/detail/${record.partsId}`}>{text}</Link>
          );
        },
        fixed: 'left' as any,
        width: 120,
      },
      {
        title: '组件名称',
        dataIndex: 'partsName',
        key: 'partsName',
        width: 120,
      },
      {
        title: '品牌',
        dataIndex: 'partsBrandName',
        key: 'partsBrandName',
        width: 100,
      },
      {
        title: '计量单位',
        dataIndex: 'partsUnit',
        key: 'partsUnit',
        width: 80,
        align: 'center' as any,
      },
      {
        title: '商品规格',
        dataIndex: 'partsSpec',
        key: 'partsSpec',
        width: 100,
      },
      {
        title: '总库存',
        dataIndex: 'oneBoxQty',
        key: 'oneBoxQty',
        width: 100,
      },
      {
        title: '所需数量',
        dataIndex: 'warehouseQty',
        key: 'warehouseQty',
        width: 100,
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '所需数量',
          dataIndex: 'warehouseQty',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                isInputNumber={true}
                numberPrecision={0}
                rules={[{ required: true, message: '请输入' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '成本单价(元)',
        dataIndex: 'psubAmtFormat',
        key: 'psubAmtFormat',
        width: 100,
        align: 'right' as any,
      },
      {
        title: '金额(元)',
        dataIndex: 'spAmtFormat',
        key: 'spAmtFormat',
        width: 100,
        align: 'right' as any,
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 80,
        fixed: 'right' as any,
        render: (text: any, record: any) => (
          <>
            <Button
              type='link'
              danger
              onClick={() => handleDelete(record?.partsId)}
            >
              删除
            </Button>
          </>
        ),
      },
    ];

    return list;
  }, [handleChange, handleDelete]);

  return (
    <div>
      <Table
        className='list-table editable'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='partsId'
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={(pageData) => {
          let totalEstimateBox = 0;
          let totalWarehouseQty = 0;

          pageData.forEach(({ estimateBox, warehouseQty }) => {
            totalEstimateBox += Number(estimateBox);
            totalWarehouseQty += Number(warehouseQty);
          });

          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {totalEstimateBox}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  {totalWarehouseQty}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align={'right'}>
                  {totalWarehouseQty}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align={'right'}>
                  {totalWarehouseQty}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};

export default ComponentInfoTable;

import React, { FC, useContext, useEffect, useState } from 'react';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import {
  closeSelectProductModal,
  openSelectProductModal,
} from '../edit/select-product-modal';
import { SelectContext } from '../edit/select-context';

import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  value?: any[];
  onChange?: (newValue: any[]) => void;
}

const ProductReplaceTable: FC<Props> = ({ value = [], onChange }) => {
  const selectContextValue = useContext(SelectContext);

  const [dataSource, setDataSource] = useState<any[]>([]);

  const handleValueChange = useStableCallback((newValue: any[]) => {
    setDataSource(newValue);
    onChange?.(newValue);
  });

  const handleDelete = useStableCallback((id: number) => {
    handleValueChange(dataSource.filter((item) => item.id !== id));
  });

  const handleAdd = useStableCallback((list) => {
    handleValueChange([...dataSource, ...list]);
  });

  const handleAddProduct = useStableCallback(() => {
    openSelectProductModal({
      queryScene: 1,
      selectedPartsIdList: dataSource?.map((item) => {
        return item.id;
      }),
      selectContextValue,
      visible: true,
      onCancel: closeSelectProductModal,
      onConfirm: handleAdd,
    });
  });

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value);
    }
  }, [value]);

  const columns = React.useMemo(
    () => [
      {
        title: '商品编号',
        dataIndex: 'partsCode',
        key: 'partsCode',
        width: '40%',
      },
      {
        title: '品牌',
        dataIndex: 'brandName',
        key: 'brandName',
        width: '40%',
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        render: (text: any, record: any) => (
          <>
            <Button type='link' danger onClick={() => handleDelete(record?.id)}>
              删除
            </Button>
          </>
        ),
      },
    ],
    [handleDelete]
  );

  return (
    <div>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <Button
        block
        type='dashed'
        icon={<PlusOutlined />}
        style={{ margin: '16px 0 24px' }}
        onClick={handleAddProduct}
      >
        添加商品
      </Button>
    </div>
  );
};

export default ProductReplaceTable;

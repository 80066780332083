import { message } from 'antd';
import { mangLocal, mangSession } from '../utils/common';
import { history } from '../utils/history';

/**
 * 请求成功情况下异常code处理
 */
const resCodeHandle = (res: any) => {
  try {
    switch (res.code) {
      case 10404: // 网关服务未找到
      case 10500: // 网关异常
      case 10501: // 网关超时
        history.replace('/500');
        break;
      case 60053: // no permission 无权限
        history.replace('/403');
        break;
      case 60050: // new jwt 拿到接口返回的data刷新掉sessionStorage里面的authorization
        mangLocal('authorization', res.data.jwt);
        break;
      case 20001: // 无效 jwt
      case 20002: // 无效请求
      case 60051: // expired jwt
      case 60052: // error Jwt
        localStorage.clear();
        mangSession('isFailJwt', true);
        history.replace(
          `/login?redirect=${encodeURIComponent(history.location.pathname)}`
        );
        message.error('登录过期，请重新登录！');
        break;
      default:
        message.error(`${res.msg || '网络异常，请稍后重试！'}`);
        break;
    }
  } catch (e) {
    message.error('网络异常，请稍后重试！');
  }
};

interface Params {
  method?: string;
  format?: 'json' | 'blob';
  headers?: { [key: string]: any };
  query?: { [key: string]: any };
  body?: { [key: string]: any };
}

export const preFetch = async (url: string, option?: Params) => {
  let finalUrl = `${process.env.REACT_APP_BASE_URL}${url}`;
  let finalBody: string | FormData = '';

  if (option?.query) {
    const [main, query] = finalUrl.split('?');
    const urlQuery = new URLSearchParams(query);
    Object.entries(option.query).forEach(([key, value]) => {
      urlQuery.set(key, value);
    });
    finalUrl = `${main}?${urlQuery.toString()}`;
  }

  if (option?.body) {
    if (typeof option.body === 'string' || option.body instanceof FormData) {
      finalBody = option.body;
    } else {
      finalBody = JSON.stringify(option.body);
    }
  }

  const defaultHeaders: HeadersInit =
    finalBody instanceof FormData
      ? {}
      : {
          Accept: 'application/json, text/javascript, */*; q=0.01',
          'Content-Type': 'application/json',
        };

  const fetchParams: RequestInit = {
    method: option?.method ?? 'GET',
    headers: {
      ...defaultHeaders,
      ...option?.headers,
    },
  };

  if (url !== '/auth/login?tokenKey=get' && url !== '/auth/login') {
    (fetchParams.headers as any).authorization = mangLocal('authorization');
    (fetchParams.headers as any).appId = mangLocal('userInfo')?.username;
  }

  if (finalBody) {
    fetchParams.body = finalBody;
  }

  const httpResponse = await fetch(finalUrl, fetchParams);

  if (option?.format === 'blob') {
    return await httpResponse.blob();
  }

  const result = await httpResponse.json();

  if (!httpResponse.ok) {
    if (httpResponse.status === 404) {
      history.replace('/404');
    } else {
      message.error(`${result.message || '网络异常，请稍后重试！'}`);
    }
    throw new Error(result.message);
  }

  // 通用成功：0、tokenKey获取成功：60000、登陆成功：60010
  // isFailJwt：jwt失效标识，防止失效退登弹出多次错误提示
  if (
    result.code &&
    result.code !== 0 &&
    result.code !== 60000 &&
    result.code !== 60010 &&
    !mangSession('isFailJwt')
  ) {
    resCodeHandle(result);
    throw new Error(result.msg);
  }

  return result;
};

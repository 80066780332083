import { preFetch } from './fetch';

// 获取菜单
export const menu = async (id: number): Promise<string[]> => {
  const success = await preFetch(`/user/menu-authority/${id}`, {
    method: 'GET',
  });
  return success.data;
};

// 资源管理
export interface ResourceTreeProps {
  id: number;
  code: string;
  name: string;
  parentId: number;
  uri: string;
  type: number;
  method: string;
  icon: string;
  status: number;
  createTime: string;
  updateTime: string;
  children: ResourceTreeProps[];
}

export interface ResourceInfoProps {
  name: string;
  code: string;
  type: number;
  icon?: string;
  method?: string;
  parentId?: number;
  uri?: string;
}

export const resourceTree = async (): Promise<ResourceTreeProps[]> => {
  const success = await preFetch('/resource/tree', {
    method: 'GET',
  });
  return success.data;
};

export const resourceDetails = async (
  id: number
): Promise<ResourceTreeProps> => {
  const success = await preFetch(`/resource/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const resourceParent = async (
  type: number
): Promise<ResourceTreeProps[]> => {
  const success = await preFetch('/resource/parent', {
    method: 'GET',
    query: { type },
  });
  return success.data;
};

export const addResource = async (body: ResourceInfoProps) => {
  const success = await preFetch('/resource', {
    method: 'POST',
    body,
  });
  return success;
};

export const editResource = async (id: number, body: ResourceInfoProps) => {
  const success = await preFetch(`/resource/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const deleteResource = async (id: number) => {
  const success = await preFetch(`/resource/${id}`, {
    method: 'DELETE',
  });
  return success;
};

// 角色管理
export interface RoleListProps {
  authResourceVOList: ResourceTreeProps[];
  id: number;
  name: string;
  remarks: string;
  resourceIdList: number[];
  roleUserNames: string;
  code: string;
  status: number;
  createTime: string;
  updateTime: string;
}

export interface RoleInfoProps {
  code: string;
  name: string;
  remarks?: string;
  resourceIdList: number[];
}

export const rolePage = async (query: {
  [key: string]: number;
}): Promise<{ records: RoleListProps[]; total: number }> => {
  const success = await preFetch('/role/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const roleList = async (): Promise<RoleListProps[]> => {
  const success = await preFetch('/role/list', {
    method: 'GET',
  });
  return success.data;
};

export const roleDetails = async (
  id: number,
  type: string
): Promise<RoleListProps> => {
  const success = await preFetch(`/role/${id}`, {
    method: 'GET',
    query: { type },
  });
  return success.data;
};

export const addRole = async (body: RoleInfoProps) => {
  const success = await preFetch('/role', {
    method: 'POST',
    body,
  });
  return success;
};

export const editRole = async (id: number, body: RoleInfoProps) => {
  const success = await preFetch(`/role/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const deleteRole = async (id: number) => {
  const success = await preFetch(`/role/${id}`, {
    method: 'DELETE',
  });
  return success;
};

// 用户管理
export interface UserListProps {
  id: number;
  avatar: string;
  email: string;
  phone: string;
  roleId: number;
  realName: string;
  roleName: string;
  username: string;
  sex: number;
  status: number;
  tenantId: number;
  tenantName: string;
  createTime: string;
  updateTime: string;
}

export interface UserInfoProps {
  roleId: number;
  tenantId: number;
  phone: string;
  realName: string;
  username: string;
  sex: number;
  email?: string;
}

export const userPage = async (query: {
  [key: string]: any;
}): Promise<{ records: UserListProps[]; total: number }> => {
  const success = await preFetch('/user/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const userDetails = async (id: number): Promise<UserListProps> => {
  const success = await preFetch(`/user/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addUser = async (body: UserInfoProps) => {
  const success = await preFetch('/user', {
    method: 'POST',
    body,
  });
  return success;
};

export const editUser = async (id: number, body: UserInfoProps) => {
  const success = await preFetch(`/user/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const deleteUser = async (ids: number[]) => {
  const success = await preFetch('/user/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

export const editUserStatus = async (id: number, disable: boolean) => {
  const success = await preFetch(`/user/status/${id}`, {
    method: 'PUT',
    query: { disable },
  });
  return success;
};

export const editUserPassword = async (id: number, body: any) => {
  const success = await preFetch(`/user/password/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const resetUserPassword = async (id: number) => {
  const success = await preFetch(`/user/reset/password/${id}`, {
    method: 'PUT',
  });
  return success;
};

// 租户管理
export interface TenantListProps {
  id: number;
  euscc: string;
  extra: string;
  legalPerson: string;
  phoneNumber: string;
  tenantName: string;
  tenantType: number;
  tenantTypeFormat: string;
  createTime: string;
  updateTime: string;
}

export interface TenantInfoProps {
  euscc: string;
  legalPerson: string;
  phoneNumber: string;
  tenantName: string;
  tenantType: number;
}

export const tenantPage = async (query: {
  [key: string]: any;
}): Promise<{ records: TenantListProps[]; total: number }> => {
  const success = await preFetch('/tenant/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const tenantList = async (): Promise<TenantListProps[]> => {
  const success = await preFetch('/tenant/list', {
    method: 'GET',
  });
  return success.data;
};

export const tenantDetails = async (id: number): Promise<TenantListProps> => {
  const success = await preFetch(`/tenant/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addTenant = async (body: TenantInfoProps) => {
  const success = await preFetch('/tenant', {
    method: 'POST',
    body,
  });
  return success;
};

export const editTenant = async (id: number, body: TenantInfoProps) => {
  const success = await preFetch(`/tenant/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const deleteTenant = async (ids: number[]) => {
  const success = await preFetch('/tenant/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

import { useMemo } from 'react';
import UseDetailTable from '../../../common/table/detail-table';
import { GetUnitContactsColumns } from '../tables/unit-contacts-columns';
import { UnitContactsProps } from '../../../../service/unit';
import { ColumnsType } from 'antd/lib/table';

interface Params {
  list: { [key: string]: any }[];
  excluedIds?: string[];
}

const UseUnitContactsTable = ({ list, excluedIds = [] }: Params) => {
  const columns: ColumnsType<UnitContactsProps> = useMemo(
    () => GetUnitContactsColumns(undefined, excluedIds),
    [excluedIds]
  );

  const table = UseDetailTable({ columns, list });

  return {
    table,
  };
};

export default UseUnitContactsTable;

import { preFetch } from './fetch';
import { UnitContactsProps } from './unit';

// 仓库列表
export interface WarehouseListProps {
  id: number;
  code: string;
  contactMobile: string;
  contactTelephone: string;
  defaultContact: string;
  location: string;
  locations: string[];
  name: string;
  updatedTime: string;
}

export interface WarehouseDetailProps {
  id?: number;
  code: string;
  name: string;
  location: string;
  locations: string[];
  address?: string;
  warehouseContacts?: UnitContactsProps[];
  warehouseLocations?: WarehouseLocationsProps[];
}

export interface WarehouseLocationsProps {
  id?: number;
  code?: string;
  desc?: string;
  name?: string;
  spec?: string;
}

export const warehousePage = async (query: {
  [key: string]: any;
}): Promise<{ records: WarehouseListProps[]; total: number }> => {
  const success = await preFetch('/erp-warehouse-info/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const warehouseList = async (): Promise<WarehouseDetailProps[]> => {
  const success = await preFetch('/erp-warehouse-info/list', {
    method: 'GET',
  });
  return success.data;
};

export const getWarehouseDetail = async (
  id: number
): Promise<WarehouseDetailProps> => {
  const success = await preFetch(`/erp-warehouse-info/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addWarehouse = async (body: WarehouseDetailProps) => {
  const success = await preFetch('/erp-warehouse-info', {
    method: 'POST',
    body,
  });
  return success;
};

export const editWarehouse = async (id: number, body: WarehouseDetailProps) => {
  const success = await preFetch(`/erp-warehouse-info/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const deleteWarehouse = async (ids: number[]) => {
  const success = await preFetch('/erp-warehouse-info/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

// 库存中心
export interface StockCenterProps {
  partsId: number;
  brandName: string;
  dynamicQty: number;
  inventoryQty: number;
  usableQty: number;
  locationCode: string;
  locationName: string;
  partsCode: string;
  partsName: string;
  partsSpec: string;
  supplierName: string;
  supplierPartsCode: string;
  warehouseCode: string;
  warehouseName: string;
  updatedTime: string;
}

export const stockPage = async (query: {
  [key: string]: any;
}): Promise<{ records: StockCenterProps[]; total: number }> => {
  const success = await preFetch('/erp-warehouse-info/stock/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const stockCanInit = async (): Promise<boolean> => {
  const success = await preFetch('/erp-warehouse-info/stock/init', {
    method: 'GET',
  });
  return success.data;
};

// 出库单
export interface OutboundOrderListProps {
  logisticsNo: string;
  logisticsUnitName: string;
  orderDate: string;
  orderId: number;
  orderStatus: number;
  orderStatusFormat: string;
  outboundContacts: string;
  outboundQty: number;
  outboundQtyFormat: string;
  porderId: number;
  realBox: number;
  salesMan: string;
  taxCompany: string;
  unitId: number;
  unitName: string;
}

export const outboundOrderPage = async (query: {
  [key: string]: any;
}): Promise<{ records: OutboundOrderListProps[]; total: number }> => {
  const success = await preFetch('/erp-outbound-order/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const outboundOrderDetail = async (orderId: number) => {
  const success = await preFetch(`/erp-outbound-order/detail/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const outboundOrderBasicInfo = async (orderId: number) => {
  const success = await preFetch(`/erp-outbound-order/basic-info/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const outboundOrderReceiveInfo = async (orderId: number) => {
  const success = await preFetch(
    `/erp-outbound-order/receive-info/${orderId}`,
    {
      method: 'GET',
    }
  );
  return success.data;
};

export const deleteOutboundOrder = async (ids: number[]): Promise<boolean> => {
  const success = await preFetch('/erp-outbound-order/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

export const auditOutboundOrder = async (orderId: number): Promise<boolean> => {
  const success = await preFetch(`/erp-outbound-order/approved/${orderId}`, {
    method: 'PUT',
  });
  return success;
};

export const rejectOutboundOrder = async (
  orderId: number
): Promise<boolean> => {
  const success = await preFetch(`/erp-outbound-order/reject/${orderId}`, {
    method: 'PUT',
  });
  return success;
};

export const printOutboundOrder = async (orderId: number) => {
  const success = await preFetch(`/erp-outbound-order/print/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const outboundOrderSaveDraft = async (body: {
  [key: string]: any;
}): Promise<boolean> => {
  const success = await preFetch('/erp-outbound-order/save-draft', {
    method: 'POST',
    body,
  });
  return success;
};

export const outboundOrderSubmit = async (body: {
  [key: string]: any;
}): Promise<boolean> => {
  const success = await preFetch('/erp-outbound-order/submit', {
    method: 'POST',
    body,
  });
  return success;
};

export const finishOutboundOrder = async (
  orderId: number
): Promise<boolean> => {
  const success = await preFetch(`/erp-outbound-order/finish/${orderId}`, {
    method: 'PUT',
  });
  return success;
};

// 入库单
export interface InboundOrderListProps {
  inboundContacts: string;
  inboundQty: number;
  inboundQtyFormat: string;
  logisticsNo: string;
  logisticsUnitName: string;
  orderDate: string;
  orderId: number;
  orderStatus: number;
  orderStatusFormat: string;
  porderId: number;
  purchaseMan: string;
  realBox: number;
  supplierId: number;
  supplierName: string;
  taxCompany: string;
}

export const inboundOrderPage = async (query: {
  [key: string]: any;
}): Promise<{ records: InboundOrderListProps[]; total: number }> => {
  const success = await preFetch('/erp-inbound-order/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const inboundOrderDetail = async (orderId: number) => {
  const success = await preFetch(`/erp-inbound-order/detail/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const inboundOrderBasicInfo = async (orderId: number) => {
  const success = await preFetch(`/erp-inbound-order/basic-info/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const inboundOrderSupplyInfo = async (orderId: number) => {
  const success = await preFetch(`/erp-inbound-order/supply-info/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const deleteInboundOrder = async (ids: number[]): Promise<boolean> => {
  const success = await preFetch('/erp-inbound-order/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

export const auditInboundOrder = async (orderId: number): Promise<boolean> => {
  const success = await preFetch(`/erp-inbound-order/approved/${orderId}`, {
    method: 'PUT',
  });
  return success;
};

export const rejectInboundOrder = async (orderId: number): Promise<boolean> => {
  const success = await preFetch(`/erp-inbound-order/reject/${orderId}`, {
    method: 'PUT',
  });
  return success;
};

export const printInboundOrder = async (orderId: number) => {
  const success = await preFetch(`/erp-inbound-order/print/${orderId}`, {
    method: 'GET',
  });
  return success.data;
};

export const inboundOrderSaveDraft = async (body: {
  [key: string]: any;
}): Promise<boolean> => {
  const success = await preFetch('/erp-inbound-order/save-draft', {
    method: 'POST',
    body,
  });
  return success;
};

export const inboundOrderSubmit = async (body: {
  [key: string]: any;
}): Promise<boolean> => {
  const success = await preFetch('/erp-inbound-order/submit', {
    method: 'POST',
    body,
  });
  return success;
};

export const finishInboundOrder = async (orderId: number): Promise<boolean> => {
  const success = await preFetch(`/erp-inbound-order/finish/${orderId}`, {
    method: 'PUT',
  });
  return success;
};

// 出库单、入库单物流信息管理
export interface ErpOrderLogistics {
  orderId: number;
  orderType: number;
  logisticsNo: string;
  logisticsUnitId: number;
  contactNumber?: string;
  contacts?: string;
  distributeType?: number;
  id?: number;
  invoicingType?: string;
  logisticsUnitName?: string;
  porderId?: number;
  remark?: string;
  shippingAmt?: number;
  shippingAmtFormat?: string;
  taxRate?: string;
}

export const getErpOrderLogistics = async (orderId: number): Promise<any> => {
  const success = await preFetch('/erp-order-logistics', {
    method: 'GET',
    query: { orderId },
  });
  return success.data;
};

export const addErpOrderLogistics = async (body: {
  [key: string]: any;
}): Promise<boolean> => {
  const success = await preFetch('/erp-order-logistics', {
    method: 'POST',
    body,
  });
  return success;
};

export const editErpOrderLogistics = async (
  id: number,
  body: any
): Promise<boolean> => {
  const success = await preFetch(`/erp-order-logistics/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

// 销售单、采购单、出库单、入库单添加商品
export const warehousePartsPage = async (query: {
  [key: string]: any;
}): Promise<{ records: any; total: number }> => {
  const success = await preFetch('/erp-warehouse-parts/page/sales-order', {
    method: 'GET',
    query,
  });
  return success.data;
};

import CryptoJS from 'crypto-js';

/**
 * 存储sessionStorage
 * @param {String} name 需要存储的内容命名
 * @param content 需要存储的内容，直接取的时候不需要传此参
 */
export const mangSession = (name: string, content?: any) => {
  return manageSave(name, content, false);
};

/**
 * 存储localStorage
 * @param {String} name 需要存储的内容命名
 * @param content 需要存储的内容，直接取的时候不需要传此参
 */
export const mangLocal = (name: string, content?: any) => {
  return manageSave(name, content);
};

export const manageSave = (name: string, content?: any, type = true) => {
  let storage = type ? localStorage : sessionStorage;
  let isLocal = window.localStorage && window.localStorage instanceof Storage;
  if (typeof name === 'string') {
    if (!content && typeof content != 'boolean') {
      let data = isLocal ? storage.getItem(name) : getCookie(name);
      try {
        data = JSON.parse(data);
      } catch (e) {
        // continue regardless of error
      }
      return data;
    } else if (
      content &&
      (Object.prototype.toString.call(content) === '[object Object]' ||
        Object.prototype.toString.call(content) === '[object Array]')
    ) {
      content = JSON.stringify(content);
      if (isLocal) {
        storage.setItem(name, content);
      } else {
        setCookie(name, content);
      }
    } else if (
      content &&
      Object.prototype.toString.call(content) === '[object Boolean]'
    ) {
      content = JSON.stringify(content);
      if (isLocal) {
        storage.setItem(name, content);
      } else {
        setCookie(name, content);
      }
    } else if (typeof content === 'string' && content === 'del') {
      storage.removeItem(name);
    } else {
      if (isLocal) {
        storage.setItem(name, content);
      } else {
        setCookie(name, content);
      }
    }
  } else if (Object.prototype.toString.call(name) === '[object Object]') {
    if (isLocal) {
      storage.setItem(name, content);
    } else {
      setCookie(name, content);
    }
  }
  return true;
};

export const getCookie = (name: string) => {
  let arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)'),
    res,
    r;
  if ((arr = document.cookie.match(reg))) {
    r = decodeURIComponent(arr[2]);
    try {
      res = JSON.parse(decodeURIComponent(arr[2]));
    } catch (e) {
      // continue regardless of error
    }
    return res ? res : r;
  }
  return r;
};

export const setCookie = (name: string, content: any) => {
  try {
    let days = 1;
    let exp = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    if (Object.prototype.toString.call(content) === '[object Object]') {
      content = JSON.stringify(content);
    }
    // 写入Cookie，toUTCString将时间转换成字符串。
    document.cookie =
      name +
      '=' +
      encodeURIComponent(content) +
      ';expires=' +
      exp.toUTCString();
  } catch (e) {
    // continue regardless of error
  }
};

/**
 * AES加密
 * @param {String} word 需要加密的内容
 * @param {String} keyStr 加密的密钥
 */
export const encrypt = (word: string, keyStr: string) => {
  keyStr = keyStr ? keyStr : 'abcdefgabcdefg12';
  const key = CryptoJS.enc.Utf8.parse(keyStr);
  const srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

/**
 * 列表页筛选查询数据过了，去除值为undefined的
 * @param {Object} obj 需要筛选的对象
 */
export const filterParams = (obj: { [key: string]: any }) => {
  let filterObj: { [key: string]: any } = {};
  for (let item in obj) {
    if (obj[item] !== undefined) {
      filterObj[item] = obj[item];
    }
  }
  return filterObj;
};

/**
 * 过滤children空数据
 * @param {Array} data 需要处理的数组
 */
export const clearChildren = (data: any) => {
  return data.map((each: any) => ({
    ...each,
    children: each.children?.length ? clearChildren(each.children) : null,
  }));
};

/**
 * @description 树状数组扁平化
 * @param { Array } tree          树状结构数组
 * @param { Array } arr           扁平化后的数组
 * @param { String } childrenKey  子节点键名
 * @return { Array }              扁平化后的数组
 */
export const flattenArray = (tree = [], arr = [], childrenKey = 'children') => {
  tree.forEach((each) => {
    arr.push(each);
    const children = each[childrenKey];
    children && flattenArray(children, arr, childrenKey);
  });
  return arr;
};

/**
 * @description 获取 location ?后数值
 * @param { String } url  ?后截取的字符串
 * @return { Object }     键值对对象
 */
export const urlParse = (url: string) => {
  let obj: { [key: string]: string } = {};
  const reg = /[?&][^?&]+=[^?&]+/g;
  const arr = url.match(reg);
  if (arr) {
    arr.forEach((item) => {
      const tempArr = item.substring(1).split('=');
      const key = decodeURIComponent(tempArr[0]);
      const val = decodeURIComponent(tempArr[1]);
      obj[key] = val;
    });
  }
  return obj;
};

/**
 * @description 数字转为指定位数，不够前面补0
 * @param { Number } num     需要处理的数字
 * @param { Number } length  指定位数
 * @return { String }        转换后的值
 */
export const prefixInteger = (num: number, length: number): string => {
  return (Array(length).join('0') + num).slice(-length);
};

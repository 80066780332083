import { useMemo } from 'react';
import UseDetailTable from '../../../common/table/detail-table';
import { GetUnitShopsColumns } from '../tables/unit-shops-columns';
import { UnitShopsProps } from '../../../../service/unit';
import { ColumnsType } from 'antd/lib/table';

interface Params {
  list: { [key: string]: any }[];
}

const UseUnitShopsTable = ({ list }: Params) => {
  const columns: ColumnsType<UnitShopsProps> = useMemo(
    () => GetUnitShopsColumns(),
    []
  );

  const table = UseDetailTable({ columns, list });

  return {
    table,
  };
};

export default UseUnitShopsTable;

import React, { FC, useEffect, useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import DropDownSelect from '../../common/select/drop-down-select';
import { getTaxCompanysList, TaxCompanysProps } from '../../../service/unit';

import { Form, Input, Button, Row, Col, DatePicker } from 'antd';
const { RangePicker } = DatePicker;

interface Props {
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const InboundOrderQuery: FC<Props> = ({ onReset, onFinish }) => {
  const [form] = Form.useForm();

  const [taxCompanys, setTaxCompanys] = useState<TaxCompanysProps[]>([]);

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  useEffect(() => {
    getTaxCompanysList()
      .then(setTaxCompanys)
      .catch((err) => {});
  }, []);

  return (
    <div className='page-custom common-query'>
      <Form
        form={form}
        name='inbound-order-query'
        layout={'inline'}
        onFinish={onFinish}
      >
        <Row>
          <Col span={8}>
            <Form.Item label='入库单号' name='orderId'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='采购单号' name='POrderId'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='供应商' name='supplierName'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='入库人' name='inboundContacts'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='采购员' name='purchaseMan'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='发票单位名称' name='unitId'>
              <DropDownSelect
                options={taxCompanys}
                hasId={true}
                eachName={'taxCompany'}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='入库日期' name='inDate'>
              <RangePicker />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Button htmlType='button' onClick={onInnerReset}>
                重置
              </Button>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InboundOrderQuery;

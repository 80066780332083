import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import TableBatchDelete from '../../common/table/table-batch-delete';
import UseTable from '../../common/table/use-table';
import {
  userPage,
  deleteUser,
  editUserStatus,
  resetUserPassword,
} from '../../../service/system';

import { Button, Divider, Modal, message } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

interface Props {
  searchForm?: { [key: string]: any };
}

const UserListTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      let params = { ...searchForm, ...listPageData };
      const { records, total } = await userPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const addRow = useStableCallback(() => {
    history.push('/system/user/add');
  });

  const editRow = useStableCallback((record: any) => {
    history.push(`/system/user/edit/${record.id}`);
  });

  const resetRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定重置用户【${record.username}】的密码吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await resetUserPassword(record.id);
          message.success('重置成功，新密码为123456');
        } catch (err) {}
      },
    });
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除用户【${record.username}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch([record.id]);
      },
    });
  });

  const deleteBatchRow = useStableCallback((ids: number[]) => {
    if (!ids || !ids.length) return message.warning('请先选择需删除的用户');
    Modal.confirm({
      title: `确定删除选中的用户吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch(ids);
      },
    });
  });

  const deleteBatch = useStableCallback(async (ids: number[]) => {
    try {
      await deleteUser(ids);
      message.success('删除成功');
      setSelectedRowKeys([]);
      getList(1);
    } catch (err) {}
  });

  const changeStatusRow = useStableCallback((record: any) => {
    const titleStatus = record.status === 1 ? '停用' : '启用';
    const contentStatus =
      record.status === 1
        ? '停用后该用户将无法登录系统，之后可再次启用。'
        : '启用后该用户可正常登录系统，之后可再次停用。';
    Modal.confirm({
      title: `确定${titleStatus}用户【${record.username}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: contentStatus,
      maskClosable: false,
      onOk: async () => {
        try {
          await editUserStatus(record.id, record.status === 1);
          message.success(`${titleStatus}成功`);
          getList(pageData.current);
        } catch (err) {}
      },
    });
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const columns = [
    {
      title: '账户名',
      dataIndex: 'username',
      key: 'username',
      width: 100,
    },
    {
      title: '姓名',
      dataIndex: 'realName',
      key: 'realName',
      width: 100,
    },
    {
      title: '租户',
      dataIndex: 'tenantName',
      key: 'tenantName',
      width: 110,
    },
    {
      title: '角色',
      dataIndex: 'roleName',
      key: 'roleName',
      width: 100,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status: number) => (
        <div className='flex-x m-s-start table-status'>
          <div className={`circle ${status === 1 ? 'green' : 'grey'}`}></div>
          <div>{status === 1 ? '正常' : '停用'}</div>
        </div>
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 160,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 210,
      render: (text: any, record: any) => (
        <>
          <Button type='link' onClick={() => editRow(record)}>
            编辑
          </Button>
          <Divider type='vertical' />
          <Button type='link' onClick={() => changeStatusRow(record)}>
            {record.status === 1 ? '停用' : '启用'}
          </Button>
          <Divider type='vertical' />
          <Button type='link' onClick={() => resetRow(record)}>
            重置密码
          </Button>
          <Divider type='vertical' />
          <Button type='link' danger onClick={() => deleteRow(record)}>
            删除
          </Button>
        </>
      ),
    },
  ];

  const { selectedRowKeys, setSelectedRowKeys, tableSelect } = TableBatchDelete(
    { deleteBatchRow }
  );

  const { table, setLoading, setList, setListTotal, pageData, setPageData } =
    UseTable({
      columns,
      getList,
      selectedRowKeys,
      onSelectChange,
    });

  useEffect(() => {
    getList(1);
  }, [getList, searchForm]);

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>用户列表</div>
        <div className='header-operate'>
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {selectedRowKeys.length > 0 && tableSelect}

      {table}
    </div>
  );
};

export default UserListTable;

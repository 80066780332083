import React, { FC, useContext, useEffect } from 'react';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import DropDownSelect from '../../../common/select/drop-down-select';
import { SelectContext } from './select-context';

import { Form, Input, Button, Row, Col } from 'antd';

interface Props {
  resetId: number;
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const SelectProductQuery: FC<Props> = ({ resetId, onReset, onFinish }) => {
  const [form] = Form.useForm();

  const selectContextValue = useContext(SelectContext);

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  useEffect(() => {
    onInnerReset();
  }, [onInnerReset, resetId]);

  return (
    <Form
      className='common-query'
      style={{
        margin: '-8px -24px 0 -30px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
      }}
      form={form}
      name='files-product-query'
      layout={'inline'}
      onFinish={onFinish}
    >
      <Row>
        <Col span={8}>
          <Form.Item label='商品编号' name='partsCode'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='商品名称' name='name'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='品牌' name='brandId'>
            <DropDownSelect
              options={selectContextValue?.gbrandSelectList}
              hasId={true}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='标准名称' name='standardDataId'>
            <DropDownSelect
              options={selectContextValue?.filesSelectListObj?.standardDatas}
              hasId={true}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='适用位置' name='location'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item>
            <Button htmlType='button' onClick={onInnerReset}>
              重置
            </Button>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SelectProductQuery;

import React, { FC } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { unitAttributes, unitTypes } from '../../../utils/definition';
import CityCascader from '../../common/select/city-cascader';

import { Form, Input, Button, Row, Col, Select } from 'antd';
const { Option } = Select;

interface Props {
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const UnitFilesListQuery: FC<Props> = ({ onReset, onFinish }) => {
  const [form] = Form.useForm();

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  return (
    <div className='page-custom common-query'>
      <Form
        form={form}
        name='unit-files-query'
        layout={'inline'}
        onFinish={onFinish}
      >
        <Row>
          <Col span={8}>
            <Form.Item label='单位名称' name='name'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='单位属性' name='propCode'>
              <Select
                placeholder='请选择'
                allowClear
                onChange={() =>
                  form.setFieldsValue({ catalog: null, owner: '' })
                }
              >
                {Object.entries(unitAttributes).map(([key, value]) => (
                  <Option value={value} key={key}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.propCode !== currentValues.propCode
              }
            >
              {({ getFieldValue }) => (
                <Form.Item label='属性分类' name='catalog'>
                  <Select placeholder='请选择' allowClear>
                    {Object.entries(
                      unitTypes[getFieldValue('propCode')] || []
                    ).map(([key, value]) => (
                      <Option value={value} key={key}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='所在地' name='locations'>
              <CityCascader changeOnSelect={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='默认联系人' name='defaultContact'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.propCode !== currentValues.propCode
              }
            >
              {({ getFieldValue }) => (
                <Form.Item label='拥有人' name='owner'>
                  <Input
                    disabled={getFieldValue('propCode') !== '客户'}
                    placeholder='请输入'
                    allowClear
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button htmlType='button' onClick={onInnerReset}>
                重置
              </Button>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UnitFilesListQuery;

import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { tenantTypeMap } from '../../../utils/definition';
import { phoneValidate } from '../../../utils/validate';
import { tenantDetails, addTenant, editTenant } from '../../../service/system';

import { Form, Input, Button, Select, Spin, message } from 'antd';
const { Option } = Select;

interface Props {
  type: 'add' | 'edit';
}

const TenantEdit: FC<Props> = (props) => {
  const history = useHistory();
  const { id } = useParams() as any;

  const [form] = Form.useForm();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const add = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await addTenant(form);
      message.success('新增成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const edit = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await editTenant(id, form);
      message.success('编辑成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onFinish = useStableCallback((values: any) => {
    if (props.type === 'add') {
      add(values);
    } else {
      edit(values);
    }
  });

  useEffect(() => {
    if (props.type === 'edit') {
      setSpinLoading(true);
      tenantDetails(id)
        .then(form.setFieldsValue)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  }, [props.type, id, form]);

  return (
    <Spin spinning={spinLoading}>
      <div className='page-custom common-details'>
        <Form
          name='tenant-edit'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label='租户编码'
            name='euscc'
            rules={[{ required: true, message: '请输入租户编码' }]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label='租户名'
            name='tenantName'
            rules={[{ required: true, message: '请输入租户名' }]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label='租户类型'
            name='tenantType'
            rules={[{ required: true, message: '请选择租户类型' }]}
          >
            <Select placeholder={'请选择'} allowClear>
              {Object.entries(tenantTypeMap).map(([key, value]) => (
                <Option key={key} value={Number(key)}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='手机号'
            name='phoneNumber'
            rules={[{ required: true, message: '请输入手机号' }, phoneValidate]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label='法人'
            name='legalPerson'
            rules={[{ required: true, message: '请输入法人' }]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Button onClick={() => history.goBack()}>取消</Button>
            <Button type='primary' htmlType='submit' loading={submitLoading}>
              确定
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default TenantEdit;

import { StockCenterProps } from '../../../service/warehouse';
import { ColumnsType } from 'antd/lib/table';

export const GetStockCenterColumns = (
  excluedIds: string[] = []
): ColumnsType<StockCenterProps> => {
  const list: ColumnsType<StockCenterProps> = [
    {
      title: '仓库编号',
      dataIndex: 'warehouseCode',
      key: 'warehouseCode',
      width: 120,
    },
    {
      title: '仓库名称',
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      width: 100,
    },
    {
      title: '库位编号',
      dataIndex: 'locationCode',
      key: 'locationCode',
      width: 100,
    },
    {
      title: '库位名称',
      dataIndex: 'locationName',
      key: 'locationName',
      width: 100,
    },
    {
      title: '商品编号',
      dataIndex: 'partsCode',
      key: 'partsCode',
      width: 100,
    },
    {
      title: '商品名称',
      dataIndex: 'partsName',
      key: 'partsName',
      width: 120,
    },
    {
      title: '品牌',
      dataIndex: 'brandName',
      key: 'brandName',
      width: 100,
    },
    {
      title: '商品规格',
      dataIndex: 'partsSpec',
      key: 'partsSpec',
      width: 100,
    },
    {
      title: '供应商',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 120,
      ellipsis: true,
    },
    {
      title: '供应商商品编号',
      dataIndex: 'supplierPartsCode',
      key: 'supplierPartsCode',
      width: 120,
      ellipsis: true,
    },
    {
      title: '库存数量',
      dataIndex: 'inventoryQty',
      key: 'inventoryQty',
      align: 'right' as any,
      width: 80,
    },
    {
      title: '动态数',
      dataIndex: 'dynamicQty',
      key: 'dynamicQty',
      align: 'right' as any,
      width: 80,
    },
    {
      title: '可用数量',
      dataIndex: 'usableQty',
      key: 'usableQty',
      align: 'right' as any,
      width: 80,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedTime',
      key: 'updatedTime',
      width: 180,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  return list;
};

import './error.scss';
import img from '../../assets/images/404.png';
import icon from '../../assets/images/404-icon.png';

import { Button } from 'antd';

import { FC } from 'react';
import { useHistory } from 'react-router-dom';

const NotFound: FC = () => {
  const history = useHistory();

  return (
    <div className='error flex-x'>
      <img className='error-img' src={img} alt='img' />

      <div className='error-info'>
        <div className='info-title flex-x m-s-start'>
          <div>抱歉，找不到您要访问的页面</div>
          <img className='title-icon' src={icon} alt='icon' />
        </div>
        <div className='info-desc'>
          您正在搜索的页面可能已被删除、更名或暂不可用
        </div>
        <div className='info-desc'>请检查您输入的网址是否正确</div>
        <Button
          className='info-btn'
          type='primary'
          onClick={() => history.goBack()}
        >
          返 回
        </Button>
      </div>
    </div>
  );
};

export default NotFound;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import {
  closeAddUnitContactsModal,
  openAddUnitContactsModal,
} from '../edit/add-contacts-modal';
import { GetUnitContactsColumns } from './unit-contacts-columns';

import { UnitContactsProps } from '../../../../service/unit';

import { Button, Divider, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

interface Props {
  source: string;
  value?: any[];
  onChange?: (newValue: any[]) => void;
}

const UnitContactsTable: FC<Props> = ({ source, value = [], onChange }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);

  const handleValueChange = useStableCallback((newValue: any[]) => {
    setDataSource(newValue);
    onChange?.(newValue);
  });

  const deleteRow = useStableCallback((id: number) => {
    handleValueChange(dataSource.filter((item) => item.id !== id));
  });

  const handleEditContacts = useStableCallback((list) => {
    let arr = [...dataSource];
    arr.forEach((item, index) => {
      if (item.id === list.id) {
        arr[index] = list;
      }
    });
    handleValueChange(arr);
  });

  const editRow = useStableCallback((record: any) => {
    openAddUnitContactsModal({
      visible: true,
      type: 'edit',
      source,
      value: record,
      onCancel: closeAddUnitContactsModal,
      onConfirm: handleEditContacts,
    });
  });

  const handleAddContacts = useStableCallback((list) => {
    list.id = `add${Math.random()}`;
    let arr = [...dataSource];
    arr.push(list);
    handleValueChange(arr);
  });

  const addRow = useStableCallback(() => {
    openAddUnitContactsModal({
      visible: true,
      type: 'add',
      source,
      onCancel: closeAddUnitContactsModal,
      onConfirm: handleAddContacts,
    });
  });

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value);
    }
  }, [value]);

  const renderOpeartor = useStableCallback((record: any) => {
    return (
      <>
        <Button type='link' onClick={() => editRow(record)}>
          编辑
        </Button>
        <Divider type='vertical' />
        <Button type='link' danger onClick={() => deleteRow(record?.id)}>
          删除
        </Button>
      </>
    );
  });

  const columns: ColumnsType<UnitContactsProps> = useMemo(
    () =>
      GetUnitContactsColumns(
        renderOpeartor,
        source === 'unit' ? [] : ['address']
      ),
    [renderOpeartor, source]
  );

  return (
    <div>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <Button
        block
        type='dashed'
        icon={<PlusOutlined />}
        style={{ margin: '16px 0 24px' }}
        onClick={addRow}
      >
        添加联系人
      </Button>
    </div>
  );
};

export default UnitContactsTable;

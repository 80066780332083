import React, { FC } from 'react';

import { Descriptions } from 'antd';

interface Props {
  title: any;
  info: any;
}

const ReceiptInfo: FC<Props> = ({ title, info }) => {
  return (
    <Descriptions title={title}>
      <Descriptions.Item label='发运方式'>
        {info?.shipping || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='门店（分公司）'>
        {info?.subShop || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='联系人'>
        {info?.contacts || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='联系电话'>
        {info?.contactNumber || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='地址'>
        {info?.deliveryAddress || '-'}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ReceiptInfo;

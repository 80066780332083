import React, { FC } from 'react';

import { Descriptions } from 'antd';

interface Props {
  billingInfo: any;
}

const BillingInfo: FC<Props> = ({ billingInfo }) => {
  return (
    <Descriptions title='开票信息'>
      <Descriptions.Item label='单位名称'>
        {billingInfo?.taxCompany || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='税号'>
        {billingInfo?.taxNo || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='开户账号'>
        {billingInfo?.taxBankNo || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='开户行'>
        {billingInfo?.taxBank || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='开票地址'>
        {billingInfo?.taxAddress || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='电话'>
        {billingInfo?.taxPhone || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='备注' span={3}>
        {billingInfo?.taxRemark || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='发票寄送地址及联系人' span={3}>
        {billingInfo?.taxMailingAddress || '-'}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default BillingInfo;

import logo from '../assets/images/logo.png';
import logoTitle from '../assets/images/logo-title.png';

import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStableCallback } from '../hooks/use-stable-callback';
import { mangLocal } from '../utils/common';
import { menu } from '../service/system';

import { Layout, Menu } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
const { Sider } = Layout;
const { SubMenu } = Menu;

const menuKeys = {
  goods: ['gfiles', 'gbrand', 'classify'],
  purchase: ['porder'],
  sales: ['sorder'],
  warehouse: ['wlist', 'stock', 'inbound', 'outbound', 'assembly'],
  unit: ['ufiles'],
  car: ['model', 'cbrand', 'series', 'vin'],
  system: ['resource', 'role', 'user', 'tenant'],
};

const findParentKey = (childKey: string) => {
  let parentKey = '';
  Object.entries(menuKeys).forEach(([key, paths]) => {
    if (paths.includes(childKey)) {
      parentKey = key;
    }
  });
  return parentKey;
};

const MenuCustom: FC = () => {
  const location = useLocation();

  const [menuList, setMenuList] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectKeys, setSelectKeys] = useState<string[]>([]);

  const onOpenChange = useStableCallback((keys: any[]) => {
    if (keys.length === 0) {
      setOpenKeys([]);
    } else {
      setOpenKeys(keys.filter((key) => !openKeys.includes(key)));
    }
  });

  const onSelect = useStableCallback(({ key }) => {
    setSelectKeys([key]);
  });

  useEffect(() => {
    const split = location.pathname.split('/').filter((each) => !!each);
    const selectKey = split[1];
    setSelectKeys([selectKey]);
    setOpenKeys([findParentKey(selectKey)]);
  }, [location]);

  useEffect(() => {
    const { id } = mangLocal('userInfo');
    menu(id)
      .then(setMenuList)
      .catch((err) => {});
  }, []);

  return (
    <Menu
      theme='dark'
      mode='inline'
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={selectKeys}
      onSelect={onSelect}
    >
      {menuList.find((each) =>
        [
          'PARTS_FILE_MANAGEMENT',
          'PARTS_BRAND_MANAGEMENT',
          'PARTS_CATEGORY_MANAGEMENT',
        ].includes(each)
      ) && (
        <SubMenu
          key='goods'
          icon={<i className='iconfont icon-shangpin'></i>}
          title='商品管理'
        >
          {menuList.includes('PARTS_FILE_MANAGEMENT') && (
            <Menu.Item key='gfiles'>
              <Link to='/goods/gfiles'>商品档案</Link>
            </Menu.Item>
          )}
          {menuList.includes('PARTS_BRAND_MANAGEMENT') && (
            <Menu.Item key='gbrand'>
              <Link to='/goods/gbrand'>品牌管理</Link>
            </Menu.Item>
          )}
          {menuList.includes('PARTS_CATEGORY_MANAGEMENT') && (
            <Menu.Item key='classify'>
              <Link to='/goods/classify'>分类管理</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {menuList.find((each) =>
        ['PURCHASE_ORDER_MANAGEMENT'].includes(each)
      ) && (
        <SubMenu
          key='purchase'
          icon={<i className='iconfont icon-caigou'></i>}
          title='采购管理'
        >
          {menuList.includes('PURCHASE_ORDER_MANAGEMENT') && (
            <Menu.Item key='porder'>
              <Link to='/purchase/porder'>采购单</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {menuList.find((each) => ['SALES_ORDER_MANAGEMENT'].includes(each)) && (
        <SubMenu
          key='sales'
          icon={<i className='iconfont icon-xiaoshou'></i>}
          title='销售管理'
        >
          {menuList.includes('SALES_ORDER_MANAGEMENT') && (
            <Menu.Item key='sorder'>
              <Link to='/sales/sorder'>销售单</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {menuList.find((each) =>
        [
          'WAREHOUSE_MANAGEMENT',
          'STOCK_MANAGEMENT',
          'INBOUND_ORDER_MANAGEMENT',
          'OUTBOUND_ORDER_MANAGEMENT',
          'ASSEMBLY_ORDER_MANAGEMENT',
        ].includes(each)
      ) && (
        <SubMenu
          key='warehouse'
          icon={<i className='iconfont icon-cangku'></i>}
          title='仓库管理'
        >
          {menuList.includes('WAREHOUSE_MANAGEMENT') && (
            <Menu.Item key='wlist'>
              <Link to='/warehouse/wlist'>仓库列表</Link>
            </Menu.Item>
          )}
          {menuList.includes('STOCK_MANAGEMENT') && (
            <Menu.Item key='stock'>
              <Link to='/warehouse/stock'>库存中心</Link>
            </Menu.Item>
          )}
          {menuList.includes('INBOUND_ORDER_MANAGEMENT') && (
            <Menu.Item key='inbound'>
              <Link to='/warehouse/inbound'>入库单</Link>
            </Menu.Item>
          )}
          {menuList.includes('OUTBOUND_ORDER_MANAGEMENT') && (
            <Menu.Item key='outbound'>
              <Link to='/warehouse/outbound'>出库单</Link>
            </Menu.Item>
          )}
          {menuList.includes('ASSEMBLY_ORDER_MANAGEMENT') && (
            <Menu.Item key='assembly'>
              <Link to='/warehouse/assembly'>组装单</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {menuList.find((each) => ['UNIT_MANAGEMENT'].includes(each)) && (
        <SubMenu
          key='unit'
          icon={<i className='iconfont icon-danwei'></i>}
          title='单位管理'
        >
          {menuList.includes('UNIT_MANAGEMENT') && (
            <Menu.Item key='ufiles'>
              <Link to='/unit/ufiles'>单位档案</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {menuList.find((each) =>
        [
          'CAR_BRAND_MANAGEMENT',
          'CAR_SERIES_MANAGEMENT',
          'CAR_MODEL_MANAGEMENT',
          'VIN_MANAGEMENT',
        ].includes(each)
      ) && (
        <SubMenu
          key='car'
          icon={<i className='iconfont icon-chexing'></i>}
          title='车型管理'
        >
          {menuList.includes('CAR_BRAND_MANAGEMENT') && (
            <Menu.Item key='cbrand'>
              <Link to='/car/cbrand'>品牌管理</Link>
            </Menu.Item>
          )}
          {menuList.includes('CAR_SERIES_MANAGEMENT') && (
            <Menu.Item key='series'>
              <Link to='/car/series'>车系管理</Link>
            </Menu.Item>
          )}
          {menuList.includes('CAR_MODEL_MANAGEMENT') && (
            <Menu.Item key='model'>
              <Link to='/car/model'>车型档案</Link>
            </Menu.Item>
          )}
          {menuList.includes('VIN_MANAGEMENT') && (
            <Menu.Item key='vin'>
              <Link to='/car/vin'>VIN码查询</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {menuList.find((each) =>
        [
          'RESOURCE_MANAGEMENT',
          'ROLE_MANAGEMENT',
          'USER_MANAGEMENT',
          'TENANT_MANAGEMENT',
        ].includes(each)
      ) && (
        <SubMenu
          key='system'
          icon={<i className='iconfont icon-xitong'></i>}
          title='系统管理'
        >
          {menuList.includes('RESOURCE_MANAGEMENT') && (
            <Menu.Item key='resource'>
              <Link to='/system/resource'>资源管理</Link>
            </Menu.Item>
          )}
          {menuList.includes('ROLE_MANAGEMENT') && (
            <Menu.Item key='role'>
              <Link to='/system/role'>角色管理</Link>
            </Menu.Item>
          )}
          {menuList.includes('USER_MANAGEMENT') && (
            <Menu.Item key='user'>
              <Link to='/system/user'>用户管理</Link>
            </Menu.Item>
          )}
          {menuList.includes('TENANT_MANAGEMENT') && (
            <Menu.Item key='tenant'>
              <Link to='/system/tenant'>租户管理</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}
    </Menu>
  );
};

const SiderCustom: FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = useStableCallback(() => {
    setCollapsed(!collapsed);
  });

  return (
    <Sider
      className='sider-custom'
      collapsible
      collapsedWidth='50'
      collapsed={collapsed}
      onCollapse={onCollapse}
      trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    >
      {/*<div className='sider-logo'>
        <img
          className={collapsed ? 'shrink' : 'lay'}
          src={collapsed ? logo : logoTitle}
          alt=''
        />
      </div>*/}

      <MenuCustom />
    </Sider>
  );
};

export default SiderCustom;

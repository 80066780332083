import React from 'react';
import { Link } from 'react-router-dom';

import { UnitListProps } from '../../../../service/unit';

import { ColumnsType } from 'antd/lib/table';

export const GetUnitFilesListColumns = (
  renderOpeartor?: (record: UnitListProps) => React.ReactElement,
  excluedIds: string[] = []
): ColumnsType<UnitListProps> => {
  const list: ColumnsType<UnitListProps> = [
    {
      title: '单位名称',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => {
        return <Link to={`/unit/ufiles/detail/${record.id}`}>{text}</Link>;
      },
      fixed: 'left' as any,
      width: 140,
    },
    {
      title: '单位别名',
      dataIndex: 'fname',
      key: 'fname',
      width: 140,
    },
    {
      title: '单位属性',
      dataIndex: 'propCode',
      key: 'propCode',
      width: 100,
    },
    {
      title: '所在地',
      dataIndex: 'locations',
      key: 'locations',
      width: 150,
      render: (text: any, record: any) => {
        return text?.map((each: string) => each).join('');
      },
    },
    {
      title: '拥有人',
      dataIndex: 'owner',
      key: 'owner',
      width: 100,
    },
    {
      title: '默认联系人',
      dataIndex: 'defaultContact',
      key: 'defaultContact',
      width: 100,
    },
    {
      title: '手机号',
      dataIndex: 'contactMobile',
      key: 'contactMobile',
      width: 120,
    },
    {
      title: '电话',
      dataIndex: 'contactTelephone',
      key: 'contactTelephone',
      width: 120,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedTime',
      key: 'updatedTime',
      width: 180,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 120,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

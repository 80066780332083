import '../../goods/files/index.scss';

import React, { FC, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useHistory,
} from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { getUnitBasic, UnitBasicInfoProps } from '../../../service/unit';

import EditUnitBasicInfo from './edit/basic';
import EditUnitDetailInfo from './edit/detail';

import { Spin, Menu, message } from 'antd';

interface Props {
  type: 'add' | 'edit';
}

const UnitFilesEdit: FC<Props> = ({ type }) => {
  const location = useLocation();
  const history = useHistory();
  const params: any = useParams();

  const [selectMenu, setSelectMenu] = useState<string[]>([]);
  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [hasBasicChanged, setHasBasicChanged] = useState(false);
  const [hasDetailChanged, setHasDetailChanged] = useState(false);
  const [basicInfo, setBasicInfo] = useState<any>(null);
  const [unitId, setUnitId] = useState<number>(0);

  const onSelect = useStableCallback(async (info: any) => {
    // 如果有变动，切换前提示报错
    if (selectMenu[0] === 'basic' && hasBasicChanged) {
      message.warning('基本信息未保存，请先保存后再切换');
      return;
    }

    if (selectMenu[0] === 'detail' && hasDetailChanged) {
      message.warning('详细信息未保存，请先保存后再切换');
      return;
    }

    if (info.key === 'detail' && !unitId) {
      message.warning('请先完善并保存基本信息');
      return;
    }

    if (type === 'add' && info.key === 'basic' && unitId) {
      try {
        const data = await getUnitBasic(unitId);
        setBasicInfo(data);
      } catch (error) {}
    }

    setSelectMenu([info.key]);
    history.replace(
      `/unit/ufiles/${type === 'add' ? `add` : `edit/${params.id}`}/${info.key}`
    );
  });

  const basicChange = useStableCallback(() => {
    setHasBasicChanged(true);
  });

  const basicSave = useStableCallback(
    (id: number, form: UnitBasicInfoProps) => {
      if (!id) return message.warning('请先检查基本信息是否保存成功');

      // 只有基础信息完善才可进入详细信息
      setUnitId(id);
      setHasBasicChanged(false);
      setBasicInfo(form);

      // 仅当单位属性为客户才需要填写详细信息
      if (form?.propCode !== '客户') return history.goBack();

      setSelectMenu(['detail']);
      history.replace(
        `/unit/ufiles/${type === 'add' ? `add` : `edit/${params.id}`}/detail`
      );
    }
  );

  const detailChange = useStableCallback(() => {
    setHasDetailChanged(true);
  });

  const detailSave = useStableCallback(() => {
    setHasDetailChanged(false);
    history.goBack();
  });

  useEffect(() => {
    const activeMenuRegex =
      type === 'add'
        ? /^\/unit\/ufiles\/add\/(\w+)$/
        : /^\/unit\/ufiles\/edit\/\w+\/(\w+)$/;
    const res = activeMenuRegex.exec(location.pathname);
    const menu = res?.[1];
    setSelectMenu([menu ?? '']);

    if (params.id) {
      setUnitId(params.id);
    }

    if (unitId && !basicInfo) {
      setSpinLoading(true);
      getUnitBasic(unitId)
        .then(setBasicInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  }, [basicInfo, location.pathname, params.id, type, unitId]);

  return (
    <Spin spinning={spinLoading}>
      <div className='page-custom goods-files-edit flex-x a-s-stretch'>
        <Menu
          className='minauto'
          style={{ width: 180 }}
          selectedKeys={selectMenu}
          onClick={onSelect}
        >
          <Menu.Item key='basic'>基本信息</Menu.Item>
          <Menu.Item
            key='detail'
            disabled={basicInfo && basicInfo?.propCode !== '客户'}
          >
            详细信息
          </Menu.Item>
        </Menu>

        <div className='goods-files-edit-step maxauto'>
          {type === 'add' ? (
            <Switch>
              <Route path='/unit/ufiles/add/basic'>
                <EditUnitBasicInfo
                  type={'add'}
                  basicInfo={basicInfo}
                  basicChange={basicChange}
                  basicSave={basicSave}
                />
              </Route>
              <Route path='/unit/ufiles/add/detail'>
                <EditUnitDetailInfo
                  type={'add'}
                  unitId={unitId}
                  basicInfo={basicInfo}
                  detailChange={detailChange}
                  detailSave={detailSave}
                />
              </Route>
              <Redirect to='/unit/ufiles/add/basic' />
            </Switch>
          ) : (
            <Switch>
              <Route path='/unit/ufiles/edit/:id/basic'>
                <EditUnitBasicInfo
                  type={'edit'}
                  basicInfo={basicInfo}
                  basicChange={basicChange}
                  basicSave={basicSave}
                />
              </Route>
              <Route path='/unit/ufiles/edit/:id/detail'>
                <EditUnitDetailInfo
                  type={'edit'}
                  unitId={unitId}
                  basicInfo={basicInfo}
                  detailChange={detailChange}
                  detailSave={detailSave}
                />
              </Route>
              <Redirect to='/unit/ufiles/edit/:id/basic' />
            </Switch>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default UnitFilesEdit;

import './index.scss';

import React, { FC, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useHistory,
} from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { filesBasicDetails } from '../../../service/goods';

import EditBasicInfo from './edit/basic';
import EditGoodsPrice from './edit/price';
import EditPhoto from './edit/photo';

import { Spin, Menu, message } from 'antd';

interface Props {
  type: 'add' | 'edit';
}

const GoodsFilesEdit: FC<Props> = ({ type }) => {
  const location = useLocation();
  const history = useHistory();
  const params: any = useParams();

  const [selectMenu, setSelectMenu] = useState<string[]>([]);
  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [hasBaseChanged, setHasBaseChanged] = useState(false);
  const [hasPriceChanged, setHasPriceChanged] = useState(false);
  const [hasPhotoChanged, setHasPhotoChanged] = useState(false);
  const [basicInfo, setBasicInfo] = useState<any>(null);
  const [partsId, setPartsId] = useState<number>(0);

  const onSelect = useStableCallback(async (info: any) => {
    // 如果有变动，切换前提示报错
    if (selectMenu[0] === 'basic' && hasBaseChanged) {
      message.warning('基本信息未保存，请先保存后再切换');
      return;
    }

    if (selectMenu[0] === 'price' && hasPriceChanged) {
      message.warning('商品价格未保存，请先保存后再切换');
      return;
    }

    if (selectMenu[0] === 'photo' && hasPhotoChanged) {
      message.warning('图片/视频未保存，请先保存后再切换');
      return;
    }

    if ((info.key === 'price' || info.key === 'photo') && !partsId) {
      message.warning('请先完善并保存基本信息');
      return;
    }

    if (type === 'add' && info.key === 'basic' && partsId) {
      try {
        const data = await filesBasicDetails(partsId);
        setBasicInfo(data);
      } catch (error) {}
    }

    setSelectMenu([info.key]);
    history.replace(
      `/goods/gfiles/${type === 'add' ? `add` : `edit/${params.id}`}/${
        info.key
      }`
    );
  });

  const basicChange = useStableCallback(() => {
    setHasBaseChanged(true);
  });

  const basicSave = useStableCallback((id) => {
    if (!id) return message.warning('请先检查基本信息是否保存成功');
    // 只有基础信息完善才可进入其他
    setPartsId(id);
    setHasBaseChanged(false);
    setSelectMenu(['price']);
    history.replace(
      `/goods/gfiles/${type === 'add' ? `add` : `edit/${params.id}`}/price`
    );
  });

  const priceChange = useStableCallback(() => {
    setHasPriceChanged(true);
  });

  const priceSave = useStableCallback(() => {
    setHasPriceChanged(false);
    setSelectMenu(['photo']);
    history.replace(
      `/goods/gfiles/${type === 'add' ? `add` : `edit/${params.id}`}/photo`
    );
  });

  const photoChange = useStableCallback(() => {
    setHasPhotoChanged(true);
  });

  const photoSave = useStableCallback(() => {
    setHasPhotoChanged(false);
    history.goBack();
  });

  useEffect(() => {
    const activeMenuRegex =
      type === 'add'
        ? /^\/goods\/gfiles\/add\/(\w+)$/
        : /^\/goods\/gfiles\/edit\/\w+\/(\w+)$/;
    const res = activeMenuRegex.exec(location.pathname);
    const menu = res?.[1];
    setSelectMenu([menu ?? '']);

    if (type === 'add') return;

    if (params.id) {
      setPartsId(params.id);
    }

    if (menu === 'basic') {
      setSpinLoading(true);
      filesBasicDetails(params.id)
        .then(setBasicInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  }, [location.pathname, params.id, type]);

  return (
    <Spin spinning={spinLoading}>
      <div className='page-custom goods-files-edit flex-x a-s-stretch'>
        <Menu
          className='minauto'
          style={{ width: 180 }}
          selectedKeys={selectMenu}
          onClick={onSelect}
        >
          <Menu.Item key='basic'>基本信息</Menu.Item>
          <Menu.Item key='price'>商品价格</Menu.Item>
          <Menu.Item key='photo'>图片/视频</Menu.Item>
        </Menu>

        <div className='goods-files-edit-step maxauto'>
          {type === 'add' ? (
            <Switch>
              <Route path='/goods/gfiles/add/basic'>
                <EditBasicInfo
                  type={'add'}
                  basicInfo={basicInfo}
                  basicChange={basicChange}
                  basicSave={basicSave}
                />
              </Route>
              <Route path='/goods/gfiles/add/price'>
                <EditGoodsPrice
                  type={'add'}
                  partsId={partsId}
                  priceChange={priceChange}
                  priceSave={priceSave}
                />
              </Route>
              <Route path='/goods/gfiles/add/photo'>
                <EditPhoto
                  type={'add'}
                  partsId={partsId}
                  photoChange={photoChange}
                  photoSave={photoSave}
                />
              </Route>
              <Redirect to='/goods/gfiles/add/basic' />
            </Switch>
          ) : (
            <Switch>
              <Route path='/goods/gfiles/edit/:id/basic'>
                <EditBasicInfo
                  type={'edit'}
                  basicInfo={basicInfo}
                  basicChange={basicChange}
                  basicSave={basicSave}
                />
              </Route>
              <Route path='/goods/gfiles/edit/:id/price'>
                <EditGoodsPrice
                  type={'edit'}
                  partsId={partsId}
                  priceChange={priceChange}
                  priceSave={priceSave}
                />
              </Route>
              <Route path='/goods/gfiles/edit/:id/photo'>
                <EditPhoto
                  type={'edit'}
                  partsId={partsId}
                  photoChange={photoChange}
                  photoSave={photoSave}
                />
              </Route>
              <Redirect to='/goods/gfiles/edit/:id/basic' />
            </Switch>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default GoodsFilesEdit;

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';

interface Params {
  basicInfo: { [key: string]: any };
}

const UseProductInfoTable = ({ basicInfo }: Params) => {
  const columns = useMemo(
    () => [
      {
        title: '组件编号',
        dataIndex: 'partsCode',
        key: 'partsCode',
        render: (text: string, record: any) => {
          return (
            <Link to={`/goods/gfiles/detail/${record.partsId}`}>{text}</Link>
          );
        },
        fixed: 'left' as any,
        width: 140,
      },
      {
        title: '组件名称',
        dataIndex: 'partsName',
        key: 'partsName',
        width: 120,
      },
      {
        title: '品牌',
        dataIndex: 'partsBrandName',
        key: 'partsBrandName',
        width: 100,
      },
      {
        title: '计量单位',
        dataIndex: 'partsUnit',
        key: 'partsUnit',
        width: 80,
        align: 'center' as any,
      },
      {
        title: '商品规格',
        dataIndex: 'partsSpec',
        key: 'partsSpec',
        width: 100,
      },
      {
        title: '总库存',
        dataIndex: 'oneBoxQty',
        key: 'oneBoxQty',
        width: 100,
      },
      {
        title: '所需数量',
        dataIndex: 'orderQtyFormat',
        key: 'orderQtyFormat',
        width: 100,
      },
      {
        title: '成本单价(元)',
        dataIndex: 'psubAmtFormat',
        key: 'psubAmtFormat',
        width: 100,
        align: 'right' as any,
      },
      {
        title: '金额(元)',
        dataIndex: 'spAmtFormat',
        key: 'spAmtFormat',
        width: 100,
        align: 'right' as any,
      },
    ],
    []
  );

  return (
    <div>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columns}
        dataSource={basicInfo?.orderItems}
        pagination={false}
        summary={() => {
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {basicInfo?.totalSalesFormat || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  {basicInfo?.estimateBoxFormat || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align={'right'}>
                  {basicInfo?.pspAmtFormat || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align={'right'}>
                  {basicInfo?.salesAmtFormat || '-'}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};

export default UseProductInfoTable;

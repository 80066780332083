import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { brandDetails, addBrand, editBrand } from '../../../service/car';
import UploadImg from '../../common/upload/upload-img';

import { Form, Input, Button, Spin, message } from 'antd';

interface Props {
  type: 'add' | 'edit';
}

const CarBrandEdit: FC<Props> = (props) => {
  const history = useHistory();
  const { id } = useParams() as any;

  const [form] = Form.useForm();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const add = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await addBrand(form);
      message.success('新增成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const edit = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await editBrand(id, form);
      message.success('编辑成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onFinish = useStableCallback((values: any) => {
    if (props.type === 'add') {
      add(values);
    } else {
      edit(values);
    }
  });

  const getImageUrl = useStableCallback((url) => {
    form.setFieldsValue({ logo: url });
    setImageUrl(url);
  });

  useEffect(() => {
    if (props.type === 'edit') {
      setSpinLoading(true);
      brandDetails(id)
        .then((res) => {
          form.setFieldsValue(res);
          setImageUrl(res.logo);
        })
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  }, [props.type, id, form]);

  return (
    <Spin spinning={spinLoading}>
      <div className='page-custom common-details'>
        <Form
          name='car-brand-edit'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label='品牌名称'
            name='name'
            rules={[{ required: true, message: '请输入品牌名称' }]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label='首字母'
            name='firstCode'
            rules={[
              { required: true, message: '请输入首字母' },
              { pattern: /^[a-zA-Z]$/, message: '首字母须为英文字母' },
            ]}
          >
            <Input placeholder={'请输入'} maxLength={1} allowClear />
          </Form.Item>
          <Form.Item
            label='logo'
            name='logo'
            valuePropName='file'
            rules={[{ required: true, message: '请上传logo' }]}
          >
            <UploadImg
              uploadData={{ type: 1 }}
              imageUrl={imageUrl}
              getImageUrl={getImageUrl}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Button onClick={() => history.goBack()}>取消</Button>
            <Button type='primary' htmlType='submit' loading={submitLoading}>
              确定
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default CarBrandEdit;

import '../../sales/order/index.scss';

import moment from 'moment';
import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import {
  inboundOrderDetail,
  inboundOrderSaveDraft,
  inboundOrderSubmit,
} from '../../../service/warehouse';
import {
  getUnitShopContactsList,
  UnitContactsProps,
  UnitShopsProps,
} from '../../../service/unit';
import { inboundPurchaseOrder } from '../../../service/purchase';

import ProductInfoTable from './edit/product-info';
import EditReceiptInfo from '../../erp/edit-receipt-info';
import ReceiptInfo from '../../erp/receipt-info';
import SalesInfo from './detail/sales-info';

import { Form, Input, Button, Spin, message, Row, Col, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  type: 'add' | 'edit';
}

const InboundOrderEdit: FC<Props> = (props) => {
  const history = useHistory();
  const productInfoRef = React.createRef() as any;
  const { id, pId } = useParams() as any;

  const [form] = Form.useForm();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [hasPurchaseId, setHasPurchaseId] = useState<boolean>(false);
  const [detailInfo, setDetailInfo] = useState<any>({});
  const [shopList, setShopList] = useState<UnitShopsProps[]>([]);
  const [shopContacts, setShopContacts] = useState<UnitShopsProps[]>([]);
  const [unitContacts, setUnitContacts] = useState<UnitContactsProps[]>([]);
  const [realBox, setRealBox] = useState<any>();

  const addProductInfo = useStableCallback(() => {
    productInfoRef?.current?.handleAddProduct();
  });

  const onValuesChange = useStableCallback(async (changedValues) => {
    const key = Object.keys(changedValues)[0];
    if (key === 'subShop') {
      if (!changedValues.subShop) {
        setShopContacts([]);
        const defaultContacts = unitContacts?.filter(
          (each: any) => each.isDefault
        )?.[0];
        form.setFieldsValue({
          contacts: defaultContacts?.unitId,
          contactNumber: defaultContacts?.mobile,
          deliveryAddress: defaultContacts?.address,
        });
        return;
      }

      const shopContacts = shopList?.filter(
        (each: any) => each.id === changedValues.subShop
      );
      setShopContacts(shopContacts);
      form.setFieldsValue({
        contacts: shopContacts[0]?.id,
        contactNumber: shopContacts[0]?.mobile,
        deliveryAddress: shopContacts[0]?.address,
      });
    } else if (key === 'contacts') {
      let selectContact: any = [];
      if (typeof changedValues.contacts === 'number') {
        selectContact = shopContacts;
      } else {
        selectContact = unitContacts?.filter(
          (each) => each.unitId === changedValues.contacts
        );
      }
      form.setFieldsValue({
        contactNumber: selectContact[0]?.mobile,
        deliveryAddress: selectContact[0]?.address,
      });
    }
  });

  const handleParmasFilter = useStableCallback(() => {
    const params = { ...form.getFieldsValue(true) };
    if (!params.orderItems || !params.orderItems.length) {
      message.warning('请至少添加一条商品信息');
      return false;
    }
    const hasTableComplete = params.orderItems?.every(
      (each: any) => each.locationId && each.warehouseQty
    );
    if (!hasTableComplete) {
      message.warning('请输入商品对应仓库和入库数量');
      return false;
    }
    for (const key in params) {
      if (params[key] === undefined) {
        params[key] = null;
      }
    }
    params.orderDate = params.orderDate.format('YYYY-MM-DD');
    params.realBox = realBox;
    params.orderItems.forEach((each: any) => {
      const warehouseObj = each.warehouseParts?.filter(
        (each1: any) => each1.locationId === each.locationId
      );
      each.warehouseId = warehouseObj?.[0]?.warehouseId;
      each.locationId = warehouseObj?.[0]?.locationId;
    });
    if (props.type === 'edit') {
      params.orderId = id;
    }
    if (hasPurchaseId) {
      params.porderId = pId || detailInfo?.purchaseOrder?.orderId;
    } else {
      if (typeof params.contacts === 'number') {
        params.contacts = shopContacts[0]?.contract;
      } else {
        params.contacts = unitContacts?.filter(
          (each) => each.unitId === params.contacts
        )[0]?.name;
      }
      params.subShop = shopList?.filter(
        (each) => each.id === params.subShop
      )[0]?.name;
    }
    return params;
  });

  const saveDraft = useStableCallback(async () => {
    await form.validateFields();
    const params = handleParmasFilter();
    if (!params) return;

    setSaveLoading(true);
    try {
      await inboundOrderSaveDraft(params);
      message.success('保存成功');
      history.push('/warehouse/inbound');
    } catch (err) {
    } finally {
      setSaveLoading(false);
    }
  });

  const submit = useStableCallback(async () => {
    await form.validateFields();
    const params = handleParmasFilter();
    if (!params) return;

    setSubmitLoading(true);
    try {
      await inboundOrderSubmit(params);
      message.success('提交成功');
      history.push('/warehouse/inbound');
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    if (props.type === 'edit') {
      setSpinLoading(true);
      inboundOrderDetail(id)
        .then((res1) => {
          res1.orderDate = moment(res1.orderDate, 'YYYY-MM-DD');
          res1.orderItems.forEach((each: any) => {
            each.locationId = each.warehouseParts?.filter(
              (each1: any) =>
                each.warehouseId === each1.warehouseId &&
                each.locationId === each1.locationId
            )?.[0]?.locationId;
          });
          form.setFieldsValue(res1);
          setDetailInfo(res1);
          setRealBox(res1.realBox);
          const hasPId = !!res1.purchaseOrder;
          setHasPurchaseId(hasPId);
          if (!hasPId) {
            return getUnitShopContactsList();
          }
        })
        .then((res2) => {
          if (!res2) return;

          setShopList(res2.unitShops);

          const units = res2.unitContacts.map((each: any) => {
            return {
              ...each,
              unitId: `unit${each.id}`,
            };
          });
          setUnitContacts(units);

          const subShopItem = res2.unitShops?.filter(
            (each: any) => each.name === form.getFieldValue('subShop')
          );
          setShopContacts(subShopItem);
          form.setFieldsValue({ subShop: subShopItem?.[0]?.id });

          const shopContactsName = subShopItem?.filter(
            (each: any) => each.contract === form.getFieldValue('contacts')
          );
          const unitContactsName = units?.filter(
            (each: any) => each.name === form.getFieldValue('contacts')
          );
          if (shopContactsName && shopContactsName.length) {
            form.setFieldsValue({ contacts: shopContactsName?.[0]?.id });
          } else {
            form.setFieldsValue({ contacts: unitContactsName?.[0]?.unitId });
          }
        })
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    } else {
      form.setFieldsValue({ orderDate: moment() });

      const hasPId = pId && pId !== 'no';
      setHasPurchaseId(hasPId);
      if (hasPId) {
        inboundPurchaseOrder(pId)
          .then((res) => {
            res.orderDate = res.orderDate || moment();
            form.setFieldsValue(res);
            setDetailInfo(res);
          })
          .catch((err) => {});
      } else {
        getUnitShopContactsList()
          .then((res) => {
            const units = res.unitContacts.map((each: any) => {
              return {
                ...each,
                unitId: `unit${each.id}`,
              };
            });
            setUnitContacts(units);
            setShopList(res.unitShops);
          })
          .catch((err) => {});
      }
    }
  }, [props.type, id, pId, form]);

  return (
    <Spin spinning={spinLoading}>
      <Form
        name='inbound-order-edit'
        className='horizontal-details edit-erp'
        scrollToFirstError={true}
        form={form}
        onValuesChange={onValuesChange}
      >
        {hasPurchaseId && (
          <div className='page-custom'>
            <div className='details-title'>
              采购单号：{detailInfo?.purchaseOrder?.orderId}
            </div>
            <div style={{ padding: '24px 32px 8px' }}>
              <SalesInfo
                title={null}
                info={detailInfo?.purchaseOrder}
                isShowMore={true}
              />
            </div>
          </div>
        )}

        <div className='page-custom'>
          <div className='details-title'>入库信息</div>
          <Row className='details-form'>
            <Col span={7}>
              <Form.Item
                label='入库人'
                name='inboundContacts'
                rules={[{ required: true, message: '请输入入库人' }]}
              >
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label='入库部门' name='inboundDept'>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label='入库日期'
                name='orderDate'
                rules={[{ required: true, message: '请选择入库日期' }]}
              >
                <DatePicker placeholder={'请选择'} />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ margin: '-8px 32px 32px' }}>
            {!hasPurchaseId && (
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={addProductInfo}
                style={{ marginBottom: '10px' }}
              >
                添加商品
              </Button>
            )}
            <Form.Item name='orderItems'>
              <ProductInfoTable
                onRef={productInfoRef}
                hasPurchaseId={hasPurchaseId}
                realBox={realBox}
                onChangeRealBox={setRealBox}
              />
            </Form.Item>
          </div>

          <div className='details-title'>供货信息</div>
          {hasPurchaseId ? (
            <div style={{ padding: '24px 32px 8px' }}>
              <ReceiptInfo title={null} info={detailInfo} />
            </div>
          ) : (
            <EditReceiptInfo
              shopList={shopList}
              shopContacts={shopContacts}
              unitContacts={unitContacts}
            />
          )}
        </div>

        <Row className='details-btn'>
          <Col span={12}>
            <Button onClick={() => history.goBack()}>取消</Button>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button loading={saveLoading} onClick={saveDraft}>
              保存草稿
            </Button>
            <Button type='primary' loading={submitLoading} onClick={submit}>
              提交
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default InboundOrderEdit;

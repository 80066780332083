import { preFetch } from './fetch';

export const download = async (url: string, fileName: string) => {
  const data = await preFetch(url, { format: 'blob' });
  const bolbUrl = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.download = fileName;
  a.href = bolbUrl;
  a.click();
};

import '../../sales/order/index.scss';

import React, { FC, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import {
  freightShareWay,
  invoiceType,
  paymentTerm,
} from '../../../utils/definition';
import ProductInfoTable from './edit/product-info';
import EditReceiptInfo from '../../erp/edit-receipt-info';
import DropDownSelect from '../../common/select/drop-down-select';
import {
  salesOrderSaveDraft,
  salesOrderSubmit,
  salesOrderDetail,
  allocationAlgorithm,
} from '../../../service/sales';
import {
  getUnitNamesList,
  getUnitShopContactsList,
  TaxCompanysProps,
  UnitContactsProps,
  UnitShopsProps,
} from '../../../service/unit';

import {
  Form,
  Input,
  Button,
  Spin,
  message,
  Row,
  Col,
  DatePicker,
  Select,
  Radio,
  InputNumber,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
const { TextArea } = Input;

interface Props {
  type: 'add' | 'edit';
}

const SalesOrderEdit: FC<Props> = (props) => {
  const history = useHistory();
  const productInfoRef = React.createRef() as any;
  const { id } = useParams() as any;

  const [form] = Form.useForm();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [unitList, setUnitList] = useState<TaxCompanysProps[]>([]);
  const [shopList, setShopList] = useState<UnitShopsProps[]>([]);
  const [shopContacts, setShopContacts] = useState<UnitShopsProps[]>([]);
  const [unitContacts, setUnitContacts] = useState<UnitContactsProps[]>([]);
  const [orderItems, setOrderItems] = useState<any[]>([]);
  const [discountItem, setDiscountItem] = useState<{
    key?: string;
    value?: string | number | null;
  }>({});
  const [productTotalInfo, setProductTotalInfo] = useState<{
    [key: string]: any;
  }>({});
  const [ppayAmtFormat, setPpayAmtFormat] = useState<string>('');

  const addProductInfo = useStableCallback(() => {
    productInfoRef?.current?.handleAddProduct();
  });

  const calculate = useStableCallback(async () => {
    let params: any = form.getFieldsValue([
      'discount',
      'discountAmt',
      'distributeType',
      'orderItems',
    ]);
    try {
      const res = await allocationAlgorithm(params);
      let newForm = { ...form.getFieldsValue(true), ...res };
      form.setFieldsValue(newForm);
      setProductTotalInfo({
        totalSales: res.totalSales,
        estimateBoxFormat: res.estimateBoxFormat,
        pspAmtFormat: res.pspAmtFormat,
        salesAmtFormat: res.salesAmtFormat,
      });
      setPpayAmtFormat(res.ppayAmtFormat);
    } catch (err) {}
  });

  const deleteProductInfo = useStableCallback(() => {
    setProductTotalInfo({
      totalSales: '',
      estimateBoxFormat: '',
      pspAmtFormat: '',
      salesAmtFormat: '',
    });
    setPpayAmtFormat('');
    setDiscountItem({ key: '', value: '' });
    form.setFieldsValue({ discount: '', discountAmt: '' });
  });

  const onValuesChange = useStableCallback(async (changedValues) => {
    const key = Object.keys(changedValues)[0];
    if (key === 'unitId') {
      if (!changedValues.unitId) {
        setShopList([]);
        setShopContacts([]);
        setUnitContacts([]);
        form.setFieldsValue({
          taxRate: '',
          contacts: '',
          contactNumber: '',
          deliveryAddress: '',
        });
        return;
      }

      const res = await getUnitShopContactsList(changedValues.unitId);
      const units = res.unitContacts.map((each: any) => {
        return {
          ...each,
          unitId: `unit${each.id}`,
        };
      });
      const defaultContacts = units?.filter((each: any) => each.isDefault)?.[0];
      setShopList(res.unitShops);
      setUnitContacts(units);
      form.setFieldsValue({
        taxRate: res.taxRate,
        contacts: defaultContacts?.unitId,
        contactNumber: defaultContacts?.mobile,
        deliveryAddress: defaultContacts?.address,
      });
    } else if (key === 'subShop') {
      if (!changedValues.subShop) {
        setShopContacts([]);
        const defaultContacts = unitContacts?.filter(
          (each: any) => each.isDefault
        )?.[0];
        form.setFieldsValue({
          contacts: defaultContacts?.unitId,
          contactNumber: defaultContacts?.mobile,
          deliveryAddress: defaultContacts?.address,
        });
        return;
      }

      const shopContacts = shopList?.filter(
        (each: any) => each.id === changedValues.subShop
      );
      setShopContacts(shopContacts);
      form.setFieldsValue({
        contacts: shopContacts[0]?.id,
        contactNumber: shopContacts[0]?.mobile,
        deliveryAddress: shopContacts[0]?.address,
      });
    } else if (key === 'contacts') {
      let selectContact: any = [];
      if (typeof changedValues.contacts === 'number') {
        selectContact = shopContacts;
      } else {
        selectContact = unitContacts?.filter(
          (each) => each.unitId === changedValues.contacts
        );
      }
      form.setFieldsValue({
        contactNumber: selectContact[0]?.mobile,
        deliveryAddress: selectContact[0]?.address,
      });
    }
  });

  const handleParmasFilter = useStableCallback(() => {
    const params = { ...form.getFieldsValue(true) };
    if (!params.orderItems || !params.orderItems.length) {
      message.warning('请至少添加一条商品信息');
      return false;
    }
    if (!canInputDiscount) {
      message.warning('请输入商品对应开单单价和销售数量');
      return false;
    }
    for (const key in params) {
      if (params[key] === undefined) {
        params[key] = null;
      }
    }
    if (props.type === 'edit') {
      params.orderId = id;
    }
    if (typeof params.contacts === 'number') {
      params.contacts = shopContacts[0]?.contract;
    } else {
      params.contacts = unitContacts?.filter(
        (each) => each.unitId === params.contacts
      )[0]?.name;
    }
    params.subShop = shopList?.filter(
      (each) => each.id === params.subShop
    )[0]?.name;
    params.orderDate = params.orderDate.format('YYYY-MM-DD');
    return params;
  });

  const saveDraft = useStableCallback(async () => {
    await form.validateFields();
    const params = handleParmasFilter();
    if (!params) return;

    setSaveLoading(true);
    try {
      await salesOrderSaveDraft(params);
      message.success('保存成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSaveLoading(false);
    }
  });

  const submit = useStableCallback(async () => {
    await form.validateFields();
    const params = handleParmasFilter();
    if (!params) return;

    setSubmitLoading(true);
    try {
      await salesOrderSubmit(params);
      message.success('提交成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const canInputDiscount = useMemo(() => {
    if (!orderItems || !orderItems.length) return false;
    return orderItems?.every((each) => each.spSubAmt && each.orderQty);
  }, [orderItems]);

  useEffect(() => {
    getUnitNamesList('客户').then(setUnitList);

    if (props.type === 'edit') {
      setSpinLoading(true);
      salesOrderDetail(id)
        .then((res) => {
          res.orderDate = moment(res.orderDate, 'YYYY-MM-DD');
          setProductTotalInfo({
            totalSales: res.totalSales,
            estimateBoxFormat: res.estimateBoxFormat,
            pspAmtFormat: res.pspAmtFormat,
            salesAmtFormat: res.salesAmtFormat,
          });
          setPpayAmtFormat(res.ppayAmtFormat);
          setOrderItems(res.orderItems);
          form.setFieldsValue(res);
          return res;
        })
        .then((res1) => {
          return getUnitShopContactsList(res1.unitId);
        })
        .then((res2) => {
          const units = res2.unitContacts.map((each: any) => {
            return {
              ...each,
              unitId: `unit${each.id}`,
            };
          });
          setUnitContacts(units);
          setShopList(res2.unitShops);

          const subShopItem = res2.unitShops?.filter(
            (each: any) => each.name === form.getFieldValue('subShop')
          );
          setShopContacts(subShopItem);
          form.setFieldsValue({ subShop: subShopItem?.[0]?.id });

          const shopContactsName = subShopItem?.filter(
            (each: any) => each.contract === form.getFieldValue('contacts')
          );
          const unitContactsName = units?.filter(
            (each: any) => each.name === form.getFieldValue('contacts')
          );
          if (shopContactsName && shopContactsName.length) {
            form.setFieldsValue({ contacts: shopContactsName?.[0]?.id });
          } else {
            form.setFieldsValue({ contacts: unitContactsName?.[0]?.unitId });
          }
        })
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    } else {
      form.setFieldsValue({ orderDate: moment(), distributeType: 0 });
    }
  }, [props.type, id, form]);

  return (
    <Spin spinning={spinLoading}>
      <Form
        name='sales-order-edit'
        className='horizontal-details edit-erp'
        scrollToFirstError={true}
        form={form}
        onValuesChange={onValuesChange}
      >
        <div className='page-custom'>
          <div className='details-title'>基本信息</div>
          <Row className='details-form'>
            <Col span={7}>
              <Form.Item
                label='单位名称'
                name='unitId'
                rules={[{ required: true, message: '请选择单位名称' }]}
              >
                <DropDownSelect options={unitList} hasId={true} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label='业务员'
                name='salesMan'
                rules={[{ required: true, message: '请输入业务员' }]}
              >
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label='开单日期'
                name='orderDate'
                rules={[{ required: true, message: '请选择开单日期' }]}
              >
                <DatePicker placeholder={'请选择'} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label='收款方式'
                name='payment'
                rules={[{ required: true, message: '请选择收款方式' }]}
              >
                <Select placeholder='请选择' allowClear>
                  {Object.entries(paymentTerm).map(([key, value]) => (
                    <Option value={value} key={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label='开票类型'
                name='invoicingType'
                rules={[{ required: true, message: '请选择开票类型' }]}
              >
                <Select placeholder='请选择' allowClear>
                  {Object.entries(invoiceType).map(([key, value]) => (
                    <Option value={value} key={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label='税率(%)' name='taxRate'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={23}>
              <Form.Item label='备注' name='remark'>
                <TextArea placeholder={'请输入'} allowClear autoSize />
              </Form.Item>
            </Col>
          </Row>

          <div className='details-title'>收货信息</div>
          <EditReceiptInfo
            shopList={shopList}
            shopContacts={shopContacts}
            unitContacts={unitContacts}
          />

          <div className='details-title'>
            <div>商品信息</div>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={addProductInfo}
            >
              添加商品
            </Button>
          </div>
          <div className='details-info'>
            <Form.Item name='orderItems'>
              <ProductInfoTable
                onChange={setOrderItems}
                onRef={productInfoRef}
                productTotalInfo={productTotalInfo}
                onDelete={deleteProductInfo}
              />
            </Form.Item>
            <div className='edit-erp-product'>
              <div className='discount flex-x m-s-end a-s-center'>
                <div className='discount-title'>整单优惠(%)：</div>
                <Form.Item className='discount-input' name='discount'>
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    controls={false}
                    placeholder='请输入'
                    disabled={
                      !canInputDiscount ||
                      (discountItem.key === 'discountAmt' &&
                        !!discountItem.value)
                    }
                    onChange={(value) => {
                      setDiscountItem({ key: 'discount', value });
                      form.setFieldsValue({ discountAmt: null });
                    }}
                  />
                </Form.Item>
                <div className='discount-title'>= 整单优惠额：</div>
                <Form.Item className='discount-input' name='discountAmt'>
                  <InputNumber
                    min={0}
                    precision={2}
                    controls={false}
                    placeholder='请输入'
                    disabled={
                      !canInputDiscount ||
                      (discountItem.key === 'discount' && !!discountItem.value)
                    }
                    onChange={(value) => {
                      setDiscountItem({ key: 'discountAmt', value });
                      form.setFieldsValue({ discount: null });
                    }}
                  />
                </Form.Item>
                <Button
                  className='discount-btn'
                  type='link'
                  onClick={calculate}
                  disabled={!canInputDiscount}
                >
                  计算
                </Button>
              </div>
              <div className='mode flex-x m-s-end a-s-center'>
                <div className='mode-title'>优惠分摊方式：</div>
                <Form.Item className='mode-radio' name='distributeType'>
                  <Radio.Group>
                    {Object.entries(freightShareWay).map(([key, value]) => (
                      <Radio value={Number(key)} key={key}>
                        {value}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className='amount flex-x m-s-end a-s-center'>
                <div className='amount-title'>应收总金额：</div>
                <div className='amount-total'>{ppayAmtFormat || '-'}</div>
              </div>
            </div>
          </div>
        </div>

        <Row className='details-btn'>
          <Col span={12}>
            <Button onClick={() => history.goBack()}>取消</Button>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button loading={saveLoading} onClick={saveDraft}>
              保存草稿
            </Button>
            <Button type='primary' loading={submitLoading} onClick={submit}>
              提交
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default SalesOrderEdit;

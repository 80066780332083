import './login.scss';
import loginLogo from '../../assets/images/logo-title.png';

import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../hooks/use-stable-callback';
import { encrypt, mangLocal, mangSession, urlParse } from '../../utils/common';
import { loginKey, login } from '../../service/user';
import Slider from './slider';

import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const Login: FC = () => {
  const history = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = useStableCallback(async (values: any) => {
    setLoading(true);
    const params = {
      methodName: 'login',
      appId: '',
      password: '',
      userKey: '',
      timestamp: '',
    };
    try {
      // 对密码进行AES加密
      const authKey = await loginKey();
      params.password = encrypt(values.password, authKey.data.tokenKey);
      params.appId = values.appId;
      params.timestamp = authKey.timestamp;
      params.userKey = authKey.data.userKey;

      // 登录
      const loginInfo = await login(params);
      mangLocal('authorization', loginInfo.data.jwt);
      mangLocal('userInfo', loginInfo.data.authUserVO);
      message.success('登录成功');

      const url = urlParse(history.location.search)?.redirect || '/';
      history.push(url);
    } catch (err) {
      form.setFieldsValue({ sliderEnd: '' });
    } finally {
      mangSession('isFailJwt', false);
      setLoading(false);
    }
  });

  const onFinishFailed = useStableCallback(() => {
    form.setFieldsValue({ sliderEnd: '' });
  });

  return (
    <div className='login flex-x m-t-b'>
      {/*<img className='login-logo' src={loginLogo} alt='' />*/}

      <Form
        name='normal_login'
        className='login-form'
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name='appId'
          rules={[{ required: true, message: '请输入账户' }]}
        >
          <Input
            prefix={<UserOutlined className='login-form-icon' />}
            placeholder='账户'
            allowClear
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input
            prefix={<LockOutlined className='login-form-icon' />}
            type='password'
            placeholder='密码'
            allowClear
          />
        </Form.Item>
        <Form.Item
          name='sliderEnd'
          rules={[{ required: true, message: '请拖动滑块完成验证' }]}
        >
          <Slider />
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
            loading={loading}
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;

import React, { FC } from 'react';

import { Descriptions } from 'antd';

interface Props {
  title: any;
  info: any;
  isShowMore?: boolean;
}

const SalesInfo: FC<Props> = ({ title, info, isShowMore = false }) => {
  return (
    <Descriptions title={title}>
      <Descriptions.Item label='供应商'>
        {info?.supplierName || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='采购员'>
        {info?.purchaseMan || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='开单日期'>
        {info?.orderDate || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='付款方式'>
        {info?.payment || '-'}
      </Descriptions.Item>
      {isShowMore && (
        <Descriptions.Item label='备注'>
          {info?.remark || '-'}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default SalesInfo;

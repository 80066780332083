import React, { FC, useEffect, useMemo, useState, useContext } from 'react';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { EditableRow } from '../../../common/editable/editable-row';
import {
  EditableCell,
  InputCell,
  DataType,
} from '../../../common/editable/editable-cell';
import {
  openSelectProductModal,
  closeSelectProductModal,
} from '../edit/select-product-modal';
import { SelectContext } from '../edit/select-context';

import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  value?: DataType[];
  onChange?: (newValue: DataType[]) => void;
}

const BOMTable: FC<Props> = ({ value = [], onChange }) => {
  const selectContextValue = useContext(SelectContext);

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const handleValueChange = useStableCallback((newValue: DataType[]) => {
    setDataSource(newValue);
    onChange?.(newValue);
  });

  const handleDelete = useStableCallback((id: React.Key) => {
    handleValueChange(dataSource.filter((item) => item.id !== id));
  });

  const handleAdd = useStableCallback((list) => {
    list.forEach((each: any) => (each.assembleNum = each?.assembleNum || 1));
    handleValueChange([...dataSource, ...list]);
  });

  const handleChange = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      handleValueChange(
        dataSource.map((each) => {
          if (row.id === each.id) {
            return {
              ...each,
              ...row,
            };
          }
          return each;
        })
      );
    }
  );

  const handleAddProduct = useStableCallback(() => {
    openSelectProductModal({
      queryScene: 2,
      selectedPartsIdList: dataSource?.map((item) => {
        return item.id;
      }),
      visible: true,
      selectContextValue,
      onCancel: closeSelectProductModal,
      onConfirm: handleAdd,
    });
  });

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value);
    }
  }, [value]);

  const columns = React.useMemo(
    () => [
      {
        title: '商品编号',
        dataIndex: 'partsCode',
        key: 'partsCode',
      },
      {
        title: '商品名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '品牌',
        dataIndex: 'brandName',
        key: 'brandName',
      },
      {
        title: '最新采购价(元)',
        dataIndex: 'latestPurchasePrice',
        key: 'latestPurchasePrice',
        align: 'right' as any,
      },
      {
        title: '数量',
        dataIndex: 'assembleNum',
        key: 'assembleNum',
        width: 110,
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '数量',
          dataIndex: 'assembleNum',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                isInputNumber={true}
                numberPrecision={0}
                rules={[{ required: true, message: '请输入' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        render: (text: any, record: any) => (
          <>
            <Button type='link' danger onClick={() => handleDelete(record?.id)}>
              删除
            </Button>
          </>
        ),
      },
    ],
    [handleChange, handleDelete]
  );

  return (
    <div>
      <Table
        className='list-table editable'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <Button
        block
        type='dashed'
        icon={<PlusOutlined />}
        style={{ margin: '16px 0 24px' }}
        onClick={handleAddProduct}
      >
        添加商品
      </Button>
    </div>
  );
};

export default BOMTable;

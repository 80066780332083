import React, { FC, useEffect, useMemo, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { filterParams } from '../../../utils/common';
import { createCloseModal, createOpenModal } from '../../../utils/modal';
import UseTable from '../../common/table/use-table';
import SelectProductQuery from './select-product-query';
import { GetSelectProductColumns } from '../tables/select-product-columns';
import { warehousePartsPage } from '../../../service/warehouse';

import { ConfigProvider, message, Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';

interface Props {
  queryType: number; // 查询类型
  selectedPartsIdList?: number[]; // 已选择的 商品 ID集合
  excluedIds?: string[];
  title: string;
  visible: boolean;
  onCancel: () => void;
  onConfirm: (list: any) => void;
}

const SelectProductModal: FC<Props> = ({
  queryType,
  selectedPartsIdList = [],
  excluedIds = [],
  title,
  visible,
  onCancel,
  onConfirm,
}) => {
  const [resetId, setResetId] = useState<number>(0);
  const [searchForm, setSearchForm] = useState<{ [key: string]: any }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      let params = {
        ...searchForm,
        ...listPageData,
        selectedPartsIdList,
        queryType,
      };
      const { records, total } = await warehousePartsPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
      onCancel();
    } finally {
      setLoading(false);
    }
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const columns: ColumnsType = useMemo(
    () => GetSelectProductColumns(excluedIds),
    [excluedIds]
  );

  const {
    table,
    setLoading,
    setList,
    setListTotal,
    pageData,
    setPageData,
    list,
  } = UseTable({
    columns,
    getList,
    rowKey: 'partsId',
    selectedRowKeys,
    onSelectChange,
    scroll: { x: '60%', y: 200 },
  });

  const onInnerConfirm = useStableCallback(() => {
    if (!selectedRowKeys || !selectedRowKeys.length)
      return message.warning('请先选择添加商品');
    const addList = list.filter((item) =>
      selectedRowKeys.includes(item.partsId)
    );
    onConfirm(addList);
    onInnerCancel();
  });

  const onInnerCancel = useStableCallback(() => {
    setResetId(resetId + 1);
    setSelectedRowKeys([]);
    onCancel();
  });

  const handleQuery = useStableCallback(async (values) => {
    await setSearchForm(filterParams(values));
    setSelectedRowKeys([]);
    getList(1);
  });

  useEffect(() => {
    getList(1);
  }, [getList]);

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title={title}
        width={'60%'}
        style={{ overflow: 'hidden' }}
        centered
        maskClosable={false}
        visible={visible}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <SelectProductQuery
          resetId={resetId}
          onReset={handleQuery}
          onFinish={handleQuery}
        />

        <div style={{ margin: '16px 0 20px', color: 'rgba(0, 0, 0, 0.65)' }}>
          商品列表
        </div>

        {table}
      </Modal>
    </ConfigProvider>
  );
};

export const openSelectProductModal = createOpenModal(
  SelectProductModal,
  'SelectProductModal'
);

export const closeSelectProductModal = createCloseModal(
  SelectProductModal,
  'SelectProductModal'
);

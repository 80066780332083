import React, { FC, useEffect, useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import {
  brandList,
  BrandListProps,
  getModelDropDownData,
} from '../../../service/car';

import DropDownSelect from '../../common/select/drop-down-select';

import { Form, Input, Button, Row, Col } from 'antd';

interface Props {
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const ModelListQuery: FC<Props> = ({ onReset, onFinish }) => {
  const [form] = Form.useForm();

  const [selectListObj, setSelectListObj] = useState<{
    [key: string]: string[];
  }>({});
  const [catalogSelectList, setCatalogSelectList] = useState<string[]>([]);
  const [brandSelectList, setBrandSelectList] = useState<BrandListProps[]>([]);

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  useEffect(() => {
    getModelDropDownData()
      .then(setSelectListObj)
      .catch((err) => {});

    getModelDropDownData('catalog')
      .then((res) => setCatalogSelectList(res.catalog))
      .catch((err) => {});

    brandList()
      .then(setBrandSelectList)
      .catch((err) => {});
  }, []);

  return (
    <div className='page-custom common-query'>
      <Form
        form={form}
        name='model-query'
        layout={'inline'}
        onFinish={onFinish}
      >
        <Row>
          <Col span={8}>
            <Form.Item label='车型名称' name='name'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='车型分类' name='catalog'>
              <DropDownSelect options={catalogSelectList} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='车型代码' name='code'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='品牌' name='brandId'>
              <DropDownSelect options={brandSelectList} hasId={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='配置销售车款' name='saleMode'>
              <DropDownSelect options={selectListObj?.saleModel} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button htmlType='button' onClick={onInnerReset}>
                重置
              </Button>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ModelListQuery;

import sliderOff from '../../assets/images/slider-off.png';
import sliderOn from '../../assets/images/slider-on.png';

import { FC, useEffect, useState } from 'react';
import { useStableCallback } from '../../hooks/use-stable-callback';

interface Props {
  value?: any;
  onChange?: (value: string) => void;
}

const Slider: FC<Props> = ({ value, onChange }) => {
  const [maxWidth] = useState<number>(326);
  const [beginClientX, setBeginClientX] = useState<number>(0);
  const [imgLeft, setImgLeft] = useState<number>(-3);
  const [bgWidth, setBgWidth] = useState<number>(0);
  const [sliderWords, setSliderWords] = useState<string>('拖动滑块到最右边');
  const [sliderIcon, setSliderIcon] = useState<string>(sliderOff);
  const [mouseMoveState, setMouseMoveState] = useState<boolean>(false);
  const [confirmSuccess, setConfirmSuccess] = useState<boolean>(false);

  const mouseDown = useStableCallback((e: any) => {
    if (!confirmSuccess) {
      e.preventDefault && e.preventDefault();
      setMouseMoveState(true);
      setBeginClientX(e.clientX);
    }
  });

  const mouseMove = useStableCallback((e: any) => {
    if (mouseMoveState) {
      let width = e.clientX - beginClientX;
      if (width > 0 && width <= maxWidth) {
        setImgLeft(width);
        setBgWidth(width + 20);
      } else if (width > maxWidth) {
        successFu();
      }
    }
  });

  const mouseUp = useStableCallback((e: any) => {
    setMouseMoveState(false);
    let width = e.clientX - beginClientX;
    if (width < maxWidth) {
      setImgLeft(-3);
      setBgWidth(0);
    }
  });

  const successFu = useStableCallback(() => {
    setConfirmSuccess(true);
    setImgLeft(maxWidth);
    setBgWidth(maxWidth + 20);
    setSliderWords('验证成功');
    setSliderIcon(sliderOn);
    onChange?.('end');
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', mouseUp);
  });

  useEffect(() => {
    if (!value) {
      setBeginClientX(0);
      setImgLeft(-3);
      setBgWidth(0);
      setSliderWords('拖动滑块到最右边');
      setSliderIcon(sliderOff);
      setMouseMoveState(false);
      setConfirmSuccess(false);
    }
    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mouseup', mouseUp);
  }, [mouseMove, mouseUp, value]);

  return (
    <div className='login-slider'>
      <div className='slider-bg' style={{ width: bgWidth + 'px' }}></div>
      <div className='slider-text'>{sliderWords}</div>
      <img
        className='slider-img'
        style={{ left: imgLeft + 'px' }}
        src={sliderIcon}
        alt=''
        onMouseDown={mouseDown}
      />
    </div>
  );
};

export default Slider;

import { FC } from 'react';
import { Table } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface Params {
  columns: any[];
  list: { [key: string]: any }[];
  rowKey?: string;
  scroll?: object;
  size?: SizeType;
}

const UseDetailTable: FC<Params> = ({
  columns,
  list,
  rowKey = 'id',
  scroll = { x: '100%' },
  size = 'middle',
}) => {
  return (
    <Table
      className='list-table'
      size={size}
      scroll={scroll}
      rowKey={rowKey}
      columns={columns}
      dataSource={list}
      pagination={false}
    />
  );
};

export default UseDetailTable;

import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { phoneValidate } from '../../../utils/validate';
import {
  userDetails,
  addUser,
  editUser,
  tenantList,
  roleList,
  RoleListProps,
  TenantListProps,
} from '../../../service/system';

import { Form, Input, Button, Radio, Select, Spin, message } from 'antd';
const { Option } = Select;

interface Props {
  type: 'add' | 'edit';
}

const UserEdit: FC<Props> = (props) => {
  const history = useHistory();
  const { id } = useParams() as any;

  const [form] = Form.useForm();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [tenantSelectList, setTenantSelectList] = useState<TenantListProps[]>(
    []
  );
  const [roleSelectList, setRoleSelectList] = useState<RoleListProps[]>([]);

  const add = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await addUser(form);
      message.success('新增成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const edit = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await editUser(id, form);
      message.success('编辑成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onFinish = useStableCallback((values: any) => {
    if (props.type === 'add') {
      add(values);
    } else {
      edit(values);
    }
  });

  useEffect(() => {
    if (props.type === 'edit') {
      setSpinLoading(true);
      userDetails(id)
        .then(form.setFieldsValue)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  }, [props.type, id, form]);

  useEffect(() => {
    tenantList()
      .then(setTenantSelectList)
      .catch((err) => {});

    roleList()
      .then(setRoleSelectList)
      .catch((err) => {});
  }, []);

  return (
    <Spin spinning={spinLoading}>
      <div className='page-custom common-details'>
        <Form
          name='user-edit'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label='账户名'
            name='username'
            rules={[{ required: true, message: '请输入账户名' }]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label='姓名'
            name='realName'
            rules={[{ required: true, message: '请输入姓名' }]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label='性别'
            name='sex'
            rules={[{ required: true, message: '请选择性别' }]}
          >
            <Radio.Group>
              <Radio value={1} key={1}>
                男
              </Radio>
              <Radio value={2} key={2}>
                女
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label='租户'
            name='tenantIds'
            rules={[{ required: true, message: '请选择租户' }]}
          >
            <Select
              mode='multiple'
              maxTagCount='responsive'
              placeholder={'请选择'}
              allowClear
            >
              {tenantSelectList.map((item: any) => (
                <Option value={item.id} key={item.id}>
                  {item.tenantName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='角色'
            name='roleId'
            rules={[{ required: true, message: '请选择角色' }]}
          >
            <Select placeholder={'请选择'} allowClear>
              {roleSelectList.map((item: any) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='手机号'
            name='phone'
            rules={[{ required: true, message: '请输入手机号' }, phoneValidate]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label='邮箱'
            name='email'
            rules={[{ type: 'email', message: '邮箱格式不正确' }]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Button onClick={() => history.goBack()}>取消</Button>
            <Button type='primary' htmlType='submit' loading={submitLoading}>
              确定
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default UserEdit;

import '../../goods/files/index.scss';

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';

import BreadcrumbCustom from '../../breadcrumb-custom';
import DetailBasicInfo from './detail/basic';
import DetailDetailInfo from './detail/detail';

import { getUnitBasic, getUnitDetail } from '../../../service/unit';

import { Button, Spin, Tabs } from 'antd';
const { TabPane } = Tabs;

const UnitFilesDetail: FC = () => {
  const params: any = useParams();
  const history = useHistory();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [basicInfo, setBasicInfo] = useState<any>(null);
  const [detailInfo, setDetailInfo] = useState<any>(null);

  const changeTabs = useStableCallback((menu) => {
    setSpinLoading(true);
    if (menu === 'detail') {
      getUnitDetail(params.id)
        .then(setDetailInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    } else {
      getUnitBasic(params.id)
        .then(setBasicInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  });

  useEffect(() => {
    setSpinLoading(true);
    getUnitBasic(params.id)
      .then(setBasicInfo)
      .catch((err) => {})
      .finally(() => {
        setSpinLoading(false);
      });
  }, [params.id]);

  const extraButton = useMemo(() => {
    return (
      <div>
        <Link to={`/unit/ufiles/edit/${params.id}`}>
          <Button>编辑</Button>
        </Link>
      </div>
    );
  }, [params.id]);

  return (
    <Spin spinning={spinLoading}>
      <BreadcrumbCustom
        paths={['单位管理', '单位档案', '单位详情']}
        title={`单位详情：${basicInfo?.name}`}
        extra={extraButton}
      />

      <Tabs
        defaultActiveKey='basic'
        className='goods-files-detail'
        onChange={changeTabs}
      >
        <TabPane tab='基本信息' key='basic'>
          <DetailBasicInfo basicInfo={basicInfo} />
        </TabPane>
        {basicInfo?.propCode === '客户' && (
          <TabPane tab='详细信息' key='detail'>
            <DetailDetailInfo basicInfo={basicInfo} detailInfo={detailInfo} />
          </TabPane>
        )}
      </Tabs>

      <div className='common-buttons flex-x m-s-end minauto'>
        <Button onClick={history.goBack}>返回</Button>
      </div>
    </Spin>
  );
};

export default UnitFilesDetail;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import BreadcrumbCustom from '../../breadcrumb-custom';
import UseUnitContactsTable from '../../unit/files/detail/unit-contacts-table';
import UseLocationInformationTable from './tables/location-information-table';

import { getWarehouseDetail } from '../../../service/warehouse';

import { Button, Descriptions, Spin } from 'antd';

const WarehouseListDetail: FC = () => {
  const params: any = useParams();
  const history = useHistory();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [warehouseList, setWarehouseList] = useState<any>(null);

  const { table: unitContactsTable } = UseUnitContactsTable({
    list: warehouseList?.warehouseContacts,
    excluedIds: ['address'],
  });
  const { table: locationInformationTable } = UseLocationInformationTable({
    list: warehouseList?.warehouseLocations,
  });

  useEffect(() => {
    setSpinLoading(true);
    getWarehouseDetail(params.id)
      .then(setWarehouseList)
      .catch((err) => {})
      .finally(() => {
        setSpinLoading(false);
      });
  }, [params.id]);

  const extraButton = useMemo(() => {
    return (
      <div>
        <Link to={`/warehouse/wlist/edit/${params.id}`}>
          <Button>编辑</Button>
        </Link>
      </div>
    );
  }, [params.id]);

  return (
    <Spin spinning={spinLoading}>
      <BreadcrumbCustom
        paths={['仓库管理', '仓库列表', '仓库详情']}
        title={`仓库详情：${warehouseList?.name}`}
        extra={extraButton}
      />

      <div className='page-custom common-details'>
        <Descriptions title='基本信息'>
          <Descriptions.Item label='仓库编号'>
            {warehouseList?.code || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='仓库名称'>
            {warehouseList?.name || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='地区'>
            {warehouseList?.locations
              ? warehouseList?.locations?.map((each: string) => each).join('')
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label='详细地址'>
            {warehouseList?.address || '-'}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          column={1}
          title='联系人'
          style={{ border: 'none', paddingBottom: 0 }}
        >
          <Descriptions.Item
            contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
          >
            {unitContactsTable}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          column={1}
          title='库位信息'
          style={{ border: 'none', paddingBottom: 0 }}
        >
          <Descriptions.Item
            contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
          >
            {locationInformationTable}
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div className='common-buttons flex-x m-s-end minauto'>
        <Button onClick={history.goBack}>返回</Button>
      </div>
    </Spin>
  );
};

export default WarehouseListDetail;

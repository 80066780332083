import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import BreadcrumbCustom from './breadcrumb-custom';

// 欢迎页
import Welcome from './user/welcome';

// 错误页
import NoPermission from './error/403';
import NotFound from './error/404';
import NetworkError from './error/500';

// 商品管理
import GoodsFiles from './goods/files/index';
import GoodsFilesEdit from './goods/files/edit';
import GoodsFilesDetail from './goods/files/detail';
import GoodsBrandManagement from './goods/brand/index';
import GoodsBrandEdit from './goods/brand/edit';
import ClassificationManagement from './goods/classify/index';

// 采购管理
import PurchaseOrder from './purchase/order/index';
import PurchaseOrderEdit from './purchase/order/edit';
import PurchaseOrderDetail from './purchase/order/detail';

// 销售管理
import SalesOrder from './sales/order/index';
import SalesOrderEdit from './sales/order/edit';
import SalesOrderDetail from './sales/order/detail';

// 仓库管理
import WarehouseList from './warehouse/list/index';
import WarehouseListEdit from './warehouse/list/edit';
import WarehouseListDetail from './warehouse/list/detail';
import StockCenter from './warehouse/stock/index';
import InboundOrder from './warehouse/inbound/index';
import InboundOrderEdit from './warehouse/inbound/edit';
import InboundOrderDetail from './warehouse/inbound/detail';
import OutboundOrder from './warehouse/outbound/index';
import OutboundOrderEdit from './warehouse/outbound/edit';
import OutboundOrderDetail from './warehouse/outbound/detail';
import AssemblyOrder from './warehouse/assembly/index';
import AssemblyOrderEdit from './warehouse/assembly/edit';
import AssemblyOrderDetail from './warehouse/assembly/detail';

// 单位管理
import UnitFiles from './unit/files/index';
import UnitFilesEdit from './unit/files/edit';
import UnitFilesDetail from './unit/files/detail';

// 车型管理
import ModelManagement from './car/model/index';
import ModelEdit from './car/model/edit';
import ModelDetail from './car/model/detail';
import CarBrandManagement from './car/brand/index';
import CarBrandEdit from './car/brand/edit';
import SeriesManagement from './car/series/index';
import SeriesEdit from './car/series/edit';
import VINCodeQuery from './car/vin/index';

// 系统管理
import ResourceManagement from './system/resource/index';
import ResourceEdit from './system/resource/edit';
import RoleManagement from './system/role/index';
import RoleEdit from './system/role/edit';
import UserManagement from './system/user/index';
import UserEdit from './system/user/edit';
import TenantManagement from './system/tenant/index';
import TenantEdit from './system/tenant/edit';

const PageCustom: FC = () => {
  return (
    <Switch>
      <Route path='/goods/gfiles/add'>
        <BreadcrumbCustom paths={['商品管理', '商品档案', '新增商品']} />
        <GoodsFilesEdit type='add' />
      </Route>
      <Route path='/goods/gfiles/edit/:id'>
        <BreadcrumbCustom paths={['商品管理', '商品档案', '编辑商品']} />
        <GoodsFilesEdit type='edit' />
      </Route>
      <Route path='/goods/gfiles/detail/:id'>
        <GoodsFilesDetail />
      </Route>
      <Route path='/goods/gfiles'>
        <BreadcrumbCustom paths={['商品管理', '商品档案']} />
        <GoodsFiles />
      </Route>

      <Route path='/goods/gbrand/add'>
        <BreadcrumbCustom paths={['商品管理', '品牌管理', '新增品牌']} />
        <GoodsBrandEdit type='add' />
      </Route>
      <Route path='/goods/gbrand/edit/:id'>
        <BreadcrumbCustom paths={['商品管理', '品牌管理', '编辑品牌']} />
        <GoodsBrandEdit type='edit' />
      </Route>
      <Route path='/goods/gbrand'>
        <BreadcrumbCustom paths={['商品管理', '品牌管理']} />
        <GoodsBrandManagement />
      </Route>

      <Route path='/goods/classify'>
        <BreadcrumbCustom paths={['商品管理', '分类管理']} />
        <ClassificationManagement />
      </Route>

      <Route path='/purchase/porder/add'>
        <BreadcrumbCustom paths={['采购管理', '采购单', '创建采购单']} />
        <PurchaseOrderEdit type='add' />
      </Route>
      <Route path='/purchase/porder/edit/:id'>
        <BreadcrumbCustom paths={['采购管理', '采购单', '编辑采购单']} />
        <PurchaseOrderEdit type='edit' />
      </Route>
      <Route path='/purchase/porder/detail/:id'>
        <PurchaseOrderDetail />
      </Route>
      <Route path='/purchase/porder'>
        <BreadcrumbCustom paths={['采购管理', '采购单']} />
        <PurchaseOrder />
      </Route>

      <Route path='/sales/sorder/add'>
        <BreadcrumbCustom paths={['销售管理', '销售单', '创建销售单']} />
        <SalesOrderEdit type='add' />
      </Route>
      <Route path='/sales/sorder/edit/:id'>
        <BreadcrumbCustom paths={['销售管理', '销售单', '编辑销售单']} />
        <SalesOrderEdit type='edit' />
      </Route>
      <Route path='/sales/sorder/detail/:id'>
        <SalesOrderDetail />
      </Route>
      <Route path='/sales/sorder'>
        <BreadcrumbCustom paths={['销售管理', '销售单']} />
        <SalesOrder />
      </Route>

      <Route path='/warehouse/wlist/add'>
        <BreadcrumbCustom paths={['仓库管理', '仓库列表', '新增仓库']} />
        <WarehouseListEdit type='add' />
      </Route>
      <Route path='/warehouse/wlist/edit/:id'>
        <BreadcrumbCustom paths={['仓库管理', '仓库列表', '编辑仓库']} />
        <WarehouseListEdit type='edit' />
      </Route>
      <Route path='/warehouse/wlist/detail/:id'>
        <WarehouseListDetail />
      </Route>
      <Route path='/warehouse/wlist'>
        <BreadcrumbCustom paths={['仓库管理', '仓库列表']} />
        <WarehouseList />
      </Route>

      <Route path='/warehouse/stock'>
        <BreadcrumbCustom paths={['仓库管理', '库存中心']} />
        <StockCenter />
      </Route>

      <Route path='/warehouse/inbound/add/:pId'>
        <BreadcrumbCustom paths={['仓库管理', '入库单', '创建入库单']} />
        <InboundOrderEdit type='add' />
      </Route>
      <Route path='/warehouse/inbound/edit/:id'>
        <BreadcrumbCustom paths={['仓库管理', '入库单', '编辑入库单']} />
        <InboundOrderEdit type='edit' />
      </Route>
      <Route path='/warehouse/inbound/detail/:id'>
        <InboundOrderDetail />
      </Route>
      <Route path='/warehouse/inbound'>
        <BreadcrumbCustom paths={['仓库管理', '入库单']} />
        <InboundOrder />
      </Route>

      <Route path='/warehouse/outbound/add/:pId'>
        <BreadcrumbCustom paths={['仓库管理', '出库单', '创建出库单']} />
        <OutboundOrderEdit type='add' />
      </Route>
      <Route path='/warehouse/outbound/edit/:id'>
        <BreadcrumbCustom paths={['仓库管理', '出库单', '编辑出库单']} />
        <OutboundOrderEdit type='edit' />
      </Route>
      <Route path='/warehouse/outbound/detail/:id'>
        <OutboundOrderDetail />
      </Route>
      <Route path='/warehouse/outbound'>
        <BreadcrumbCustom paths={['仓库管理', '出库单']} />
        <OutboundOrder />
      </Route>

      <Route path='/warehouse/assembly/add'>
        <BreadcrumbCustom paths={['仓库管理', '组装单', '创建组装单']} />
        <AssemblyOrderEdit type='add' />
      </Route>
      <Route path='/warehouse/assembly/edit/:id'>
        <BreadcrumbCustom paths={['仓库管理', '组装单', '编辑组装单']} />
        <AssemblyOrderEdit type='edit' />
      </Route>
      <Route path='/warehouse/assembly/detail/:id'>
        <AssemblyOrderDetail />
      </Route>
      <Route path='/warehouse/assembly'>
        <BreadcrumbCustom paths={['仓库管理', '组装单']} />
        <AssemblyOrder />
      </Route>

      <Route path='/unit/ufiles/add'>
        <BreadcrumbCustom paths={['单位管理', '单位档案', '新增单位']} />
        <UnitFilesEdit type='add' />
      </Route>
      <Route path='/unit/ufiles/edit/:id'>
        <BreadcrumbCustom paths={['单位管理', '单位档案', '编辑单位']} />
        <UnitFilesEdit type='edit' />
      </Route>
      <Route path='/unit/ufiles/detail/:id'>
        <UnitFilesDetail />
      </Route>
      <Route path='/unit/ufiles'>
        <BreadcrumbCustom paths={['单位管理', '单位档案']} />
        <UnitFiles />
      </Route>

      <Route path='/car/model/add'>
        <BreadcrumbCustom paths={['车型管理', '车型档案', '新增车型']} />
        <ModelEdit type='add' />
      </Route>
      <Route path='/car/model/edit/:id'>
        <BreadcrumbCustom paths={['车型管理', '车型档案', '编辑车型']} />
        <ModelEdit type='edit' />
      </Route>
      <Route path='/car/model/detail/:id'>
        <ModelDetail />
      </Route>
      <Route path='/car/model'>
        <BreadcrumbCustom paths={['车型管理', '车型档案']} />
        <ModelManagement />
      </Route>

      <Route path='/car/cbrand/add'>
        <BreadcrumbCustom paths={['车型管理', '品牌管理', '新增品牌']} />
        <CarBrandEdit type='add' />
      </Route>
      <Route path='/car/cbrand/edit/:id'>
        <BreadcrumbCustom paths={['车型管理', '品牌管理', '编辑品牌']} />
        <CarBrandEdit type='edit' />
      </Route>
      <Route path='/car/cbrand'>
        <BreadcrumbCustom paths={['车型管理', '品牌管理']} />
        <CarBrandManagement />
      </Route>

      <Route path='/car/series/add'>
        <BreadcrumbCustom paths={['车型管理', '车系管理', '新增车系']} />
        <SeriesEdit type='add' />
      </Route>
      <Route path='/car/series/edit/:id'>
        <BreadcrumbCustom paths={['车型管理', '车系管理', '编辑车系']} />
        <SeriesEdit type='edit' />
      </Route>
      <Route path='/car/series'>
        <BreadcrumbCustom paths={['车型管理', '车系管理']} />
        <SeriesManagement />
      </Route>

      <Route path='/car/vin'>
        <BreadcrumbCustom paths={['车型管理', 'VIN码查询']} />
        <VINCodeQuery />
      </Route>

      <Route path='/system/resource/add'>
        <BreadcrumbCustom paths={['系统管理', '资源管理', '新增资源']} />
        <ResourceEdit type='add' />
      </Route>
      <Route path='/system/resource/edit/:id'>
        <BreadcrumbCustom paths={['系统管理', '资源管理', '编辑资源']} />
        <ResourceEdit type='edit' />
      </Route>
      <Route path='/system/resource'>
        <BreadcrumbCustom paths={['系统管理', '资源管理']} />
        <ResourceManagement />
      </Route>

      <Route path='/system/role/add'>
        <BreadcrumbCustom paths={['系统管理', '角色管理', '新增角色']} />
        <RoleEdit type='add' />
      </Route>
      <Route path='/system/role/edit/:id'>
        <BreadcrumbCustom paths={['系统管理', '角色管理', '编辑角色']} />
        <RoleEdit type='edit' />
      </Route>
      <Route path='/system/role'>
        <BreadcrumbCustom paths={['系统管理', '角色管理']} />
        <RoleManagement />
      </Route>

      <Route path='/system/user/add'>
        <BreadcrumbCustom paths={['系统管理', '用户管理', '新增用户']} />
        <UserEdit type='add' />
      </Route>
      <Route path='/system/user/edit/:id'>
        <BreadcrumbCustom paths={['系统管理', '用户管理', '编辑用户']} />
        <UserEdit type='edit' />
      </Route>
      <Route path='/system/user'>
        <BreadcrumbCustom paths={['系统管理', '用户管理']} />
        <UserManagement />
      </Route>

      <Route path='/system/tenant/add'>
        <BreadcrumbCustom paths={['系统管理', '租户管理', '新增租户']} />
        <TenantEdit type='add' />
      </Route>
      <Route path='/system/tenant/edit/:id'>
        <BreadcrumbCustom paths={['系统管理', '租户管理', '编辑租户']} />
        <TenantEdit type='edit' />
      </Route>
      <Route path='/system/tenant'>
        <BreadcrumbCustom paths={['系统管理', '租户管理']} />
        <TenantManagement />
      </Route>

      <Route path='/403'>
        <NoPermission />
      </Route>
      <Route path='/404'>
        <NotFound />
      </Route>
      <Route path='/500'>
        <NetworkError />
      </Route>

      <Route path='/welcome'>
        <Welcome />
      </Route>

      <Redirect exact from='/' to='/welcome' />
      <Redirect from='*' to='/404' />
    </Switch>
  );
};

export default PageCustom;

import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import BatchImportFile from '../../common/upload/batch-import-file';
import BatchUploadImg from '../../common/upload/batch-upload-img';
import TableBatchDelete from '../../common/table/table-batch-delete';
import UseTable from '../../common/table/use-table';
import { downloadTemplate } from '../../../utils/definition';
import { brandPage, deleteBrand } from '../../../service/car';

import { Button, Divider, Modal, message } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

interface Props {
  searchForm?: { [key: string]: any };
}

const CarBrandListTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const [downloadUrl] = useState<string>(
    `${process.env.REACT_APP_DOWNLOAD_URL}${downloadTemplate['车型品牌']}`
  );

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      let params = { ...searchForm, ...listPageData };
      const { records, total } = await brandPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const addRow = useStableCallback(() => {
    history.push('/car/cbrand/add');
  });

  const editRow = useStableCallback((record: any) => {
    history.push(`/car/cbrand/edit/${record.id}`);
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除品牌【${record.name}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch([record.id]);
      },
    });
  });

  const deleteBatchRow = useStableCallback((ids: number[]) => {
    if (!ids || !ids.length) return message.warning('请先选择需删除的品牌');
    Modal.confirm({
      title: `确定删除选中的品牌吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch(ids);
      },
    });
  });

  const deleteBatch = useStableCallback(async (ids: number[]) => {
    try {
      await deleteBrand(ids);
      message.success('删除成功');
      setSelectedRowKeys([]);
      getList(1);
    } catch (err) {}
  });

  const columns: ColumnsType = [
    {
      title: '品牌名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '品牌LOGO',
      dataIndex: 'logo',
      key: 'logo',
      align: 'center',
      render: (text: any, record: any) => (
        <img
          style={{ width: '32px', height: '32px', borderRadius: '50%' }}
          src={record.logo}
          alt=''
        />
      ),
    },
    {
      title: '首字母',
      dataIndex: 'firstCode',
      key: 'firstCode',
      align: 'center',
    },
    {
      title: '品牌ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: '更新时间',
      key: 'updatedTime',
      dataIndex: 'updatedTime',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (text: any, record: any) => (
        <>
          <Button type='link' onClick={() => editRow(record)}>
            编辑
          </Button>
          <Divider type='vertical' />
          <Button type='link' danger onClick={() => deleteRow(record)}>
            删除
          </Button>
        </>
      ),
    },
  ];

  const { selectedRowKeys, setSelectedRowKeys, tableSelect } = TableBatchDelete(
    { deleteBatchRow }
  );

  const { table, setLoading, setList, setListTotal, pageData, setPageData } =
    UseTable({
      columns,
      getList,
      selectedRowKeys,
      onSelectChange,
    });

  useEffect(() => {
    getList(1);
  }, [getList, searchForm]);

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>品牌列表</div>
        <div className='header-operate flex-x'>
          <Button href={downloadUrl} download>
            下载模板
          </Button>
          <BatchUploadImg uploadData={{ type: 1, importOperation: true }} />
          <BatchImportFile
            importUrl={'/car-brand/import'}
            getList={getList}
            btnName={'批量导入品牌'}
          />
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {selectedRowKeys.length > 0 && tableSelect}

      {table}
    </div>
  );
};

export default CarBrandListTable;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { EditableRow } from '../../../common/editable/editable-row';
import {
  EditableCell,
  InputCell,
  DataType,
} from '../../../common/editable/editable-cell';

import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  value?: DataType[];
  onChange?: (newValue: DataType[]) => void;
}

const LocationInformationTable: FC<Props> = ({ value = [], onChange }) => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const handleValueChange = useStableCallback((newValue: DataType[]) => {
    setDataSource(newValue);
    onChange?.(newValue);
  });

  const handleAdd = useStableCallback(() => {
    const newData: DataType = {
      key: Date.now(),
      code: null,
      name: null,
      spec: null,
      desc: null,
    };
    handleValueChange([...dataSource, newData]);
  });

  const handleDelete = useStableCallback((key: React.Key) => {
    handleValueChange(dataSource.filter((item) => item.key !== key));
  });

  const handleChange = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      handleValueChange(
        dataSource.map((each) => {
          if (row.key === each.key) {
            const newItem = {
              ...each,
              ...row,
            };
            return newItem;
          }
          return each;
        })
      );
    }
  );

  useEffect(() => {
    if (value && value.length) {
      value.forEach((each) => {
        each.key = each.id || each.key;
      });
      setDataSource(value);
    }
  }, [value]);

  const columns = React.useMemo(
    () => [
      {
        title: '库位编号',
        dataIndex: 'code',
        key: 'code',
        width: '20%',
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '库位编号',
          dataIndex: 'code',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                rules={[{ required: true, message: '请输入库位编号' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '库位名称',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '库位名称',
          dataIndex: 'name',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                rules={[{ required: true, message: '请输入库位名称' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '长宽高(mm)',
        dataIndex: 'spec',
        key: 'spec',
        width: '20%',
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '长宽高(mm)',
          dataIndex: 'spec',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell name={name} onChange={onChange}>
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '描述',
        dataIndex: 'desc',
        key: 'desc',
        width: '25%',
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '描述',
          dataIndex: 'desc',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell name={name} onChange={onChange}>
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: '15%',
        render: (text: any, record: any) => (
          <>
            <Button
              type='link'
              danger
              onClick={() => handleDelete(record?.key)}
            >
              删除
            </Button>
          </>
        ),
      },
    ],
    [handleChange, handleDelete]
  );

  return (
    <div>
      <Table
        className='list-table editable'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='key'
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <Button
        block
        type='dashed'
        icon={<PlusOutlined />}
        style={{ margin: '16px 0 4px' }}
        onClick={handleAdd}
      >
        添加库位
      </Button>
    </div>
  );
};

export default LocationInformationTable;

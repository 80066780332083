import React, { FC, useState } from 'react';
import { mangLocal } from '../../../utils/common';

import { Upload, message, Button } from 'antd';

interface Props {
  importUrl: string;
  getList: (value: any) => Promise<any>;
  btnName?: string;
}

const BatchImportFile: FC<Props> = ({ importUrl, getList, btnName = '批量导入' }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const uploadHeaders = {
    authorization: mangLocal('authorization'),
    appId: mangLocal('userInfo')?.username,
  };

  const batchImport = {
    name: 'file',
    maxCount: 1,
    showUploadList: false,
    disabled: loading,
    action: `${process.env.REACT_APP_BASE_URL}${importUrl}`,
    headers: uploadHeaders,
    onChange(info: any) {
      const { status, response } = info.file;
      if (!status) return;
      if (status === 'uploading') {
        setLoading(true);
      } else if (status === 'done') {
        setLoading(false);
        if (response.code && response.code !== 0) {
          return message.error(response.msg);
        }
        message.success('上传成功，导入数据正在后台处理中~');
        getList(1);
      } else {
        setLoading(false);
        message.error('导入失败');
      }
    },
  };

  return (
    <Upload {...batchImport}>
      <Button loading={loading}>{btnName}</Button>
    </Upload>
  );
};

export default BatchImportFile;

import React, { FC, useEffect, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { phoneValidate } from '../../../../utils/validate';
import { createOpenModal, createCloseModal } from '../../../../utils/modal';
import { UnitContactsProps } from '../../../../service/unit';

import { ConfigProvider, Modal, Form, Input, Radio } from 'antd';

interface Props {
  visible: boolean;
  type: string;
  source: string;
  value?: UnitContactsProps;
  onCancel: () => void;
  onConfirm: (values: any) => void;
}

const AddUnitContactsModal: FC<Props> = ({
  visible,
  type,
  source,
  value,
  onCancel,
  onConfirm,
}) => {
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onInnerConfirm = useStableCallback(async () => {
    await form.validateFields();
    const values = form.getFieldsValue(true);
    setSubmitLoading(true);
    try {
      onConfirm(values);
      onInnerCancel();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onInnerCancel = useStableCallback(() => {
    form.resetFields();
    onCancel();
  });

  useEffect(() => {
    if (type === 'add') {
      form.setFieldsValue({ isDefault: true });
    } else {
      form.setFieldsValue(value);
    }
  }, [form, type, value]);

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title='添加联系人'
        centered
        maskClosable={false}
        visible={visible}
        confirmLoading={submitLoading}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <Form form={form} labelCol={{ span: 4 }} name='add-contacts'>
          <Form.Item
            name='name'
            label='姓名'
            rules={[{ required: true, message: '请输入姓名' }]}
          >
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item
            name='mobile'
            label='手机号'
            rules={[{ required: true, message: '请输入手机号' }, phoneValidate]}
          >
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item name='telephone' label='电话'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item name='qq' label='QQ'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item
            name='email'
            label='Email'
            rules={[{ type: 'email', message: '邮箱格式不正确' }]}
          >
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          {source === 'unit' && (
            <Form.Item name='address' label='收货地址'>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          )}
          <Form.Item name='isDefault' label='是否默认'>
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export const openAddUnitContactsModal = createOpenModal(
  AddUnitContactsModal,
  'AddUnitContactsModal'
);

export const closeAddUnitContactsModal = createCloseModal(
  AddUnitContactsModal,
  'AddUnitContactsModal'
);

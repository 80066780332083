import './welcome.scss';
import welcomeLogo from '../../assets/images/welcome.png';

import { FC } from 'react';

const Welcome: FC = () => {
  return (
    <div className='welcome flex-x m-t-b'>
      <img className='img' src={welcomeLogo} alt='' />
      <p className='text'>欢迎使用云销飞车</p>
    </div>
  );
};

export default Welcome;

import React, { FC } from 'react';

import { Select } from 'antd';
const { Option } = Select;

interface Props {
  options: any[];
  hasId?: boolean;
  eachName?: string;
  disabled?: boolean;
  value?: any;
  onChange?: (value: any) => void;
}

const DropDownSelect: FC<Props> = ({
  options,
  hasId = false,
  eachName = 'name',
  disabled = false,
  value,
  onChange,
}) => {
  return (
    <>
      <Select
        showSearch
        allowClear
        placeholder='请选择'
        optionFilterProp='children'
        filterOption={(input, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        value={value}
        onChange={onChange}
      >
        {options?.map((item: any, index: number) =>
          hasId ? (
            <Option value={item.id} key={item.id}>
              {item[eachName]}
            </Option>
          ) : (
            <Option value={item} key={index}>
              {item}
            </Option>
          )
        )}
      </Select>
    </>
  );
};

export default DropDownSelect;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { clearChildren } from '../../../utils/common';
import { resourceTree, deleteResource } from '../../../service/system';

import { Button, Table, Divider, Modal, message } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

const ResourceManagement = () => {
  const history = useHistory();

  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getList = useStableCallback(async () => {
    setLoading(true);
    try {
      const res = await resourceTree();
      setList(clearChildren(res));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const addRow = useStableCallback(() => {
    history.push('/system/resource/add');
  });

  const editRow = useStableCallback((record: any) => {
    history.push(`/system/resource/edit/${record.id}`);
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除资源【${record.name}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          await deleteResource(record.id);
          message.success('删除成功');
          getList();
        } catch (err) {}
      },
    });
  });

  useEffect(() => {
    getList();
  }, [getList]);

  const expandedRowRender = (record: any) => {
    const columns = [
      { title: '资源名称', dataIndex: 'name', key: 'name' },
      { title: 'KEY', dataIndex: 'code', key: 'code' },
      { title: '路径', dataIndex: 'uri', key: 'uri' },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        render: (text: any, record: any) => (
          <>
            <Button type='link' onClick={() => editRow(record)}>
              编辑
            </Button>
            <Divider type='vertical' />
            <Button type='link' danger onClick={() => deleteRow(record)}>
              删除
            </Button>
          </>
        ),
      },
    ];

    return (
      <Table
        rowKey='id'
        columns={columns}
        dataSource={record.children}
        pagination={false}
      />
    );
  };

  const columns = [
    { title: '资源名称', dataIndex: 'name', key: 'name' },
    { title: 'KEY', dataIndex: 'code', key: 'code' },
    { title: '图标', dataIndex: 'icon', key: 'icon' },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (text: any, record: any) => (
        <>
          <Button type='link' onClick={() => editRow(record)}>
            编辑
          </Button>
          <Divider type='vertical' />
          <Button type='link' danger onClick={() => deleteRow(record)}>
            删除
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>资源列表</div>
        <div className='header-operate'>
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={getList} />
        </div>
      </div>

      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        loading={loading}
        columns={columns}
        dataSource={list}
        pagination={false}
        expandable={{
          childrenColumnName: 'childrenColumnName',
          expandedRowRender: (record: any) => {
            return expandedRowRender(record);
          },
          rowExpandable(record) {
            return record.children && record.children.length > 0;
          },
        }}
      />
    </div>
  );
};

export default ResourceManagement;

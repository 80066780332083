import React from 'react';
import { Link } from 'react-router-dom';

import { FilesListProps } from '../../../../service/goods';

import { ColumnsType } from 'antd/lib/table';

export const GetGoodsFilesListColumns = (
  renderOpeartor?: (record: FilesListProps) => React.ReactElement,
  excluedIds: string[] = []
): ColumnsType<FilesListProps> => {
  const list: ColumnsType<FilesListProps> = [
    {
      title: '商品编号',
      dataIndex: 'partsCode',
      key: 'partsCode',
      render: (text: string, record: any) => {
        return <Link to={`/goods/gfiles/detail/${record.id}`}>{text}</Link>;
      },
      fixed: 'left' as any,
      width: 120,
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left' as any,
      width: 160,
    },
    {
      title: '品牌',
      dataIndex: 'brandName',
      key: 'brandName',
      fixed: 'left' as any,
      width: 80,
    },
    {
      title: '计量单位',
      dataIndex: 'unit',
      key: 'unit',
      align: 'center' as any,
      fixed: 'left' as any,
      width: 80,
    },
    {
      title: '商品规格',
      dataIndex: 'spec',
      key: 'spec',
      fixed: 'left' as any,
      width: 100,
      ellipsis: true,
    },
    {
      title: '4s销售价(元)',
      dataIndex: 'salePrice4s',
      key: 'salePrice4s',
      align: 'right' as any,
      width: 120,
    },
    {
      title: '4s进店价(元)',
      dataIndex: 'purchasePrice4s',
      key: 'purchasePrice4s',
      align: 'right' as any,
      width: 120,
    },
    {
      title: '销售商价(元)',
      dataIndex: 'dealerPrice',
      key: 'dealerPrice',
      align: 'right' as any,
      width: 120,
    },
    {
      title: '修理厂价(元)',
      dataIndex: 'repairPrice',
      key: 'repairPrice',
      align: 'right' as any,
      width: 120,
    },
    {
      title: '车主价(元)',
      dataIndex: 'ownerPrice',
      key: 'ownerPrice',
      align: 'right' as any,
      width: 120,
    },
    {
      title: '标准名称',
      dataIndex: 'standardDataName',
      key: 'standardDataName',
      width: 120,
    },
    {
      title: '标准型号',
      dataIndex: 'standardModelName',
      key: 'standardModelName',
      width: 120,
    },
    {
      title: '适用位置',
      dataIndex: 'location',
      key: 'location',
      width: 120,
    },
    {
      title: '产地',
      dataIndex: 'localitys',
      key: 'localitys',
      width: 140,
      render: (text: any, record: any) => {
        return text?.map((each: string) => each).join('/');
      },
    },
    {
      title: '质保政策',
      dataIndex: 'warrantyPolicy',
      key: 'warrantyPolicy',
      width: 100,
    },
    {
      title: '单箱数量',
      dataIndex: 'oneBoxQty',
      key: 'oneBoxQty',
      align: 'center' as any,
      width: 80,
    },
    {
      title: '最小起订量',
      dataIndex: 'minOrderQty',
      key: 'minOrderQty',
      align: 'center' as any,
      width: 100,
    },
    {
      title: '包装要求',
      dataIndex: 'packing',
      key: 'packing',
      width: 120,
      ellipsis: true,
    },
    {
      title: '适用车型',
      dataIndex: 'saleMode',
      key: 'saleMode',
      width: 120,
      ellipsis: true,
    },
    {
      title: '车型分类',
      dataIndex: 'modelCatalog',
      key: 'modelCatalog',
      width: 120,
      ellipsis: true,
    },
    {
      title: '车型代码',
      dataIndex: 'modelCode',
      key: 'modelCode',
      width: 120,
      ellipsis: true,
    },
    {
      title: '商品属性',
      dataIndex: 'partsProp',
      key: 'partsProp',
      width: 100,
    },
    {
      title: '租户',
      dataIndex: 'tenantName',
      key: 'tenantName',
      width: 120,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedTime',
      key: 'updatedTime',
      width: 180,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 160,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

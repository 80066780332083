import React, { FC, useEffect } from 'react';
import * as echarts from 'echarts';

interface Props {
  historyPrice: { [key: string]: any }[];
}

const PriceEcharts: FC<Props> = ({ historyPrice }) => {
  const option: echarts.EChartsOption = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['4s销售价', '4s进店价', '销售商价', '修理厂价', '车主价'],
    },
    color: ['#0676ED', '#2FC25B', '#FFD300', '#FF5579', '#A256EC'],
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: historyPrice?.map((item) => {
        return item.createdTime.split(' ')[0];
      }),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: '4s销售价',
        type: 'line',
        stack: 'Total',
        data: historyPrice?.map((item) => {
          return item.salePrice4s;
        }),
      },
      {
        name: '4s进店价',
        type: 'line',
        stack: 'Total',
        data: historyPrice?.map((item) => {
          return item.purchasePrice4s;
        }),
      },
      {
        name: '销售商价',
        type: 'line',
        stack: 'Total',
        data: historyPrice?.map((item) => {
          return item.dealerPrice;
        }),
      },
      {
        name: '修理厂价',
        type: 'line',
        stack: 'Total',
        data: historyPrice?.map((item) => {
          return item.repairPrice;
        }),
      },
      {
        name: '车主价',
        type: 'line',
        stack: 'Total',
        data: historyPrice?.map((item) => {
          return item.ownerPrice;
        }),
      },
    ],
  };

  useEffect(() => {
    const chartDom = document.getElementById('main')!;
    const myChart = echarts.init(chartDom);
    option && myChart.setOption(option);
  });

  return <div id='main' style={{ width: '100%', height: '360px' }}></div>;
};

export default PriceEcharts;

import React, { FC, useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { mangLocal } from '../../../utils/common';

import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

interface Props {
  uploadData: { [key: string]: any };
  imageUrl: string;
  getImageUrl: (value: string) => void;
}

const UploadImg: FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const uploadHeaders = {
    authorization: mangLocal('authorization'),
    appId: mangLocal('userInfo')?.username,
  };
  const uploadAction = `${process.env.REACT_APP_BASE_URL}/file/upload/image`;

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.warning('请上传 JPG/PNG 格式的文件');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.warning('图片须小于 2MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleUploadChange = useStableCallback((info) => {
    const { status, response } = info.file;
    if (!status) return;
    if (status === 'uploading') {
      setLoading(true);
    } else if (status === 'done') {
      setLoading(false);
      if (response.code && response.code !== 0) {
        return message.error(response.msg);
      }
      const url = response.data;
      props.getImageUrl(url);
    } else {
      setLoading(false);
      message.error('上传失败');
    }
  });

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );

  return (
    <Upload
      name='file'
      listType='picture-card'
      showUploadList={false}
      maxCount={1}
      action={uploadAction}
      headers={uploadHeaders}
      data={props.uploadData}
      beforeUpload={beforeUpload}
      onChange={handleUploadChange}
    >
      {props.imageUrl ? (
        <img src={props.imageUrl} alt='' style={{ width: '100%' }} />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default UploadImg;

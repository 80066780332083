// 下载模板
export const downloadTemplate: { [key: string]: string } = {
  车型品牌: '品牌.xlsx',
  车型车系: '车系.xlsx',
  商品历史价格: '商品历史价格.xlsx',
  商品品牌: '商品品牌.xlsx',
  商品信息: '商品信息.xlsx',
  商品分类: '商品分类.xlsx',
  商品标准名称: '商品标准名称.xlsx',
  单位信息: '单位信息.xlsx',
  库存初始化: '库存初始化.xlsx',
};

// 用户类型
export const userStatusMap: { [key: number]: string } = {
  1: '正常',
  2: '停用',
};

// 租户类型
export const tenantTypeMap: { [key: number]: string } = {
  1: '平台',
  2: '供应商',
  3: '分销商',
  4: '修理厂',
};

// 商品档案上传图片类型
export const photoTypeMap: { [key: number]: string } = {
  4: '商品主图',
  5: '商品爆炸图',
  6: '商品介绍',
  7: '功能介绍',
  8: '商品视频',
  9: '使用介绍',
  10: '商品安装介绍',
  11: '商品文档',
  12: '安装操作视频',
};

// 分类级别
export const categoryLevel: { [key: number]: string } = {
  1: '一级',
  2: '二级',
  3: '三级',
};

// 单位属性
export const unitAttributes: string[] = [
  '客户',
  '供应商',
  '物流',
  '保险',
  '租户',
];

// 单位类型（与选择的单位属性有关）
export const unitTypes: { [key: string]: string[] } = {
  客户: ['个人车主', '企业客户', '汽修企业', '汽配商', '工厂', '4S站'],
  供应商: ['工厂', '品牌公司', '汽配商', '4S站', '个人'],
  物流: ['物流公司', '快递企业', '物流专线', '摩托快运'],
  保险: ['无'],
  租户: ['无'],
};

// 单据订单状态 - 销售单、出库单
export const erpOrderStatusMap1: { [key: string]: string[] } = {
  0: ['待提交', '#52C41A', 'icon-daitijiao', ''],
  10: ['待审核', '#FAAD14', 'icon-daishenhe', 'icon-jiaobiao_daishenhe'],
  20: ['待出库', '#FF4D4F', 'icon-daichuku', 'icon-jiaobiao_daichuku'],
  21: ['部分出库', '#FF4D4F', 'icon-bufenchuku', 'icon-jiaobiao_bufenchuku'],
  22: ['已出库', '#FF4D4F', 'icon-chuku', 'icon-jiaobiao_yichuku'],
  30: ['已完成', '#12C99B', 'icon-yiwancheng', 'icon-jiaobiao_yiwancheng'],
};
// 单据订单状态 - 采购单、入库单
export const erpOrderStatusMap2: { [key: string]: string[] } = {
  0: ['待提交', '#52C41A', 'icon-daitijiao', ''],
  10: ['待审核', '#FAAD14', 'icon-daishenhe', 'icon-jiaobiao_daishenhe'],
  20: ['待入库', '#FF4D4F', 'icon-chuku', 'icon-jiaobiao_dairuku'],
  21: ['部分入库', '#FF4D4F', 'icon-bufenruku', 'icon-jiaobiao_bufenruku'],
  22: ['已入库', '#FF4D4F', 'icon-daichuku', 'icon-jiaobiao_yiruku'],
  30: ['已完成', '#12C99B', 'icon-yiwancheng', 'icon-jiaobiao_yiwancheng'],
};

// 收款方式
export const paymentTerm: string[] = [
  '对公转账',
  '微信',
  '支付宝',
  '现金',
  '挂账30天',
  '挂账45天',
  '挂账90天',
  '挂账',
];

// 收款状态
export const paymentStatus: string[] = ['待收款', '部分收款', '收款完成'];

// 付款状态
export const payStatus: string[] = ['待付款', '部分付款', '付款完成'];

// 发票类型
export const invoiceType: string[] = ['增值发票', '普通发票', '未税不开票'];

// 票据状态
export const ticketStatus: string[] = ['待开票', '已开票', '未税不开票'];

// 发运方式
export const shippingMethod: string[] = [
  '物流',
  '快递',
  '闪送',
  '送货上门',
  '客户自取',
  '摩托车托运',
];

// 退货状态
export const returnStatus: string[] = [
  '无退货',
  '退货待发回',
  '退货到库',
  '退货完成',
];

// 运费分摊方式
export const freightShareWay: { [key: number]: string } = {
  0: '按数量',
  1: '按金额',
};

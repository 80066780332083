import { createContext } from 'react';
import { GbrandListProps } from '../../../../service/goods';

export const SelectContext = createContext<{
  gbrandSelectList: GbrandListProps[];
  filesSelectListObj: { [key: string]: string[] };
}>({
  gbrandSelectList: [],
  filesSelectListObj: {},
});

import React, { FC } from 'react';
import UseUnitContactsTable from './unit-contacts-table';

import { Descriptions } from 'antd';

interface Props {
  basicInfo: any;
}

const DetailBasicInfo: FC<Props> = ({ basicInfo }) => {
  const { table: unitContactsTable } = UseUnitContactsTable({
    list: basicInfo?.unitContacts,
  });

  return (
    <div className='detail-tabpanel'>
      <Descriptions title='基本信息'>
        <Descriptions.Item label='单位名称'>
          {basicInfo?.name || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='单位属性'>
          {basicInfo?.propCode || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='属性分类'>
          {basicInfo?.catalog || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='单位别名'>
          {basicInfo?.fname || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='所在地'>
          {basicInfo?.locations
            ? basicInfo?.locations?.map((each: string) => each).join('')
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label='详细地址'>
          {basicInfo?.address || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='网页' span={3}>
          {basicInfo?.url || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='备注' span={3}>
          {basicInfo?.remark || '-'}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        column={1}
        title='联系人'
        style={{ border: 'none', paddingBottom: 0 }}
      >
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          {unitContactsTable}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title='银行信息'>
        <Descriptions.Item label='开户名称'>
          {basicInfo?.accName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='开户账号'>
          {basicInfo?.accBankNo || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='开户行'>
          {basicInfo?.accBank || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='备注'>
          {basicInfo?.accRemark || '-'}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title='开票信息'>
        <Descriptions.Item label='单位名称'>
          {basicInfo?.taxCompany || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='税号'>
          {basicInfo?.taxNo || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='开户账号'>
          {basicInfo?.taxBankNo || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='开户行'>
          {basicInfo?.taxBank || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='开票地址'>
          {basicInfo?.taxAddress || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='电话'>
          {basicInfo?.taxPhone || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='税率(%)'>
          {basicInfo?.taxRate || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='备注' span={2}>
          {basicInfo?.taxRemark || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='发票寄送地址及联系人' span={3}>
          {basicInfo?.taxMailingAddress || '-'}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DetailBasicInfo;

import React, { FC, useState, useEffect, useMemo } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { GetStockCenterColumns } from './files-list-columns';
import { downloadTemplate } from '../../../utils/definition';
import BatchImportFile from '../../common/upload/batch-import-file';
import UseTable from '../../common/table/use-table';

import {
  stockPage,
  stockCanInit,
  StockCenterProps,
} from '../../../service/warehouse';

import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

interface Props {
  searchForm?: { [key: string]: any };
}

const StockCenterTable: FC<Props> = ({ searchForm }) => {
  const [downloadUrl] = useState<string>(
    `${process.env.REACT_APP_DOWNLOAD_URL}${downloadTemplate['库存初始化']}`
  );
  const [canInit, setCanInit] = useState<boolean>(false);

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      let params = { ...searchForm, ...listPageData };
      const { records, total } = await stockPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const columns: ColumnsType<StockCenterProps> = useMemo(
    () => GetStockCenterColumns(),
    []
  );

  const { table, setLoading, setList, setListTotal, pageData, setPageData } =
    UseTable({
      columns,
      getList,
      rowKey: 'partsId',
      rowCanSelect: false,
    });

  useEffect(() => {
    getList(1);
  }, [getList, searchForm]);

  useEffect(() => {
    stockCanInit().then(setCanInit);
  }, []);

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>库存明细</div>
        <div className='header-operate flex-x'>
          {canInit && (
            <>
              <Button href={downloadUrl} download>
                下载模板
              </Button>
              <BatchImportFile
                importUrl={'/erp-warehouse-info/stock/import'}
                getList={getList}
                btnName={'库存初始化'}
              />
            </>
          )}
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {table}
    </div>
  );
};

export default StockCenterTable;

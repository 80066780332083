import React, { FC, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { GetGoodsFilesListColumns } from './tables/files-list-columns';
import { mangLocal } from '../../../utils/common';
import { downloadTemplate } from '../../../utils/definition';

import BatchImportFile from '../../common/upload/batch-import-file';
import TableBatchDelete from '../../common/table/table-batch-delete';
import UseTable from '../../common/table/use-table';
import BatchUploadImg from '../../common/upload/batch-upload-img';

import {
  filesPage,
  deleteFiles,
  onSaleFiles,
  offSaleFiles,
  FilesListProps,
} from '../../../service/goods';

import { Button, Divider, Modal, message } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

interface Props {
  searchForm?: { [key: string]: any };
}

const GoodsFilesListTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();
  const userInfo = mangLocal('userInfo');

  const [downloadUrl] = useState<string>(
    `${process.env.REACT_APP_DOWNLOAD_URL}${downloadTemplate['商品信息']}`
  );

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      if (searchForm && searchForm.updatedTime) {
        searchForm.startTime = searchForm.updatedTime[0]?.format(
          'YYYY-MM-DD HH:mm:ss'
        );
        searchForm.endTime = searchForm.updatedTime[1]?.format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }
      let params = { ...searchForm, ...listPageData };
      const { records, total } = await filesPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const addRow = useStableCallback(() => {
    history.push('/goods/gfiles/add');
  });

  const editRow = useStableCallback((record: any) => {
    history.push(`/goods/gfiles/edit/${record.id}`);
  });

  const saleRow = useStableCallback((record: any) => {
    const type = record.onSale ? '下架' : '上架';
    Modal.confirm({
      title: `确定${type}商品【${record.name}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: async () => {
        try {
          if (record.onSale) {
            await offSaleFiles(record.id);
          } else {
            await onSaleFiles(record.id);
          }
          message.success(`${type}成功`);
          getList(1);
        } catch (err) {}
      },
    });
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除商品【${record.name}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch([record.id]);
      },
    });
  });

  const deleteBatchRow = useStableCallback((ids: number[]) => {
    if (!ids || !ids.length) return message.warning('请先选择需删除的商品');
    Modal.confirm({
      title: `确定删除选中的商品吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch(ids);
      },
    });
  });

  const deleteBatch = useStableCallback(async (ids: number[]) => {
    try {
      await deleteFiles(ids);
      message.success('删除成功');
      setSelectedRowKeys([]);
      getList(1);
    } catch (err) {}
  });

  const renderOpeartor = useStableCallback((record: any) => {
    return (
      <>
        <Button type='link' onClick={() => saleRow(record)}>
          {record.onSale ? '下架' : '上架'}
        </Button>
        <Divider type='vertical' />
        <Button type='link' onClick={() => editRow(record)}>
          编辑
        </Button>
        <Divider type='vertical' />
        <Button type='link' danger onClick={() => deleteRow(record)}>
          删除
        </Button>
      </>
    );
  });

  const columns: ColumnsType<FilesListProps> = useMemo(
    () =>
      GetGoodsFilesListColumns(
        renderOpeartor,
        userInfo?.tenantType === 1 ? [] : ['tenantName']
      ),
    [renderOpeartor, userInfo?.tenantType]
  );

  const { selectedRowKeys, setSelectedRowKeys, tableSelect } = TableBatchDelete(
    { deleteBatchRow }
  );

  const { table, setLoading, setList, setListTotal, pageData, setPageData } =
    UseTable({
      columns,
      getList,
      selectedRowKeys,
      onSelectChange,
    });

  useEffect(() => {
    getList(1);
  }, [getList, searchForm]);

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>商品列表</div>
        <div className='header-operate flex-x'>
          <Button href={downloadUrl} download>
            下载模板
          </Button>
          <BatchUploadImg uploadData={{ type: 100, importOperation: true }} />
          <BatchImportFile
            importUrl={'/erp-parts/import'}
            getList={getList}
            btnName={'批量导入商品'}
          />
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {selectedRowKeys.length > 0 && tableSelect}

      {table}
    </div>
  );
};

export default GoodsFilesListTable;

import React, {
  FC,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { EditableRow } from '../../../common/editable/editable-row';
import {
  EditableCell,
  InputCell,
  DataType,
} from '../../../common/editable/editable-cell';
import {
  openSelectProductModal,
  closeSelectProductModal,
} from '../../../erp/modal/select-product-modal';

import { Button, Table } from 'antd';

interface Props {
  value?: DataType[];
  onChange?: (newValue: DataType[]) => void;
  onRef: any;
  productTotalInfo: { [key: string]: any };
  onDelete: () => void;
}

const ProductInfoTable: FC<Props> = ({
  value = [],
  onChange,
  onRef,
  productTotalInfo,
  onDelete,
}) => {
  useImperativeHandle(onRef, () => ({ handleAddProduct }));

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const handleValueChange = useStableCallback((newValue: DataType[]) => {
    setDataSource(newValue);
    onChange?.(newValue);
  });

  const handleDelete = useStableCallback((partsId: React.Key) => {
    handleValueChange(dataSource.filter((item) => item.partsId !== partsId));
    onDelete();
  });

  const handleAdd = useStableCallback((list) => {
    list.forEach((each: any) => {
      each.displayModelName = each?.mname;
    });
    handleValueChange([...dataSource, ...list]);
  });

  const handleChange = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      handleValueChange(
        dataSource.map((each) => {
          if (row.partsId === each.partsId) {
            return {
              ...each,
              ...row,
            };
          }
          return each;
        })
      );
    }
  );

  const handleAddProduct = useStableCallback(() => {
    openSelectProductModal({
      queryType: 10,
      selectedPartsIdList: dataSource?.map((item) => {
        return item.partsId;
      }),
      excluedIds: ['inDynamicQtyFormat', 'latestInActualAmtFormat'],
      title: '添加销售商品',
      visible: true,
      onCancel: closeSelectProductModal,
      onConfirm: handleAdd,
    });
  });

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value);
    }
  }, [value]);

  const columns = React.useMemo(
    () => [
      {
        title: '商品编号',
        dataIndex: 'partsCode',
        key: 'partsCode',
        render: (text: string, record: any) => {
          return (
            <Link to={`/goods/gfiles/detail/${record.partsId}`} target='_blank'>
              {text}
            </Link>
          );
        },
        fixed: 'left' as any,
        width: 120,
      },
      {
        title: '商品名称',
        dataIndex: 'partsName',
        key: 'partsName',
        fixed: 'left' as any,
        width: 100,
      },
      {
        title: '品牌',
        dataIndex: 'partsBrandName',
        key: 'partsBrandName',
        width: 80,
      },
      {
        title: '显示车型',
        dataIndex: 'displayModelName',
        key: 'displayModelName',
        width: 100,
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '显示车型',
          dataIndex: 'displayModelName',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell name={name} onChange={onChange}>
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '计量单位',
        dataIndex: 'partsUnit',
        key: 'partsUnit',
        width: 80,
        align: 'center' as any,
      },
      {
        title: '商品规格',
        dataIndex: 'partsSpec',
        key: 'partsSpec',
        width: 80,
      },
      {
        title: '单箱数量',
        dataIndex: 'oneBoxQty',
        key: 'oneBoxQty',
        width: 80,
      },
      {
        title: '上次实销单价(元)',
        dataIndex: 'latestActualAmtFormat',
        key: 'latestActualAmtFormat',
        width: 130,
        align: 'right' as any,
        fixed: 'right' as any,
      },
      {
        title: '开单单价(元)',
        dataIndex: 'spSubAmt',
        key: 'spSubAmt',
        width: 100,
        fixed: 'right' as any,
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '开单单价(元)',
          dataIndex: 'spSubAmt',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                isInputNumber={true}
                rules={[{ required: true, message: '请输入' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '销售数量',
        dataIndex: 'orderQty',
        key: 'orderQty',
        width: 100,
        fixed: 'right' as any,
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '销售数量',
          dataIndex: 'orderQty',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                isInputNumber={true}
                numberPrecision={0}
                rules={[{ required: true, message: '请输入' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '预估箱数',
        dataIndex: 'estimateBoxFormat',
        key: 'estimateBoxFormat',
        width: 80,
        fixed: 'right' as any,
      },
      {
        title: '实销单价(元)',
        dataIndex: 'psubAmtFormat',
        key: 'psubAmtFormat',
        width: 100,
        align: 'right' as any,
        fixed: 'right' as any,
      },
      {
        title: '开单总额(元)',
        dataIndex: 'spAmtFormat',
        key: 'spAmtFormat',
        width: 100,
        align: 'right' as any,
        fixed: 'right' as any,
      },
      {
        title: '实销总额(元)',
        dataIndex: 'pamtFormat',
        key: 'pamtFormat',
        width: 100,
        align: 'right' as any,
        fixed: 'right' as any,
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 80,
        fixed: 'right' as any,
        render: (text: any, record: any) => (
          <>
            <Button
              type='link'
              danger
              onClick={() => handleDelete(record?.partsId)}
            >
              删除
            </Button>
          </>
        ),
      },
    ],
    [handleChange, handleDelete]
  );

  return (
    <div>
      <Table
        className='list-table editable'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='partsId'
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={() => {
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={9}>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  {productTotalInfo?.totalSales || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} colSpan={2}>
                  {productTotalInfo?.estimateBoxFormat || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12} align={'right'}>
                  {productTotalInfo?.pspAmtFormat || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13} align={'right'}>
                  {productTotalInfo?.salesAmtFormat || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};

export default ProductInfoTable;

import React, { FC } from 'react';
import UseProductInfoTable from './product-info-table';
import OrderStatusLogo from '../../../erp/order-status';
import { freightShareWay } from '../../../../utils/definition';

import { Descriptions } from 'antd';

interface Props {
  basicInfo: any;
}

const DetailBasicInfo: FC<Props> = ({ basicInfo }) => {
  const productInfoTable = UseProductInfoTable({ basicInfo });

  return (
    <div className='detail-tabpanel detail-erp'>
      <div className='detail-erp-logo'>
        <OrderStatusLogo type={1} status={basicInfo?.orderStatus} />
      </div>

      <Descriptions title='基本信息'>
        <Descriptions.Item label='单位名称'>
          {basicInfo?.unitName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='业务员'>
          {basicInfo?.salesMan || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='开单日期'>
          {basicInfo?.orderDate || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='收款方式'>
          {basicInfo?.payment || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='开票类型'>
          {basicInfo?.invoicingType || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='税率(%)'>
          {basicInfo?.taxRate || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='备注'>
          {basicInfo?.remark || '-'}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions column={1} title='商品信息'>
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          {productInfoTable}

          <div className='edit-erp-product'>
            <div className='discount flex-x m-s-end a-s-center'>
              <div className='discount-title'>
                整单优惠(%)：{basicInfo?.discount || '-'}
              </div>
              <div className='discount-title'>
                = 整单优惠额：{basicInfo?.discountAmtFormat || '-'}
              </div>
            </div>
            <div className='mode flex-x m-s-end a-s-center'>
              <div className='mode-title'>
                优惠分摊方式：
                {freightShareWay[basicInfo?.distributeType] || '-'}
              </div>
            </div>
            <div className='amount flex-x m-s-end a-s-center'>
              <div className='amount-title'>应收总金额：</div>
              <div className='amount-total'>
                {basicInfo?.ppayAmtFormat || '-'}
              </div>
            </div>
          </div>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DetailBasicInfo;

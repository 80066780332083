import './index.scss';

import React, { FC, useEffect, useMemo, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { prefixInteger } from '../../../utils/common';
import { createCloseModal, createOpenModal } from '../../../utils/modal';
import UseDetailTable from '../../common/table/detail-table';
import { GetPrintListColumns } from '../../erp/tables/print-list-columns';
import { printSalesOrder } from '../../../service/sales';

import { ConfigProvider, Descriptions, Input, Modal, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';

interface Props {
  visible: boolean;
  orderId: number;
  onCancel: () => void;
}

const SalesOrderPrintModal: FC<Props> = ({ visible, orderId, onCancel }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isPrint, setIsPrint] = useState<boolean>(false);
  const [printInfo, setPrintInfo] = useState<{ [key: string]: any }>({});

  const getPrintInfo = useStableCallback(async () => {
    setLoading(true);
    try {
      const res = await printSalesOrder(orderId);
      res.orderItems.forEach((each: any, index: number) => {
        each.indexCode = prefixInteger(index + 1, 3);
      });
      setPrintInfo(res);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const columns: ColumnsType = useMemo(
    () => GetPrintListColumns(['warehouseCode']),
    []
  );

  const table = UseDetailTable({
    columns,
    list: printInfo?.orderItems,
    scroll: { x: '100%', y: 200 },
    size: 'small',
  });

  const onInnerConfirm = useStableCallback(async () => {
    await setIsPrint(true);
    window.print();
  });

  const onInnerCancel = useStableCallback(async () => {
    await setIsPrint(false);
    onCancel();
  });

  useEffect(() => {
    if (visible) {
      getPrintInfo();
    }
  }, [getPrintInfo, visible]);

  useEffect(() => {
    const handlePrint = () => {
      setIsPrint(false);
    };
    window.addEventListener('afterprint', handlePrint);

    return () => {
      window.removeEventListener('afterprint', handlePrint);
    };
  }, []);

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        className='print-erp'
        width={'70%'}
        style={{ overflow: 'hidden' }}
        centered
        closable={false}
        maskClosable={false}
        okText={'打印'}
        visible={visible}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <Spin spinning={loading}>
          {isPrint ? (
            <span className='print-erp-title'>{printInfo?.titleName}</span>
          ) : (
            <Input
              className='print-erp-title'
              placeholder='请输入开单名称'
              value={printInfo.titleName}
              onChange={(e) =>
                setPrintInfo({ ...printInfo, titleName: e.target.value })
              }
            />
          )}

          <Descriptions column={5} className='print-erp-info'>
            <Descriptions.Item label='单位'>
              {printInfo?.unitName || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='日期'>
              {printInfo?.orderDate || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='单号'>
              {printInfo?.orderId || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='收款方式'>
              {printInfo?.payment || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='开票类型'>
              {printInfo?.invoicingType || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='收货联系人'>
              {printInfo?.contacts || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='联系方式'>
              {printInfo?.contactNumber || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='收货地址' span={3}>
              {printInfo?.deliveryAddress || '-'}
            </Descriptions.Item>
          </Descriptions>

          {table}
          <div className='print-erp-total flex-x a-s-center a-l-r'>
            <p className='col1'>金额大写：{printInfo?.amountInWords}</p>
            <p className='col2'>数量总计：{printInfo?.salesTotal}</p>
            <p className='col3'>金额总计：{printInfo?.amountTotalFormat}元</p>
          </div>

          <Descriptions column={5} className='print-erp-info'>
            <Descriptions.Item label='业务员'>
              {printInfo?.salesMan || '-'}
            </Descriptions.Item>
            <Descriptions.Item label='联系方式'>
              {isPrint ? (
                <span>{printInfo?.tenantUnitTelephone}</span>
              ) : (
                <Input
                  className='edit-input'
                  placeholder='请输入'
                  value={printInfo?.tenantUnitTelephone}
                  onChange={(e) =>
                    setPrintInfo({
                      ...printInfo,
                      tenantUnitTelephone: e.target.value,
                    })
                  }
                />
              )}
            </Descriptions.Item>
            <Descriptions.Item label='地址' span={3}>
              {isPrint ? (
                <span>{printInfo?.tenantUnitAddress}</span>
              ) : (
                <Input
                  className='edit-input'
                  placeholder='请输入'
                  value={printInfo?.tenantUnitAddress}
                  onChange={(e) =>
                    setPrintInfo({
                      ...printInfo,
                      tenantUnitAddress: e.target.value,
                    })
                  }
                />
              )}
            </Descriptions.Item>
            <Descriptions.Item label='物流公司' span={5}>
              {isPrint ? (
                <span>{printInfo?.logisticsUnitNames}</span>
              ) : (
                <Input
                  className='edit-input'
                  placeholder='请输入'
                  value={printInfo?.logisticsUnitNames}
                  onChange={(e) =>
                    setPrintInfo({
                      ...printInfo,
                      logisticsUnitNames: e.target.value,
                    })
                  }
                />
              )}
            </Descriptions.Item>
            <Descriptions.Item label='备注' span={5}>
              {printInfo?.remark || '-'}
            </Descriptions.Item>
          </Descriptions>

          <p className='print-erp-name'>云销飞车销售平台</p>
        </Spin>
      </Modal>
    </ConfigProvider>
  );
};

export const openSalesOrderPrintModal = createOpenModal(
  SalesOrderPrintModal,
  'SalesOrderPrintModal'
);

export const closeSalesOrderPrintModal = createCloseModal(
  SalesOrderPrintModal,
  'SalesOrderPrintModal'
);

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';

interface Params {
  basicInfo: { [key: string]: any };
}

const UseIncomingInfoTable = ({ basicInfo }: Params) => {
  const columns = useMemo(
    () => [
      {
        title: '商品编号',
        dataIndex: 'partsCode',
        key: 'partsCode',
        render: (text: string, record: any) => {
          return (
            <Link to={`/goods/gfiles/detail/${record.partsId}`}>{text}</Link>
          );
        },
        fixed: 'left' as any,
        width: 120,
      },
      {
        title: '商品名称',
        dataIndex: 'partsName',
        key: 'partsName',
        width: 100,
      },
      {
        title: '品牌',
        dataIndex: 'partsBrandName',
        key: 'partsBrandName',
        width: 80,
      },
      {
        title: '适用车型',
        dataIndex: 'mname',
        key: 'mname',
        width: 120,
      },
      {
        title: '计量单位',
        dataIndex: 'partsUnit',
        key: 'partsUnit',
        width: 80,
        align: 'center' as any,
      },
      {
        title: '商品规格',
        dataIndex: 'partsSpec',
        key: 'partsSpec',
        width: 80,
      },
      {
        title: '单箱数量',
        dataIndex: 'oneBoxQty',
        key: 'oneBoxQty',
        width: 80,
      },
      {
        title: '采购数量',
        dataIndex: 'orderQtyFormat',
        key: 'orderQtyFormat',
        width: 80,
      },
      {
        title: '仓库/库位',
        dataIndex: 'warehouseCode',
        key: 'warehouseCode',
        width: 140,
        render: (text: any, record: any) => (
          <div>
            {record.warehouseCode}/{record.locationCode}
          </div>
        ),
      },
      {
        title: '入库数量',
        dataIndex: 'warehouseQtyFormat',
        key: 'warehouseQtyFormat',
        width: 80,
      },
    ],
    []
  );

  return (
    <div>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='partsId'
        columns={columns}
        dataSource={basicInfo?.orderItems}
        pagination={false}
        summary={() => {
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  {basicInfo?.orderQtyFormat || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} colSpan={2} align={'center'}>
                  实际箱数：{basicInfo?.realBox || '-'}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};

export default UseIncomingInfoTable;

import React, { useMemo, useState } from 'react';

import { Button } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

interface Params {
  deleteBatchRow: (params: number[]) => void;
}

const TableBatchDelete = ({ deleteBatchRow }: Params) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const tableSelect = useMemo(
    () => (
      <div className='list-multiple flex-x m-s-between'>
        <div className='flex-x'>
          <InfoCircleFilled className='select-color' />
          <div className='select-num'>
            已选择&nbsp;
            <span className='select-color'>{selectedRowKeys.length}</span>
            &nbsp;项
          </div>
        </div>
        <div>
          <Button type='link' onClick={() => deleteBatchRow(selectedRowKeys)}>
            批量删除
          </Button>
          <Button type='link' onClick={() => setSelectedRowKeys([])}>
            清空
          </Button>
        </div>
      </div>
    ),
    [deleteBatchRow, selectedRowKeys],
  );

  return { selectedRowKeys, setSelectedRowKeys, tableSelect };
};

export default TableBatchDelete;

import { useMemo } from 'react';
import UseDetailTable from '../../../common/table/detail-table';
import { GetUnitCarsColumns } from '../tables/unit-cars-columns';
import { UnitCarsProps } from '../../../../service/unit';
import { ColumnsType } from 'antd/lib/table';

interface Params {
  list: { [key: string]: any }[];
}

const UseUnitCarsTable = ({ list }: Params) => {
  const columns: ColumnsType<UnitCarsProps> = useMemo(
    () => GetUnitCarsColumns(),
    []
  );

  const table = UseDetailTable({ columns, list });

  return {
    table,
  };
};

export default UseUnitCarsTable;

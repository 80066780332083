import React, { FC } from 'react';
import UseUnitShopsTable from './unit-shops-table';
import UseUnitCarsTable from './unit-cars-table';

import { Descriptions } from 'antd';

interface Props {
  basicInfo: any;
  detailInfo: any;
}

const DetailDetailInfo: FC<Props> = ({ basicInfo, detailInfo }) => {
  const { table: unitShopsTable } = UseUnitShopsTable({
    list: detailInfo?.unitShops,
  });
  const { table: unitCarsTable } = UseUnitCarsTable({
    list: detailInfo?.unitCars,
  });

  return (
    <div className='detail-tabpanel'>
      <Descriptions title='详细信息'>
        <Descriptions.Item label='单位拥有人'>
          {detailInfo?.owner || '-'}
        </Descriptions.Item>
      </Descriptions>

      {['汽修企业', '汽配商', '4S站'].includes(basicInfo?.catalog) && (
        <Descriptions
          column={1}
          title='门店信息'
          style={{ border: 'none', paddingBottom: 0 }}
        >
          <Descriptions.Item
            contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
          >
            {unitShopsTable}
          </Descriptions.Item>
        </Descriptions>
      )}

      {['个人车主', '企业客户'].includes(basicInfo?.catalog) && (
        <Descriptions
          column={1}
          title='车辆信息'
          style={{ border: 'none', paddingBottom: 0 }}
        >
          <Descriptions.Item
            contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
          >
            {unitCarsTable}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
};

export default DetailDetailInfo;

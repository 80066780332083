import { preFetch } from './fetch';

export const loginKey = async () => {
  const success = await preFetch('/auth/login?tokenKey=get', {
    method: 'GET',
  });
  return success;
};

export const login = async (userInfo: object) => {
  const success = await preFetch('/auth/login', {
    method: 'POST',
    body: userInfo,
  });
  return success;
};

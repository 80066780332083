import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';

interface Params {
  basicInfo: { [key: string]: any };
}

const UseProductInfoTable = ({ basicInfo }: Params) => {
  const columns = useMemo(
    () => [
      {
        title: '商品编号',
        dataIndex: 'partsCode',
        key: 'partsCode',
        render: (text: string, record: any) => {
          return (
            <Link to={`/goods/gfiles/detail/${record.partsId}`}>{text}</Link>
          );
        },
        fixed: 'left' as any,
        width: 120,
      },
      {
        title: '商品名称',
        dataIndex: 'partsName',
        key: 'partsName',
        width: 100,
      },
      {
        title: '品牌',
        dataIndex: 'partsBrandName',
        key: 'partsBrandName',
        width: 80,
      },
      {
        title: '适用车型',
        dataIndex: 'mname',
        key: 'mname',
        width: 120,
      },
      {
        title: '计量单位',
        dataIndex: 'partsUnit',
        key: 'partsUnit',
        width: 80,
        align: 'center' as any,
      },
      {
        title: '商品规格',
        dataIndex: 'partsSpec',
        key: 'partsSpec',
        width: 80,
      },
      {
        title: '单箱数量',
        dataIndex: 'oneBoxQty',
        key: 'oneBoxQty',
        width: 80,
      },
      {
        title: '采购数量',
        dataIndex: 'orderQtyFormat',
        key: 'orderQtyFormat',
        width: 80,
      },
      {
        title: '预估箱数',
        dataIndex: 'estimateBoxFormat',
        key: 'estimateBoxFormat',
        width: 80,
      },
      {
        title: '上次实购单价(元)',
        dataIndex: 'latestActualAmtFormat',
        key: 'latestActualAmtFormat',
        width: 130,
        align: 'right' as any,
      },
      {
        title: '采购单价(元)',
        dataIndex: 'spSubAmtFormat',
        key: 'spSubAmtFormat',
        width: 100,
        align: 'right' as any,
      },
      {
        title: '实购单价(元)',
        dataIndex: 'psubAmtFormat',
        key: 'psubAmtFormat',
        width: 100,
        align: 'right' as any,
      },
      {
        title: '采购总额(元)',
        dataIndex: 'spAmtFormat',
        key: 'spAmtFormat',
        width: 100,
        align: 'right' as any,
      },
      {
        title: '实购总额(元)',
        dataIndex: 'pamtFormat',
        key: 'pamtFormat',
        width: 100,
        align: 'right' as any,
      },
    ],
    []
  );

  return (
    <div>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columns}
        dataSource={basicInfo?.orderItems}
        pagination={false}
        summary={() => {
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  {basicInfo?.totalSalesFormat || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} colSpan={4}>
                  {basicInfo?.estimateBoxFormat || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12} align={'right'}>
                  {basicInfo?.pspAmtFormat || '-'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13} align={'right'}>
                  {basicInfo?.purchaseAmtFormat || '-'}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};

export default UseProductInfoTable;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { mangLocal } from '../../../utils/common';

import DropDownSelect from '../../common/select/drop-down-select';
import CityCascader from '../../common/select/city-cascader';

import { tenantList, TenantListProps } from '../../../service/system';
import {
  getFilesDropDownData,
  gbrandList,
  GbrandListProps,
} from '../../../service/goods';
import { getModelDropDownData } from '../../../service/car';

import { Form, Input, Button, Row, Col, Select, DatePicker } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props {
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const GoodsFilesListQuery: FC<Props> = ({ onReset, onFinish }) => {
  const userInfo = useMemo(() => {
    return mangLocal('userInfo');
  }, []);

  const [form] = Form.useForm();

  const [tenantSelectList, setTenantSelectList] = useState<TenantListProps[]>(
    []
  );
  const [gbrandSelectList, setGbrandSelelctList] = useState<GbrandListProps[]>(
    []
  );
  const [catalogSelectList, setCatalogSelectList] = useState<string[]>([]);
  const [filesSelectListObj, setFilesSelectListObj] = useState<{
    [key: string]: string[];
  }>({});

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  useEffect(() => {
    tenantList()
      .then(setTenantSelectList)
      .catch((err) => {});

    getModelDropDownData('catalog')
      .then((res) => setCatalogSelectList(res.catalog))
      .catch((err) => {});

    getFilesDropDownData()
      .then(setFilesSelectListObj)
      .catch((err) => {});
  }, []);

  useEffect(() => {
    gbrandList(userInfo?.tenantIds?.[0] || '')
      .then(setGbrandSelelctList)
      .catch((err) => {});
  }, [userInfo?.tenantIds]);

  return (
    <div className='page-custom common-query goods-files-query'>
      <Form
        form={form}
        name='goods-files-query'
        layout={'inline'}
        onFinish={onFinish}
      >
        {userInfo?.tenantType === 1 && (
          <div className='files-row flex-x m-s-start a-s-start'>
            <p>租户：</p>
            <Row>
              <Col span={10}>
                <Form.Item name='tenantId' style={{ paddingLeft: '30px' }}>
                  <Select mode='multiple' allowClear placeholder='请选择'>
                    {tenantSelectList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.tenantName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
        <div className='files-row flex-x m-s-start a-s-start'>
          <p>商品信息：</p>
          <Row>
            <Col span={8}>
              <Form.Item label='商品编号' name='partsCode'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='商品名称' name='name'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='品牌' name='brandId'>
                <DropDownSelect options={gbrandSelectList} hasId={true} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='标准名称' name='standardDataId'>
                <DropDownSelect
                  options={filesSelectListObj?.standardDatas}
                  hasId={true}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='标准型号' name='standardModelId'>
                <DropDownSelect
                  options={filesSelectListObj?.standardModels}
                  hasId={true}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='产地' name='localitys'>
                <CityCascader type='all' />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className='files-row flex-x m-s-start a-s-start'>
          <p>适用车型：</p>
          <Row>
            <Col span={8}>
              <Form.Item label='车型分类' name='modelCatalog'>
                <DropDownSelect options={catalogSelectList} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='车型代码' name='modelCode'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='底盘号' name='chassisCode'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className='files-row flex-x m-s-start a-s-start'>
          <p>其他选项：</p>
          <Row>
            <Col span={10}>
              <Form.Item label='更新时间' name='updatedTime'>
                <RangePicker placeholder={['开始时间', '结束时间']} />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item>
                <Button htmlType='button' onClick={onInnerReset}>
                  重置
                </Button>
                <Button type='primary' htmlType='submit'>
                  查询
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default GoodsFilesListQuery;

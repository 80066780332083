import React, { useMemo, useState } from 'react';

import { Table } from 'antd';

interface Params {
  columns: any[];
  getList: (params: any) => Promise<any>;
  rowKey?: string;
  rowCanSelect?: boolean;
  selectedRowKeys?: number[];
  onSelectChange?: (params: any[]) => void;
  onSelectCheckbox?: (params: any) => any;
  scroll?: object;
  components?: any | undefined;
}

const UseTable = ({
  columns,
  getList,
  rowKey = 'id',
  rowCanSelect = true,
  selectedRowKeys,
  onSelectChange,
  onSelectCheckbox,
  scroll = { x: '100%' },
  components = undefined,
}: Params) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [listTotal, setListTotal] = useState<number>(0);
  const [pageData, setPageData] = useState<{ [key: string]: number }>({
    current: 1,
    size: 10,
  });

  const table = useMemo(
    () => (
      <Table
        className='list-table'
        size={'middle'}
        scroll={scroll}
        rowKey={rowKey}
        components={components}
        columns={columns}
        loading={loading}
        dataSource={list}
        rowSelection={
          rowCanSelect
            ? {
                selectedRowKeys,
                onChange: onSelectChange,
                getCheckboxProps: onSelectCheckbox,
              }
            : undefined
        }
        pagination={{
          current: pageData.current,
          pageSize: pageData.size,
          total: listTotal,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: getList,
        }}
      />
    ),
    [
      scroll,
      rowKey,
      components,
      columns,
      loading,
      list,
      rowCanSelect,
      selectedRowKeys,
      onSelectChange,
      onSelectCheckbox,
      pageData,
      listTotal,
      getList,
    ]
  );

  return {
    table,
    setLoading,
    setList,
    setListTotal,
    pageData,
    setPageData,
    list,
  };
};

export default UseTable;

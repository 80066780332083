import React, { FC, useEffect, useMemo, useState } from 'react';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { EditableRow } from '../../../common/editable/editable-row';
import {
  EditableCell,
  InputCell,
  DataType,
  SelectCell,
} from '../../../common/editable/editable-cell';
import { mangLocal } from '../../../../utils/common';
import { getFilesPartsSupplier } from '../../../../service/goods';

import { Button, Select, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  value?: DataType[];
  onChange?: (newValue: DataType[]) => void;
}

const PartsSuppliersTable: FC<Props> = ({ value = [], onChange }) => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [supplier, setSupplier] = useState<{ [key: string]: any }[]>([]);

  const userInfo = useMemo(() => {
    return mangLocal('userInfo');
  }, []);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const handleValueChange = useStableCallback((newValue: DataType[]) => {
    setDataSource(newValue);
    onChange?.(newValue);
  });

  const handleAdd = useStableCallback(() => {
    const newData: DataType = {
      key: Date.now(),
      supplierId: null,
      supplierCode: null,
      supplierPartsCode: null,
    };
    handleValueChange([...dataSource, newData]);
  });

  const handleDelete = useStableCallback((key: React.Key) => {
    handleValueChange(dataSource.filter((item) => item.key !== key));
  });

  const handleChange = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      handleValueChange(
        dataSource.map((each) => {
          if (row.key === each.key) {
            const newItem = {
              ...each,
              ...row,
            };
            if (changedKey === 'supplierId') {
              newItem.supplierCode = supplier?.find(
                (each: any) => each.id === newItem.supplierId
              )?.supplierCode;
            }
            return newItem;
          }
          return each;
        })
      );
    }
  );

  useEffect(() => {
    getFilesPartsSupplier(userInfo?.tenantIds?.[0] || '')
      .then(setSupplier)
      .catch((err) => {});
  }, [userInfo?.tenantIds]);

  useEffect(() => {
    if (value && value.length) {
      value.forEach((each) => {
        each.key = each.id || each.key;
      });
      setDataSource(value);
    }
  }, [value]);

  const columns = React.useMemo(
    () => [
      {
        title: '供应商名称',
        dataIndex: 'supplierId',
        key: 'supplierId',
        width: '25%',
        ellipsis: true,
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'select',
          title: '供应商名称',
          dataIndex: 'supplierId',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { record }: any
          ) => {
            return (
              <SelectCell name={name} onChange={onChange}>
                {supplier.map((each) => (
                  <Select.Option
                    disabled={
                      dataSource.some((item) => item.supplierId === each.id) &&
                      record.supplierId !== each.id
                    }
                    key={each.id}
                    value={each.id}
                  >
                    {each.supplierName}
                  </Select.Option>
                ))}
              </SelectCell>
            );
          },
        }),
      },
      {
        title: '供应商编号',
        dataIndex: 'supplierCode',
        key: 'supplierCode',
        width: '25%',
      },
      {
        title: '供应商商品编号',
        dataIndex: 'supplierPartsCode',
        key: 'supplierPartsCode',
        width: '25%',
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '供应商商品编号',
          dataIndex: 'supplierPartsCode',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell name={name} onChange={onChange}>
                {originCell}
              </InputCell>
            );
          },
        }),
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: '25%',
        render: (text: any, record: any) => (
          <>
            <Button
              type='link'
              danger
              onClick={() => handleDelete(record?.key)}
            >
              删除
            </Button>
          </>
        ),
      },
    ],
    [dataSource, handleChange, handleDelete, supplier]
  );

  return (
    <div>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='supplierId'
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <Button
        block
        type='dashed'
        icon={<PlusOutlined />}
        style={{ margin: '16px 0 4px' }}
        onClick={handleAdd}
      >
        添加供应商
      </Button>
    </div>
  );
};

export default PartsSuppliersTable;

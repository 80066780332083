import React from 'react';

import { UnitContactsProps } from '../../../../service/unit';

import { ColumnsType } from 'antd/lib/table';

export const GetUnitContactsColumns = (
  renderOpeartor?: (record: UnitContactsProps) => React.ReactElement,
  excluedIds: string[] = []
): ColumnsType<UnitContactsProps> => {
  const list: ColumnsType<UnitContactsProps> = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: '手机号',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 120,
    },
    {
      title: '电话',
      dataIndex: 'telephone',
      key: 'telephone',
      width: 120,
    },
    {
      title: 'QQ',
      dataIndex: 'qq',
      key: 'qq',
      width: 120,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 160,
    },
    {
      title: '收货地址',
      dataIndex: 'address',
      key: 'address',
      width: 160,
    },
    {
      title: '默认联系人',
      dataIndex: 'isDefault',
      key: 'isDefault',
      width: 100,
      render: (text: any, record: any) => {
        return text ? '是' : '否';
      },
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 120,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

import React, { FC } from 'react';
import { cityList } from '../../../utils/city';
import { useStableCallback } from '../../../hooks/use-stable-callback';

import { Cascader, Empty } from 'antd';

interface Props {
  type?: string;
  disabled?: boolean;
  changeOnSelect?: boolean;
  value?: any;
  onChange?: (value: any) => void;
}

const CityCascader: FC<Props> = ({
  type = 'China',
  disabled = false,
  changeOnSelect = false,
  value,
  onChange,
}) => {
  const options = type === 'China' ? cityList[0].children : cityList;

  const originFilter = useStableCallback((inputValue, path) => {
    return path.some(
      (option: any) =>
        option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  });

  return (
    <Cascader
      fieldNames={{ label: 'name', value: 'name' }}
      options={options}
      showSearch={{ filter: originFilter }}
      placeholder='请选择'
      allowClear
      disabled={disabled}
      changeOnSelect={changeOnSelect}
      value={value}
      onChange={onChange}
      notFoundContent={
        <Empty
          className='ant-empty-small'
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      }
    />
  );
};

export default CityCascader;

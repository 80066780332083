import React, { FC } from 'react';

import { Descriptions } from 'antd';

interface Props {
  title: any;
  info: any;
  isShowMore?: boolean;
}

const SalesInfo: FC<Props> = ({ title, info, isShowMore = false }) => {
  return (
    <Descriptions title={title}>
      <Descriptions.Item label='单位名称'>
        {info?.unitName || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='业务员'>
        {info?.salesMan || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='开单日期'>
        {info?.orderDate || '-'}
      </Descriptions.Item>
      <Descriptions.Item label='收款方式'>
        {info?.payment || '-'}
      </Descriptions.Item>
      {isShowMore && (
        <Descriptions.Item label='备注'>
          {info?.remark || '-'}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default SalesInfo;

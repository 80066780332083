import '../../sales/order/index.scss';
import './edit.scss';

import moment from 'moment';
import React, { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import {
  closeSelectProductModal,
  openSelectProductModal,
} from '../../erp/modal/select-product-modal';
import {
  inboundOrderDetail,
  inboundOrderSaveDraft,
  inboundOrderSubmit,
} from '../../../service/warehouse';

import ComponentInfoTable from './edit/component-info';

import {
  Form,
  Input,
  Button,
  Spin,
  message,
  Row,
  Col,
  DatePicker,
  Card,
  Descriptions,
  InputNumber,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  type: 'add' | 'edit';
}

const AssemblyOrderEdit: FC<Props> = (props) => {
  const history = useHistory();
  const componentInfoRef = React.createRef() as any;
  const { id } = useParams() as any;

  const [form] = Form.useForm();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [productList, setProductList] = useState<any[]>([]);

  const productTitleExtra = useMemo(() => {
    return (
      <>
        <span>商品编号：</span>
        <Link to={`/goods/gfiles/detail/${id}`}>{id}</Link>
      </>
    );
  }, [id]);

  const addProductInfo = useStableCallback(() => {
    openSelectProductModal({
      queryType: 30,
      selectedPartsIdList: productList?.map((item) => {
        return item.partsId;
      }),
      excluedIds: [
        'outDynamicQtyFormat',
        'inDynamicQtyFormat',
        'latestOutActualAmtFormat',
        'salePrice4sFormat',
        'repairPriceFormat',
        'ownerPriceFormat',
      ],
      title: '添加组装商品',
      visible: true,
      onCancel: closeSelectProductModal,
      onConfirm: handleAddProduct,
    });
  });

  const handleAddProduct = useStableCallback(() => {});

  const addComponentInfo = useStableCallback(() => {
    componentInfoRef?.current?.handleAddComponent();
  });

  const handleParmasFilter = useStableCallback(() => {
    const params = { ...form.getFieldsValue(true) };
    if (!params.orderItems || !params.orderItems.length) {
      message.warning('请至少添加一条商品信息');
      return false;
    }
    const hasTableComplete = params.orderItems?.every(
      (each: any) => each.warehouseId && each.warehouseQty
    );
    if (!hasTableComplete) {
      message.warning('请输入商品对应仓库和入库数量');
      return false;
    }
    for (const key in params) {
      if (params[key] === undefined) {
        params[key] = null;
      }
    }
    params.orderDate = params.orderDate.format('YYYY-MM-DD');
    if (props.type === 'edit') {
      params.orderId = id;
    }
    return params;
  });

  const saveDraft = useStableCallback(async () => {
    await form.validateFields();
    const params = handleParmasFilter();
    if (!params) return;

    setSaveLoading(true);
    try {
      await inboundOrderSaveDraft(params);
      message.success('保存成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSaveLoading(false);
    }
  });

  const submit = useStableCallback(async () => {
    await form.validateFields();
    const params = handleParmasFilter();
    if (!params) return;

    setSubmitLoading(true);
    try {
      await inboundOrderSubmit(params);
      message.success('提交成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    if (props.type === 'edit') {
      setSpinLoading(true);
      inboundOrderDetail(id)
        .then((res1) => {
          res1.orderDate = moment(res1.orderDate, 'YYYY-MM-DD');
          form.setFieldsValue(res1);
        })
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    } else {
      form.setFieldsValue({ orderDate: moment() });
    }
  }, [props.type, id, form]);

  return (
    <Spin spinning={spinLoading}>
      <Form
        name='assembly-order-edit'
        className='horizontal-details edit-erp assembly'
        scrollToFirstError={true}
        form={form}
      >
        <div className='page-custom'>
          <div className='details-title'>组装信息</div>
          <Row className='details-form'>
            <Col span={7}>
              <Form.Item
                label='经手人'
                name='inboundContacts'
                rules={[{ required: true, message: '请输入经手人' }]}
              >
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label='组装部门' name='inboundDept'>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label='组装日期'
                name='orderDate'
                rules={[{ required: true, message: '请选择组装日期' }]}
              >
                <DatePicker placeholder={'请选择'} />
              </Form.Item>
            </Col>
            <Col span={23}>
              <Form.Item label='备注' name='inboundContacts'>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <div className='add-product'>
            <Button
              className='add-product-btn'
              type='primary'
              icon={<PlusOutlined />}
              onClick={addProductInfo}
            >
              添加商品
            </Button>

            <div className='add-product-list'>
              <div className='header flex-x a-s-center m-s-between'>
                <p className='header-name'>组装商品1</p>
                <Button type='primary' ghost>
                  删除
                </Button>
              </div>
              <Card className='card' type='inner' title={productTitleExtra}>
                <Descriptions>
                  <Descriptions.Item label='商品名称'>{'-'}</Descriptions.Item>
                  <Descriptions.Item label='品牌'>{'-'}</Descriptions.Item>
                  <Descriptions.Item label='商品规格'>{'-'}</Descriptions.Item>
                  <Descriptions.Item label='计量单位'>{'-'}</Descriptions.Item>
                  <Descriptions.Item label='成本单价(元)'>
                    {'-'}
                  </Descriptions.Item>
                  <Descriptions.Item label='含组装费用成本单价(元)'>
                    {'-'}
                  </Descriptions.Item>
                </Descriptions>

                <Form.Item name='orderItems'>
                  <ComponentInfoTable onRef={componentInfoRef} />
                  <Button
                    className='card-add'
                    type='dashed'
                    block
                    icon={<PlusOutlined />}
                    onClick={addComponentInfo}
                  >
                    添加组件
                  </Button>
                </Form.Item>

                <div className='card-input flex-x a-s-center m-s-end'>
                  <div className='flex-x a-s-center m-s-start m-l-32'>
                    <span>组装费用：</span>
                    <InputNumber
                      className='input'
                      min={0}
                      precision={2}
                      controls={false}
                      defaultValue={0}
                      placeholder='请输入'
                    />
                  </div>
                  <div className='flex-x a-s-center m-s-start m-l-32'>
                    <span>组装数量：</span>
                    <InputNumber
                      className='input'
                      min={1}
                      max={100}
                      controls={false}
                      defaultValue={1}
                      placeholder='请输入'
                    />
                    <span className='tip'>最大组装数量：100</span>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>

        <Row className='details-btn'>
          <Col span={12}>
            <Button onClick={() => history.goBack()}>取消</Button>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <span className='fee-total'>组装费用总计：0.00</span>
            <Button loading={saveLoading} onClick={saveDraft}>
              保存草稿
            </Button>
            <Button type='primary' loading={submitLoading} onClick={submit}>
              提交
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default AssemblyOrderEdit;

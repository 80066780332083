import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import OrderStatusLogo from '../../../erp/order-status';
import UseProductInfoTable from './product-info-table';

import { Card, Descriptions } from 'antd';

interface Props {
  basicInfo: any;
}

const DetailBasicInfo: FC<Props> = ({ basicInfo }) => {
  const productInfoTable = UseProductInfoTable({ basicInfo });

  const titleExtra = useMemo(() => {
    return (
      <>
        <span>组装单号：{basicInfo?.purchaseOrder?.orderId}</span>
        <Link
          className='detail-tabpanel-link'
          to={`/warehouse/assembly/detail/${basicInfo?.purchaseOrder?.orderId}`}
        >
          查看详情
        </Link>
      </>
    );
  }, [basicInfo]);

  const productTitleExtra = useMemo(() => {
    return (
      <>
        <span>商品编号：</span>
        <Link to={`/goods/gfiles/detail/${basicInfo?.purchaseOrder?.orderId}`}>
          {basicInfo?.purchaseOrder?.orderId}
        </Link>
      </>
    );
  }, [basicInfo]);

  return (
    <div className='detail-tabpanel detail-erp'>
      <div className='detail-erp-logo'>
        <OrderStatusLogo type={2} status={basicInfo?.orderStatus} />
      </div>

      <Descriptions title={titleExtra}>
        <Descriptions.Item label='经手人'>
          {basicInfo?.supplierName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='组装部门'>
          {basicInfo?.purchaseMan || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='组装日期'>
          {basicInfo?.orderDate || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='组装总费用(元)'>
          {basicInfo?.payment || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='备注'>
          {basicInfo?.payment || '-'}
        </Descriptions.Item>
      </Descriptions>

      <div className='detail-tabpanel-space'></div>

      <Descriptions column={1} title='商品信息'>
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          <Card
            className='detail-erp-card'
            type='inner'
            title={productTitleExtra}
          >
            <Descriptions>
              <Descriptions.Item label='商品名称'>
                {basicInfo?.supplierName || '-'}
              </Descriptions.Item>
              <Descriptions.Item label='品牌'>
                {basicInfo?.purchaseMan || '-'}
              </Descriptions.Item>
              <Descriptions.Item label='商品规格'>
                {basicInfo?.orderDate || '-'}
              </Descriptions.Item>
              <Descriptions.Item label='计量单位'>
                {basicInfo?.payment || '-'}
              </Descriptions.Item>
              <Descriptions.Item label='成本单价(元)'>
                {basicInfo?.payment || '-'}
              </Descriptions.Item>
              <Descriptions.Item label='含组装费用成本单价(元)'>
                {basicInfo?.payment || '-'}
              </Descriptions.Item>
              <Descriptions.Item label='组装费用(元)'>
                {basicInfo?.payment || '-'}
              </Descriptions.Item>
              <Descriptions.Item label='组装数量'>
                {basicInfo?.payment || '-'}
              </Descriptions.Item>
            </Descriptions>

            {productInfoTable}
          </Card>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DetailBasicInfo;

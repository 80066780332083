import '../../goods/files/index.scss';
import './index.scss';

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { closeAuditModal, openAuditModal } from '../../erp/modal/audit-modal';
import { closeSalesOrderPrintModal, openSalesOrderPrintModal } from './print';

import BreadcrumbCustom from '../../breadcrumb-custom';
import DetailBasicInfo from './detail/basic';
import DetailReceiptInfo from './detail/receipt';
import DetailInvoiceInfo from './detail/invoice';

import {
  auditSalesOrder,
  canDeliver,
  rejectSalesOrder,
  salesOrderBasicInfo,
  salesOrderBillInfo,
  salesOrderReceiveInfo,
} from '../../../service/sales';

import { Button, message, Spin, Tabs } from 'antd';
const { TabPane } = Tabs;

const SalesOrderDetail: FC = () => {
  const params: any = useParams();
  const history = useHistory();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [menuItem, setMenuItem] = useState<string>('basic');
  const [basicInfo, setBasicInfo] = useState<any>(null);
  const [receiptInfo, setReceiptInfo] = useState<any>(null);
  const [invoiceInfo, setInvoiceInfo] = useState<any>(null);

  const changeTabs = useStableCallback((menu) => {
    setSpinLoading(true);
    setMenuItem(menu);
    if (menu === 'invoice') {
      salesOrderBillInfo(params.id)
        .then(setInvoiceInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    } else if (menu === 'receipt') {
      salesOrderReceiveInfo(params.id)
        .then(setReceiptInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    } else {
      salesOrderBasicInfo(params.id)
        .then(setBasicInfo)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  });

  const handlePrint = useStableCallback(() => {
    openSalesOrderPrintModal({
      visible: true,
      orderId: params.id,
      onCancel: closeSalesOrderPrintModal,
    });
  });

  const handleAudit = useStableCallback(() => {
    openAuditModal({
      source: '销售单',
      orderId: params.id,
      visible: true,
      onReject: onReject,
      onAudit: onAudit,
      onCancel: closeAuditModal,
    });
  });

  const onReject = useStableCallback(async () => {
    try {
      await rejectSalesOrder(params.id);
      message.success('驳回成功');
      closeAuditModal();
      changeTabs(menuItem);
    } catch (err) {}
  });

  const onAudit = useStableCallback(async () => {
    try {
      await auditSalesOrder(params.id);
      message.success('审核通过');
      closeAuditModal();
      changeTabs(menuItem);
    } catch (err) {}
  });

  const shippingRow = useStableCallback(async () => {
    const res = await canDeliver(params.id);
    if (!res) {
      return message.error('暂无可发货商品，请至出库单查看');
    }
    history.push(`/warehouse/outbound/add/${params.id}`);
  });

  useEffect(() => {
    setSpinLoading(true);
    salesOrderBasicInfo(params.id)
      .then(setBasicInfo)
      .catch((err) => {})
      .finally(() => {
        setSpinLoading(false);
      });
  }, [params.id]);

  const extraButton = useMemo(() => {
    return (
      <div>
        {[20, 21, 22, 30].includes(basicInfo?.orderStatus) && (
          <Button onClick={handlePrint}>打印</Button>
        )}
      </div>
    );
  }, [basicInfo?.orderStatus, handlePrint]);

  return (
    <Spin spinning={spinLoading}>
      <BreadcrumbCustom
        paths={['销售管理', '销售单', '销售单详情']}
        title={`销售单详情：${params.id}`}
        extra={extraButton}
      />

      <Tabs
        defaultActiveKey='basic'
        className='goods-files-detail'
        onChange={changeTabs}
      >
        <TabPane tab='基本信息' key='basic'>
          <DetailBasicInfo basicInfo={basicInfo} />
        </TabPane>
        <TabPane tab='收货信息' key='receipt'>
          <DetailReceiptInfo receiptInfo={receiptInfo} />
        </TabPane>
        <TabPane tab='开票信息' key='invoice'>
          <DetailInvoiceInfo invoiceInfo={invoiceInfo} />
        </TabPane>
      </Tabs>

      <div className='common-buttons flex-x m-s-end minauto'>
        <Button onClick={history.goBack}>返回</Button>
        {basicInfo?.orderStatus === 10 && (
          <Button type='primary' onClick={handleAudit}>
            审核
          </Button>
        )}
        {[20, 21].includes(basicInfo?.orderStatus) && (
          <Button type='primary' onClick={shippingRow}>
            发货
          </Button>
        )}
      </div>
    </Spin>
  );
};

export default SalesOrderDetail;

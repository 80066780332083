import UseDetailTable from '../../../common/table/detail-table';

interface Params {
  list: { [key: string]: any }[];
  rowKey?: string;
}

const UseReplaceProductPriceTable = ({ list, rowKey }: Params) => {
  const columns = [
    {
      title: '商品编号',
      dataIndex: 'partsCode',
      key: 'partsCode',
      width: 100,
    },
    {
      title: '4s销售价(元)',
      dataIndex: 'salePrice4s',
      key: 'salePrice4s',
      align: 'right',
      width: 100,
    },
    {
      title: '4s进店价(元)',
      dataIndex: 'purchasePrice4s',
      key: 'purchasePrice4s',
      align: 'right',
      width: 100,
    },
    {
      title: '销售商价(元)',
      dataIndex: 'dealerPrice',
      key: 'dealerPrice',
      align: 'right',
      width: 100,
    },
    {
      title: '修理厂价(元)',
      dataIndex: 'repairPrice',
      key: 'repairPrice',
      align: 'right',
      width: 100,
    },
    {
      title: '车主价(元)',
      dataIndex: 'ownerPrice',
      key: 'ownerPrice',
      align: 'right',
      width: 100,
    },
  ];

  const table = UseDetailTable({ columns, list, rowKey });

  return {
    table,
  };
};

export default UseReplaceProductPriceTable;

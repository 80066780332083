import React from 'react';
import { Link } from 'react-router-dom';
import { erpOrderStatusMap1 } from '../../../../utils/definition';
import { OutboundOrderListProps } from '../../../../service/warehouse';

import { ColumnsType } from 'antd/lib/table';

export const GetOutboundOrderListColumns = (
  renderOpeartor?: (record: OutboundOrderListProps) => React.ReactElement,
  excluedIds: string[] = []
): ColumnsType<OutboundOrderListProps> => {
  const list: ColumnsType<OutboundOrderListProps> = [
    {
      title: '出库单号',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (text: string, record: any) => (
        <>
          {record.orderStatus !== 0 ? (
            <Link to={`/warehouse/outbound/detail/${record.orderId}`}>
              {text}
            </Link>
          ) : (
            <div>{text}</div>
          )}
        </>
      ),
      fixed: 'left' as any,
      width: 140,
    },
    {
      title: '销售单号',
      dataIndex: 'porderId',
      key: 'porderId',
      render: (text: string, record: any) => (
        <>
          {text ? (
            <Link to={`/sales/sorder/detail/${record.porderId}`}>{text}</Link>
          ) : (
            <div>-</div>
          )}
        </>
      ),
      fixed: 'left' as any,
      width: 140,
    },
    {
      title: '单位名称',
      dataIndex: 'unitName',
      key: 'unitName',
      width: 120,
    },
    {
      title: '出库人',
      dataIndex: 'outboundContacts',
      key: 'outboundContacts',
      width: 100,
    },
    {
      title: '业务员',
      dataIndex: 'salesMan',
      key: 'salesMan',
      width: 100,
    },
    {
      title: '出库数量',
      dataIndex: 'outboundQtyFormat',
      key: 'outboundQtyFormat',
      width: 100,
    },
    {
      title: '实际箱数',
      dataIndex: 'realBox',
      key: 'realBox',
      width: 100,
    },
    {
      title: '发票单位名称',
      dataIndex: 'taxCompany',
      key: 'taxCompany',
      width: 140,
    },
    {
      title: '订单状态',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      width: 120,
      render: (status: number) => (
        <div className='flex-x m-s-start table-status'>
          <div
            className={`iconfont ${erpOrderStatusMap1[status]?.[2]}`}
            style={{ color: erpOrderStatusMap1[status]?.[1] }}
          ></div>
          <div>{erpOrderStatusMap1[status]?.[0]}</div>
        </div>
      ),
    },
    {
      title: '物流单位',
      dataIndex: 'logisticsUnitName',
      key: 'logisticsUnitName',
      width: 100,
    },
    {
      title: '物流单号',
      dataIndex: 'logisticsNo',
      key: 'logisticsNo',
      width: 120,
    },
    {
      title: '出库日期',
      dataIndex: 'orderDate',
      key: 'orderDate',
      width: 120,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 140,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

import React, { FC, useState } from 'react';
import ReactPlayer from 'react-player';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { fileValidate } from '../../../../utils/validate';
import { preFetch } from '../../../../service/fetch';

import { Upload, message, Image } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

interface Props {
  uploadData: { [key: string]: any };
  imageUrls: string[];
  getImageUrl: (value: string) => void;
}

const UploadPhoto: FC<Props> = ({ uploadData, imageUrls, getImageUrl }) => {
  const [fileLists, setFileLists] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [isFetch, setIsFetch] = useState<boolean>(false);

  const handlePreview = useStableCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setVisible(true);
    }
  );

  const handleEvent = useStableCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
    }
  );

  const beforeUpload = (file: any, fileList: any) => {
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.warning('图片/视频必须小于20M');
      setFileLists([]);
      return false;
    }
    if (isFetch) return false;
    setIsFetch(false);
    setFileLists(fileList);
    return false;
  };

  const onChange = useStableCallback(async () => {
    if (isFetch || !fileLists.length) return;
    setIsFetch(true);
    setLoading(true);
    const formData: any = new FormData();
    Object.entries(uploadData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    fileLists.forEach((each: any) => {
      formData.append('files', each);
    });
    try {
      if (!uploadData?.type) return message.warning('请先选择图片/视频类型');
      const { data } = await preFetch('/file/upload/images', {
        method: 'post',
        body: formData,
      });
      getImageUrl(data);
      message.success('上传成功');
    } catch (err) {
    } finally {
      setIsFetch(false);
      setLoading(false);
    }
  });

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片/视频</div>
    </div>
  );

  return (
    <Upload
      name='files'
      listType='picture-card'
      maxCount={9}
      multiple
      showUploadList={false}
      disabled={loading}
      fileList={fileLists}
      beforeUpload={beforeUpload}
      onChange={onChange}
    >
      {imageUrls.length ? (
        fileValidate(imageUrls[0]) === 'image' ? (
          <>
            <Image
              style={{ width: '100%' }}
              src={imageUrls[0]}
              preview={{ visible: false }}
              onClick={handlePreview}
            />
            <div style={{ display: 'none' }} onClick={handleEvent}>
              <Image.PreviewGroup
                preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
              >
                {imageUrls.map((each, index) => (
                  <Image src={each} key={index} />
                ))}
              </Image.PreviewGroup>
            </div>
          </>
        ) : (
          <ReactPlayer url={imageUrls} playing loop controls />
        )
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default UploadPhoto;

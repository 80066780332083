import React, { FC, useEffect, useState } from 'react';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import DropDownSelect from '../../../common/select/drop-down-select';
import { getModelDropDownData } from '../../../../service/car';

import { Form, Input, Button, Row, Col } from 'antd';

interface Props {
  resetId: number;
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const SelectModelsQuery: FC<Props> = ({ resetId, onReset, onFinish }) => {
  const [form] = Form.useForm();

  const [catalogSelectList, setCatalogSelectList] = useState<string[]>([]);

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  useEffect(() => {
    onInnerReset();
  }, [onInnerReset, resetId]);

  useEffect(() => {
    getModelDropDownData('catalog')
      .then((res) => setCatalogSelectList(res.catalog))
      .catch((err) => {});
  }, []);

  return (
    <Form
      className='common-query'
      style={{
        margin: '-8px -24px 0 -30px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
      }}
      form={form}
      name='files-models-query'
      layout={'inline'}
      onFinish={onFinish}
    >
      <Row>
        <Col span={8}>
          <Form.Item label='车型名称' name='name'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='车型分类' name='catalog'>
            <DropDownSelect options={catalogSelectList} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='车型代码' name='code'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='车系' name='SName'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='年款' name='year'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='底盘号' name='chassisCode'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button htmlType='button' onClick={onInnerReset}>
              重置
            </Button>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SelectModelsQuery;

import UseDetailTable from '../../../common/table/detail-table';
import { WarehouseLocationsProps } from '../../../../service/warehouse';
import { ColumnsType } from 'antd/lib/table';

interface Params {
  list: { [key: string]: any }[];
}

const UseLocationInformationTable = ({ list }: Params) => {
  const columns: ColumnsType<WarehouseLocationsProps> = [
    {
      title: '库位编号',
      dataIndex: 'code',
      key: 'code',
      width: '25%',
    },
    {
      title: '库位名称',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: '长宽高(mm)',
      dataIndex: 'spec',
      key: 'spec',
      width: '25%',
    },
    {
      title: '描述',
      dataIndex: 'desc',
      key: 'desc',
      width: '25%',
      ellipsis: true,
    },
  ];

  const table = UseDetailTable({ columns, list });

  return {
    table,
  };
};

export default UseLocationInformationTable;

export const phoneValidate: { [key: string]: any } = {
  pattern: /^1[0-9]{10}$/,
  message: '手机号格式不正确',
};

export const englishValidate: { [key: string]: any } = {
  pattern: /^[a-zA-Z\s]*$/,
  message: '请输入英文字母',
};

export const numberValidate: { [key: string]: any } = {
  pattern: /^(\d+\.?)?\d{0,2}$/,
  message: '请输入数字，且最多两位小数',
};

export const fileValidate = (file: string): string | boolean => {
  if (!file) return false;
  const imgType = ['gif', 'jpeg', 'jpg', 'bmp', 'png'];
  const videoType = [
    'avi',
    'wmv',
    'mkv',
    'mp4',
    'mov',
    'rm',
    '3gp',
    'flv',
    'mpg',
    'rmvb',
  ];
  if (RegExp('.(' + imgType.join('|') + ')$', 'i').test(file.toLowerCase())) {
    return 'image';
  } else if (
    RegExp('.(' + videoType.join('|') + ')$', 'i').test(file.toLowerCase())
  ) {
    return 'video';
  } else {
    return false;
  }
};

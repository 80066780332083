import { preFetch } from './fetch';

// 车型档案
export interface ModelListProps {
  id: number;
  bodyForm: string;
  bodyStructure: string;
  brandId: number;
  brandName: string;
  catalog: string;
  code: string;
  drivingModel: string;
  eds: string;
  engineCode: string;
  engineType: string;
  extra: string;
  fourWd: string;
  gearBoxCode: string;
  gearBoxType: string;
  level: string;
  manuGuidePrice: string;
  manufacturer: string;
  motorCode: string;
  motorType: string;
  name: string;
  powerType: string;
  remarks: string;
  saleMode: string;
  seriesName: string;
  sid: number;
  spicture: string;
  warrantyPolicy: string;
  createdTime: string;
  updatedTime: string;
}

export interface ModelInfoProps {
  name: string;
  catalog: string;
  brandId: number;
  sid: number;
  manufacturer: string;
  saleMode: string;
  code: string;
  manuGuidePrice: string;
  bodyStructure?: string;
  drivingModel?: string;
  eds?: string;
  engineCode?: string;
  engineType?: string;
  fourWd?: string;
  gearBoxCode?: string;
  gearBoxType?: string;
  level?: string;
  motorCode?: string;
  powerType?: string;
  warrantyPolicy?: string;
}

export const modelPage = async (query: {
  [key: string]: any;
}): Promise<{ records: ModelListProps[]; total: number }> => {
  const success = await preFetch('/car-model/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const partsModelPage = async (query: {
  [key: string]: any;
}): Promise<{ records: ModelListProps[]; total: number }> => {
  const success = await preFetch('/car-model/page/parts', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const unitModelPage = async (
  brandId: number | string = ''
): Promise<any[]> => {
  const success = await preFetch('/car-model/list/unit', {
    method: 'GET',
    query: { brandId },
  });
  return success.data;
};

export const modelDetails = async (id: number): Promise<ModelListProps> => {
  const success = await preFetch(`/car-model/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addModel = async (body: ModelInfoProps) => {
  const success = await preFetch('/car-model', {
    method: 'POST',
    body,
  });
  return success;
};

export const editModel = async (id: number, body: ModelInfoProps) => {
  const success = await preFetch(`/car-model/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const deleteModel = async (ids: number[]) => {
  const success = await preFetch('/car-model/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

export const getModelDropDownData = async (
  code: string = ''
): Promise<{ [key: string]: string[] }> => {
  const success = await preFetch('/car-model/drop-down-box-data', {
    method: 'GET',
    query: { code },
  });
  return success.data;
};

// 品牌管理
export interface BrandListProps {
  id: number;
  firstCode: string;
  fname: string;
  logo: string;
  name: string;
  createdTime: string;
  updatedTime: string;
}

export interface BrandInfoProps {
  firstCode: string;
  name: string;
  logo: string;
  fname?: string;
}

export const brandPage = async (query: {
  [key: string]: any;
}): Promise<{ records: BrandListProps[]; total: number }> => {
  const success = await preFetch('/car-brand/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const brandList = async (): Promise<BrandListProps[]> => {
  const success = await preFetch('/car-brand/list', {
    method: 'GET',
  });
  return success.data;
};

export const brandDetails = async (id: number): Promise<BrandListProps> => {
  const success = await preFetch(`/car-brand/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addBrand = async (body: BrandInfoProps) => {
  const success = await preFetch('/car-brand', {
    method: 'POST',
    body,
  });
  return success;
};

export const editBrand = async (id: number, body: BrandInfoProps) => {
  const success = await preFetch(`/car-brand/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const deleteBrand = async (ids: number[]) => {
  const success = await preFetch('/car-brand/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

// 车系管理
export interface SeriesListProps {
  id: number;
  brandId: number;
  brandName: string;
  extra: string;
  firstCode: string;
  fname: string;
  name: string;
  sname: string;
  spicture: string;
  createdTime: string;
  updatedTime: string;
}

export interface SeriesInfoProps {
  brandId: number;
  name: string;
  sname: string;
  fname: string;
  spicture: string;
  firstCode?: string;
}

export const seriesPage = async (query: {
  [key: string]: any;
}): Promise<{ records: SeriesListProps[]; total: number }> => {
  const success = await preFetch('/car-series/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const seriesByBrand = async (
  brandId: number
): Promise<SeriesListProps[]> => {
  const success = await preFetch(`/car-series/${brandId}/list`, {
    method: 'GET',
  });
  return success.data;
};

export const seriesDetails = async (id: number): Promise<SeriesListProps> => {
  const success = await preFetch(`/car-series/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addSeries = async (body: SeriesInfoProps) => {
  const success = await preFetch('/car-series', {
    method: 'POST',
    body,
  });
  return success;
};

export const editSeries = async (id: number, body: SeriesInfoProps) => {
  const success = await preFetch(`/car-series/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const deleteSeries = async (ids: number[]) => {
  const success = await preFetch('/car-series/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

// VIN码查询
export interface VINInfoProps {
  carModelId: number;
  saleMode: string;
}

export const getVINCarSales = async (
  vinCode: string
): Promise<{ saleModels: VINInfoProps[]; total: number }> => {
  const success = await preFetch(`/vin-info/sale-modes/${vinCode}`, {
    method: 'GET',
  });
  return success.data;
};

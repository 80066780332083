import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../hooks/use-stable-callback';
import { mangLocal } from '../utils/common';
import {
  openEditPasswordModal,
  closeEditPasswordModal,
} from '../components/system/user/modal/edit-password';

import { Layout, Avatar, Menu, Dropdown, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
const { Header } = Layout;

const HeaderCustom: FC = () => {
  const history = useHistory();

  const userInfo = mangLocal('userInfo');

  const editPassword = useStableCallback(() => {
    openEditPasswordModal({
      userInfo,
      visible: true,
      logout,
      onCancel: closeEditPasswordModal,
    });
  });

  const logout = useStableCallback(() => {
    localStorage.clear();
    history.push('/login');
  });

  const menu = (
    <Menu>
      <Menu.Item key='editPassword'>
        <span onClick={editPassword}>修改密码</span>
      </Menu.Item>
      <Menu.Item key='logout'>
        <span onClick={logout}>退出登录</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className='header-custom flex-x m-l-r m-s-end'>
      <Avatar
        size={24}
        style={{ backgroundColor: '#A6DBFF' }}
        icon={<UserOutlined />}
      />
      <Dropdown overlay={menu}>
        <Button className='header-btn' type='text'>
          {userInfo?.username}
        </Button>
      </Dropdown>
    </Header>
  );
};

export default HeaderCustom;

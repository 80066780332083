import React, { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import TableBatchDelete from '../../common/table/table-batch-delete';
import UseTable from '../../common/table/use-table';
import { GetSalesOrderListColumns } from './tables/files-list-columns';
import { closeSalesOrderPrintModal, openSalesOrderPrintModal } from './print';
import {
  canDeliver,
  deleteSalesOrder,
  SalesOrderListProps,
  salesOrderPage,
} from '../../../service/sales';

import { Button, Divider, Modal, message } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

interface Props {
  searchForm?: { [key: string]: any };
}

const SalesOrderTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      if (searchForm && searchForm.billDate) {
        searchForm.startDate = searchForm.billDate[0]?.format('YYYY-MM-DD');
        searchForm.endDate = searchForm.billDate[1]?.format('YYYY-MM-DD');
      }
      let params = { ...searchForm, ...listPageData };
      const { records, total } = await salesOrderPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const onSelectCheckbox = useStableCallback((record: any) => ({
    disabled: record.orderStatus !== 0,
  }));

  const addRow = useStableCallback(() => {
    history.push('/sales/sorder/add');
  });

  const editRow = useStableCallback((record: any) => {
    history.push(`/sales/sorder/edit/${record.orderId}`);
  });

  const auditRow = useStableCallback((record: any) => {
    history.push(`/sales/sorder/detail/${record.orderId}`);
  });

  const shippingRow = useStableCallback(async (record: any) => {
    const res = await canDeliver(record.orderId);
    if (!res) {
      return message.error('暂无可发货商品，请至出库单查看');
    }
    history.push(`/warehouse/outbound/add/${record.orderId}`);
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除销售单【${record.orderId}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch([record.orderId]);
      },
    });
  });

  const deleteBatchRow = useStableCallback((ids: number[]) => {
    if (!ids || !ids.length) return message.warning('请先选择需删除的销售单');
    Modal.confirm({
      title: `确定删除选中的销售单吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch(ids);
      },
    });
  });

  const deleteBatch = useStableCallback(async (ids: number[]) => {
    try {
      await deleteSalesOrder(ids);
      message.success('删除成功');
      setSelectedRowKeys([]);
      getList(1);
    } catch (err) {}
  });

  const printRow = useStableCallback((record: any) => {
    openSalesOrderPrintModal({
      visible: true,
      orderId: record.orderId,
      onCancel: closeSalesOrderPrintModal,
    });
  });

  const renderOpeartor = useStableCallback((record: any) => {
    return (
      <>
        {record.orderStatus === 0 && (
          <>
            <Button type='link' onClick={() => editRow(record)}>
              编辑
            </Button>
            <Divider type='vertical' />
            <Button type='link' danger onClick={() => deleteRow(record)}>
              删除
            </Button>
          </>
        )}
        {record.orderStatus === 10 && (
          <Button type='link' onClick={() => auditRow(record)}>
            审核
          </Button>
        )}
        {[20, 21].includes(record.orderStatus) && (
          <>
            <Button type='link' onClick={() => shippingRow(record)}>
              发货
            </Button>
            <Divider type='vertical' />
            <Button type='link' onClick={() => printRow(record)}>
              打印
            </Button>
          </>
        )}
        {[22, 30].includes(record.orderStatus) && (
          <Button type='link' onClick={() => printRow(record)}>
            打印
          </Button>
        )}
      </>
    );
  });

  const columns: ColumnsType<SalesOrderListProps> = useMemo(
    () => GetSalesOrderListColumns(renderOpeartor),
    [renderOpeartor]
  );

  const { selectedRowKeys, setSelectedRowKeys, tableSelect } = TableBatchDelete(
    { deleteBatchRow }
  );

  const { table, setLoading, setList, setListTotal, pageData, setPageData } =
    UseTable({
      columns,
      getList,
      rowKey: 'orderId',
      selectedRowKeys,
      onSelectChange,
      onSelectCheckbox,
    });

  useEffect(() => {
    getList(1);
  }, [getList, searchForm]);

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>销售单列表</div>
        <div className='header-operate flex-x'>
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {selectedRowKeys.length > 0 && tableSelect}

      {table}
    </div>
  );
};

export default SalesOrderTable;

import React, { FC, useEffect, useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import BatchImportFile from '../../common/upload/batch-import-file';
import TableBatchDelete from '../../common/table/table-batch-delete';
import UseTable from '../../common/table/use-table';
import { downloadTemplate } from '../../../utils/definition';
import { openAddNameModal, closeAddNameModal } from './add-name-modal';

import { standardDataPage, deleteStandardData } from '../../../service/goods';

import { Button, Divider, Input, message, Modal } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
const { Search } = Input;

interface Props {
  activeKey: string;
}

const StandardName: FC<Props> = ({ activeKey }) => {
  const [queryName, setQueryName] = useState<string>('');

  const [downloadUrl] = useState<string>(
    `${process.env.REACT_APP_DOWNLOAD_URL}${downloadTemplate['商品标准名称']}`
  );

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      let params = { ...listPageData, queryName };
      const { records, total } = await standardDataPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const onNameChange = useStableCallback((e) => {
    setQueryName(e.target.value);
  });

  const addRow = useStableCallback(() => {
    openAddNameModal({
      type: 'add',
      visible: true,
      onCancel: closeAddNameModal,
      onConfirm: () => getList(1),
    });
  });

  const editRow = useStableCallback((record: any) => {
    openAddNameModal({
      type: 'edit',
      visible: true,
      values: record,
      onCancel: closeAddNameModal,
      onConfirm: () => getList(1),
    });
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除标准名称【${record.name}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch([record.id]);
      },
    });
  });

  const deleteBatchRow = useStableCallback((ids: number[]) => {
    if (!ids || !ids.length) return message.warning('请先选择需删除的标准名称');
    Modal.confirm({
      title: `确定删除选中的标准名称吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch(ids);
      },
    });
  });

  const deleteBatch = useStableCallback(async (ids: number[]) => {
    try {
      await deleteStandardData(ids);
      message.success('删除成功');
      setSelectedRowKeys([]);
      getList(1);
    } catch (err) {}
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const columns = [
    {
      title: '标准名称',
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: '别称',
      dataIndex: 'snames',
      key: 'snames',
      width: 140,
      ellipsis: true,
      render: (text: any, record: any) => {
        return text?.map((each: string) => each).join(', ');
      },
    },
    {
      title: '英文名称',
      dataIndex: 'fname',
      key: 'fname',
      width: 80,
    },
    {
      title: '分类',
      dataIndex: 'categoryName',
      key: 'categoryName',
      width: 160,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 100,
      render: (text: any, record: any) => (
        <>
          <Button type='link' onClick={() => editRow(record)}>
            编辑
          </Button>
          <Divider type='vertical' />
          <Button type='link' danger onClick={() => deleteRow(record)}>
            删除
          </Button>
        </>
      ),
    },
  ];

  const { selectedRowKeys, setSelectedRowKeys, tableSelect } = TableBatchDelete(
    { deleteBatchRow }
  );

  const { table, setLoading, setList, setListTotal, pageData, setPageData } =
    UseTable({
      columns,
      getList,
      selectedRowKeys,
      onSelectChange,
    });

  useEffect(() => {
    if (activeKey === 'name') {
      getList(1);
    }
  }, [getList, activeKey]);

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>标准名称列表</div>
        <div className='header-operate'>
          <Search
            placeholder='搜索分类/标准名称'
            value={queryName}
            onChange={onNameChange}
            onPressEnter={() => getList(1)}
            onSearch={() => getList(1)}
            style={{ width: 260, marginRight: '8px' }}
          />
          <Button href={downloadUrl} download>
            下载模板
          </Button>
          <BatchImportFile
            importUrl={'/erp-parts-standard-data/import'}
            getList={getList}
          />
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {selectedRowKeys.length > 0 && tableSelect}

      {table}
    </div>
  );
};

export default StandardName;

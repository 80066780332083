import React, { FC, useEffect, useMemo, useState } from 'react';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import {
  closeAddUnitShopsModal,
  openAddUnitShopsModal,
} from '../edit/add-shops-modal';
import { GetUnitShopsColumns } from './unit-shops-columns';

import { UnitShopsProps } from '../../../../service/unit';

import { Button, Divider, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

interface Props {
  value?: any[];
  onChange?: (newValue: any[]) => void;
}

const UnitShopsTable: FC<Props> = ({ value = [], onChange }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);

  const handleValueChange = useStableCallback((newValue: any[]) => {
    setDataSource(newValue);
    onChange?.(newValue);
  });

  const deleteRow = useStableCallback((id: number) => {
    handleValueChange(dataSource.filter((item) => item.id !== id));
  });

  const handleEditShops = useStableCallback((list) => {
    let arr = [...dataSource];
    arr.forEach((item, index) => {
      if (item.id === list.id) {
        arr[index] = list;
      }
    });
    handleValueChange(arr);
  });

  const editRow = useStableCallback((record: any) => {
    openAddUnitShopsModal({
      visible: true,
      type: 'edit',
      value: record,
      onCancel: closeAddUnitShopsModal,
      onConfirm: handleEditShops,
    });
  });

  const handleAddShops = useStableCallback((list) => {
    list.id = `add${Math.random()}`;
    let arr = [...dataSource];
    arr.push(list);
    handleValueChange(arr);
  });

  const addRow = useStableCallback(() => {
    openAddUnitShopsModal({
      visible: true,
      type: 'add',
      onCancel: closeAddUnitShopsModal,
      onConfirm: handleAddShops,
    });
  });

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value);
    }
  }, [value]);

  const renderOpeartor = useStableCallback((record: any) => {
    return (
      <>
        <Button type='link' onClick={() => editRow(record)}>
          编辑
        </Button>
        <Divider type='vertical' />
        <Button type='link' danger onClick={() => deleteRow(record?.id)}>
          删除
        </Button>
      </>
    );
  });

  const columns: ColumnsType<UnitShopsProps> = useMemo(
    () => GetUnitShopsColumns(renderOpeartor),
    [renderOpeartor]
  );

  return (
    <div>
      <Table
        className='list-table'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='id'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <Button
        block
        type='dashed'
        icon={<PlusOutlined />}
        style={{ margin: '16px 0 24px' }}
        onClick={addRow}
      >
        添加门店
      </Button>
    </div>
  );
};

export default UnitShopsTable;

import './index.scss';

import React, { FC, useEffect, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { createOpenModal, createCloseModal } from '../../../utils/modal';
import { categoryLevel } from '../../../utils/definition';
import { englishValidate } from '../../../utils/validate';
import DropDownSelect from '../../common/select/drop-down-select';

import {
  categoryList,
  addStandardData,
  editStandardData,
  CategoryTreeProps,
  StandardDataProps,
} from '../../../service/goods';

import { ConfigProvider, Modal, Form, Input, Select, message } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: { span: 16, offset: 4 },
};

interface Props {
  type: string;
  visible: boolean;
  values?: StandardDataProps;
  onCancel: () => void;
  onConfirm: () => void;
}

const AddNameModal: FC<Props> = ({
  type,
  visible,
  values,
  onCancel,
  onConfirm,
}) => {
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [categorySelect, setCategorySelect] = useState<CategoryTreeProps[]>([]);

  const changeCategoryLevel = useStableCallback(async (level: number) => {
    await form.setFieldsValue({ categoryId: null });
    getCategoryList(level);
  });

  const getCategoryList = useStableCallback(async (level: number) => {
    try {
      const res = await categoryList(level);
      setCategorySelect(res);
    } catch (err) {}
  });

  const onInnerConfirm = useStableCallback(async () => {
    await form.validateFields();
    const params = form.getFieldsValue(true);
    setSubmitLoading(true);
    try {
      if (type === 'add') {
        await addStandardData(params);
        message.success('新增成功');
      } else {
        await editStandardData(params.id, params);
        message.success('编辑成功');
      }
      onInnerCancel();
      onConfirm();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onInnerCancel = useStableCallback(() => {
    form.resetFields();
    setCategorySelect([]);
    onCancel();
  });

  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(values);
      getCategoryList(values?.categoryLevel as any);
    }
    if (!form.getFieldValue('snames')) {
      form.setFieldsValue({ snames: [null] });
    }
  }, [form, getCategoryList, type, values]);

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title='新增标准名称'
        centered
        maskClosable={false}
        visible={visible}
        confirmLoading={submitLoading}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <Form
          form={form}
          name='add-name'
          className='standard-name'
          {...formItemLayout}
        >
          <Form.Item name='name' label='标准名称' rules={[{ required: true }]}>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item
            name='categoryLevel'
            label='分类级别'
            rules={[{ required: true }]}
          >
            <Select
              placeholder='请选择'
              allowClear
              disabled={type === 'edit'}
              onChange={changeCategoryLevel}
            >
              {Object.entries(categoryLevel).map(([key, value]) => (
                <Option value={Number(key)} key={key}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='categoryId'
            label='分类'
            rules={[{ required: true }]}
          >
            <DropDownSelect
              options={categorySelect}
              hasId={true}
              disabled={type === 'edit'}
            />
          </Form.Item>
          <Form.Item name='fname' label='英文名称' rules={[englishValidate]}>
            <Input placeholder='请输入' maxLength={30} allowClear />
          </Form.Item>
          <Form.List name='snames'>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? '别称' : ''}
                    key={field.key}
                  >
                    <Form.Item {...field} noStyle>
                      <Input placeholder='请输入' allowClear />
                    </Form.Item>
                    {index === fields.length - 1 ? (
                      <PlusCircleOutlined
                        className='snames-btn'
                        onClick={() => add()}
                      />
                    ) : (
                      <MinusCircleOutlined
                        className='snames-btn'
                        onClick={() => remove(field.name)}
                      />
                    )}
                  </Form.Item>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export const openAddNameModal = createOpenModal(AddNameModal, 'AddNameModal');

export const closeAddNameModal = createCloseModal(AddNameModal, 'AddNameModal');

import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import {
  modelDetails,
  addModel,
  editModel,
  getModelDropDownData,
  seriesByBrand,
  brandList,
  SeriesListProps,
  BrandListProps,
} from '../../../service/car';

import DropDownSelect from '../../common/select/drop-down-select';

import {
  Form,
  Input,
  Button,
  Spin,
  message,
  Row,
  Col,
  InputNumber,
} from 'antd';

interface Props {
  type: 'add' | 'edit';
}

const ModelEdit: FC<Props> = (props) => {
  const history = useHistory();
  const { id } = useParams() as any;

  const [form] = Form.useForm();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [selectListObj, setSelectListObj] = useState<{
    [key: string]: string[];
  }>({});
  const [catalogSelectList, setCatalogSelectList] = useState<string[]>([]);
  const [brandSelectList, setBrandSelectList] = useState<BrandListProps[]>([]);
  const [seriesSelectList, setSeriesSelectList] = useState<SeriesListProps[]>(
    []
  );

  const getSeriesByBrand = useStableCallback(async (brandId) => {
    if (!brandId) return;
    try {
      const data = await seriesByBrand(brandId);
      setSeriesSelectList(data);
    } catch (err) {}
  });

  const add = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await addModel(form);
      message.success('新增成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const edit = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await editModel(id, form);
      message.success('编辑成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onValuesChange = useStableCallback(async (changedValues) => {
    if (Object.keys(changedValues)[0] === 'brandId') {
      await form.setFieldsValue({ sid: null });
      getSeriesByBrand(changedValues.brandId);
    }
  });

  const onFinish = useStableCallback((values: any) => {
    for (const key in values) {
      if (values[key] === undefined) {
        values[key] = null;
      }
    }
    if (props.type === 'add') {
      add(values);
    } else {
      edit(values);
    }
  });

  useEffect(() => {
    getModelDropDownData()
      .then(setSelectListObj)
      .catch((err) => {});

    getModelDropDownData('catalog')
      .then((res) => setCatalogSelectList(res.catalog))
      .catch((err) => {});

    brandList()
      .then(setBrandSelectList)
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (props.type === 'edit') {
      setSpinLoading(true);
      modelDetails(id)
        .then((res) => {
          form.setFieldsValue(res);
          getSeriesByBrand(res.brandId);
        })
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  }, [props.type, id, form, getSeriesByBrand]);

  return (
    <Spin spinning={spinLoading}>
      <Form
        name='model-edit'
        layout='vertical'
        className='multi-details'
        scrollToFirstError={true}
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        <div className='page-custom'>
          <div className='details-title'>基本信息</div>
          <Row className='details-info'>
            <Col span={6}>
              <Form.Item
                label='车型名称'
                name='name'
                rules={[{ required: true, message: '请输入车型名称' }]}
              >
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='车型代码' name='code'>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='车型分类' name='catalog'>
                <DropDownSelect options={catalogSelectList} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='品牌'
                name='brandId'
                rules={[{ required: true, message: '请选择品牌' }]}
              >
                <DropDownSelect options={brandSelectList} hasId={true} />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item
                label='车系'
                name='sid'
                dependencies={['brandId']}
                rules={[{ required: true, message: '请选择车系' }]}
              >
                <DropDownSelect options={seriesSelectList} hasId={true} />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item
                label='生产厂商'
                name='manufacturer'
                rules={[{ required: true, message: '请选择生产厂商' }]}
              >
                <DropDownSelect options={selectListObj?.manufacturer} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='配置销售车款'
                name='saleMode'
                rules={[{ required: true, message: '请输入配置销售车款' }]}
              >
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='厂商指导价(万元)' name='manuGuidePrice'>
                <InputNumber
                  min={0}
                  precision={2}
                  controls={false}
                  placeholder='请输入'
                />
              </Form.Item>
            </Col>
          </Row>

          <div className='details-title'>详细信息</div>
          <Row className='details-info'>
            <Col span={6}>
              <Form.Item label='级别' name='level'>
                <DropDownSelect options={selectListObj?.level} />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='车体结构' name='bodyStructure'>
                <DropDownSelect options={selectListObj?.bodyStructure} />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='动力类型' name='powerType'>
                <DropDownSelect options={selectListObj?.powerType} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='发动机型号' name='engineCode'>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='发动机类型' name='engineType'>
                <Input
                  placeholder={'请输入'}
                  allowClear
                  disabled={props.type === 'add'}
                />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='排放标准' name='eds'>
                <DropDownSelect options={selectListObj?.eds} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='变速箱类型' name='gearBoxType'>
                <DropDownSelect options={selectListObj?.gearBoxType} />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='变速箱型号' name='gearBoxCode'>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='驱动方式' name='drivingModel'>
                <DropDownSelect options={selectListObj?.drivingModel} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label='分动器(四驱)类型' name='fourWd'>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='电机型号' name='motorCode'>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={3}>
              <Form.Item label='保修政策' name='warrantyPolicy'>
                <DropDownSelect options={selectListObj?.warrantyPolicy} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Row className='details-btn'>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button onClick={() => history.goBack()}>取消</Button>
            <Button type='primary' htmlType='submit' loading={submitLoading}>
              确定
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ModelEdit;

export const cityList = [
  {
    name: '国内',
    children: [
      {
        name: '北京市',
        children: [{ name: '北京市' }, { name: '北京市辖县' }],
      },
      {
        name: '天津市',
        children: [{ name: '天津市' }, { name: '天津市辖县' }],
      },
      {
        name: '河北省',
        children: [
          { name: '石家庄市' },
          { name: '唐山市' },
          { name: '秦皇岛市' },
          { name: '邯郸市' },
          { name: '邢台市' },
          { name: '保定市' },
          { name: '张家口市' },
          { name: '承德市' },
          { name: '沧州市' },
          { name: '廊坊市' },
          { name: '衡水市' },
        ],
      },
      {
        name: '山西省',
        children: [
          { name: '太原市' },
          { name: '大同市' },
          { name: '阳泉市' },
          { name: '长治市' },
          { name: '晋城市' },
          { name: '朔州市' },
          { name: '晋中市' },
          { name: '运城市' },
          { name: '忻州市' },
          { name: '临汾市' },
          { name: '吕梁市' },
        ],
      },
      {
        name: '内蒙古自治区',
        children: [
          { name: '呼和浩特市' },
          { name: '包头市' },
          { name: '乌海市' },
          { name: '赤峰市' },
          { name: '通辽市' },
          { name: '鄂尔多斯市' },
          { name: '呼伦贝尔市' },
          { name: '巴彦淖尔市' },
          { name: '乌兰察布市' },
          { name: '兴安盟' },
          { name: '锡林郭勒盟' },
          { name: '阿拉善盟' },
        ],
      },
      {
        name: '辽宁省',
        children: [
          { name: '沈阳市' },
          { name: '大连市' },
          { name: '鞍山市' },
          { name: '抚顺市' },
          { name: '本溪市' },
          { name: '丹东市' },
          { name: '锦州市' },
          { name: '营口市' },
          { name: '阜新市' },
          { name: '辽阳市' },
          { name: '盘锦市' },
          { name: '铁岭市' },
          { name: '朝阳市' },
          { name: '葫芦岛市' },
        ],
      },
      {
        name: '吉林省',
        children: [
          { name: '长春市' },
          { name: '吉林市' },
          { name: '四平市' },
          { name: '辽源市' },
          { name: '通化市' },
          { name: '白山市' },
          { name: '松原市' },
          { name: '白城市' },
          { name: '延边朝鲜族自治州' },
        ],
      },
      {
        name: '黑龙江省',
        children: [
          { name: '哈尔滨市' },
          { name: '齐齐哈尔市' },
          { name: '鸡西市' },
          { name: '鹤岗市' },
          { name: '双鸭山市' },
          { name: '大庆市' },
          { name: '伊春市' },
          { name: '佳木斯市' },
          { name: '七台河市' },
          { name: '牡丹江市' },
          { name: '黑河市' },
          { name: '绥化市' },
          { name: '大兴安岭地区' },
        ],
      },
      {
        name: '上海市',
        children: [{ name: '上海市' }, { name: '上海市辖县' }],
      },
      {
        name: '江苏省',
        children: [
          { name: '南京市' },
          { name: '无锡市' },
          { name: '徐州市' },
          { name: '常州市' },
          { name: '苏州市' },
          { name: '南通市' },
          { name: '连云港市' },
          { name: '淮安市' },
          { name: '盐城市' },
          { name: '扬州市' },
          { name: '镇江市' },
          { name: '泰州市' },
          { name: '宿迁市' },
        ],
      },
      {
        name: '浙江省',
        children: [
          { name: '杭州市' },
          { name: '宁波市' },
          { name: '温州市' },
          { name: '嘉兴市' },
          { name: '湖州市' },
          { name: '绍兴市' },
          { name: '金华市' },
          { name: '衢州市' },
          { name: '舟山市' },
          { name: '台州市' },
          { name: '丽水市' },
        ],
      },
      {
        name: '安徽省',
        children: [
          { name: '合肥市' },
          { name: '芜湖市' },
          { name: '蚌埠市' },
          { name: '淮南市' },
          { name: '马鞍山市' },
          { name: '淮北市' },
          { name: '铜陵市' },
          { name: '安庆市' },
          { name: '黄山市' },
          { name: '滁州市' },
          { name: '阜阳市' },
          { name: '宿州市' },
          { name: '巢湖市' },
          { name: '六安市' },
          { name: '亳州市' },
          { name: '池州市' },
          { name: '宣城市' },
        ],
      },
      {
        name: '福建省',
        children: [
          { name: '福州市' },
          { name: '厦门市' },
          { name: '莆田市' },
          { name: '三明市' },
          { name: '泉州市' },
          { name: '漳州市' },
          { name: '南平市' },
          { name: '龙岩市' },
          { name: '宁德市' },
        ],
      },
      {
        name: '江西省',
        children: [
          { name: '南昌市' },
          { name: '景德镇市' },
          { name: '萍乡市' },
          { name: '九江市' },
          { name: '新余市' },
          { name: '鹰潭市' },
          { name: '赣州市' },
          { name: '吉安市' },
          { name: '宜春市' },
          { name: '抚州市' },
          { name: '上饶市' },
        ],
      },
      {
        name: '山东省',
        children: [
          { name: '济南市' },
          { name: '青岛市' },
          { name: '淄博市' },
          { name: '枣庄市' },
          { name: '东营市' },
          { name: '烟台市' },
          { name: '潍坊市' },
          { name: '济宁市' },
          { name: '泰安市' },
          { name: '威海市' },
          { name: '日照市' },
          { name: '莱芜市' },
          { name: '临沂市' },
          { name: '德州市' },
          { name: '聊城市' },
          { name: '滨州市' },
          { name: '菏泽市' },
        ],
      },
      {
        name: '河南省',
        children: [
          { name: '郑州市' },
          { name: '开封市' },
          { name: '洛阳市' },
          { name: '平顶山市' },
          { name: '安阳市' },
          { name: '鹤壁市' },
          { name: '新乡市' },
          { name: '焦作市' },
          { name: '濮阳市' },
          { name: '许昌市' },
          { name: '漯河市' },
          { name: '三门峡市' },
          { name: '南阳市' },
          { name: '商丘市' },
          { name: '信阳市' },
          { name: '周口市' },
          { name: '驻马店市' },
          { name: '河南省直辖县级行政区划' },
        ],
      },
      {
        name: '湖北省',
        children: [
          { name: '武汉市' },
          { name: '黄石市' },
          { name: '十堰市' },
          { name: '宜昌市' },
          { name: '襄樊市' },
          { name: '鄂州市' },
          { name: '荆门市' },
          { name: '孝感市' },
          { name: '荆州市' },
          { name: '黄冈市' },
          { name: '咸宁市' },
          { name: '随州市' },
          { name: '恩施土家族苗族自治州' },
          { name: '省直辖行政单位' },
        ],
      },
      {
        name: '湖南省',
        children: [
          { name: '长沙市' },
          { name: '株洲市' },
          { name: '湘潭市' },
          { name: '衡阳市' },
          { name: '邵阳市' },
          { name: '岳阳市' },
          { name: '常德市' },
          { name: '张家界市' },
          { name: '益阳市' },
          { name: '郴州市' },
          { name: '永州市' },
          { name: '怀化市' },
          { name: '娄底市' },
          { name: '湘西土家族苗族自治州' },
        ],
      },
      {
        name: '广东省',
        children: [
          { name: '广州市' },
          { name: '韶关市' },
          { name: '深圳市' },
          { name: '珠海市' },
          { name: '汕头市' },
          { name: '佛山市' },
          { name: '江门市' },
          { name: '湛江市' },
          { name: '茂名市' },
          { name: '肇庆市' },
          { name: '惠州市' },
          { name: '梅州市' },
          { name: '汕尾市' },
          { name: '河源市' },
          { name: '阳江市' },
          { name: '清远市' },
          { name: '东莞市' },
          { name: '中山市' },
          { name: '潮州市' },
          { name: '揭阳市' },
          { name: '云浮市' },
        ],
      },
      {
        name: '广西壮族自治区',
        children: [
          { name: '南宁市' },
          { name: '柳州市' },
          { name: '桂林市' },
          { name: '梧州市' },
          { name: '北海市' },
          { name: '防城港市' },
          { name: '钦州市' },
          { name: '贵港市' },
          { name: '玉林市' },
          { name: '百色市' },
          { name: '贺州市' },
          { name: '河池市' },
          { name: '来宾市' },
          { name: '崇左市' },
        ],
      },
      {
        name: '海南省',
        children: [
          { name: '海口市' },
          { name: '三亚市' },
          { name: '省直辖县级行政单位' },
        ],
      },
      {
        name: '重庆市',
        children: [{ name: '重庆市' }, { name: '重庆市辖县' }],
      },
      {
        name: '四川省',
        children: [
          { name: '成都市' },
          { name: '自贡市' },
          { name: '攀枝花市' },
          { name: '泸州市' },
          { name: '德阳市' },
          { name: '绵阳市' },
          { name: '广元市' },
          { name: '遂宁市' },
          { name: '内江市' },
          { name: '乐山市' },
          { name: '南充市' },
          { name: '眉山市' },
          { name: '宜宾市' },
          { name: '广安市' },
          { name: '达州市' },
          { name: '雅安市' },
          { name: '巴中市' },
          { name: '资阳市' },
          { name: '阿坝藏族羌族自治州' },
          { name: '甘孜藏族自治州' },
          { name: '凉山彝族自治州' },
        ],
      },
      {
        name: '贵州省',
        children: [
          { name: '贵阳市' },
          { name: '六盘水市' },
          { name: '遵义市' },
          { name: '安顺市' },
          { name: '铜仁地区' },
          { name: '黔西南布依族苗族自治州' },
          { name: '毕节地区' },
          { name: '黔东南苗族侗族自治州' },
          { name: '黔南布依族苗族自治州' },
        ],
      },
      {
        name: '云南省',
        children: [
          { name: '昆明市' },
          { name: '曲靖市' },
          { name: '玉溪市' },
          { name: '保山市' },
          { name: '昭通市' },
          { name: '丽江市' },
          { name: '思茅市' },
          { name: '临沧市' },
          { name: '楚雄彝族自治州' },
          { name: '红河哈尼族彝族自治州' },
          { name: '文山壮族苗族自治州' },
          { name: '西双版纳傣族自治州' },
          { name: '大理白族自治州' },
          { name: '德宏傣族景颇族自治州' },
          { name: '怒江傈僳族自治州' },
          { name: '迪庆藏族自治州' },
        ],
      },
      {
        name: '西藏自治区',
        children: [
          { name: '拉萨市' },
          { name: '昌都地区' },
          { name: '山南地区' },
          { name: '日喀则地区' },
          { name: '那曲地区' },
          { name: '阿里地区' },
          { name: '林芝地区' },
        ],
      },
      {
        name: '陕西省',
        children: [
          { name: '西安市' },
          { name: '铜川市' },
          { name: '宝鸡市' },
          { name: '咸阳市' },
          { name: '渭南市' },
          { name: '延安市' },
          { name: '汉中市' },
          { name: '榆林市' },
          { name: '安康市' },
          { name: '商洛市' },
        ],
      },
      {
        name: '甘肃省',
        children: [
          { name: '兰州市' },
          { name: '嘉峪关市' },
          { name: '金昌市' },
          { name: '白银市' },
          { name: '天水市' },
          { name: '武威市' },
          { name: '张掖市' },
          { name: '平凉市' },
          { name: '酒泉市' },
          { name: '庆阳市' },
          { name: '定西市' },
          { name: '陇南市' },
          { name: '临夏回族自治州' },
          { name: '甘南藏族自治州' },
        ],
      },
      {
        name: '青海省',
        children: [
          { name: '西宁市' },
          { name: '海东地区' },
          { name: '海北藏族自治州' },
          { name: '黄南藏族自治州' },
          { name: '海南藏族自治州' },
          { name: '果洛藏族自治州' },
          { name: '玉树藏族自治州' },
          { name: '海西蒙古族藏族自治州' },
        ],
      },
      {
        name: '宁夏回族自治区',
        children: [
          { name: '银川市' },
          { name: '石嘴山市' },
          { name: '吴忠市' },
          { name: '固原市' },
          { name: '中卫市' },
        ],
      },
      {
        name: '新疆维吾尔自治区',
        children: [
          { name: '乌鲁木齐市' },
          { name: '克拉玛依市' },
          { name: '吐鲁番地区' },
          { name: '哈密地区' },
          { name: '昌吉回族自治州' },
          { name: '博尔塔拉蒙古自治州' },
          { name: '巴音郭楞蒙古自治州' },
          { name: '阿克苏地区' },
          { name: '克孜勒苏柯尔克孜自治州' },
          { name: '喀什地区' },
          { name: '和田地区' },
          { name: '伊犁哈萨克自治州' },
          { name: '塔城地区' },
          { name: '阿勒泰地区' },
          { name: '省直辖行政单位' },
        ],
      },
    ],
  },
  {
    name: '国外',
    children: [
      { name: '阿尔巴尼亚' },
      { name: '安道尔' },
      { name: '安哥拉' },
      { name: '安圭拉' },
      { name: '南极洲' },
      { name: '安提瓜和巴布达' },
      { name: '阿根廷' },
      { name: '亚美尼亚' },
      { name: '阿鲁巴' },
      { name: '澳大利亚' },
      { name: '奥地利' },
      { name: '阿塞拜疆' },
      { name: '阿联酋' },
      { name: '巴哈马' },
      { name: '巴林' },
      { name: '孟加拉' },
      { name: '巴巴多斯' },
      { name: '伯利兹' },
      { name: '比利时' },
      { name: '贝宁' },
      { name: '百慕大' },
      { name: '不丹' },
      { name: '玻利维亚' },
      { name: '波黑' },
      { name: '博茨瓦纳' },
      { name: '布维岛' },
      { name: '巴西' },
      { name: '文莱' },
      { name: '保加利亚' },
      { name: '布其纳法索' },
      { name: '布隆迪' },
      { name: '白俄罗斯' },
      { name: '喀麦隆' },
      { name: '加拿大' },
      { name: '佛得角' },
      { name: '中非共和国' },
      { name: '智利' },
      { name: '中国' },
      { name: '圣诞岛' },
      { name: '可可岛' },
      { name: '哥伦比亚' },
      { name: '瑞士' },
      { name: '刚果' },
      { name: '库克群岛' },
      { name: '哥斯达黎加' },
      { name: '象牙海岸' },
      { name: '古巴' },
      { name: '塞浦路斯' },
      { name: '捷克共和国' },
      { name: '丹麦' },
      { name: '吉布提' },
      { name: '多明哥' },
      { name: '德国' },
      { name: '多米尼加' },
      { name: '阿尔及利亚' },
      { name: '厄瓜多尔' },
      { name: '埃及' },
      { name: '西撒哈拉' },
      { name: '西班牙' },
      { name: '爱沙尼亚' },
      { name: '埃塞俄比亚' },
      { name: '斐济' },
      { name: '福克兰群岛' },
      { name: '芬兰' },
      { name: '法国' },
      { name: '密克罗尼西亚' },
      { name: '加蓬' },
      { name: '赤道几内亚' },
      { name: '法属圭亚那' },
      { name: '冈比亚' },
      { name: '格鲁吉亚' },
      { name: '加纳' },
      { name: '直布罗陀' },
      { name: '希腊' },
      { name: '格陵兰' },
      { name: '格林纳达' },
      { name: '瓜德罗普' },
      { name: '关岛' },
      { name: '危地马拉' },
      { name: '几内亚' },
      { name: '几内亚比绍' },
      { name: '圭亚那' },
      { name: '克罗地亚' },
      { name: '海地' },
      { name: '洪都拉斯' },
      { name: '中国香港' },
      { name: '匈牙利' },
      { name: '冰岛' },
      { name: '印度' },
      { name: '印度尼西亚' },
      { name: '伊朗' },
      { name: '伊拉克' },
      { name: '英联邦的印度洋领域' },
      { name: '爱尔兰' },
      { name: '以色列' },
      { name: '意大利' },
      { name: '牙买加' },
      { name: '日本' },
      { name: '约旦' },
      { name: '哈萨克斯坦' },
      { name: '肯尼亚' },
      { name: '基里巴斯' },
      { name: '朝鲜' },
      { name: '韩国' },
      { name: '柬埔寨' },
      { name: '科摩罗' },
      { name: '科威特' },
      { name: '吉尔吉斯斯坦' },
      { name: '开曼群岛' },
      { name: '老挝' },
      { name: '斯里兰卡' },
      { name: '拉托维亚' },
      { name: '黎巴嫩' },
      { name: '莱索托' },
      { name: '利比里亚' },
      { name: '利比亚' },
      { name: '列支敦士登' },
      { name: '立陶宛' },
      { name: '卢森堡' },
      { name: '圣卢西亚' },
      { name: '中国澳门' },
      { name: '马达加斯加' },
      { name: '马拉维' },
      { name: '马来西亚' },
      { name: '马尔代夫' },
      { name: '马里' },
      { name: '马尔他' },
      { name: '马绍尔群岛' },
      { name: '毛里塔尼亚' },
      { name: '毛里求斯' },
      { name: '墨西哥' },
      { name: '摩尔多瓦' },
      { name: '摩纳哥' },
      { name: '蒙古' },
      { name: '摩洛哥' },
      { name: '莫桑比克' },
      { name: '缅甸' },
      { name: '纳米比亚' },
      { name: '瑙鲁' },
      { name: '尼泊尔' },
      { name: '荷兰' },
      { name: '新喀里多尼亚' },
      { name: '新西兰' },
      { name: '尼加拉瓜' },
      { name: '尼日尔' },
      { name: '尼日利亚' },
      { name: '挪威' },
      { name: '阿曼' },
      { name: '巴基斯坦' },
      { name: '法属玻里尼西亚' },
      { name: '帕劳' },
      { name: '巴拿马' },
      { name: '巴布亚新几内亚' },
      { name: '巴拉圭' },
      { name: '秘鲁' },
      { name: '菲律宾' },
      { name: '皮特开恩群岛' },
      { name: '波兰' },
      { name: '葡萄牙' },
      { name: '卡塔尔' },
      { name: '罗马尼亚' },
      { name: '俄罗斯联邦' },
      { name: '卢旺达' },
      { name: '萨尔瓦多' },
      { name: '圣赫勒那' },
      { name: '圣马力诺' },
      { name: '圣多美与普林西比' },
      { name: '沙特阿拉伯' },
      { name: '塞内加尔' },
      { name: '塞舌尔' },
      { name: '塞拉利昂' },
      { name: '新加坡' },
      { name: '斯洛伐克' },
      { name: '斯洛文尼亚' },
      { name: '所罗门群岛' },
      { name: '索马里' },
      { name: '苏丹' },
      { name: '苏里南' },
      { name: '斯威士兰' },
      { name: '瑞典' },
      { name: '叙利亚' },
      { name: '中国台湾' },
      { name: '塔吉克斯坦' },
      { name: '坦桑尼亚' },
      { name: '泰国' },
      { name: '土尔其' },
      { name: '英国' },
      { name: '美国' },
      { name: '乌拉圭' },
      { name: '乌兹别克斯坦' },
      { name: '越南' },
      { name: '不列颠岛(英)' },
      { name: '不列颠岛(美)' },
      { name: '西萨摩亚' },
      { name: '也门' },
      { name: '南斯拉夫' },
      { name: '南非' },
      { name: '扎伊尔' },
      { name: '赞比亚' },
      { name: '津巴布韦' },
    ],
  },
];

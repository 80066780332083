import './index.scss';

import React, { useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { getVINCarSales, modelDetails } from '../../../service/car';

import { Input, Button, Empty, Spin, Row, Col, Image, message } from 'antd';

const VINCodeQuery = () => {
  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [tabSelected, setTabSelected] = useState<number>(0);
  const [VINCode, setVINCode] = useState<string>('');
  const [carSales, setCarSales] = useState<{ [key: string]: any }>({});
  const [carModels, setCarModels] = useState<{ [key: string]: any }>({});

  const tabChange = useStableCallback((value: number) => {
    setTabSelected(value);
    getVINCarModels(value);
  });

  const inputChange = (e: any) => {
    setTimeout(() => {
      setVINCode(e.target.defaultValue);
    });
  };

  const searchVINCode = useStableCallback(async () => {
    if (!/^[a-zA-Z0-9]{17}$/.test(VINCode)) {
      return message.warning('请输入17位仅包含数字或字母的VIN码');
    }
    setSpinLoading(true);
    try {
      const data = await getVINCarSales(VINCode);
      setCarSales(data);
      if (!data.total) return;
      data.saleModels.forEach((item: { [key: string]: any }, index: number) => {
        if (index === 0) {
          setTabSelected(item.carModelId);
          getVINCarModels(item.carModelId);
        }
      });
    } catch (err) {
    } finally {
      setSpinLoading(false);
    }
  });

  const getVINCarModels = useStableCallback(async (id: number) => {
    try {
      const data = await modelDetails(id);
      setCarModels(data);
    } catch (err) {}
  });

  return (
    <div className='vin'>
      <div className='vin-search'>
        <Row gutter={24}>
          <Col span={5}></Col>
          <Col span={11}>
            <Input
              className='search-input'
              placeholder='请输入17位VIN码'
              maxLength={17}
              allowClear
              onChange={inputChange}
            />
          </Col>
          <Col span={3}>
            <Button className='search-btn' type='primary' onClick={searchVINCode}>
              查询
            </Button>
          </Col>
        </Row>
      </div>

      <Spin spinning={spinLoading}>
        {!carSales.total && (
          <Empty style={{ padding: '100px 0' }} />
        )}

        {carSales.total && (
          <div className='vin-list'>
            <Row gutter={24}>
              <Col span={5}>
                <div className='list-tabs'>
                  <div className='total'>查询结果：{carSales.total}条</div>
                  <div className='tabs'>
                    {carSales.saleModels.map((item: { [key: string]: any }, index: number) => (
                      <div
                        key={index}
                        className={tabSelected === item.carModelId ? 'active' : ''}
                        onClick={() => tabChange(item.carModelId)}
                      >
                        {item.saleMode}
                      </div>
                    ))}
                  </div>
                </div>
              </Col>

              <Col span={19}>
                <div className='list-view flex-x m-s-start a-s-start'>
                  <div className='logo'>
                    <Image width={200} src={carModels.spicture} />
                  </div>
                  <div className='infos'>
                    <div className='infos-title'>
                      {carModels.name} {carModels.saleMode}
                    </div>
                    <div className='details flex-x m-s-start a-s-start'>
                      <div className='details-left'>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>品牌：</p>
                          <p className='row-text'>{carModels.brandName}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>生产厂商：</p>
                          <p className='row-text'>{carModels.manufacturer}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>车系：</p>
                          <p className='row-text'>{carModels.seriesName}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>配置销售车款：</p>
                          <p className='row-text'>{carModels.saleMode}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>车型分类：</p>
                          <p className='row-text'>{carModels.catalog}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>车型代码：</p>
                          <p className='row-text'>{carModels.code ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>厂商指导价位：</p>
                          <p className='row-text'>{carModels.manuGuidePrice ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>级别：</p>
                          <p className='row-text'>{carModels.level ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>车体结构：</p>
                          <p className='row-text'>{carModels.bodyStructure ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>动力类型：</p>
                          <p className='row-text'>{carModels.powerType ?? '-'}</p>
                        </div>
                      </div>
                      <div className='details-right'>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>发动机：</p>
                          <p className='row-text'>{carModels.engineType ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>发动机型号：</p>
                          <p className='row-text'>{carModels.engineCode ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>排放标准：</p>
                          <p className='row-text'>{carModels.eds ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>变速箱类型：</p>
                          <p className='row-text'>{carModels.gearBoxType ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>变速箱型号：</p>
                          <p className='row-text'>{carModels.gearBoxCode ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>驱动方式：</p>
                          <p className='row-text'>{carModels.drivingModel ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>分动器(四驱)类型：</p>
                          <p className='row-text'>{carModels.fourWd ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>电机型号：</p>
                          <p className='row-text'>{carModels.motorCode ?? '-'}</p>
                        </div>
                        <div className='left-row flex-x m-s-start a-s-start'>
                          <p className='row-title'>保修政策：</p>
                          <p className='row-text'>{carModels.warrantyPolicy ?? '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default VINCodeQuery;

import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { roleDetails, addRole, editRole } from '../../../service/system';

import { Form, Input, Button, Spin, message } from 'antd';

import RoleEditTreeTransfer from './transfer';

interface Props {
  type: 'add' | 'edit';
}

const RoleEdit: FC<Props> = (props) => {
  const history = useHistory();
  const { id } = useParams() as any;

  const [form] = Form.useForm();

  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const add = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await addRole(form);
      message.success('新增成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const edit = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await editRole(id, form);
      message.success('编辑成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onFinish = useStableCallback((values: any) => {
    if (props.type === 'add') {
      add(values);
    } else {
      edit(values);
    }
  });

  useEffect(() => {
    if (props.type === 'edit') {
      setSpinLoading(true);
      roleDetails(id, 'edit')
        .then(form.setFieldsValue)
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  }, [props.type, id, form]);

  return (
    <Spin spinning={spinLoading}>
      <div className='page-custom common-details'>
        <Form
          name='role-edit'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label='角色名称'
            name='name'
            rules={[{ required: true, message: '请输入角色名称' }]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label='角色代码'
            name='code'
            rules={[
              { required: true, message: '请输入角色代码' },
              () => ({
                validator(_, value) {
                  if (value && /[\u4E00-\u9FA5]/g.test(value)) {
                    return Promise.reject(new Error('角色代码不能包含汉字'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder={'请输入20个字符以内的角色代码'}
              maxLength={20}
              allowClear
            />
          </Form.Item>
          <Form.Item label='备注' name='remarks'>
            <Input.TextArea
              placeholder={'请输入'}
              allowClear
              autoSize={{ minRows: 2, maxRows: 4 }}
            />
          </Form.Item>
          <Form.Item
            label='资源权限'
            name='resourceIdList'
            rules={[{ required: true, message: '请选择资源权限' }]}
          >
            <RoleEditTreeTransfer />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Button onClick={() => history.goBack()}>取消</Button>
            <Button type='primary' htmlType='submit' loading={submitLoading}>
              确定
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default RoleEdit;

import React from 'react';

import { UnitCarsProps } from '../../../../service/unit';

import { ColumnsType } from 'antd/lib/table';

export const GetUnitCarsColumns = (
  renderOpeartor?: (record: UnitCarsProps) => React.ReactElement,
  excluedIds: string[] = []
): ColumnsType<UnitCarsProps> => {
  const list: ColumnsType<UnitCarsProps> = [
    {
      title: '车牌号',
      dataIndex: 'carNo',
      key: 'carNo',
      width: 100,
    },
    {
      title: '车架号',
      dataIndex: 'frameNo',
      key: 'frameNo',
      width: 140,
    },
    {
      title: '品牌',
      dataIndex: 'brandName',
      key: 'brandName',
      width: 80,
    },
    {
      title: '车型名称',
      dataIndex: 'modelName',
      key: 'modelName',
      width: 140,
    },
    {
      title: '车型分类',
      dataIndex: 'modelCatalog',
      key: 'modelCatalog',
      width: 140,
      ellipsis: true,
    },
    {
      title: '动力类型',
      dataIndex: 'powerType',
      key: 'powerType',
      width: 100,
    },
    {
      title: '发动机型号',
      dataIndex: 'engineCode',
      key: 'engineCode',
      width: 100,
    },
    {
      title: '变速箱类型',
      dataIndex: 'gearBoxType',
      key: 'gearBoxType',
      width: 100,
    },
    {
      title: '变速箱型号',
      dataIndex: 'gearBoxCode',
      key: 'gearBoxCode',
      width: 100,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      width: 100,
      ellipsis: true,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 120,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

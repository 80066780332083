import React, { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import {
  addResource,
  editResource,
  resourceParent,
  resourceDetails,
} from '../../../service/system';

import { Form, Input, Button, Radio, Select, Spin, message } from 'antd';
const { Option } = Select;

interface Props {
  type: 'add' | 'edit';
}

const ResourceEdit: FC<Props> = (props) => {
  const history = useHistory();
  const { id } = useParams() as any;

  const [form] = Form.useForm();

  const [parentIdOptions, setParentIdOptions] = useState<
    { [key: string]: any }[]
  >([{ id: 0, name: '无' }]);
  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const getParentIdOptions = useStableCallback(async (type) => {
    const initOption = [{ id: 0, name: '无' }];
    try {
      const data = await resourceParent(type);
      setParentIdOptions(initOption.concat(data));
    } catch (err) {}
  });

  const onValuesChange = useStableCallback(async (changedValues) => {
    if (Object.keys(changedValues)[0] === 'type') {
      if (changedValues.type === 3) {
        form.setFieldsValue({ uri: null });
      } else {
        form.setFieldsValue({ icon: null });
      }
      if (changedValues.type !== 2) {
        form.setFieldsValue({ method: null });
      }
      form.setFieldsValue({ parentId: null });
      getParentIdOptions(changedValues.type);
    }
  });

  const add = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await addResource(form);
      message.success('新增成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const edit = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await editResource(id, form);
      message.success('编辑成功');
      history.goBack();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onFinish = useStableCallback((values: any) => {
    if (props.type === 'add') {
      add(values);
    } else {
      edit(values);
    }
  });

  useEffect(() => {
    if (props.type === 'edit') {
      setSpinLoading(true);
      resourceDetails(id)
        .then((res) => {
          form.setFieldsValue(res);
          getParentIdOptions(res.type);
        })
        .catch((err) => {})
        .finally(() => {
          setSpinLoading(false);
        });
    }
  }, [props.type, id, form, getParentIdOptions]);

  return (
    <Spin spinning={spinLoading}>
      <div className='page-custom common-details'>
        <Form
          name='resource-edit'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <Form.Item
            label='资源名称'
            name='name'
            rules={[{ required: true, message: '请输入资源名称' }]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label='KEY'
            name='code'
            rules={[
              { required: true, message: '请输入KEY' },
              () => ({
                validator(_, value) {
                  if (value && /[\u4E00-\u9FA5]/g.test(value)) {
                    return Promise.reject(new Error('KEY不能包含汉字'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label='类型'
            name='type'
            rules={[{ required: true, message: '请选择类型' }]}
          >
            <Radio.Group>
              <Radio value={1} key={1}>
                菜单
              </Radio>
              <Radio value={2} key={2}>
                API资源
              </Radio>
              <Radio value={3} key={3}>
                资源分类
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label='路径' name='uri'>
            <Input
              disabled={form.getFieldValue('type') === 3}
              placeholder={'请输入'}
              allowClear
            />
          </Form.Item>
          <Form.Item label='图标' name='icon'>
            <Input
              disabled={
                form.getFieldValue('type') === 1 ||
                form.getFieldValue('type') === 2
              }
              placeholder={'请输入'}
              allowClear
            />
          </Form.Item>
          <Form.Item label='访问方式' name='method'>
            <Radio.Group
              disabled={
                form.getFieldValue('type') === 1 ||
                form.getFieldValue('type') === 3
              }
            >
              <Radio value='GET' key={1}>
                GET
              </Radio>
              <Radio value='POST' key={2}>
                POST
              </Radio>
              <Radio value='PUT' key={3}>
                PUT
              </Radio>
              <Radio value='DELETE' key={4}>
                DELETE
              </Radio>
              <Radio value='PATCH' key={5}>
                PATCH
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label='上级资源' name='parentId' dependencies={['type']}>
            <Select placeholder={'请选择'}>
              {parentIdOptions.map((item: any) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Button onClick={() => history.goBack()}>取消</Button>
            <Button type='primary' htmlType='submit' loading={submitLoading}>
              确定
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default ResourceEdit;

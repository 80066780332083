import React, { FC } from 'react';
import UseDeliveryInfoTable from '../../../erp/delivery-info-table';

import { Descriptions } from 'antd';

interface Props {
  logisticsInfo: any;
}

const DetailLogisticsInfo: FC<Props> = ({ logisticsInfo }) => {
  const { table: deliveryInfoTable } = UseDeliveryInfoTable({
    list: logisticsInfo?.logistics && [logisticsInfo.logistics],
    excluedIds: ['outOrderId', 'inOrderId'],
  });

  return (
    <div className='detail-tabpanel detail-erp'>
      <Descriptions column={1} title='出库物流'>
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          {deliveryInfoTable}
        </Descriptions.Item>
      </Descriptions>

      <div className='detail-tabpanel-space'></div>

      <Descriptions column={1} title='入库物流'>
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          {deliveryInfoTable}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DetailLogisticsInfo;

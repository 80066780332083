import React, { FC, useEffect, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { createOpenModal, createCloseModal } from '../../../../utils/modal';
import DropDownSelect from '../../../common/select/drop-down-select';

import {
  brandList,
  BrandListProps,
  unitModelPage,
} from '../../../../service/car';
import { UnitCarsProps } from '../../../../service/unit';

import { ConfigProvider, Modal, Form, Input } from 'antd';

interface Props {
  visible: boolean;
  type: string;
  value?: UnitCarsProps;
  onCancel: () => void;
  onConfirm: (values: any) => void;
}

const AddUnitCarsModal: FC<Props> = ({
  visible,
  type,
  value,
  onCancel,
  onConfirm,
}) => {
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [brandSelectList, setBrandSelectList] = useState<BrandListProps[]>([]);
  const [modelSelectList, setModelSelectList] = useState<any[]>([]);

  const onInnerConfirm = useStableCallback(async () => {
    await form.validateFields();
    const values = form.getFieldsValue(true);
    if (values && values.modelId) {
      modelSelectList.forEach((each) => {
        if (each.id === values.modelId) {
          values.modelName = each.name;
          values.modelCatalog = each.catalog;
          values.engineCode = each.engineCode;
          values.gearBoxCode = each.gearBoxCode;
          values.gearBoxType = each.gearBoxType;
          values.powerType = each.powerType;
        }
      });
    }
    if (values && values.brandId) {
      brandSelectList.forEach((each) => {
        if (each.id === values.brandId) {
          values.brandName = each.name;
        }
      });
    }
    setSubmitLoading(true);
    try {
      onConfirm(values);
      onInnerCancel();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onInnerCancel = useStableCallback(() => {
    form.resetFields();
    onCancel();
  });

  const getModelsByBrand = useStableCallback(async (brandId?: number) => {
    try {
      const data = await unitModelPage(brandId);
      setModelSelectList(data);
    } catch (err) {}
  });

  const onValuesChange = useStableCallback(async (changedValues) => {
    if (Object.keys(changedValues)[0] === 'brandId') {
      await form.setFieldsValue({ modelId: null });
      getModelsByBrand(changedValues.brandId);
    }
  });

  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(value);
    }
  });

  useEffect(() => {
    brandList()
      .then(setBrandSelectList)
      .catch((err) => {});

    getModelsByBrand();
  }, [getModelsByBrand]);

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title='添加车辆'
        centered
        maskClosable={false}
        visible={visible}
        confirmLoading={submitLoading}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          name='add-cars'
          onValuesChange={onValuesChange}
        >
          <Form.Item label='车牌号' name='carNo'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item label='车架号' name='frameNo'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item label='品牌' name='brandId'>
            <DropDownSelect options={brandSelectList} hasId={true} />
          </Form.Item>
          <Form.Item label='车型名称' name='modelId'>
            <DropDownSelect options={modelSelectList} hasId={true} />
          </Form.Item>
          <Form.Item label='备注' name='remark'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export const openAddUnitCarsModal = createOpenModal(
  AddUnitCarsModal,
  'AddUnitCarsModal'
);

export const closeAddUnitCarsModal = createCloseModal(
  AddUnitCarsModal,
  'AddUnitCarsModal'
);

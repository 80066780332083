import React, { FC, useEffect, useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import DropDownSelect from '../../common/select/drop-down-select';
import { getModelDropDownData } from '../../../service/car';

import { Form, Input, Button, Row, Col } from 'antd';

interface Props {
  resetId: number;
  onReset: (value: { [key: string]: any }) => void;
  onFinish: (value: { [key: string]: any }) => void;
}

const SelectProductQuery: FC<Props> = ({ resetId, onReset, onFinish }) => {
  const [form] = Form.useForm();

  const [catalogSelectList, setCatalogSelectList] = useState<string[]>([]);

  const onInnerReset = useStableCallback(() => {
    form.resetFields();
    onReset(form.getFieldsValue(true));
  });

  useEffect(() => {
    onInnerReset();

    getModelDropDownData('catalog')
      .then((res) => setCatalogSelectList(res.catalog))
      .catch((err) => {});
  }, [onInnerReset, resetId]);

  return (
    <Form
      className='common-query'
      style={{
        margin: '-8px -24px 0 -38px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
      }}
      form={form}
      name='sales-product-query'
      layout={'inline'}
      onFinish={onFinish}
    >
      <Row style={{ marginBottom: '-8px' }}>
        <Col span={8}>
          <Form.Item label='商品编号' name='partsCode'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='商品名称' name='partsName'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='车型分类' name='modelCatalog'>
            <DropDownSelect options={catalogSelectList} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='底盘号' name='chassisCode'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='发动机型号' name='engineCode'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='变速箱型号' name='gearBoxCode'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label='电动机型号' name='motorCode'>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item>
            <Button htmlType='button' onClick={onInnerReset}>
              重置
            </Button>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SelectProductQuery;

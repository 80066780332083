import React, {
  FC,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { EditableRow } from '../../../common/editable/editable-row';
import {
  EditableCell,
  InputCell,
  DataType,
  SelectCell,
} from '../../../common/editable/editable-cell';
import {
  openSelectProductModal,
  closeSelectProductModal,
} from '../../../erp/modal/select-product-modal';

import { Button, InputNumber, Select, Table } from 'antd';

interface Props {
  value?: DataType[];
  onChange?: (newValue: DataType[]) => void;
  onRef: any;
  hasSalesId: boolean;
  realBox: any;
  onChangeRealBox: (newValue: any) => void;
}

const ProductInfoTable: FC<Props> = ({
  value = [],
  onChange,
  onRef,
  hasSalesId,
  realBox,
  onChangeRealBox,
}) => {
  useImperativeHandle(onRef, () => ({ handleAddProduct }));

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );

  const handleValueChange = useStableCallback((newValue: DataType[]) => {
    setDataSource(newValue);
    onChange?.(newValue);
  });

  const handleDelete = useStableCallback((partsId: React.Key) => {
    handleValueChange(dataSource.filter((item) => item.partsId !== partsId));
  });

  const handleAdd = useStableCallback((list) => {
    handleValueChange([...dataSource, ...list]);
  });

  const handleChange = useStableCallback(
    (row: DataType, changedKey: keyof DataType) => {
      handleValueChange(
        dataSource.map((each) => {
          if (row.partsId === each.partsId) {
            return {
              ...each,
              ...row,
            };
          }
          return each;
        })
      );
    }
  );

  const handleAddProduct = useStableCallback(() => {
    openSelectProductModal({
      queryType: 11,
      selectedPartsIdList: dataSource?.map((item) => {
        return item.partsId;
      }),
      excluedIds: [
        'outDynamicQtyFormat',
        'inDynamicQtyFormat',
        'latestInActualAmtFormat',
      ],
      title: '添加出库商品',
      visible: true,
      onCancel: closeSelectProductModal,
      onConfirm: handleAdd,
    });
  });

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value);
    }
  }, [value]);

  const columns = React.useMemo(() => {
    const list = [
      {
        title: '商品编号',
        dataIndex: 'partsCode',
        key: 'partsCode',
        render: (text: string, record: any) => {
          return (
            <Link to={`/goods/gfiles/detail/${record.partsId}`} target='_blank'>
              {text}
            </Link>
          );
        },
        fixed: 'left' as any,
        width: 120,
      },
      {
        title: '商品名称',
        dataIndex: 'partsName',
        key: 'partsName',
        fixed: 'left' as any,
        width: 100,
      },
      {
        title: '品牌',
        dataIndex: 'partsBrandName',
        key: 'partsBrandName',
        width: 80,
      },
      {
        title: hasSalesId ? '显示车型' : '适用车型',
        dataIndex: hasSalesId ? 'displayModelName' : 'mname',
        key: 'mname',
        width: 120,
      },
      {
        title: '计量单位',
        dataIndex: 'partsUnit',
        key: 'partsUnit',
        width: 80,
        align: 'center' as any,
      },
      {
        title: '商品规格',
        dataIndex: 'partsSpec',
        key: 'partsSpec',
        width: 80,
      },
      {
        title: '单箱数量',
        dataIndex: 'oneBoxQty',
        key: 'oneBoxQty',
        width: 80,
      },
      {
        title: '预估箱数',
        dataIndex: 'estimateBox',
        key: 'estimateBox',
        width: 80,
        fixed: 'right' as any,
      },
      {
        title: '实际箱数',
        dataIndex: 'realBox',
        key: 'realBox',
        width: 100,
        fixed: 'right' as any,
      },
      {
        title: '仓库/库位(库存数量)',
        dataIndex: 'warehouseId',
        key: 'warehouseId',
        width: 170,
        fixed: 'right' as any,
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'select',
          title: '仓库/库位(库存数量)',
          dataIndex: 'warehouseId',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { record }: any
          ) => {
            return (
              <SelectCell name={name} onChange={onChange}>
                {record.warehouseParts?.map((each: any) => (
                  <Select.Option key={each.id} value={each.id}>
                    {each.warehouseName}/{each.locationName}({each.inventoryQty}
                    )
                  </Select.Option>
                ))}
              </SelectCell>
            );
          },
        }),
      },
      {
        title: '出库数量',
        dataIndex: 'warehouseQty',
        key: 'warehouseQty',
        width: 100,
        fixed: 'right' as any,
        onCell: (record: DataType): any => ({
          record,
          editable: true,
          editType: 'input',
          title: '出库数量',
          dataIndex: 'warehouseQty',
          handleChange,
          renderFormCell: (
            name: keyof DataType,
            onChange: () => void,
            { originCell }: any
          ) => {
            return (
              <InputCell
                name={name}
                onChange={onChange}
                isInputNumber={true}
                numberPrecision={0}
                rules={[{ required: true, message: '请输入' }]}
              >
                {originCell}
              </InputCell>
            );
          },
        }),
      },
    ];

    if (!hasSalesId) {
      list.push({
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 80,
        fixed: 'right' as any,
        render: (text: any, record: any) => (
          <>
            <Button
              type='link'
              danger
              onClick={() => handleDelete(record?.partsId)}
            >
              删除
            </Button>
          </>
        ),
      });
    }

    return list;
  }, [handleChange, handleDelete, hasSalesId]);

  return (
    <div>
      <Table
        className='list-table editable'
        size={'middle'}
        scroll={{ x: '100%' }}
        rowKey='partsId'
        components={components}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        summary={(pageData) => {
          let totalEstimateBox = 0;
          let totalWarehouseQty = 0;

          pageData.forEach(({ estimateBox, warehouseQty }) => {
            totalEstimateBox += Number(estimateBox);
            totalWarehouseQty += Number(warehouseQty);
          });

          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={7}>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  {totalEstimateBox}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  <InputNumber
                    min={0}
                    controls={false}
                    placeholder='请输入'
                    value={realBox}
                    onChange={(value) => onChangeRealBox(value)}
                  />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}>
                  {totalWarehouseQty.toFixed(0)}
                </Table.Summary.Cell>
                {!hasSalesId && (
                  <Table.Summary.Cell index={11}></Table.Summary.Cell>
                )}
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};

export default ProductInfoTable;

import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import OrderStatusLogo from '../../../erp/order-status';
import UseIncomingInfoTable from './incoming-info-table';
import SalesInfo from './sales-info';

import { Descriptions } from 'antd';

interface Props {
  basicInfo: any;
}

const DetailBasicInfo: FC<Props> = ({ basicInfo }) => {
  const incomingInfoTable = UseIncomingInfoTable({ basicInfo });

  const titleExtra = useMemo(() => {
    return (
      <>
        <span>采购单号：{basicInfo?.purchaseOrder?.orderId}</span>
        <Link
          className='detail-tabpanel-link'
          to={`/purchase/porder/detail/${basicInfo?.purchaseOrder?.orderId}`}
        >
          查看详情
        </Link>
      </>
    );
  }, [basicInfo]);

  return (
    <div className='detail-tabpanel detail-erp'>
      <div className='detail-erp-logo'>
        <OrderStatusLogo type={2} status={basicInfo?.orderStatus} />
      </div>

      {basicInfo?.purchaseOrder && (
        <>
          <SalesInfo title={titleExtra} info={basicInfo?.purchaseOrder} />
          <div className='detail-tabpanel-space'></div>
        </>
      )}

      <Descriptions title='入库信息'>
        <Descriptions.Item label='入库人'>
          {basicInfo?.inboundContacts || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='入库部门'>
          {basicInfo?.inboundDept || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='入库日期'>
          {basicInfo?.orderDate || '-'}
        </Descriptions.Item>
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
          span={3}
        >
          {incomingInfoTable}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DetailBasicInfo;

import React from 'react';
import { Link } from 'react-router-dom';
import { erpOrderStatusMap2 } from '../../../../utils/definition';
import { InboundOrderListProps } from '../../../../service/warehouse';

import { ColumnsType } from 'antd/lib/table';

export const GetInboundOrderListColumns = (
  renderOpeartor?: (record: InboundOrderListProps) => React.ReactElement,
  excluedIds: string[] = []
): ColumnsType<InboundOrderListProps> => {
  const list: ColumnsType<InboundOrderListProps> = [
    {
      title: '入库单号',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (text: string, record: any) => (
        <>
          {record.orderStatus !== 0 ? (
            <Link to={`/warehouse/inbound/detail/${record.orderId}`}>
              {text}
            </Link>
          ) : (
            <div>{text}</div>
          )}
        </>
      ),
      fixed: 'left' as any,
      width: 140,
    },
    {
      title: '采购单号',
      dataIndex: 'porderId',
      key: 'porderId',
      render: (text: string, record: any) => (
        <>
          {text ? (
            <Link to={`/purchase/porder/detail/${record.porderId}`}>
              {text}
            </Link>
          ) : (
            <div>-</div>
          )}
        </>
      ),
      fixed: 'left' as any,
      width: 140,
    },
    {
      title: '供应商',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 120,
    },
    {
      title: '入库人',
      dataIndex: 'inboundContacts',
      key: 'inboundContacts',
      width: 100,
    },
    {
      title: '采购员',
      dataIndex: 'purchaseMan',
      key: 'purchaseMan',
      width: 100,
    },
    {
      title: '入库数量',
      dataIndex: 'inboundQtyFormat',
      key: 'inboundQtyFormat',
      width: 100,
    },
    {
      title: '实际箱数',
      dataIndex: 'realBox',
      key: 'realBox',
      width: 100,
    },
    {
      title: '发票单位名称',
      dataIndex: 'taxCompany',
      key: 'taxCompany',
      width: 140,
    },
    {
      title: '订单状态',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      width: 120,
      render: (status: number) => (
        <div className='flex-x m-s-start table-status'>
          <div
            className={`iconfont ${erpOrderStatusMap2[status]?.[2]}`}
            style={{ color: erpOrderStatusMap2[status]?.[1] }}
          ></div>
          <div>{erpOrderStatusMap2[status]?.[0]}</div>
        </div>
      ),
    },
    {
      title: '物流单位',
      dataIndex: 'logisticsUnitName',
      key: 'logisticsUnitName',
      width: 100,
    },
    {
      title: '物流单号',
      dataIndex: 'logisticsNo',
      key: 'logisticsNo',
      width: 120,
    },
    {
      title: '入库日期',
      dataIndex: 'orderDate',
      key: 'orderDate',
      width: 120,
    },
  ].filter((each) => !excluedIds.includes(each.dataIndex));

  if (renderOpeartor) {
    list.push({
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right' as any,
      width: 140,
      render: (text: any, record: any) => renderOpeartor(record),
    });
  }

  return list;
};

import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { addFilesPhoto, filesPhotoDetails } from '../../../../service/goods';
import { photoTypeMap } from '../../../../utils/definition';

import UploadPhoto from './photo-upload';

import { Form, Button, Row, Col, Select, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;

interface Props {
  type: string;
  partsId: number;
  photoChange?: () => void;
  photoSave: () => void;
}

const EditPhoto: FC<Props> = ({ type, partsId, photoChange, photoSave }) => {
  const location = useLocation();
  const history = useHistory();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [photoInfo, setPhotoInfo] = useState<any>(null);
  const [partsFiles, setPartsFiles] = useState<any[]>([
    { type: null, urls: [] },
  ]);

  const addRow = useStableCallback(() => {
    const obj = { type: null, urls: [] };
    setPartsFiles([...partsFiles, obj]);
  });

  const deleteRow = useStableCallback((index: number) => {
    const partsFilesCopy = [...partsFiles];
    partsFilesCopy.splice(index, 1);
    setPartsFiles(partsFilesCopy);
  });

  const changeType = useStableCallback((val, index) => {
    setPartsFiles(
      partsFiles.map((_each, _index) => {
        if (index === _index) {
          return {
            ..._each,
            type: val,
          };
        }
        return _each;
      })
    );
  });

  const getImageUrl = useStableCallback((urls, index) => {
    setPartsFiles(
      partsFiles.map((_each, _index) => {
        if (index === _index) {
          return {
            ..._each,
            urls,
          };
        }
        return _each;
      })
    );
  });

  const onFinish = useStableCallback(async () => {
    if (
      partsFiles.some((each) => !each.type || !each.urls || !each.urls.length)
    )
      return message.warning('请先完善信息，类型和图片/视频不能为空');
    setSubmitLoading(true);
    try {
      await addFilesPhoto({ partsFiles, partsId });
      photoSave();
      message.success('保存成功');
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    if (!photoInfo) return;
    setPartsFiles(photoInfo?.partsFiles);
  }, [photoInfo]);

  useEffect(() => {
    const activeMenuRegex =
      type === 'add'
        ? /^\/goods\/gfiles\/add\/(\w+)$/
        : /^\/goods\/gfiles\/edit\/\w+\/(\w+)$/;
    const res = activeMenuRegex.exec(location.pathname);
    if (res?.[1] !== 'photo' || !partsId) return;
    filesPhotoDetails(partsId)
      .then(setPhotoInfo)
      .catch((err) => {});
  }, [location.pathname, partsId, type]);

  return (
    <div>
      <Form
        className='step-edit-photo'
        onValuesChange={photoChange}
        onFinish={onFinish}
      >
        <div className='common-setting-title flex-x m-s-between'>
          <p>上传图片/视频</p>
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            添加
          </Button>
        </div>
        <div className='common-setting-content'>
          <Row gutter={64}>
            {partsFiles?.map((item, index) => (
              <Col span={12} key={index}>
                <Form.Item label='图片/视频类型：'>
                  <Select
                    placeholder={'请选择'}
                    allowClear
                    value={item.type}
                    onChange={(val) => changeType(val, index)}
                  >
                    {Object.entries(photoTypeMap).map(([key, value]) => (
                      <Option key={key} value={Number(key)}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                  <div
                    className='flex-x a-s-end m-s-start'
                    style={{ marginTop: '16px' }}
                  >
                    <UploadPhoto
                      uploadData={{ type: item.type, importOperation: false }}
                      imageUrls={item.urls}
                      getImageUrl={(urls) => getImageUrl(urls, index)}
                    />
                    <Button type='link' onClick={() => deleteRow(index)}>
                      删除
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            ))}
          </Row>
        </div>

        <div className='common-setting-buttons flex-x m-s-end minauto'>
          <Button onClick={history.goBack}>取消</Button>
          <Button type='primary' htmlType='submit' loading={submitLoading}>
            保存
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditPhoto;

import React, { FC, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { createCloseModal, createOpenModal } from '../../../../utils/modal';
import { encrypt } from '../../../../utils/common';
import { loginKey } from '../../../../service/user';
import { editUserPassword } from '../../../../service/system';

import { ConfigProvider, Form, Input, message, Modal } from 'antd';

interface Props {
  userInfo: { [key: string]: any };
  visible: boolean;
  logout: () => void;
  onCancel: () => void;
}

const EditPasswordModal: FC<Props> = ({
  userInfo,
  visible,
  logout,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const onConfirm = useStableCallback(async () => {
    await form.validateFields();
    setLoading(true);
    const params = {
      password: '',
      userKey: '',
    };
    try {
      // 对密码进行AES加密
      const authKey = await loginKey();
      const password = form.getFieldValue('password');
      params.password = encrypt(password, authKey.data.tokenKey);
      params.userKey = authKey.data.userKey;

      // 修改密码
      await editUserPassword(userInfo?.id, params);
      message.success('修改成功，请退出重新登录');
      onCancel();
      setTimeout(() => {
        logout();
      }, 2000);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title='修改密码'
        width={480}
        centered
        destroyOnClose
        maskClosable={false}
        visible={visible}
        confirmLoading={loading}
        onCancel={onCancel}
        onOk={onConfirm}
      >
        <Form
          form={form}
          name='edit-password'
          labelCol={{ flex: '95px' }}
          preserve={false}
        >
          <Form.Item
            name='username'
            label='用户名'
            initialValue={userInfo?.username}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name='password'
            label='新密码'
            rules={[{ required: true, message: '请输入新密码' }]}
          >
            <Input.Password placeholder='请输入' autoComplete='new-password' />
          </Form.Item>
          <Form.Item
            name='confirmPassword'
            label='确认新密码'
            dependencies={['password']}
            rules={[
              { required: true, message: '请确认新密码' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('您输入的两个密码不一致'));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder='请输入'
              autoComplete='confirm-password'
            />
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export const openEditPasswordModal = createOpenModal(
  EditPasswordModal,
  'EditPasswordModal'
);

export const closeEditPasswordModal = createCloseModal(
  EditPasswordModal,
  'EditPasswordModal'
);

import UseDetailTable from '../../../common/table/detail-table';

interface Params {
  list: { [key: string]: any }[];
}

const UsePartsSuppliersTable = ({ list }: Params) => {
  const columns = [
    {
      title: '供应商编号',
      dataIndex: 'supplierCode',
      key: 'supplierCode',
    },
    {
      title: '供应商名称',
      dataIndex: 'supplierName',
      key: 'supplierName',
    },
    {
      title: '供应商商品编号',
      dataIndex: 'supplierPartsCode',
      key: 'supplierPartsCode',
    },
  ];

  const table = UseDetailTable({ columns, list });

  return {
    table,
  };
};

export default UsePartsSuppliersTable;

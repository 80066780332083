import React, { FC, useState } from 'react';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { preFetch } from '../../../service/fetch';

import { Upload, message, Button } from 'antd';

interface Props {
  uploadData: { [key: string]: any };
}

const BatchUploadImg: FC<Props> = ({ uploadData }) => {
  const [fileLists, setFileLists] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFetch, setIsFetch] = useState<boolean>(false);

  const beforeUpload = (file: any, fileList: any) => {
    setIsFetch(false);
    setFileLists(fileList.filter((each: any) => each.type.includes('image')));
    return false;
  };

  const onChange = useStableCallback(async () => {
    if (isFetch) return;
    setIsFetch(true);
    setLoading(true);
    const formData: any = new FormData();
    Object.entries(uploadData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    fileLists.forEach((each: any) => {
      formData.append('files', each);
    });
    try {
      await preFetch('/file/upload/images', { method: 'post', body: formData });
      message.success('导入成功');
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  return (
    <Upload
      name='files'
      directory
      maxCount={1}
      showUploadList={false}
      disabled={loading}
      fileList={fileLists}
      beforeUpload={beforeUpload}
      onChange={onChange}
    >
      <Button loading={loading}>批量导入图片</Button>
    </Upload>
  );
};

export default BatchUploadImg;

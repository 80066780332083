import { preFetch } from './fetch';

// 商品档案
export interface FilesListProps {
  brandId: number;
  brandName: string;
  dealerPrice: number;
  id: number;
  locality: string;
  localitys: string[];
  location: string;
  minOrderQty: string;
  modelCatalog: string;
  modelCode: string;
  name: string;
  onSale: boolean;
  oneBoxQty: string;
  ownerPrice: number;
  packing: string;
  partsCode: string;
  partsProp: string;
  purchasePrice4s: number;
  repairPrice: number;
  saleMode: string;
  salePrice4s: number;
  spec: string;
  standardDataId: number;
  standardDataName: string;
  standardModelId: number;
  standardModelName: string;
  tenantId: number;
  tenantName: string;
  unit: string;
  updatedTime: string;
  warrantyPolicy: string;
}

export interface FilesSubListProps {
  brandId: number;
  brandName: string;
  id: number;
  latestPurchasePrice: number;
  location: string;
  name: string;
  oneBoxQty: string;
  partsCode: string;
  standardDataId: number;
  standardDataName: string;
}

export const filesPage = async (query: {
  [key: string]: any;
}): Promise<{ records: FilesListProps[]; total: number }> => {
  const success = await preFetch('/erp-parts/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const filesSubPage = async (query: {
  [key: string]: any;
}): Promise<{ records: FilesSubListProps[]; total: number }> => {
  const success = await preFetch('/erp-parts/page/sub', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const getFilesDropDownData = async (
  code: string = ''
): Promise<{ [key: string]: string[] }> => {
  const success = await preFetch('/erp-parts/drop-down-box-data', {
    method: 'GET',
    query: { code },
  });
  return success.data;
};

export const getFilesPartsSupplier = async (
  tenantId: number | null = null
): Promise<{ [key: string]: any }[]> => {
  const success = await preFetch('/erp-parts-supplier/list', {
    method: 'GET',
    query: { tenantId },
  });
  return success.data;
};

export const deleteFiles = async (ids: number[]) => {
  const success = await preFetch('/erp-parts/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

export const onSaleFiles = async (id: number): Promise<boolean> => {
  const success = await preFetch(`/erp-parts/on-sale/${id}`, {
    method: 'PUT',
  });
  return success;
};

export const offSaleFiles = async (id: number): Promise<boolean> => {
  const success = await preFetch(`/erp-parts/off-sale/${id}`, {
    method: 'PUT',
  });
  return success;
};

export const filesBasicDetails = async (id: number) => {
  const success = await preFetch(`/erp-parts/basic-info/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addFilesBasic = async (body: any): Promise<number> => {
  const success = await preFetch('/erp-parts', {
    method: 'POST',
    body,
  });
  return success.data;
};

export const editFilesBasic = async (
  id: number,
  body: any
): Promise<boolean> => {
  const success = await preFetch(`/erp-parts/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const filesPriceDetails = async (partsId: number) => {
  const success = await preFetch(
    `/erp-parts-price-record/replace_prices/${partsId}`,
    {
      method: 'GET',
    }
  );
  return success.data;
};

export const addFilesPrice = async (body: any): Promise<boolean> => {
  const success = await preFetch('/erp-parts-price-record', {
    method: 'POST',
    body,
  });
  return success;
};

export const filesHistoryPrice = async (
  query: any
): Promise<{ [key: string]: any }[]> => {
  const success = await preFetch('/erp-parts-price-record/history_price', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const filesPhotoDetails = async (partsId: number) => {
  const success = await preFetch('/erp-parts-file/list', {
    method: 'GET',
    query: { partsId },
  });
  return success.data;
};

export const addFilesPhoto = async (body: any): Promise<boolean> => {
  const success = await preFetch('/erp-parts-file', {
    method: 'POST',
    body,
  });
  return success;
};

// 品牌管理
export interface GbrandListProps {
  id: number;
  fname: string;
  logo: string;
  name: string;
  createdTime: string;
  updatedTime: string;
}

export interface GbrandInfoProps {
  name: string;
  logo: string;
  fname?: string;
}

export const gbrandPage = async (query: {
  [key: string]: any;
}): Promise<{ records: GbrandListProps[]; total: number }> => {
  const success = await preFetch('/erp-parts-brand/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const gbrandList = async (
  tenantId: number
): Promise<GbrandListProps[]> => {
  const success = await preFetch('/erp-parts-brand/list', {
    method: 'GET',
    query: { tenantId },
  });
  return success.data;
};

export const gbrandDetails = async (id: number): Promise<GbrandListProps> => {
  const success = await preFetch(`/erp-parts-brand/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addGBrand = async (body: GbrandInfoProps) => {
  const success = await preFetch('/erp-parts-brand', {
    method: 'POST',
    body,
  });
  return success;
};

export const editGBrand = async (id: number, body: GbrandInfoProps) => {
  const success = await preFetch(`/erp-parts-brand/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const deleteGBrand = async (ids: number[]) => {
  const success = await preFetch('/erp-parts-brand/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

// 分类管理
export interface CategoryTreeProps {
  id: number;
  level: number;
  name: string;
  code: string;
  createdTime: string;
  updatedTime: string;
  extra?: string;
  pid?: number;
  children?: CategoryTreeProps[];
}

export interface CategoryInfoProps {
  code: string;
  level: number;
  name: string;
  pid?: number;
}

export const categoryTree = async (): Promise<CategoryTreeProps[]> => {
  const success = await preFetch('/erp-parts-category/tree', {
    method: 'GET',
  });
  return success.data;
};

export const categoryList = async (
  level: number
): Promise<CategoryTreeProps[]> => {
  const success = await preFetch('/erp-parts-category/list', {
    method: 'GET',
    query: { level },
  });
  return success.data;
};

export const categoryParent = async (
  level: number
): Promise<CategoryTreeProps[]> => {
  const success = await preFetch('/erp-parts-category/parent', {
    method: 'GET',
    query: { level },
  });
  return success.data;
};

export const addCategory = async (body: CategoryInfoProps) => {
  const success = await preFetch('/erp-parts-category', {
    method: 'POST',
    body,
  });
  return success;
};

export const editCategoryName = async (id: number, name: string) => {
  const success = await preFetch(`/erp-parts-category/name/${id}`, {
    method: 'PUT',
    query: { name },
  });
  return success;
};

export const deleteCategory = async (id: number) => {
  const success = await preFetch(`/erp-parts-category/${id}`, {
    method: 'DELETE',
  });
  return success;
};

export interface StandardDataProps {
  id: number;
  category1: number;
  category2: number;
  category3: number;
  categoryId: number;
  categoryLevel: number;
  categoryName: string;
  name: string;
  fname: string;
  sname?: string;
  snames: string[];
  createdTime: string;
  updatedTime: string;
  extra?: string;
}

export interface StandardDataInfoProps {
  categoryId: number;
  categoryLevel: number;
  name: string;
  fname?: string;
  snames?: string[];
}

export const standardDataPage = async (query: {
  [key: string]: any;
}): Promise<{ records: StandardDataProps[]; total: number }> => {
  const success = await preFetch('/erp-parts-standard-data/page', {
    method: 'GET',
    query,
  });
  return success.data;
};

export const getFilesStandardData = async (
  id: number | string = ''
): Promise<StandardDataProps> => {
  const success = await preFetch(`/erp-parts-standard-data/${id}`, {
    method: 'GET',
  });
  return success.data;
};

export const addStandardData = async (body: StandardDataInfoProps) => {
  const success = await preFetch('/erp-parts-standard-data', {
    method: 'POST',
    body,
  });
  return success;
};

export const editStandardData = async (
  id: number,
  body: StandardDataInfoProps
) => {
  const success = await preFetch(`/erp-parts-standard-data/${id}`, {
    method: 'PUT',
    body,
  });
  return success;
};

export const deleteStandardData = async (ids: number[]) => {
  const success = await preFetch('/erp-parts-standard-data/batch', {
    method: 'DELETE',
    body: ids,
  });
  return success;
};

import React, { FC } from 'react';
import OrderStatusLogo from '../../../erp/order-status';
import BillingInfo from '../../../erp/billing-info';

interface Props {
  invoiceInfo: any;
}

const DetailInvoiceInfo: FC<Props> = ({ invoiceInfo }) => {
  return (
    <div className='detail-tabpanel detail-erp'>
      <div className='detail-erp-logo'>
        <OrderStatusLogo type={1} status={invoiceInfo?.orderStatus} />
      </div>

      <BillingInfo billingInfo={invoiceInfo?.billingInfo} />
    </div>
  );
};

export default DetailInvoiceInfo;

import React, { FC } from 'react';
import UseCarModelsTable from './car-models-table';
import UsePartsSuppliersTable from './parts-suppliers-table';
import UsePartsReplacesTable from './parts-replaces-table';

import { Descriptions } from 'antd';

interface Props {
  basicInfo: any;
}

const DetailBasicInfo: FC<Props> = ({ basicInfo }) => {
  const { table: carModelsTable } = UseCarModelsTable({
    list: basicInfo?.carModels,
  });
  const { table: partsSuppliersTable } = UsePartsSuppliersTable({
    list: basicInfo?.partsSuppliers,
  });
  const { table: partsReplacesTable } = UsePartsReplacesTable({
    list: basicInfo?.partsReplaces,
    excluedIds: ['name', 'latestPurchasePrice', 'assembleNum'],
  });
  const { table: BomsTable } = UsePartsReplacesTable({
    list: basicInfo?.boms,
  });

  return (
    <div className='detail-tabpanel'>
      <Descriptions title='基本信息'>
        <Descriptions.Item label='商品编号'>
          {basicInfo?.partsCode || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='商品名称'>
          {basicInfo?.name || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='商品属性'>
          {basicInfo?.partsProp || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='品牌'>
          {basicInfo?.brandName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='品牌属性'>
          {basicInfo?.brandProp || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='标准名称'>
          {basicInfo?.standardDataName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='商品分类'>
          {basicInfo?.partsCategoryName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='标准型号'>
          {basicInfo?.standardModelName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='适用位置'>
          {basicInfo?.location || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='产地'>
          {basicInfo?.localitys
            ? basicInfo?.localitys?.map((each: string) => each).join('/')
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label='质保政策'>
          {basicInfo?.warrantyPolicy || '-'}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title='规格及包装'>
        <Descriptions.Item label='计量单位'>
          {basicInfo?.unit || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='商品规格'>
          {basicInfo?.spec || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='单箱数量'>
          {basicInfo?.oneBoxQty || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='最小起订量'>
          {basicInfo?.minOrderQty || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='包装要求'>
          {basicInfo?.packing || '-'}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        column={1}
        title='适用车型'
        style={{ border: 'none', paddingBottom: 0 }}
      >
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          {carModelsTable}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        column={1}
        title='商品供应商'
        style={{ border: 'none', paddingBottom: 0 }}
      >
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          {partsSuppliersTable}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        column={1}
        title='商品替换'
        style={{ border: 'none', paddingBottom: 0 }}
      >
        <Descriptions.Item
          contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
        >
          {partsReplacesTable}
        </Descriptions.Item>
      </Descriptions>

      {basicInfo?.partsProp !== '组件' && (
        <Descriptions
          column={1}
          title='BOM'
          style={{ border: 'none', paddingBottom: 0 }}
        >
          <Descriptions.Item
            contentStyle={{ overflow: 'hidden', display: 'inline-block' }}
          >
            {BomsTable}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
};

export default DetailBasicInfo;

import React, { FC, useState } from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import { createOpenModal, createCloseModal } from '../../../utils/modal';
import { categoryLevel } from '../../../utils/definition';
import DropDownSelect from '../../common/select/drop-down-select';

import {
  categoryParent,
  addCategory,
  CategoryTreeProps,
} from '../../../service/goods';

import { ConfigProvider, Modal, Form, Input, Select, message } from 'antd';
const { Option } = Select;

interface Props {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const AddCategoryModal: FC<Props> = ({ visible, onCancel, onConfirm }) => {
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [parentCategory, setParentCategory] = useState<CategoryTreeProps[]>([]);

  const getParentCategory = useStableCallback(async (level: number) => {
    await form.setFieldsValue({ pid: null });
    try {
      const res = await categoryParent(level);
      setParentCategory(res);
    } catch (err) {}
  });

  const onInnerConfirm = useStableCallback(async () => {
    await form.validateFields();
    const values = form.getFieldsValue(true);
    setSubmitLoading(true);
    try {
      await addCategory(values);
      message.success('新增成功');
      onInnerCancel();
      onConfirm();
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onInnerCancel = useStableCallback(() => {
    form.resetFields();
    onCancel();
  });

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title='新增分类'
        centered
        maskClosable={false}
        visible={visible}
        confirmLoading={submitLoading}
        onOk={onInnerConfirm}
        onCancel={onInnerCancel}
      >
        <Form form={form} labelCol={{ span: 4 }} name='add-category'>
          <Form.Item name='code' label='分类ID' rules={[{ required: true }]}>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item name='name' label='分类名称' rules={[{ required: true }]}>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item name='level' label='分类级别' rules={[{ required: true }]}>
            <Select
              placeholder='请选择'
              allowClear
              onChange={getParentCategory}
            >
              {Object.entries(categoryLevel).map(([key, value]) => (
                <Option value={key} key={key}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.level !== currentValues.level
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('level') === '2' ||
              getFieldValue('level') === '3' ? (
                <Form.Item
                  name='pid'
                  label='父级分类'
                  rules={[{ required: true }]}
                >
                  <DropDownSelect options={parentCategory} hasId={true} />
                </Form.Item>
              ) : null
            }
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export const openAddCategoryModal = createOpenModal(
  AddCategoryModal,
  'AddCategoryModal'
);

export const closeAddCategoryModal = createCloseModal(
  AddCategoryModal,
  'AddCategoryModal'
);

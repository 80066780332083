import React, { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableCallback } from '../../../hooks/use-stable-callback';
import TableBatchDelete from '../../common/table/table-batch-delete';
import UseTable from '../../common/table/use-table';
import { GetPurchaseOrderListColumns } from './tables/files-list-columns';
import {
  closePurchaseOrderPrintModal,
  openPurchaseOrderPrintModal,
} from './print';
import {
  canInbound,
  deletePurchaseOrder,
  PurchaseOrderListProps,
  purchaseOrderPage,
} from '../../../service/purchase';

import { Button, Divider, Modal, message } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

interface Props {
  searchForm?: { [key: string]: any };
}

const PurchaseOrderTable: FC<Props> = ({ searchForm }) => {
  const history = useHistory();

  const getList = useStableCallback(async (current: number, size?: number) => {
    const listPageData = {
      current: current,
      size: size ?? pageData.size,
    };
    setLoading(true);
    setPageData(listPageData);
    try {
      if (searchForm && searchForm.billDate) {
        searchForm.startDate = searchForm.billDate[0]?.format('YYYY-MM-DD');
        searchForm.endDate = searchForm.billDate[1]?.format('YYYY-MM-DD');
      }
      let params = { ...searchForm, ...listPageData };
      const { records, total } = await purchaseOrderPage(params);
      setList(records);
      setListTotal(total);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  const onSelectChange = useStableCallback((selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  });

  const onSelectCheckbox = useStableCallback((record: any) => ({
    disabled: record.orderStatus !== 0,
  }));

  const addRow = useStableCallback(() => {
    history.push('/purchase/porder/add');
  });

  const editRow = useStableCallback((record: any) => {
    history.push(`/purchase/porder/edit/${record.orderId}`);
  });

  const auditRow = useStableCallback((record: any) => {
    history.push(`/purchase/porder/detail/${record.orderId}`);
  });

  const inboundRow = useStableCallback(async (record: any) => {
    const res = await canInbound(record.orderId);
    if (!res) {
      return message.error('暂无可入库商品，请至入库单查看');
    }
    history.push(`/warehouse/inbound/add/${record.orderId}`);
  });

  const deleteRow = useStableCallback((record: any) => {
    Modal.confirm({
      title: `确定删除采购单【${record.orderId}】吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch([record.orderId]);
      },
    });
  });

  const deleteBatchRow = useStableCallback((ids: number[]) => {
    if (!ids || !ids.length) return message.warning('请先选择需删除的采购单');
    Modal.confirm({
      title: `确定删除选中的采购单吗？`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '该操作无法撤销，请谨慎操作。',
      maskClosable: false,
      onOk: () => {
        deleteBatch(ids);
      },
    });
  });

  const deleteBatch = useStableCallback(async (ids: number[]) => {
    try {
      await deletePurchaseOrder(ids);
      message.success('删除成功');
      setSelectedRowKeys([]);
      getList(1);
    } catch (err) {}
  });

  const printRow = useStableCallback((record: any) => {
    openPurchaseOrderPrintModal({
      visible: true,
      orderId: record.orderId,
      onCancel: closePurchaseOrderPrintModal,
    });
  });

  const renderOpeartor = useStableCallback((record: any) => {
    return (
      <>
        {record.orderStatus === 0 && (
          <>
            <Button type='link' onClick={() => editRow(record)}>
              编辑
            </Button>
            <Divider type='vertical' />
            <Button type='link' danger onClick={() => deleteRow(record)}>
              删除
            </Button>
          </>
        )}
        {record.orderStatus === 10 && (
          <Button type='link' onClick={() => auditRow(record)}>
            审核
          </Button>
        )}
        {[20, 21].includes(record.orderStatus) && (
          <>
            <Button type='link' onClick={() => inboundRow(record)}>
              入库
            </Button>
            <Divider type='vertical' />
            <Button type='link' onClick={() => printRow(record)}>
              打印
            </Button>
          </>
        )}
        {[22, 30].includes(record.orderStatus) && (
          <Button type='link' onClick={() => printRow(record)}>
            打印
          </Button>
        )}
      </>
    );
  });

  const columns: ColumnsType<PurchaseOrderListProps> = useMemo(
    () => GetPurchaseOrderListColumns(renderOpeartor),
    [renderOpeartor]
  );

  const { selectedRowKeys, setSelectedRowKeys, tableSelect } = TableBatchDelete(
    { deleteBatchRow }
  );

  const { table, setLoading, setList, setListTotal, pageData, setPageData } =
    UseTable({
      columns,
      getList,
      rowKey: 'orderId',
      selectedRowKeys,
      onSelectChange,
      onSelectCheckbox,
    });

  useEffect(() => {
    getList(1);
  }, [getList, searchForm]);

  return (
    <div className='page-custom common-list'>
      <div className='list-header flex-x m-s-between'>
        <div className='header-title'>采购单列表</div>
        <div className='header-operate flex-x'>
          <Button type='primary' icon={<PlusOutlined />} onClick={addRow}>
            新增
          </Button>
          <ReloadOutlined className='refresh' onClick={() => getList(1)} />
        </div>
      </div>

      {selectedRowKeys.length > 0 && tableSelect}

      {table}
    </div>
  );
};

export default PurchaseOrderTable;

import React, { FC } from 'react';
import ReactPlayer from 'react-player';
import { photoTypeMap } from '../../../../utils/definition';
import { fileValidate } from '../../../../utils/validate';
import DetailPhotoPreview from './photo-preview';

import { Descriptions, Empty } from 'antd';

interface Props {
  photoInfo: any;
}

const DetailPhoto: FC<Props> = ({ photoInfo }) => {
  return (
    <div className='detail-tabpanel detail-tabpanel-photo'>
      {photoInfo?.partsFiles ? (
        <Descriptions title='图片/视频' layout='vertical' column={4}>
          {photoInfo?.partsFiles.map((item: any, index: number) => (
            <Descriptions.Item label={photoTypeMap[item?.type]} key={index}>
              {fileValidate(item?.urls[0]) === 'image' ? (
                <DetailPhotoPreview url={item?.urls} />
              ) : (
                <ReactPlayer
                  width={120}
                  height={120}
                  url={item?.urls}
                  playing
                  loop
                  controls
                />
              )}
            </Descriptions.Item>
          ))}
        </Descriptions>
      ) : (
        <Empty style={{ padding: '100px 0' }} />
      )}
    </div>
  );
};

export default DetailPhoto;

import React, { FC, useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { mangLocal } from '../../../../utils/common';
import { SelectContext } from './select-context';

import DropDownSelect from '../../../common/select/drop-down-select';
import CityCascader from '../../../common/select/city-cascader';
import CarModelsTable from '../tables/car-models';
import PartsSuppliersTable from '../tables/parts-suppliers';
import ProductReplaceTable from '../tables/product-replace';
import BOMTable from '../tables/bom';

import {
  gbrandList,
  GbrandListProps,
  getFilesDropDownData,
  getFilesStandardData,
  addFilesBasic,
  editFilesBasic,
} from '../../../../service/goods';

import { Form, Input, Button, Row, Col, message, InputNumber } from 'antd';

interface Props {
  type: string;
  basicInfo?: any;
  basicChange?: () => void;
  basicSave: (id: number) => void;
}

const EditBasicInfo: FC<Props> = ({
  type,
  basicInfo,
  basicChange,
  basicSave,
}) => {
  const history = useHistory();
  const params: any = useParams();
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [filesSelectListObj, setFilesSelectListObj] = useState<{
    [key: string]: string[];
  }>({});
  const [gbrandSelectList, setGbrandSelelctList] = useState<GbrandListProps[]>(
    []
  );
  const selectContextValue = useMemo(
    () => ({ filesSelectListObj, gbrandSelectList }),
    [filesSelectListObj, gbrandSelectList]
  );

  const getCategoryByStandard = useStableCallback(async (standardDataId) => {
    if (!standardDataId) return;
    try {
      const data = await getFilesStandardData(standardDataId);
      form.setFieldsValue({ partsCategory: data.categoryName });
    } catch (err) {}
  });

  const onValuesChange = useStableCallback(async (changedValues) => {
    if (Object.keys(changedValues)[0] === 'standardDataId') {
      await form.setFieldsValue({ partsCategory: null });
      getCategoryByStandard(changedValues.standardDataId);
    }
    basicChange?.();
  });

  const add = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      const data = await addFilesBasic(form);
      basicSave(data);
      message.success('新增成功，请继续下一步');
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const edit = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await editFilesBasic(params?.id, form);
      basicSave(params?.id);
      message.success('编辑成功，请继续下一步');
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onFinish = useStableCallback((values: any) => {
    for (const key in values) {
      if (values[key] === undefined) {
        values[key] = null;
      }
    }
    if (values.carModels && values.carModels.length) {
      values.modelIds = values.carModels.map((each: any) => {
        return each.id;
      });
    }
    if (values.partsReplaces && values.partsReplaces.length) {
      values.partsReplaceIds = values.partsReplaces.map((each: any) => {
        return each.id;
      });
    }
    if (type === 'add') {
      add(values);
    } else {
      edit(values);
    }
  });

  useEffect(() => {
    if (type === 'add') {
      form.setFieldsValue({ partsProp: '成品' });
    }

    if (!basicInfo) return;
    form.setFieldsValue(basicInfo);
  }, [basicInfo, form, type]);

  useEffect(() => {
    const { tenantIds } = mangLocal('userInfo');
    gbrandList(tenantIds?.[0] || '')
      .then(setGbrandSelelctList)
      .catch((err) => {});

    getFilesDropDownData()
      .then(setFilesSelectListObj)
      .catch((err) => {});
  }, []);

  return (
    <SelectContext.Provider value={selectContextValue}>
      <div>
        <Form
          layout='vertical'
          labelAlign='left'
          scrollToFirstError={true}
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <div className='common-setting-title'>基本信息</div>
          <div className='common-setting-content'>
            <Row gutter={64}>
              <Col span={8}>
                <Form.Item
                  label='商品编号'
                  name='partsCode'
                  rules={[{ required: true, message: '请输入商品编号' }]}
                >
                  <Input placeholder='请输入' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='商品名称'
                  name='name'
                  rules={[{ required: true, message: '请输入商品名称' }]}
                >
                  <Input placeholder='请输入' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='商品属性'
                  name='partsProp'
                  rules={[{ required: true, message: '请选择商品属性' }]}
                >
                  <DropDownSelect options={filesSelectListObj?.partsProp} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='品牌'
                  name='brandId'
                  rules={[{ required: true, message: '请选择品牌' }]}
                >
                  <DropDownSelect options={gbrandSelectList} hasId={true} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='品牌属性'
                  name='brandProp'
                  rules={[{ required: true, message: '请选择品牌属性' }]}
                >
                  <DropDownSelect options={filesSelectListObj?.brandProp} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='标准名称' name='standardDataId'>
                  <DropDownSelect
                    options={filesSelectListObj?.standardDatas}
                    hasId={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='商品分类'
                  name='partsCategory'
                  dependencies={['standardDataId']}
                >
                  <Input placeholder='请输入' allowClear disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='标准型号' name='standardModelId'>
                  <DropDownSelect
                    options={filesSelectListObj?.standardModels}
                    hasId={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='适用位置' name='location'>
                  <Input placeholder='请输入' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='产地' name='localitys'>
                  <CityCascader type='all' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='质保政策' name='warrantyPolicy'>
                  <DropDownSelect
                    options={filesSelectListObj?.warrantyPolicy}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className='common-setting-title'>规格及包装</div>
          <div className='common-setting-content'>
            <Row gutter={64}>
              <Col span={8}>
                <Form.Item
                  label='计量单位'
                  name='unit'
                  rules={[{ required: true, message: '请选择计量单位' }]}
                >
                  <DropDownSelect options={filesSelectListObj?.unit} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='商品规格' name='spec'>
                  <Input placeholder='请输入' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='单箱数量' name='oneBoxQty'>
                  <InputNumber min={0} controls={false} placeholder='请输入' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='最小起订量' name='minOrderQty'>
                  <InputNumber min={0} controls={false} placeholder='请输入' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='包装要求' name='packing'>
                  <Input placeholder='请输入' allowClear />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className='common-setting-title'>适用车型</div>
          <div className='common-setting-content'>
            <Form.Item name='carModels'>
              <CarModelsTable />
            </Form.Item>
          </div>

          <div className='common-setting-title'>商品供应商</div>
          <div className='common-setting-content'>
            <Form.Item
              validateStatus={'custom-error' as ''}
              name='partsSuppliers'
              rules={[
                {
                  message: '请先选择供应商（请勿重复添加）',
                  validator: async (rule, value) => {
                    if (value?.some((each: any) => !each.supplierId)) {
                      throw new Error(rule?.message as string);
                    }
                  },
                },
              ]}
            >
              <PartsSuppliersTable />
            </Form.Item>
          </div>

          <div className='common-setting-title' style={{ paddingTop: '19px' }}>
            商品替换
          </div>
          <div className='common-setting-content'>
            <Form.Item name='partsReplaces'>
              <ProductReplaceTable />
            </Form.Item>
          </div>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.partsProp !== currentValues.partsProp
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('partsProp') !== '组件' ? (
                <>
                  <div className='common-setting-title'>BOM</div>
                  <div className='common-setting-content'>
                    <Form.Item name='boms'>
                      <BOMTable />
                    </Form.Item>
                  </div>
                </>
              ) : null
            }
          </Form.Item>

          <div className='common-setting-buttons flex-x m-s-end minauto'>
            <Button onClick={history.goBack}>取消</Button>
            <Button type='primary' htmlType='submit' loading={submitLoading}>
              保存
            </Button>
          </div>
        </Form>
      </div>
    </SelectContext.Provider>
  );
};

export default EditBasicInfo;

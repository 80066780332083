import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStableCallback } from '../../../../hooks/use-stable-callback';
import { unitAttributes, unitTypes } from '../../../../utils/definition';
import UnitContactsTable from '../tables/contacts';
import CityCascader from '../../../common/select/city-cascader';

import {
  addUnitBasic,
  editUnitBasic,
  UnitBasicInfoProps,
} from '../../../../service/unit';

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Select,
  InputNumber,
} from 'antd';
const { TextArea } = Input;
const { Option } = Select;

interface Props {
  type: string;
  basicInfo?: UnitBasicInfoProps;
  basicChange?: () => void;
  basicSave: (id: number, form: UnitBasicInfoProps) => void;
}

const EditUnitBasicInfo: FC<Props> = ({
  type,
  basicInfo,
  basicChange,
  basicSave,
}) => {
  const history = useHistory();
  const params: any = useParams();
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const add = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      const data = await addUnitBasic(form);
      basicSave(data, form);
      message.success(
        form?.propCode !== '客户' ? '新增成功' : '新增成功，请继续下一步'
      );
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const edit = useStableCallback(async (form) => {
    setSubmitLoading(true);
    try {
      await editUnitBasic(params?.id, form);
      basicSave(params?.id, form);
      message.success(
        form?.propCode !== '客户' ? '编辑成功' : '编辑成功，请继续下一步'
      );
    } catch (err) {
    } finally {
      setSubmitLoading(false);
    }
  });

  const onFinish = useStableCallback((values: any) => {
    const form = { ...values };
    if (!form.unitContacts || !form.unitContacts.length)
      return message.warning('请至少添加一条联系人信息');

    for (const key in form) {
      if (form[key] === undefined) {
        form[key] = null;
      }
    }

    form?.unitContacts?.forEach((each: any) => {
      if (each?.id?.toString().includes('add')) {
        each.id = null;
      }
    });

    if (type === 'add') {
      add(form);
    } else {
      edit(form);
    }
  });

  useEffect(() => {
    if (!basicInfo) return;
    form.setFieldsValue(basicInfo);
  }, [basicInfo, form, type]);

  return (
    <div>
      <Form
        layout='vertical'
        labelAlign='left'
        scrollToFirstError={true}
        form={form}
        onValuesChange={basicChange}
        onFinish={onFinish}
      >
        <div className='common-setting-title'>基本信息</div>
        <div className='common-setting-content'>
          <Row gutter={64}>
            <Col span={8}>
              <Form.Item
                label='单位名称'
                name='name'
                rules={[{ required: true, message: '请输入单位名称' }]}
              >
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='单位属性'
                name='propCode'
                rules={[{ required: true, message: '请选择单位属性' }]}
              >
                <Select
                  placeholder='请选择'
                  allowClear
                  onChange={() => form.setFieldsValue({ catalog: null })}
                >
                  {Object.entries(unitAttributes).map(([key, value]) => (
                    <Option value={value} key={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.propCode !== currentValues.propCode
                }
              >
                {({ getFieldValue }) => (
                  <Form.Item
                    label='属性分类'
                    name='catalog'
                    rules={[{ required: true, message: '请选择属性分类' }]}
                  >
                    <Select placeholder='请选择' allowClear>
                      {Object.entries(
                        unitTypes[getFieldValue('propCode')] || []
                      ).map(([key, value]) => (
                        <Option value={value} key={key}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='单位别名' name='fname'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='单位所在地' name='locations'>
                <CityCascader />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='详细地址' name='address'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='网页' name='url'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={12}>
              <Form.Item label='备注' name='remark'>
                <TextArea
                  rows={4}
                  placeholder='请输入'
                  allowClear
                  maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className='common-setting-title'>联系人</div>
        <div className='common-setting-content'>
          <Form.Item name='unitContacts'>
            <UnitContactsTable source='unit' />
          </Form.Item>
        </div>

        <div className='common-setting-title'>银行信息</div>
        <div className='common-setting-content'>
          <Row gutter={64}>
            <Col span={8}>
              <Form.Item label='开户名称' name='accName'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='开户账号' name='accBankNo'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='开户行' name='accBank'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='备注' name='accRemark'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className='common-setting-title'>开票信息</div>
        <div className='common-setting-content'>
          <Row gutter={64}>
            <Col span={8}>
              <Form.Item label='单位名称' name='taxCompany'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='税号' name='taxNo'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='开户账号' name='taxBankNo'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='开户行' name='taxBank'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='开票地址' name='taxAddress'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='电话' name='taxPhone'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='税率(%)' name='taxRate'>
                <InputNumber
                  min={0}
                  max={100}
                  precision={2}
                  controls={false}
                  placeholder='请输入'
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='备注' name='taxRemark'>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col span={12}>
              <Form.Item label='发票收寄信息' name='taxMailingAddress'>
                <TextArea
                  rows={4}
                  placeholder='示例：张三，138xxxx0000，上海省上海市xxxx'
                  allowClear
                  maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className='common-setting-buttons flex-x m-s-end minauto'>
          <Button onClick={history.goBack}>取消</Button>
          <Button type='primary' htmlType='submit' loading={submitLoading}>
            保存
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditUnitBasicInfo;
